import
{ Box, Divider, HStack, Pressable, ScrollView, Text, VStack, CloseIcon, Button, Input, Tooltip, } from 'native-base';
import React, { FC, useRef } from 'react';
import { AVButtonRoundedFull } from 'components/common/AVButtons';
import AVCancel from 'components/common/AVCancel';
import { StyleSheet } from 'react-native';
import './style.css';
import * as AV from 'AVCore/AVCore';
import { useWindowDimension } from 'Utility/WindowDimensions';
import { Loc } from 'App/Loc';
import { Media, Person } from '../../AVCore/AVCore';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { setUser, setEntitydata, setMediaList } from '../../Redux/Actions/userActions';
import { setFeeds } from '../../Redux/Actions/feedsAction';
import PhotosAlbum from '../Home/Photoalbum';
import AlbumList from '../Home/AlbumList';
import MP3Icon from '../../assets/images/MP3ICON.png';
import AVInputtext from '../../components/AVInputtext';
import
{
    EditIcon, AddIcon,
} from '../../assets/icons/icon-assets';
import helping from '../../assets/images/helping.png';
import inform from '../../assets/images/inform.png';
import settings from '../../Platform/settings.json';
import _ from 'lodash';
import PreviewAlbums from 'components/common/PreviewAlbums';
import { VideoIcon } from 'assets/icons/icon-assets';

interface Props
{
    isOwner : any | undefined,
    Parentdata : any | undefined,
    isPhotoPage : any | undefined,
    isDocumentPage : any | undefined,
    albumlist : any | undefined,
    filesListany : any | undefined,
    setalbumlist : any | undefined,
    setfilesList : any | undefined,
    filesList : any | undefined,
    editAccess : any | undefined,
}

const Createalbumpopup : FC<Props> = ( {
    isOwner,
    Parentdata,
    isPhotoPage,
    isDocumentPage,
    albumlist,
    filesList,
    setalbumlist,
    setfilesList,
    editAccess
} ) =>
{
    const dimensions = useWindowDimension();
    const [ showAddMedia, setShowAddMedia ] = React.useState( false );
    const [ images, setImages ] = React.useState<any>( [] );
    const [ videos, setvideos ] = React.useState<any>( [] );
    const [ media, setMedia ] = React.useState<any>( [] );
    const [ picker, setPicker ] = React.useState( false );
    const [ text, setText ] = React.useState( '' );
    const [ isLoading, setIsLoading ] = React.useState( false );
    const [ isImageProcessing, setIsImageProcessing ] = React.useState( false );
    const [ alertMessage, setAlertMessage ] = React.useState<any>( '' );
    const [ successMessage, setSuccessMessage ] = React.useState( null );
    const imageInputRef : any = React.useRef( null );
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
    const [ Img, setImg ] = React.useState<any>( [] );
    const [ video, setvio ] = React.useState<any>( [] );
    const [ isActive, setIsActive ] = React.useState( false );
    const [ popup, setpopup ] = React.useState( false );
    const [ album, setalbum ] = React.useState( false );
    const [ albumdata, setalbumdata ] = React.useState<any>();
    const [ albumdatamedia, setalbumdatamedia ] = React.useState();
    const [ isEdit, setisEdit ] = React.useState( false );
    const [ isDone, setIsDone ] = React.useState( false );
    const [ pdf, setpdf ] = React.useState<any>( [] );
    const isMobile = dimensions?.width <= 500 || false;
    const userfeeds = useSelector( ( state : RootStateOrAny ) => state.feeds.feeds[ 0 ] );
	const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
	const MediaListItem = useSelector( ( state : RootStateOrAny ) => state.user.mediaListItem )
    const dispatch = useDispatch();
    console.log( "Createalbum" )
    console.log( "text", text )
    const [ showPreview, setShowPreview ] = React.useState( false );
    const [ singleImage, setSingleImage ] = React.useState();
    const [ allImages, setShowAllImage ] = React.useState( [] );

	const handleDrop = async e => {
		e.preventDefault();

		const doc = e.dataTransfer.files;
		const fileInput: any = imageInputRef.current;
		let files: any = Array.from(doc);
		// Check file extensions
		const updatedFiles = files.map(file => {
			const fileName = file.name;
			if (fileName.toLowerCase().endsWith(".jfif")) {
				const updatedFileName = fileName.replace(/\.\w+$/, ".jpg");
				const updatedFile = new File([file], updatedFileName, { type: "image/jpeg" });
				return updatedFile;
			} else {
				return file;
			}
		});
		files = updatedFiles;
		let m = "";
		console.log(files);
		if (files.length > 20) {
			m = Loc.currentLang?.alertMessage?.maxUploadFiles || "You cannot upload more than 20 files per post";
			setAlertMessage(m);
			return;
		}
		setShowAddMedia(false);
		setPicker(false);
		files.forEach((img: any) => {
			const reader = new FileReader();
			reader.readAsDataURL(img);
			reader.onload = (readerEvent: any) => {
				if (img.type.startsWith("image")) setImages(images => [...images, readerEvent.target.result]);
				else setvideos(videos => [...videos, readerEvent.target.result]);
			};
		});
		setIsImageProcessing(true);
		const data = await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region);
		if (data !== null) {
			setMedia(prev => [...prev, ...data]);
			if (
				data?.filter(x => x?.type?.startsWith("application"))?.length > 0 ||
				data?.filter(x => x?.type?.startsWith("text"))?.length > 0 ||
				data?.filter(x => x?.type?.startsWith("audio"))?.length > 0
			) {
				data?.forEach(docs => {
					if (docs?.type?.startsWith("application")) {
						setpdf(pdf => [...pdf, docs]);
					}
				});
			}
			data?.forEach(img => {
				if (img?.type?.startsWith("image")) setImg(images => [...images, img]);
				else if (img?.type?.startsWith("video")) setvio(videos => [...videos, img]);
			});
		}

		fileInput.value = null;
		setIsImageProcessing(false);
	};

	const handleDragOver = e => {
		e.preventDefault();
	};

	const handleImages = async (e: any) => {
		const fileInput: any = imageInputRef.current;
		let files: any = [];
		files = Array.from(e?.target?.files);
		const updatedFiles = files.map(file => {
			const fileName = file.name;
			if (fileName.toLowerCase().endsWith(".jfif")) {
				const updatedFileName = fileName.replace(/\.\w+$/, ".jpg");
				const updatedFile = new File([file], updatedFileName, { type: "image/jpeg" });
				return updatedFile;
			} else {
				return file;
			}
		});
		files = updatedFiles;
		let m = "";
		console.log(files);
		if (files.length > 20) {
			m = Loc.currentLang?.alertMessage?.maxUploadFiles || "You cannot upload more than 20 files per post";
			setAlertMessage(m);
			return;
		}
		setShowAddMedia(false);
		setPicker(false);
		files.forEach((img: any) => {
			const reader = new FileReader();
			reader.readAsDataURL(img);
			reader.onload = (readerEvent: any) => {
				if (img.type.startsWith("image")) setImages(images => [...images, readerEvent.target.result]);
				else setvideos(videos => [...videos, readerEvent.target.result]);
			};
		});
		setIsImageProcessing(true);
		const data = await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region);
		if (data?.length > 0) {
			setMedia(prev => [...prev, ...data]);
			if (
				data?.filter(x => x?.type?.startsWith("application"))?.length > 0 ||
				data?.filter(x => x?.type?.startsWith("text"))?.length > 0 ||
				data?.filter(x => x?.type?.startsWith("audio"))?.length > 0
			) {
				data?.forEach(docs => {
					if (docs?.type?.startsWith("application") || docs?.type?.startsWith("text")) {
						setpdf(pdf => [...pdf, docs]);
					}
				});
			}
			data?.forEach(img => {
				if (img?.type?.startsWith("image")) setImg(images => [...images, img]);
				else if (img?.type?.startsWith("video")) setvio(videos => [...videos, img]);
			});
		}

		fileInput.value = null;
		setIsImageProcessing(false);
	};

	const addMediaToList = async (list, mediaItem) => {
		let mediaparam = {
			source: AV.AVEntity.getFk(list),
			item: mediaItem,
		};
		let savemedia : any = await AV.Media.addManyToList( mediaparam );
        if ( MediaListItem?.filter( x => x?.id === savemedia?.id )?.length > 0 )
        {
            MediaListItem[ MediaListItem.indexOf( MediaListItem?.find( x => x?.id === savemedia?.id ) ) ] = savemedia;
        }
        else
        {
            MediaListItem?.push( savemedia );
        }
        dispatch( setMediaList( MediaListItem ) )
		if (savemedia) {
			setalbumdata(savemedia);
			setalbumdatamedia(savemedia?.media?.filter(x => x?.deleted !== true));
		}
		updateListAndState(isDocumentPage ? filesList : albumlist, savemedia);
	};

	const list_to_tree = list => {
		var map = {},
			node: any,
			roots: any = [],
			i;

		for (i = 0; i < list?.length; i += 1) {
			map[list[i]?.id] = i; // initialize the map
			if (list[i]?.children?.length === 0 || !list[i]?.children) list[i]["children"] = []; // initialize the children
			if (list[i]?.galleryPost?.length === 0 || !list[i]?.galleryPost) list[i]["galleryPost"] = [];
		}

		for (i = 0; i < list?.length; i += 1) {
			node = list[i];
			if (node.parentPost !== undefined) {
				if (
					list[map[node.parentPost]]?.gallery?.items?.filter(x => x === node.id)?.length > 0 &&
					node.gallery == undefined
				) {
					let excitingpost = list[map[node.parentPost]]?.galleryPost?.filter(x => x?.id === node?.id);
					if (excitingpost?.length === 0 || excitingpost === undefined)
						list[map[node.parentPost]]?.galleryPost.push(node);
				} else {
					let excitingcomment = list[map[node.parentPost]]?.children?.filter(x => x?.id === node?.id);
					if (excitingcomment?.length === 0 || excitingcomment === undefined)
						list[map[node.parentPost]]?.children.push(node);
				}
			} else {
				roots.push(node);
			}
		}
		return roots;
	};

	const handlePublish = async () => {
		if (media.length > 20) {
			setAlertMessage("You cannot upload more than 20 files per post!");
			return;
		}

		setAlertMessage(null);
		setIsLoading(true);

		if (!album) {
			let mediaparam: any = {
				owner: AV.AVBase.getFk(Parentdata ? Parentdata : Person.self()),
				name: text,
				listType: isPhotoPage ? "Album" : "Folder",
				type: "MediaList",
				region: currentUser?.region,
			};
			let val = await AV.Media.createList(mediaparam);
			if (isDocumentPage) {
				filesList.push(val);
				filesList = _.uniqBy(filesList, "id");
				setfilesList(filesList);
			} else {
				albumlist.push(val);
				albumlist = _.uniqBy(albumlist, "id");
				setalbumlist(albumlist);
			}

			if (media?.length > 0) {
				await addMediaToList(val, media);
			}
		} else {
			await addMediaToList(albumdata, media);
		}
		let owner = Parentdata ? Parentdata : Person.self();
		let userfeed = userfeeds?.filter(x => x?.owner?.id == owner?.id);
		let param: AV.IFeedParam = {
			feed: { id: owner?.id, pk: owner?.id, type: "Feed" },
			forward: true,
			startDate: new Date(userfeed?.length > 0 ? userfeed[0]?.lastUpdated : 0),
		};
		if (param?.feed?.id) {
			let response: any = await AV.Feed.getFeed(param);
			let feeds: AV.IFeed[] = [];
			let PostList = await AV.Feed.getPostList(response);
			if (PostList?.length > 0) {
				let Post: any = await AV.Feed.getPosts(AV.AVEntity.getFk(response), PostList?.slice(0, 5));
				if (Post?.length > 0) feeds = feeds?.concat(Post);
			}
			var concatenatedarray = userfeeds?.concat(feeds);
			let updatefeeds = list_to_tree(concatenatedarray);
			updatefeeds?.sort(function (a: any, b: any) {
				let c: any = new Date(b.createdate);
				let d: any = new Date(a.createdate);
				return c - d;
			});
			updatefeeds = _.uniqBy(updatefeeds, "id");
			dispatch(setFeeds(updatefeeds));
			setpopup(false);
			setMedia([]);
			setImages([]);
			setImg([]);
			setvio([]);
			setText("");
			setpdf([]);
			setvideos([]);
			setIsLoading(false);
		}
	};

    const menuRefs = useRef(null);

    useOutsideAlerter( menuRefs );
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {
                    setisEdit( false );
                }
            }
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }

    const mobile = dimensions?.width < 600 || false;

    const handleEditAlbum = async () =>
    {
        setisEdit( !isEdit );
        if ( album ) setText( albumdata?.title ? albumdata?.title : albumdata?.name );
    };

    const handleSaveList = async () =>
    {
        setIsLoading( true );
        albumdata.name = text;
        var savemedia = await AV.Media.saveList( albumdata );
        if ( savemedia )
        {
            updateListAndState( isDocumentPage ? filesList : albumlist, savemedia );
            setIsLoading( false );
            setisEdit( false );
        }
    };

    const updateListAndState = ( list, updatedList ) =>
    {
        if ( isDocumentPage )
        {
            let excitingpost = list?.find( ( x ) => x?.id === updatedList?.id );
            if ( excitingpost )
            {
                list[ list.indexOf( excitingpost ) ] = updatedList;
                setfilesList( list );
                if ( currentUser?.id == Parentdata?.id )
                {
                    currentUser.files = list;
                    dispatch( setUser( currentUser ) );
                }
                else
                {
                    entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === Parentdata?.id ) ) ] = Parentdata;
                    dispatch( setEntitydata( entitydatalist ) );
                }
            }
        } else
        {
            let excitingpost = list?.find( ( x ) => x?.id === updatedList?.id );
            if ( excitingpost )
            {
                list = list?.filter( ( x ) => x?.id !== updatedList?.id );
                list.push( updatedList );
                setalbumlist( list );
                if ( currentUser?.id == Parentdata?.id )
                {
                    currentUser.albums = list;
                    dispatch( setUser( currentUser ) );
                }
                else
                {
                    entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === Parentdata?.id ) ) ] = Parentdata;
                    dispatch( setEntitydata( entitydatalist ) );
                }
                console.log( "list", list );
            }

        }
    };

    const AllAlbumsLink = () => (
        <HStack right={ '25px' }>
            <Pressable
                onPress={ () =>
                {
                    setalbum( false );
                    setText( '' );
                    setalbumdata( '' );
                    setisEdit( false );
                } }
            >
                <Text
                    fontWeight={ '500' }
                    marginLeft={ '15px' }
                    fontSize={ '16px' }
                    py={ '12px' }
                    color={ '#00A7BC' }
                >
                    { isPhotoPage ? Loc.currentLang.menu.allAlbums : Loc.currentLang.menu.allFolders }
                </Text>
            </Pressable>
        </HStack>
    );

    const AlbumActions = ( { items, isOwner, setpopup, setpdf, handleEditAlbum, setisEdit } ) => (
        <HStack alignItems={ 'flex-start' }>
            { album && !isEdit && isOwner && <AllAlbumsLink /> }
            { isPhotoPage  ? (
                <Tooltip
                    label={ Loc.currentLang.helpText.person.photoTab }
                    w={ 200 }
                    openDelay={ 500 }
                >
                    <Box
                        marginRight={ '10px' }
                        p={ '9px' }
                        shadow={ 1 }
                        bgColor={ 'gray.100' }
                        display={ albumlist?.length === 0 ? 'none' : '' }
                        rounded={ 'full' }
                    >
                        <img src={ helping } height={ '17px' } width={ '17px' } />
                    </Box>
                </Tooltip>

            ) : (
                <Tooltip
                    label={ Loc.currentLang.helpText.person.documentTab }
                    w={ 200 }
                    openDelay={ 500 }
                >
                    <Box
                        marginRight={ '10px' }
                        p={ '9px' }
                        shadow={ 1 }
                        bgColor={ 'gray.100' }
                        display={ filesList?.length === 0 ? 'none' : '' }
                        rounded={ 'full' }
                    >
                        <img src={ helping } height={ '17px' } width={ '17px' } />
                    </Box>
                </Tooltip>
            ) }
            { items?.length > 0 && isOwner && !isEdit && ( Parentdata?.type == 'Event' ? editAccess : true) && (
                <Pressable
                    backgroundColor={ 'AVColor.liteBg' }
                    rounded={ 'full' }
                    p={ '10px' }
                    marginRight={ '10px' }
                    _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                    onPress={ () =>
                    {
                        handleEditAlbum();
                    } }
                >
                    <EditIcon size={ '20px' } />
                </Pressable>
            ) }
            { isOwner && !isEdit && (Parentdata?.type == 'Event' ? editAccess:true) && (
                <Button
                    p={ '10px' }
                    rounded={ 'full' }
                    bgColor={ 'gray.100' }
                    _hover={ { bgColor: 'gray.50' } }
                    shadow={ 1 }
                    onPress={ () =>
                    {
                        setpopup( true );
                        setText("")
                        setpdf([]);
                        setMedia([]);
                        setImages([]);
                        setvideos([]);
                    } }
                >
                    <AddIcon size={ '20px' } color={ '#32AE78' } />
                </Button>
            ) }
           
            { album && !isEdit && !isOwner && (
                <Button
                    rounded='full'
                    backgroundColor={ '#EEEEEE' }
                    paddingX={ '20px' }
                    height={ '37px' }
                    width={ '100px' }
                    onPress={ () =>
                    {
                        setalbum( false );
                        setText( '' );
                        setalbumdata( '' );
                        setisEdit( false );
                    } }
                >
                    <Text fontSize={ '14px' }>{ Loc.currentLang.menu.cancel }</Text>
                </Button>
            ) }
            { isEdit && (
                <Button
                    backgroundColor={ 'rgb(50, 174, 120)' }
                    rounded={ 'full' }
                    paddingX={ '10px' }
                    paddingY={ '15px' }
                    onPress={ () => setisEdit( false ) }
                >
                    Cancel
                </Button>
            ) }
        </HStack>

    );

	return (
		<>
			<Box
				zIndex={-3}
				width={"100%"}
				minHeight={"340px"}
				marginRight={4}
				p={2}
				h="auto"
				shadow={1}
				bgColor="#fff"
				rounded="lg">
				<VStack>
					<VStack marginTop={album && isEdit ? "5px" : ""} marginBottom={album && isEdit ? "15px" : ""}>
						<HStack alignItems={"center"} justifyContent={"space-between"}>
							{album && isEdit ? (
								<Input
									left={"15px"}
									fontSize={"18px"}
									width={"360px"}
									variant="underlined"
									value={text}
									onChangeText={e => setText(e)}
									//@ts-ignore
									rightElement={
										text?.trim()?.length > 0 && (
											<Pressable onPress={() => setText("")}>
												<CloseIcon size="12px" color="AVColor.secondaryText" />
											</Pressable>
										)
									}
								/>
							) : (
								<Text
									fontWeight={"500"}
									marginLeft={"15px"}
									fontSize={"22px"}
									color={"AVColor.primary"}
									py={"12px"}
									width={"230px"}
									ellipsizeMode="tail"
									numberOfLines={1}>
									{album
										? albumdata?.title
											? albumdata?.title
											: albumdata?.name
										: isPhotoPage
											? Loc.currentLang.menu.albums
											: Loc.currentLang.menu.files}
								</Text>
							)}

							{album && isEdit && (
								<HStack justifyContent={"center"} space={3}>
									<Button
										rounded="full"
										backgroundColor={"#EEEEEE"}
										paddingX={"20px"}
										height={"37px"}
										width={"100px"}
										onPress={() => {
											setIsDone(false);
											setisEdit(false);
										}}>
										<Text fontSize={"14px"}>{Loc.currentLang.menu.cancel}</Text>
									</Button>
									<AVButtonRoundedFull
										btnText={Loc.currentLang.menu.done}
										loading={isLoading}
										isLoadingText={Loc.currentLang.menu.done}
										callback={() => {
											handleSaveList();
										}}
										px={undefined}
										height={undefined}
										isDisabled={text?.trim()?.length == 0}
										isLoading={undefined}
									/>
									{/*    <AVButtonRoundInvert btnText={ Loc.currentLang.menu.cancel } callback={ () => setisEdit(false) } />*/}
								</HStack>
							)}
							{((album && !isEdit) || !album) && (
								<AlbumActions
									items={isPhotoPage ? albumlist : filesList}
									isOwner={isOwner}
									setpopup={setpopup}
									setpdf={setpdf}
									handleEditAlbum={handleEditAlbum}
									setisEdit={setisEdit}
								/>
							)}
						</HStack>
					</VStack>
				</VStack>
				<Divider my={2} zIndex={-3} />
				{!album && (
					<HStack width={"100%"} flexWrap={"wrap"}>
						{isPhotoPage
							? albumlist?.map((item: any, index) => (
									<AlbumList
										key={index}
										item={item}
										setalbumlist={setalbumlist}
										menuRefs={menuRefs}
										isPhotoPage={isPhotoPage}
										setfilesList={setfilesList}
										setisEdit={setisEdit}
										isEdit={isEdit}
										setalbum={setalbum}
										setalbumdata={setalbumdata}
										setalbumdatamedia={setalbumdatamedia}
									/>
								))
							: filesList?.map((item: any, index) => (
									<AlbumList
										item={item}
										key={index}
										isPhotoPage={isPhotoPage}
										menuRefs={menuRefs}
										setalbumlist={setalbumlist}
										setfilesList={setfilesList}
										setisEdit={setisEdit}
										isEdit={isEdit}
										setalbum={setalbum}
										setalbumdata={setalbumdata}
										setalbumdatamedia={setalbumdatamedia}
									/>
								))}
						{isPhotoPage && (albumlist?.length === 0 || albumlist?.length === undefined) && (
							<HStack
								width={"100%"}
								top={isMobile ? "20px" : "0px"}
								padding={isMobile ? "10px" : "10px"}
								marginTop={isMobile ? "-10px" : "0px"}
								space={isMobile ? 2 : 2}>
								<img src={inform} style={{ width: "20px", height: "20px" }} />
								{Loc.currentLang.helpText.person.photoTab}
							</HStack>
						)}

						{!isPhotoPage && filesList?.length === 0 && (
							<HStack
								width={"100%"}
								top={isMobile ? "20px" : "0px"}
								padding={isMobile ? "10px" : "10px"}
								marginTop={isMobile ? "-10px" : "0px"}
								space={isMobile ? 2 : 2}>
								<img src={inform} style={{ width: "20px", height: "20px" }} />
								{Loc.currentLang.helpText.person.documentTab}
							</HStack>
						)}
					</HStack>
				)}

				{album && (
					<HStack width={"100%"} flexWrap={"wrap"}>
						<PhotosAlbum
							isOwner={isOwner}
							popup={popup}
							Parentdata={Parentdata}
							filesList={filesList}
							setfilesList={setfilesList}
							setalbumlist={setalbumlist}
							albumlist={albumlist}
							setpopup={setpopup}
							setImages={setImages}
							setvideos={setvideos}
							setMedia={setMedia}
							setText={setText}
							isEdit={isEdit}
							setisEdit={setisEdit}
							albumdata={albumdata}
							albumdatamedia={albumdatamedia}
							setalbumdatamedia={setalbumdatamedia}
							setalbumdata={setalbumdata}
							isPhotoPage={isPhotoPage}
							isDocumentPage={isDocumentPage}
							setIsDone={setIsDone}
							data={undefined}
						/>
					</HStack>
				)}
			</Box>
			{popup && (
				<Box position="fixed" bgColor={"AVColor.overlay2"} bottom={0} right={0} top={0} left={0}>
					<div
						className={`create_post_popup ${mobile && "create_post_popup_mobile"}`}
						style={{
							width: mobile ? dimensions.width - 15 : "500px",
							maxHeight: mobile ? "650px" : "700px",
							marginTop: "12px",
						}}>
						<VStack>
							<HStack
								alignItems={"center"}
								justifyContent={"space-between"}
								px={5}
								marginTop={"20px"}
								borderBottomColor={"AVColor.grey"}>
								<Text fontSize={24}>
									{album && isPhotoPage
										? `Add Media to ${albumdata?.title ? albumdata?.title : albumdata?.name}`
										: album && isDocumentPage
											? `Add Document to ${albumdata?.title ? albumdata?.title : albumdata?.name}`
											: isPhotoPage
												? Loc.currentLang.menu.createAlbum
												: Loc.currentLang.menu.addDocument}
								</Text>{" "}
								<AVCancel
									callback={() => {
										setpopup(false);
										setpdf([]);
										setImg([]);
									}}
									color={undefined}
									size={undefined}
								/>
							</HStack>

							<ScrollView showsHorizontalScrollIndicator={false} maxHeight={mobile ? "400px" : "450px"}>
								<div>
									<Box my={5} mx={10} zIndex={isActive ? "-1" : "0"}>
										{!album && (
											<AVInputtext
												type={"text"}
												label={isPhotoPage ? Loc.currentLang.menu.ablumName : Loc.currentLang.menu.folderName}
												onChange={e => {
													setText(e.target.value);
												}}
												value={text}
												height={"15px"}
												defaultValue={undefined}
												width={undefined}
												minRows={undefined}
												multiline={undefined}
												maxLength={undefined}
												size={undefined}
												placeholder={undefined}
											/>
										)}
									</Box>
									<input
										type="file"
										accept={isDocumentPage ? "" : "image/jpeg,image/jfif,image/png,image/webp,image/gif,video/mp4,"}
										multiple
										hidden
										ref={imageInputRef}
										onChange={handleImages}
									/>

									{!showAddMedia && (
										<Pressable
											marginLeft={"44px"}
											mx={5}
											borderWidth={1}
											borderRadius={5}
											overflow={"hidden"}
											borderColor={"avcolors.black"}
											borderStyle={"dashed"}
											maxH={"300px"}
											width={"82%"}
											zIndex={0}
											onPress={() => imageInputRef?.current.click()}>
											<div className="image-drop-zone" onDrop={handleDrop} onDragOver={handleDragOver}>
												<VStack alignItems={"center"} justifyContent={"center"} minH={"150px"} space={2}>
													<VStack alignItems={"center"} space={3}>
														<HStack justifyContent={"center"} space={2}>
															<Text color={"#00A7BC"}>{Loc.currentLang.menu.uploadFile}</Text>
														</HStack>
														{/*    <Text textAlign={ 'center' } color={ 'gray.300' }>{ isDocumentPage ? "Doc, XLS, CSV,PDF" : ("Add Photos /Videos") }</Text>*/}
													</VStack>
												</VStack>
											</div>
										</Pressable>
									)}

									<HStack my={3} mx={5} space={4} alignItems={"center"} zIndex={0}>
										{(images && images.length) || (videos && videos.length) || (pdf && pdf?.length > 0) ? (
											<RenderImages
												setImg={setImg}
												pdf={pdf}
												setpdf={setpdf}
												Img={Img}
												setvio={setvio}
												video={video}
												images={images}
												imageInputRef={imageInputRef}
												setImages={setImages}
												setvideos={setvideos}
												videos={videos}
												setMedia={setMedia}
												media={media}
												setShowPreview={setShowPreview}
												setSingleImage={setSingleImage}
												setShowAllImage={setShowAllImage}
											/>
										) : (
											<Pressable onPress={() => {}}></Pressable>
										)}
									</HStack>
								</div>
							</ScrollView>
							{(alertMessage || successMessage) && (
								<HStack alignItems={"center"} justifyContent={"center"}>
									<Text fontSize={16} color={alertMessage ? "danger.600" : "success.600"}>
										{alertMessage || successMessage}
									</Text>
								</HStack>
							)}

                            <Divider my={ 3 } px={ 3 } />
                            <HStack justifyContent={ 'center' } pr={ 3 } mb={ 4 } space={ 3 }>
                                <>
                                    <AVButtonRoundedFull
                                        btnText={ Loc.currentLang.menu.ok }
                                        callback={ handlePublish }
                                        loading={ isLoading || isImageProcessing }
                                        isDisabled={ !album ? !text  : media?.length === 0 }
                                        isLoadingText={ isImageProcessing
                                            ? Loc.currentLang.post.processing
                                            : Loc.currentLang.post.publishing } px={ undefined } height={ undefined } isLoading={ undefined } />
                                </>
                            </HStack>
                        </VStack>
                    </div>
                </Box>
            ) }
            { showPreview && (
                <PreviewAlbums item={ singleImage } items={ allImages } setShowPreview={ setShowPreview } setShowAllImage={ setShowAllImage } />
            ) }
        </>
    );
};

const RenderImages = ({
    images,
    imageInputRef,
    setImages,
    videos,
    setvideos,
    setMedia,
    media,
    setImg,
    Img,
    setvio,
    video,
    pdf,
    setpdf,
    setShowPreview,
    setSingleImage,
    setShowAllImage
} ) =>
{
    const [ itemList, setItemList ] = React.useState<any>( [] );

    const Grid2styles = StyleSheet.create({
        container: { flex: 1, flexDirection: 'row', flexWrap: 'wrap', minwidth: '120px' },
        img1: {
            backgroundColor: 'lightblue',
            borderColor: '#fff',
            borderWidth: 1,
            width: '110px',
            height: '120px',
        },
    });

    const handlecancelimage = (img) => {
        //setMedia([]);
        setMedia(media.filter((f) => f != img));
        setImages(images.filter((f) => f != img));
        setvideos(videos.filter((f) => f != img));
        setImg(Img?.filter((f) => f != img));
        setvio(video?.filter((f) => f != img));
    };


    const handlecanceldoc = (pdfs) => {
        setpdf(pdf.filter((f) => f != pdfs));
    };
    // Function to handle document press
    const handledocument = async (item) => {
        if (item?.type.startsWith('image') || item?.type.startsWith('video')) {
            //const mediaItems = item.filter(
            //    (item) => item?.type.startsWith('image') || item?.type.startsWith('video')
            //);
            itemList?.push( item )
            setItemList( itemList )
            openPreview( item, itemList);
            return;
        }
    };
    // Function to render media items
    const openPreview = (item, items) => {
        setShowPreview(true);
        setSingleImage(item);
        setShowAllImage(items);
    };

    return (
        <>
            <div className='add_pics_inside1 p0'>
                { (Img.length > 0 || video.length > 0 || pdf?.length > 0) && (

                    <HStack alignContent={ 'center' } alignSelf={ 'center' } justifyContent={ 'center' } space={ 3 }>
                        { (Img.length > 0 || video.length > 0 || pdf?.length > 0) && (
                            <Box style={ Grid2styles.container }>
                                { Img.length > 0 &&
                                    Img?.slice(0, 20).map((media, i) => (
                                        <VStack>
                                            <Pressable onPress={ () => handledocument(media) }>
                                                <HStack style={ Grid2styles[ 'img1' ] } key={ media.id + i }>
                                                    <Pressable
                                                        position={ 'absolute' }
                                                        background={ '#dcdc' }
                                                        borderRadius={ '50px' }
                                                        zIndex={ 4 }
                                                        padding={ 2 }
                                                        right={ 0 }
                                                        onPress={ () => {
                                                            handlecancelimage(media);
                                                        } }
                                                    >
                                                        <CloseIcon color={ '#fff' } size={ 5 } />
                                                    </Pressable>
                                                    <img
                                                        width={ '110px' }
                                                        height={ '120px' }
                                                        src={ Media.getURL(media) }
                                                        key={ media.id + i }
                                                        alt=''
                                                    />
                                                </HStack>
                                                <Text fontSize={ '16px' } fontWeight={ 400 } numberOfLines={ 1 } width={ '100px' }>{ media?.orgFile }</Text>
                                            </Pressable>
                                        </VStack>
                                    )) }
                                { pdf?.length > 0 &&
                                    pdf?.slice(0, 20).map((pdfmedia, i) => (
                                        <VStack>
                                            <Pressable onPress={ () => handledocument(pdfmedia) }>
                                                <HStack style={ Grid2styles[ 'img1' ] } key={ pdfmedia?.id + i }>
                                                    <Pressable
                                                        position={ 'absolute' }
                                                        background={ '#dcdc' }
                                                        borderRadius={ '50px' }
                                                        zIndex={ 4 }
                                                        padding={ 2 }
                                                        right={ 0 }
                                                        onPress={ () => {
                                                            handlecanceldoc(pdfmedia);
                                                        } }
                                                    >
                                                        <CloseIcon color={ '#fff' } size={ 5 } />
                                                    </Pressable>
                                                    <img
                                                        width={ '110px' }
                                                        height={ '120px' }
                                                        src={
                                                            (pdfmedia?.type === 'application/pdf' && settings?.pdf) ||
                                                            ((pdfmedia?.type === 'application/vnd.ms-powerpoint' || pdfmedia?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') && settings?.powerpoint) ||
                                                            ((pdfmedia?.type === 'application/msword' || pdfmedia?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && settings?.word) ||
                                                            (pdfmedia?.type === 'audio/wav' && MP3Icon) ||
                                                            ((pdfmedia?.type === 'application/vnd.ms-excel' || pdfmedia?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && settings?.excel) ||
                                                            settings?.file
                                                        }
                                                        key={ pdfmedia.id + i }
                                                        alt=''
                                                    />

                                                </HStack>
                                                <Text fontSize={ '16px' } fontWeight={ 400 } numberOfLines={ 1 } width={ '100px' }>{ pdfmedia?.orgFile }</Text>
                                            </Pressable>
                                        </VStack>

                                    )) }
                                { video?.length > 0 && video.slice(0, 20).map((vid, i) => (
                                    <Box key={ i }>
                                        <Pressable onPress={ () => handledocument(vid) }>
                                            <HStack style={ Grid2styles[ 'img1' ] } key={ vid + i }>
                                                <Pressable
                                                    position={ 'absolute' }
                                                    background={ '#dcdc' }
                                                    borderRadius={ '50px' }
                                                    zIndex={ 4 }
                                                    padding={ 2 }
                                                    right={ 0 }
                                                    onPress={ () => {
                                                        handlecancelimage(vid);
                                                    } }
                                                >
                                                    <CloseIcon color={ '#fff' } size={ 5 } />
                                                </Pressable>
                                                <img
                                                    width={ '110px' }
                                                    height={ '120px' }
                                                    src={ Media.getVideoPicURL(vid) }
                                                    key={ media.id + i }
                                                    alt=''
                                                />
                                            </HStack>
                                            <Text fontSize={ '16px' } fontWeight={ 400 } numberOfLines={ 1 } width={ '100px' }>{ vid?.orgFile }</Text>
                                            <HStack position={ 'absolute' } top={ '30%' } left={ '25%' }>
                                                <VideoIcon size={ '60px' } color='white' />
                                            </HStack>
                                        </Pressable>
                                    </Box>
                                )) }

                            </Box>
                        ) }
                    </HStack>
                ) }
            </div>
        </>
    );
};

export default Createalbumpopup;
