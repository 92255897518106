import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Center, CloseIcon, Divider, FlatList, HStack, Image, Input, Modal, Pressable, ScrollView, Text, Tooltip, VStack } from 'native-base';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as AV from '../../AVCore/AVCore';
import Moment from 'moment';
import { CalenderCardIcon, LocationCardIcon, TimerCardIcon, Event, CreateTeam, TeamProfile, TeamBackground, EliteClub, Community, Neighbor, Organization, GradClass, Class, School, Group, Club, Profile } from '../../assets/icons/icon-assets';

interface Props
{
    seriesEventsList : any | undefined,
    event : any | undefined,
} 

const SeriesEvents : FC<Props> = ( {
    seriesEventsList,
    event } ) =>
{
    const navigate = useNavigate();
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
    const navigateToEvent = async(item) =>
    {
        let isSeries = event.seriesEvents.find( x => x.id == event.id );
        if ( isSeries )
        {
            let fk = {
                id: event.series,
                pk: event.series.slice( -4 ),
                region: event.region,
                type: event.type
            }
            event = await AV.AVEntity.get( fk )
        }
        //event.start = item.start;
        //event.end = item.end;
        event.id = item.id;
        event.participants = item?.participants;
        event.resources = item?.resources;
        //event.status = item.status ? item.status : event.status;
        //event.location = item.location ? item.location : event.location;
        navigate( `/Events/${ event?.series }/${ event?.region }`, { state: { team: event, series: item } } )
    }

    return (
        <>
            <>
                <>
                    { seriesEventsList?.length > 0 &&
                        seriesEventsList?.map( ( item, i ) =>
                        {
                            let participant = event?.participants?.find( x => x.id == currentUser.id );
                            console.log('Participantssss::::::', participant)
                            let profileImg = event?.thumb ? event?.thumb : event?.profile?.pic?.id;
                            let backgroundImg = event?.background ? event.background : event?.profile?.background?.id;
                            console.log( "item.location", item.location );
                            let profileImage = "";
                            let backgroundImage = "";
                            let thumb : any = AV.AVEntity.getLink( event );
                            if ( thumb )
                                profileImage = AV.Media.getThumb( thumb );
                            else if ( item?.profile?.pic?.id )
                                profileImage = AV.Media.getThumb( AV.AVEntity.getLink( item ) );
                            if ( item?.background )
                                backgroundImage = AV.Media.getBackground( item );
                            else if ( item?.profile?.background?.id )
                                backgroundImage = AV.Media.getBackground( AV.AVEntity.getLink( item ) );

                            return (
                                <Pressable
                                    width={ '235px' }
                                    margin={ '12px' }
                                    bgColor={ 'rgba(0,167,188,0.11)' }
                                    borderRadius={ 6 }
                                    borderColor={ 'AVColor.bgcolor' }
                                    shadow={ 1 }
                                    overflow={ 'hidden' }
                                    _hover={ {
                                        shadow: 3,
                                    } }
                                    onPress={ async() =>
                                    {
                                       await navigateToEvent(item);
                                    }}     
                                >
                                    <Box
                                        width={ '100%' }
                                        height={  '69px' }
                                        bgColor={ 'rgba(0,167,188,0.26)' }
                                        justifyContent={ 'center' }
                                        alignItems={ 'center' }
                                    >
                                        { ( backgroundImg ) && <Image
                                            source={ {
                                                uri: backgroundImage,
                                            } }
                                            width={ '100%' }
                                            height={ '100%' }
                                            position={ 'absolute' }
                                            zIndex={ -3 }
                                            alt={ 'Background Image' }
                                        />
                                        }
                                        { profileImg ? (
                                            <Image
                                                source={ {
                                                    uri: profileImage,
                                                } }
                                                width={  '54px' }
                                                height={'54px' }
                                                position={  undefined }
                                                borderRadius={ '50%' }
                                                alt={ 'Profile Image' }
                                            />
                                        ) : (
                                            <>
                                                    { <Event height={ '50' } width={ '50' } color={ "#32AE78" } /> }
                                            </>
                                        ) }
                                    </Box>
                                    <VStack px={ 2 } py={ 1.5 } justifyContent={ 'center' } space={ 1 }>
                                        <HStack justifyContent={ 'center' }>
                                            <Tooltip label={ event?.title ? event?.title : event?.name } openDelay={ 500 }>
                                                <Pressable
                                                    onPress={ () =>
                                                    {
                                                        navigate( `/Events/${ item?.id }/${ event?.region }`, { state: { team: event } } );
                                                    } }

                                                >
                                                    <Text fontSize={ '18px' } width={ '200px' } color={ 'AVColor.primaryText' } numberOfLines={ 1 } text-overflow={ 'ellipsis' } textAlign={ 'center' } fontWeight={ 500 } >
                                                        { event?.title ? event?.title : event?.name }
                                                    </Text>
                                                    { ( item?.type == 'LeagueTeam' && item.subType !== 'Class' ) &&
                                                        ( <Text fontSize={ '18px' } width={ '200px' } color={ 'AVColor.primaryText' } numberOfLines={ 1 } text-overflow={ 'ellipsis' } textAlign={ 'center' } fontWeight={ 500 } >
                                                        { event?.data ? event?.data : event?.season ? event?.season : event?.title }
                                                        </Text> ) }
                                                </Pressable>
                                            </Tooltip>
                                        </HStack>
                                        { event?.type == 'Event' && ( <VStack >
                                            <HStack alignItems={ 'center' } space={ '15px' } marginTop={ '5px' }>
                                                <CalenderCardIcon height={ '24' } width={ '24' } color={ 'black' } />
                                                <Text color={ 'black' } fontSize={ '14px' }>
                                                    { Moment( item?.start ).format( 'MM/DD/YYYY' ) }

                                                </Text>
                                            </HStack>
                                            <HStack alignItems={ 'center' } space={ '15px' } >
                                                <TimerCardIcon height={ '24' } width={ '24' } color={ 'black' } />
                                                <Text color={ 'black' } fontSize={ '14px' }>
                                                    { Moment( item?.start ).format( 'LT' ) } -{ ' ' }
                                                    { Moment( item?.end ).format( 'LT' ) }
                                                </Text>
                                            </HStack>
                                            <HStack alignItems={ 'center' } space={ '10px' } >
                                                <HStack width={ '30px' }>
                                                    <LocationCardIcon height={ '24' } width={ '24' } color={ 'black' } />
                                                </HStack>
                                                <Text numberOfLines={ 1 } fontSize={ '14px' } color={ 'black' }>
                                                    { item.location ? item.location?.title : item?.locURL ? item?.locURL : event?.location?.title ? event?.location?.title : event?.locURL ? event?.locURL : event?.url }
                                                </Text>
                                            </HStack>
                                        </VStack> ) }
                                    </VStack>
                                    { event?.type == 'Event' && ( <HStack py={ 1 } alignItems={ 'center' } justifyContent={ 'center' } backgroundColor={ 'rgba(0,167,188,1)' } height={ '25px' }>
                                        <Text fontSize='14px' color={ 'rgba(255,255,255,1)' } fontWeight={ 500 } textAlign='center'>
                                            { item?.status ? item?.status : participant?.status ? participant?.status : 'RSVP' }
                                        </Text>
                                    </HStack> ) }
                                </Pressable>
                            );
                        } ) }
                </>
   
            </>
        </>
    );
}

export default React.memo( SeriesEvents );