import React, { useCallback, useRef, useState } from 'react';
import { HStack, Box, VStack, Text, Button, Input, TextArea, ScrollView, FlatList, Pressable, Badge, Avatar, Divider, CloseIcon, AddIcon, Image } from 'native-base';
import { useWindowDimension } from '../../Utility/WindowDimensions';
import { Loc } from '../../App/Loc';
import AVCancel from '../../components/common/AVCancel';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import ProfilePic from '../common/ProfilePicture';
import * as AV from '../../AVCore/AVCore';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ConvoCard from './ConvoCard';
import EmojiPicker from 'emoji-picker-react';
import FilePicker from '../../Platform/FilePicker';
import { AVButtonRoundedFull } from '../../components/common/AVButtons';
import { ActivityIndicator, Keyboard } from 'react-native';
import { setConvo } from '../../Redux/Actions/convoActions';
import { AVBase } from '../../AVCore/AVBase';
import { SignalRService } from '../../AVCore/SignalR'
import
{
    PaperPlaneIcon,
    AttachIcon,
    LikeIcon,
    GifIcon,
    HappyIcon,
    AddPhotoIcon,
} from '../../assets/icons/icon-assets';
import AVInputtext from '../../components/AVInputtext';
import AVConfirmationModal from '../common/AVConfirmationModal';
import settings from '../../Platform/settings.json';
import Tooltip from '@mui/material/Tooltip';
import { setIsConvoScreenOpen } from '../../Redux/Actions/userActions';
import useInitSSE from '../../Hooks/useInitSSE';
import { debounce } from "lodash";


const Messages = ( { isHeader, setShowEmoji, RenderImages, showEmoji, imageInputRef, onEmojiClick, handleuserRedirect, getItemLayout, ReplyLoadMedia, setHeader, setShowAllImage, setSingleImage, setShowPreview, setConvoImage, setItemHeights, itemHeights, setIsdelete, props, mdlConvoVisible, textSearchRef,
    mdlCreateConvoVisible,
    setMdlCreateConvoVisible,
    toFK,
    isOpenConvo,
    isConvoData,
    setConvoSubject,
    setIsOpenConvo,
    searchInput,
    filteredResults,
    showSearch,
    showFilePicker,
    handleCreateModel,
    typingUserName,
    isTyping,
    searchItems,
    handleSentToProfile,
    handleSendMessage,
    setConvoLoading,
    isLoading,
    handleSubject,
    subject,
    handleMessage,
    handleRemoveItem,
    getImageGalleryFromFilePickerCreateConvo,
    convoSubject,
    setMdlConvoVisible,
    convoPost,
    convo,
    showConvoFilePicker,
    handleGetConvo,
    handleSendConvoMessage,
    replyValue,
    setreplyValue,
    handleChatMessage,
    media,
    value,
    profile,
    setValue,
    Loading,
    images,
    setImages,
    videoUrls,
    setvideos,
    handleImages,
    handleFilePicker,
    onRefresh,
    setSubject,
    settoFK,
    handlePublish,
    setIsHide,
    isHide,
    isImageProcessing,
    setMedia,
    refConvoList,
    setShowConvoFilePicker,
    HandleEditSet,
    handleUpdatePost,
    itemEdit,
    setShowSearch,
    editingImage,
    setSearchInput,
    setUpdateConvoImage,
    setEditingImage, setConvoPost, fetchloading, setConvos, setIsConvoData } ) =>
{
    const dimensions = useWindowDimension();
    const isMobile = dimensions?.width <= 500 || false;
    const stateUser = useSelector( ( state : RootStateOrAny ) => state );
    const currentUser = stateUser?.user?.user[ 0 ];
    const navigate = useNavigate();
    const [ renderedItems, setRenderedItems ] = React.useState( [] );
    const { CloseSSE } = useInitSSE();
    const [ isOpen, setIsOpen ] = React.useState( false );
    const [ selectItem, setSelectItem ] = React.useState<any>( '' )
    const [ showFeedbackImpressions, setShowFeedbackImpressions ] = React.useState( false );
    const userconvo = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    let id = convo?.id == undefined ? isConvoData : convo?.id
    const currentConvo = userconvo?.filter( x => x?.id == id) || [];
    const currentConvoFirstItem = currentConvo.length > 0 ? currentConvo[ 0 ] : null;
    const filteredConvoPost = currentConvoFirstItem?.posts?.filter( x => x?.status !== "Deleted" ) || [];
    const [ isConfirmDelete, setisConfirmDelete ] = useState( false );
    const [ Confirmationtext, setConfirmationtext ] = React.useState( "Are you sure ?" );
    const [ selectType, setselectType ] = React.useState<any>();
    const [ itemSelect, setItemSelect ] = React.useState<any>( '' );
    const [ isLoader, setLoader ] = useState( false );
    const menuRef = useRef( null );
    useOutsideAlerter( menuRef, null );
    const dispatch = useDispatch();
    const [ isDeleteLoading, setisdeleteLoading ] = React.useState( false );
    const inputRef : any = useRef();
    const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );

    React.useEffect( () =>
    {
        if ( !isMobile && mdlConvoVisible )
        {
            inputRef?.current?.focus();
        }

    }, [ convoPost, mdlConvoVisible, isMobile ] )

    React.useEffect( () =>
    {
        setConvoSubject( currentConvo[ 0 ]?.subject )
        handleGetConvo( currentConvo[ 0 ] ? currentConvo[ 0 ] : userconvo[0] )
        console.log( "filteredConvoPost", filteredConvoPost )
    }, [ isOpenConvo, isConvoData ] )

    const handleKeyPress = ( event ) =>
    {
        if ( event.key === 'Enter' && !event.shiftKey && value?.trim()?.length > 0 )
        {
            if ( mdlConvoVisible )
            {
                if ( isImageProcessing )
                {
                    event.preventDefault();
                }
                if ( itemEdit.length !== 0 )
                {
                    handleUpdatePost( itemEdit );
                }
                else if ( !isMobile && !isLoading )
                {
                    handleSendConvoMessage( convo );
                }
            }
            else
            {
                if ( toFK.length > 1 && subject && !isLoading && ( value?.trim()?.length === 0 || media?.length === 0 ) )
                {
                    handleSendMessage();
                }
                if ( toFK.length == 1 && ( value?.trim()?.length === 0 || media?.length === 0 ) )
                {
                    handleSendMessage();
                }

            }
        }
    };



    const menuRefs = useRef( null );
    const addPhotoIconRef = useRef( null );

    useOutsideAlerter( menuRefs, addPhotoIconRef );

    function useOutsideAlerter ( ref, exceptionRef )
    {
        React.useEffect( () =>
        {
            // Alert if clicked on outside of element
            function handleClickOutside ( event )
            {


                if ( ref.current && !ref.current.contains( event.target ) )
                {
                    setShowFeedbackImpressions( false );
                    setIsOpen( false )
                    setShowEmoji( false );
                }
                else if (
                    ref.current &&
                    !ref.current.contains( event.target ) &&
                    !( exceptionRef.current && exceptionRef.current.contains( event.target ) )
                )
                {
                    setShowEmoji( false );
                }
            }

            // Bind the event listener
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                // Unbind the event listener on clean up
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref, exceptionRef ] );
    }

    const handleLikePost = async ( type ) =>
    {
        try
        {
            // Find the existing impression of the current user
            let existingImpression = selectItem?.impressions?.find( ( x ) => x?.id === currentUser?.id );

            if ( !existingImpression || existingImpression.impression !== type )
            {
                // Optimistically update the state to show the impression immediately
                let optimisticImpressions = existingImpression
                    ? selectItem.impressions.filter( ( x ) => x.id !== currentUser.id )
                    : selectItem.impressions || [];

                let optimisticImpression = {
                    id: currentUser.id,
                    impression: type
                };

                let optimisticSelectItem = {
                    ...selectItem,
                    impressions: [ ...optimisticImpressions, optimisticImpression ]
                };

                // Update the conversation posts optimistically
                updateConversationPosts( optimisticSelectItem );

                // Call the API to add the impression
                //let val = await AV.Post.addImpression( selectItem, type );

                let param : AV.IListItemParam = { source: AVBase.getFk( selectItem ), type: type };

                if ( !SignalRService.getConnection )
                {
                    await SignalRService.createConnection();

                    await SignalRService.startSignalRConnection();

                    await SignalRService.joinGroup( currentConvo.id );
                }

                await SignalRService.addImpression( param );


                // Update the state with the actual response from the API
                //handleApiResponse( val );
            } else
            {
                // Optimistically remove the impression from the UI immediately
                let optimisticImpressions = selectItem.impressions.filter( ( x ) => x.id !== currentUser.id );
                let optimisticSelectItem = {
                    ...selectItem,
                    impressions: optimisticImpressions
                };

                // Update the conversation posts optimistically
                updateConversationPosts( optimisticSelectItem );

                // Remove impression if already liked with the same type
                await handleremoveLike( type );
            }
        } catch ( error )
        {
            console.error( "Error handling like post:", error );
            // Optionally, handle the error and revert the optimistic update if necessary
        }

        setShowFeedbackImpressions( false );
    };



    const updateConversationPosts = ( updatedItem ) =>
    {
        let index = convoPost?.findIndex( ( a ) => a?.id === updatedItem?.id );
        if ( index !== -1 )
        {
            convoPost[ index ] = updatedItem;
            //setConvoPost([...convoPost]); // Uncomment if needed to update the state
        }

        let userFeedsImpre = userconvo?.find( ( x ) => x?.id === updatedItem?.pk );
        if ( userFeedsImpre )
        {
            let postIndex = userFeedsImpre.posts?.findIndex( ( x ) => x?.id === updatedItem?.id );
            if ( postIndex !== -1 )
            {
                userFeedsImpre.posts[ postIndex ] = updatedItem;
                setConvoPost( [ ...userFeedsImpre.posts ] );
                let chatIndex = userconvo.findIndex( ( x ) => x.id === userFeedsImpre.id );
                if ( chatIndex !== -1 )
                {
                    userconvo[ chatIndex ] = userFeedsImpre;
                    //dispatch(setConvo(userconvo)); // Uncomment if needed to update the state
                }
            }
        }
    };

    const handleApiResponse = ( val ) =>
    {
        if ( val )
        {
            if ( selectItem.galleryPost?.length > 0 ) val.galleryPost = selectItem.galleryPost;
            if ( selectItem.ReplyPost?.length > 0 ) val.ReplyPost = selectItem.ReplyPost;

            let index = convoPost?.findIndex( ( a ) => a?.id === val.id );
            if ( index !== -1 )
            {
                convoPost[ index ] = val;
                //setConvoPost([...convoPost]); // Uncomment if needed to update the state
            }

            let userFeedsImpre = userconvo?.find( ( x ) => x.id === val.pk );
            if ( userFeedsImpre )
            {
                let postIndex = userFeedsImpre.posts?.findIndex( ( x ) => x.id === val.id );
                if ( postIndex !== -1 )
                {
                    userFeedsImpre.posts[ postIndex ] = val;
                    setConvoPost( [ ...userFeedsImpre.posts ] );
                    let chatIndex = userconvo.findIndex( ( x ) => x.id === userFeedsImpre.id );
                    if ( chatIndex !== -1 )
                    {
                        userconvo[ chatIndex ] = userFeedsImpre;
                        dispatch( setConvo( userconvo ) ); // Uncomment if needed to update the state
                    }
                }
            }
        }
    };




    //remove impression
    const handleremoveLike = async ( type ) =>
    {
        let val : any = await AV.Post.removeImpression( selectItem, type );

        // Update val if necessary
        if ( selectItem?.galleryPost?.length > 0 && val )
        {
            val.galleryPost = selectItem?.galleryPost;
        }
        if ( selectItem?.ReplyPost?.length > 0 && val )
        {
            val.ReplyPost = selectItem?.ReplyPost;
        }

        // Update convoPost
        let index = convoPost?.findIndex( ( a ) => a?.id === selectItem?.id );
        if ( index !== -1 )
        {
            convoPost[ index ] = val;
            setConvoPost( [ ...convoPost ] );
        }

        // Update userconvo
        let userFeedsImpre = userconvo?.filter( ( x ) => x?.id === val.pk );
        if ( userFeedsImpre?.length > 0 )
        {
            let userFeedsImpression = userFeedsImpre[ 0 ]?.posts?.filter( ( x ) => x?.id === val?.id );
            if ( userFeedsImpression?.length > 0 )
            {
                userFeedsImpre[ 0 ].posts = convoPost;
                let postIndex = userFeedsImpre[ 0 ].posts?.findIndex( ( x ) => x?.id === val?.id );
                if ( postIndex !== -1 )
                {
                    userFeedsImpre[ 0 ].posts[ postIndex ] = val;
                    let chatIndex = userconvo.findIndex( ( x ) => x?.id === userFeedsImpre[ 0 ]?.id );
                    if ( chatIndex !== -1 )
                    {
                        userconvo[ chatIndex ] = userFeedsImpre[ 0 ];
                        dispatch( setConvo( [ ...userconvo ] ) );
                    }
                }
            }
        }

        setShowFeedbackImpressions( false );
    };

    const handleDeleteMessage = () =>
    {
        setisConfirmDelete( true )
        setConfirmationtext( 'Are you sure? You want to delete your message' );
        setItemSelect( selectItem );
        setselectType( 'DeleteConvo' )
    }

    const handleDelteconvo = async () =>
    {
        let selectItem = itemSelect;
        let selectedReply = convoPost?.filter( a => a?.parentPost === selectItem?.id );

        setLoader( true );

        // Deleting the selected item
        await AV.Post.delete( selectItem ).then( x =>
        {
            selectItem.status = "Deleted";
            setConvoPost( convoPost?.filter( a => a?.id !== selectItem?.id ) );

            let userFeedsImpre = userconvo?.filter( ( x : any ) => x?.id === selectItem?.pk );
            let userFeedsImpression = userFeedsImpre[ 0 ]?.posts?.filter(
                ( b : any ) => b?.id === selectItem?.id
            );

            if ( userFeedsImpression?.length > 0 )
            {
                userFeedsImpre[ 0 ].posts[
                    userFeedsImpre[ 0 ]?.posts.indexOf( userFeedsImpression[ 0 ] )
                ] = selectItem;

                userconvo[ userconvo.indexOf( userFeedsImpre[ 0 ] ) ] = userFeedsImpre[ 0 ];
                dispatch( setConvo( userconvo ) );
            }
        } );
        if ( selectedReply[ 0 ] )
        {
            // Deleting the selected replies
            await AV.Post.delete( selectedReply[ 0 ] ).then( x =>
            {
                selectedReply.forEach( reply =>
                {
                    reply.status = "Deleted";
                    setConvoPost( convoPost?.filter( a => a?.id !== reply?.id ) );

                    let userFeedsImpre = userconvo?.filter( ( x : any ) => x?.id === reply?.pk );
                    let userFeedsImpression = userFeedsImpre[ 0 ]?.posts?.filter(
                        ( b : any ) => b?.id === reply?.id
                    );

                    if ( userFeedsImpression?.length > 0 )
                    {
                        userFeedsImpre[ 0 ].posts[
                            userFeedsImpre[ 0 ]?.posts.indexOf( userFeedsImpression[ 0 ] )
                        ] = reply;

                        userconvo[ userconvo.indexOf( userFeedsImpre[ 0 ] ) ] = userFeedsImpre[ 0 ];
                        dispatch( setConvo( userconvo ) );
                    }
                } );
            } );
        }
        setIsOpen( false );
        setLoader( false );
        setisConfirmDelete( false );
    };


    const renderItem = useCallback( ( { item } ) => (
        <ConvoCard
            data={ item }
            { ...props }
            key={ item.id }
            convoPost={ convoPost }
            itemHeights={ itemHeights }
            setItemHeights={ setItemHeights }
            replyValue={ replyValue }
            setreplyValue={ setreplyValue }
            profile={ profile }
            setConvoImage={ setConvoImage }
            setShowPreview={ setShowPreview }
            setSingleImage={ setSingleImage }
            setShowAllImage={ setShowAllImage }
            setHeader={ setHeader }
            HandleEditSet={ HandleEditSet }
            isOpen={ isOpen } setIsOpen={ setIsOpen }
            selectItem={ selectItem } setSelectItem={ setSelectItem }
            setShowFeedbackImpressions={ setShowFeedbackImpressions }
            showFeedbackImpressions={ showFeedbackImpressions }
            setMdlCreateConvoVisible={ setMdlCreateConvoVisible }
            mdlCreateConvoVisible={ mdlCreateConvoVisible }
            isOpenConvo={ isOpenConvo }
            setIsOpenConvo={ setIsOpenConvo }
        />
    ), [ convoPost, itemHeights, replyValue, setreplyValue, profile, setConvoImage, setShowPreview, setSingleImage, setShowAllImage, setHeader, HandleEditSet ] );

    const keyExtractor = useCallback( item => item?.id?.toString(), [] );

    const onEndReached = useCallback( () =>
    {
        // Handle end reached event here
        // e.g., fetch more data
        onRefresh();
    }, [ onRefresh ] );

    const debouncedHandleChatMessage = useCallback( debounce( ( e ) =>
    {
        handleChatMessage( e );
    }, 300 ), [ handleChatMessage ] );

    const updateRenderedItems = useCallback( ( items ) =>
    {
        setRenderedItems( items );
    }, [] );

    const feedbackOptions = [
        { impression: 'Like', title: 'Like', uri: settings.like },
        { impression: 'Unlike', title: 'Unlike', uri: settings.unlike },
        { impression: 'heart', title: 'Heart', uri: settings.heart },
        { impression: 'Wow', title: 'Wow', uri: settings.wow },
        { impression: 'Lol', title: 'Lol', uri: settings.lol },
        { impression: 'Cheer', title: 'Cheer', uri: settings.cheer },
        { impression: 'Question', title: 'Question', uri: settings.question },
        { impression: 'broken', title: 'broken', uri: settings.broken },
        { impression: 'Sad', title: 'Sad', uri: settings.sad },
        { impression: 'Mad', title: 'Mad', uri: settings.mad },
    ];

    const handlePress = ( type ) =>
    {
        handleLikePost( type );
        setShowFeedbackImpressions( false );
        setIsOpen( false );
    };

    React.useEffect( () =>
    {
    }, [ userconvo, convoPost ] );

    return (
        <Box
            position={ [ 'fixed' ] }
            top={ [ `${ 101 - 1 }px` ] }
            right={ [ !isMobile ? `${ 460 - 1 }px` : 0 ] }
            w={ isMobile ? '100%' : dimensions.width < 800 ? '350px' : '460px' }
            h={ 'calc(100vh - 50px)' }
            bgColor={ [ 'AVColor.white' ] }
            shadow={ [ 10 ] }
            borderColor={ 'AVColor.grey' }
            borderWidth={ 1 }
            zIndex={ isHeader ? -3 : 9999 }
        >
            <VStack
                paddingLeft={ 3 }
                paddingRight={ isMobile ? '5px' : 3 }
                h={ '100%' }
                width={ '100%' }
                position={ 'relative' }
                py={ '12px' }
            >
                <HStack justifyContent={ 'space-between' } width={ '100%' }>

                    <Text
                        fontSize={ 24 }
                        fontWeight={ 'bold' }
                        width={ '300px' }
                        numberOfLines={ 1 }
                        ellipsizeMode='tail'
                    >

                        {!isOpenConvo
                            ? Loc.currentLang.message.newMessage
                            : convoSubject
                                ? convoSubject
                                : Loc.currentLang.menu.messages }{ ' ' }
                    </Text>
                    { fetchloading && (
                        <HStack right={ '30%' } alignItems={ 'center' } justifyContent={ 'center' }>
                            <ActivityIndicator size={ 30 } color="green" />
                        </HStack>
                    ) }
                    <HStack alignItems={ [ 'center' ] } space={ '26px' }>
                        <AVCancel
                            callback={ async () =>
                            {
                                //Close SSE on convo screen close
                               // CloseSSE();
                                await SignalRService.stopSignalRConnection();
                                let setConvoScreenOpen = { isOpen: false, convoId: undefined }
                                setConvos( [] ); setIsConvoData( '' );
                                dispatch( setIsConvoScreenOpen( setConvoScreenOpen ) );
                                setMdlCreateConvoVisible( false );
                                setIsOpenConvo( false );
                                setShowEmoji( false );
                                setConvoLoading( false );
                            } } color={ '' } size={ undefined } />
                    </HStack>
                </HStack>
                { !isOpenConvo && (
                    <HStack>
                        <Text paddingTop={ 2 }>{ Loc.currentLang.menu.to }:</Text>
                        <Box>
                            <ScrollView showsHorizontalScrollIndicator={ false } maxHeight={ '250px' }>
                                <HStack
                                    width={ '350px' }
                                    maxHeight={ '100px' }
                                    padding={ '5px' }
                                    flexWrap={ 'wrap' }
                                    space={ 1 }
                                >
                                    { toFK?.map( ( item, i ) => (
                                        <HStack
                                            key={ item.id + i }
                                            bgColor={ 'AVColor.primary' }
                                            borderRadius={ 19 }
                                            height={ '30px' }
                                            alignContent={ 'space-around' }
                                            alignSelf={ 'end' }
                                            marginRight={ '10px' }
                                            marginBottom={ '10px' }

                                        >
                                            <HStack paddingLeft={ '10px' } paddingRight={ '10px' }>
                                                <Text
                                                    color={ '#ffffff' }
                                                    fontSize={ 12 }
                                                    width={ '80px' }
                                                    numberOfLines={ 1 }
                                                    text-overflow={ 'ellipsis' }
                                                    lineHeight={ '2.2rem' }
                                                >
                                                    { item.title }
                                                </Text>
                                                <Pressable
                                                    onPress={ () =>
                                                    {
                                                        handleRemoveItem( item );
                                                    } }
                                                >

                                                    <CloseIcon
                                                        marginLeft={ '12px' }
                                                        marginTop={ '12px' }
                                                        size={ '10px' }
                                                        color={ '#ffffff' }
                                                    />
                                                </Pressable>
                                            </HStack>
                                        </HStack>
                                    ) ) }
                                    <Input
                                        ref={ textSearchRef }
                                        autoFocus
                                        size="lg"
                                        borderWidth={ 0 }
                                        borderColor='gray.300'
                                        variant='underlined'
                                        width={ '100%' }
                                        onChangeText={ ( e ) => searchItems( e ) }
                                        value={ searchInput }
                                    />
                                    <Divider width='100%' />
                                </HStack>
                            </ScrollView>
                        </Box>
                    </HStack>
                ) }
                { isOpenConvo && (
                    <VStack>
                        { convo?.subject?.trim()?.length >= 1 && (
                            <HStack width={ '100%' } >
                                <HStack
                                    space={ '11px' }
                                    padding={ 2 }
                                    width={ '75%' }
                                    alignItems={ 'flex-start' }
                                    justifyContent={ 'flex-start' }>
                                    { convo?.people?.length > 0 && convo?.people?.slice( 0, 3 ).map( ( item, index ) => (
                                        <>
                                            {
                                                <VStack key={ index } width={ '15%' }>
                                                    <Pressable onPress={ () => handleuserRedirect( item ) }>
                                                        <ProfilePic
                                                            width={ 36 }
                                                            height={ 36 }
                                                            link={
                                                                entitydatalist?.find( ( x : any ) => x?.id == item?.id ) ?
                                                                    AV.AVEntity.getLink( entitydatalist?.find( ( x : any ) => x?.id == item?.id ) ) : ( convo?.posts?.find( x => x?.person?.id === item?.id )?.person || item ) as AV.ILink
                                                            }
                                                        />

                                                    </Pressable>
                                                    <Text
                                                        numberOfLines={ 1 }
                                                        textAlign={ 'center' }
                                                        fontWeight={ 'bold' }
                                                        fontSize={ '10px' }>
                                                        { convo?.posts?.filter(
                                                            x => x?.person?.id === item?.id,
                                                        )[ 0 ]?.person?.title
                                                            ? convo?.posts?.filter(
                                                                x => x?.person?.id === item?.id,
                                                            )[ 0 ]?.person?.title
                                                            : item?.title }
                                                    </Text>
                                                </VStack>
                                            }
                                        </>
                                    ) ) }
                                    { convo?.people?.length > 3 && (
                                        <Avatar.Group
                                            _avatar={ {
                                                size: "39"
                                            } } space={ 0.1 }
                                            max={ 1 }
                                        >
                                            { convo?.people
                                                ?.slice( 3, convo?.people?.length )
                                                .map( ( item, index ) => (
                                                    <VStack>
                                                        <Pressable onPress={ () => handleuserRedirect( item ) }>
                                                            <ProfilePic width={ 36 } height={ 36 } link={ item } />
                                                            <Text
                                                                numberOfLines={ 1 }
                                                                fontWeight={ 'bold' }
                                                                textAlign={ 'center' }
                                                                fontSize={ '10px' }>
                                                                { convo?.posts?.filter(
                                                                    x => x?.person?.id === item?.id,
                                                                )[ 0 ]?.person?.title
                                                                    ? convo?.posts?.filter(
                                                                        x => x?.person?.id === item?.id,
                                                                    )[ 0 ]?.person?.title
                                                                    : item?.title }
                                                            </Text>
                                                        </Pressable>
                                                    </VStack>
                                                ) ) }
                                        </Avatar.Group>
                                    ) }
                                </HStack>
                                { convo?.type !== 'GroupConvo' && ( <Pressable
                                    position={ 'absolute' }
                                    right={ 0 }
                                    fontWeight={ 500 }
                                    bottom={ '5px' }
                                    fontSize={ '14px' }
                                    onPress={ () =>
                                    {
                                        setMdlConvoVisible( false );
                                        setIsOpenConvo( false )
                                        setSubject( convo?.subject );
                                        setIsHide( true );
                                        setreplyValue( '' );
                                        setShowSearch( false );
                                        setSearchInput( '' )
                                        settoFK( convo?.people?.filter( ( x : any ) => x?.id !== currentUser?.id ) );
                                    } }
                                >
                                    <Text color={ '#00A7BC' }>
                                        { Loc.currentLang.menu.manageGroup }
                                    </Text>
                                </Pressable> ) }
                            </HStack>
                        ) }
                        { convo?.subject?.trim()?.length == 0 && (
                            <HStack space={ 2 }>
                                <Pressable onPress={ () => handleuserRedirect( profile ) }>
                                    <ProfilePic width={ 38 } height={ 38 } link={ entitydatalist?.find( ( x : any ) => x?.id == profile?.id ) ?
                                        AV.AVEntity.getLink( entitydatalist?.find( ( x : any ) => x?.id == profile?.id ) ) : profile } />
                                </Pressable>
                                <HStack mt={ '10px' }>
                                    <Pressable
                                        onPress={ () =>
                                            navigate( `/profile/${ profile?.id }/${ profile?.region }`, {
                                                state: { user: profile },
                                            } )
                                        }
                                    >
                                        {/*<Tooltip label={ profile?.title } openDelay={ 500 }>*/ }
                                        <Pressable
                                            onPress={ () =>
                                                navigate( `/profile/${ profile?.id }/${ profile?.region }`, {
                                                    state: { user: profile },
                                                } )
                                            }
                                        >
                                            <Text
                                                fontWeight={ 'bold' }
                                                fontSize={ 'sm' }
                                                width={ '200px' }
                                                text-overflow={ 'ellipsis' }
                                                numberOfLines={ 1 }
                                            >
                                                { profile?.title }
                                            </Text>
                                        </Pressable>
                                        {/*</Tooltip>*/ }
                                    </Pressable>
                                </HStack>
                            </HStack>
                        ) }
                    </VStack>
                ) }
                <VStack>
                    { !isOpenConvo ? (
                        <>
                            { showSearch && (
                                <Box
                                    background={ '#ffffff' }
                                    position={ 'absolute' }
                                    borderRadius='md'
                                    shadow='9'
                                    left={ '40px' }
                                    zIndex={ 999 }
                                    width={ '249px' }
                                    maxH={ '170px' }
                                    overflowY={ 'auto' }
                                >
                                    { filteredResults?.map( ( item, i ) => (
                                        <Pressable
                                            key={ item.id + i }
                                            padding={ '10px' }
                                            onPress={ () => handleSentToProfile( item ) }
                                        >
                                            <Box key={ item.id + i }>
                                                <HStack space={ 2 }>
                                                    <ProfilePic width={ 38 } height={ 38 } link={
                                                        currentUser?.id == item?.id ?
                                                            AV.AVEntity.getLink( currentUser ) :
                                                            entitydatalist?.find( ( x : any ) => x?.id == item?.id ) ?
                                                                AV.AVEntity.getLink( entitydatalist?.find( ( x : any ) => x?.id == item?.id ) ) : item } />
                                                    <VStack marginLeft={ 0 }>
                                                        {/* <Tooltip label={ item.title } openDelay={ 500 }>*/ }
                                                        <Pressable onPress={ () => handleSentToProfile( item ) }>
                                                            <Text
                                                                fontWeight='bold'
                                                                numberOfLines={ 1 }
                                                                width='150px'
                                                                text-overflow={ 'ellipsis' }
                                                            >
                                                                { item?.title }
                                                            </Text>
                                                        </Pressable>
                                                    </VStack>
                                                </HStack>
                                            </Box>
                                        </Pressable>
                                    ) ) }
                                </Box>
                            ) }
                            { toFK.length > 1 && (
                                <>
                                    <Divider width={ [ `${ 400 - 1 }px` ] } right={ 3 } top={ 2 } />
                                    <VStack
                                        height={ '100%' }
                                        width='106%'
                                        top={ 2 }
                                        right={ [ `${ 12 - 1 }px` ] }
                                        zIndex={ -1 }
                                    >
                                        <TextArea
                                            autoCompleteType={ '' }
                                            value={ subject }
                                            defaultValue={ convo?.subject && isHide ? convo?.subject : subject }
                                            margin-Left={ isMobile ? '4px' : '' }
                                            onChange={ handleSubject }
                                            totalLines={ 2 }
                                            width={ '100%' }
                                            borderRadius={ 1 }
                                            fontSize={ '16px' }
                                            placeholder={ Loc.currentLang.menu.enterSubject }
                                            borderWidth={ 2 }
                                        />
                                    </VStack>
                                </>
                            ) }
                            { !showSearch && showFilePicker && (
                                <Box
                                    alignItems='center'
                                    justifyContent='center'
                                    minHeight={ 220 }
                                    height={ 'auto' }
                                    borderWidth='1'
                                    borderStyle={ 'dashed' }
                                    rounded='sm'
                                    mt='1'
                                    px='3'
                                    py='1'
                                >
                                    {/* @ts-ignore */ }

                                    <FilePicker getPostGallery={ getImageGalleryFromFilePickerCreateConvo } />
                                </Box>
                            ) }
                        </>
                    ) : (
                        <>
                            <VStack
                                height={
                                    !isMobile && showEmoji
                                        ? ' calc(100vh - 675px)'
                                        : !isMobile && showConvoFilePicker
                                            ? ' calc(100vh - 470px)'
                                            : isMobile && showEmoji
                                                ? ' calc(100vh - 600px)'
                                                : isMobile && showConvoFilePicker
                                                    ? ' calc(100vh - 600px)'
                                                    : replyValue?.text?.trim()?.length < 120
                                                        ? ' calc(100vh - 425px)' :
                                                        replyValue?.text?.trim()?.length > 120
                                                            ? ' calc(100vh - 457px)' :
                                                            replyValue?.media ?
                                                                'calc(100vh - 475px)'
                                                                : isMobile
                                                                    ? 'calc(100vh - 355px)'
                                                                    : 'calc(100vh - 330px)'
                                }
                                overflowY={ 'scroll' }
                                zIndex={ -3 }
                            >
                                <FlatList
                                    ref={ refConvoList }
                                    getItemLayout={ getItemLayout }
                                    inverted
                                    data={ filteredConvoPost }
                                    renderItem={ renderItem }
                                    removeClippedSubviews={ true }
                                    disableVirtualization={ true }
                                    keyExtractor={ keyExtractor }
                                    initialNumToRender={ 10 }
                                    maxToRenderPerBatch={ 20 }
                                    windowSize={ 20 }
                                    onEndReached={ onEndReached }
                                    onEndReachedThreshold={ 0.1 }
                                    extraData={ renderedItems } // This will force the FlatList to re-render only when renderedItems changes
                                />
                            </VStack>
                        </>
                    ) }
                </VStack>
                { replyValue && (
                    <>
                        <VStack
                            backgroundColor={ 'gray.100' }
                            borderRadius={ '8px' }
                            width={ '94%' }
                            overflowY={ 'auto' }
                            position={ 'absolute' }
                            padding={ '10px' }
                            shadow={ '0px 4px 12px rgba(0, 0, 0, 0.1)' } // Adding shadow for depth
                            top={
                                ( replyValue?.text?.length < 30 && !replyValue?.media && !replyValue?.galleryPost && images && images.length > 0 ) ?
                                    'calc(100vh - 475px)' : ( !replyValue?.text && replyValue?.media && !replyValue?.galleryPost && images && images.length > 0 ) ?
                                        'calc(100vh - 495px)' : ( !replyValue?.text && replyValue?.galleryPost && images && images.length > 0 ) ?
                                            'calc(100vh - 518px)' :
                                            ( replyValue?.text?.length < 30 && !replyValue?.media && !replyValue?.galleryPost && showConvoFilePicker )
                                                ? 'calc(100vh - 455px)' :
                                                ( !replyValue?.text && replyValue?.media && showConvoFilePicker ) ?
                                                    'calc(100vh - 475px)' :
                                                    ( !replyValue?.text && replyValue?.galleryPost && showConvoFilePicker ) ?
                                                        'calc(100vh - 495px)' : ( !replyValue?.text && replyValue?.galleryPost && !showConvoFilePicker ) ?
                                                            'calc(100vh - 360px)'
                                                            : ( replyValue?.text?.length > 30 && replyValue?.text?.length < 60 && !replyValue?.media && !isMobile && !showConvoFilePicker )
                                                                ? 'calc(100vh - 312px)' :
                                                                ( replyValue?.text?.length > 30 && replyValue?.text?.length < 60 && !replyValue?.media && !isMobile && images && images.length > 0 )
                                                                    ? 'calc(100vh - 478px)' :
                                                                    ( replyValue?.text?.length > 30 && replyValue?.text?.length < 60 && !replyValue?.media && !isMobile && showConvoFilePicker )
                                                                        ? 'calc(100vh - 460px)' :
                                                                        ( replyValue?.text?.length > 60 && !replyValue?.media && images && images.length > 0 )
                                                                            ? 'calc(100vh - 505px)' :
                                                                            ( replyValue?.text?.length > 60 && !replyValue?.media && !showConvoFilePicker )
                                                                                ? 'calc(100vh - 340px)' :
                                                                                ( replyValue?.text?.length > 60 && !replyValue?.media && showConvoFilePicker )
                                                                                    ? 'calc(100vh - 485px)' : ( replyValue?.text?.length < 30 && !replyValue?.media && !showConvoFilePicker ) ? 'calc(100vh - 320px)'
                                                                                        :
                                                                                        ( replyValue?.text?.length < 30 && !replyValue?.media && showConvoFilePicker ) ? 'calc(100vh - 453px)'
                                                                                            :
                                                                                            ( replyValue?.media )
                                                                                                ? 'calc(100vh - 370px)'
                                                                                                : 'calc(100vh - 370px )'
                            }
                        >
                            <VStack width={ '100%' } padding={ '10px' } backgroundColor={ 'white' } borderRadius={ '6px' }>
                                <HStack alignItems={ 'center' } justifyContent={ 'space-between' } width={ '100%' }>
                                    <Text fontSize={ '16px' } fontWeight={ 700 }>
                                        { replyValue?.person?.title }
                                    </Text>
                                    <AVCancel callback={ setreplyValue } color={ undefined } size={ undefined } />
                                </HStack>
                                <Text fontSize={ '18px' } fontWeight={ 400 } numberOfLines={ 3 }>
                                    { replyValue?.text }
                                </Text>
                                <ReplyLoadMedia item={ replyValue } />
                            </VStack>
                        </VStack>
                    </>
                ) }
                { !isHide && (
                    <Box position={ 'fixed' } bottom={ 0 } right={ isMobile ? 0 : '' } left={ isMobile ? 0 : '' } backgroundColor={ 'white' }>
                        <VStack height={ '100%' } width='100%' right={ isMobile ? '' : '11px' }>
                            <VStack height={ '100%' } width={ isMobile ? '99%' : '100%' }>
                                <Box width={ '100%' } margin-Left={ isMobile ? '4px' : '' }>
                                    {/*<AVInputtext*/ }
                                    {/*    type={ 'text' }*/ }
                                    {/*    minRows='5'*/ }
                                    {/*    size='medium'*/ }
                                    {/*    multiline={ true }*/ }
                                    {/*    maxLength={ undefined }*/ }
                                    {/*    margin-Left='2px'*/ }
                                    {/*    onChange={ mdlConvoVisible ? handleChatMessage : handleMessage }*/ }
                                    {/*    defaultValue={ undefined }*/ }
                                    {/*    width={ undefined } value={ value }*/ }
                                    {/*    height={ '60px' }*/ }
                                    {/*    label={ Loc.currentLang.placeholderText.messagePlaceholder } placeholder={ '' } />*/ }
                                    { isTyping && (
                                        <HStack marginLeft={ '10px' } marginBottom={ '7px' }>
                                            <Text fontSize={ '16px' } color={ 'gray.400' }>{ typingUserName
                                            }</Text>
                                        </HStack>
                                    ) }
                                    <div style={ { display: "flex", flexDirection: "row", marginLeft: '5px', width: '450px' } }>
                                        <Box width={ isMobile ? '70%' : '87%' } marginTop={ '3px' } left={ '5px' }>
                                            <AVInputtext
                                                type={ 'text' }
                                                minRows='100f'
                                                size='medium'
                                                multiline={ true }
                                                maxLength={ undefined }
                                                onChange={ mdlConvoVisible && value?.trim()?.length == 0 ? handleChatMessage : mdlConvoVisible &&  value?.trim()?.length > 0 ?debouncedHandleChatMessage : handleMessage }
                                                defaultValue={ undefined }
                                                width={ '100%' }
                                                value={value?.trim()?.length == 0 ? value : undefined}
                                                height={ isMobile ? '25px' : '40px' }
                                                label={ Loc.currentLang.menu.typeYourMsgHere }
                                                placeholder={ undefined }
                                                inputRef={ inputRef }
                                                autoFocus={ !isMobile && mdlConvoVisible }
                                                onKeyPress={ handleKeyPress }

                                            />
                                        </Box>
                                        <Button
                                            left={ isMobile ? '' : '3px' }
                                            isDisabled={
                                                mdlConvoVisible
                                                    ? !value && media?.length == 0
                                                    :
                                                    ( ( toFK.length > 1 && !subject ) ||
                                                        ( value?.trim()?.length === 0 && media?.length === 0 ) ||
                                                        toFK?.length === 0 )
                                            }
                                            isLoading={ isLoading ? isLoading : Loading }

                                            onPress={
                                                !isOpenConvo
                                                    ? handleSendMessage
                                                    : itemEdit.length !== 0
                                                        ? () => handleUpdatePost( itemEdit )
                                                        : () => handleSendConvoMessage( convo )
                                            }
                                            marginLeft={ '10px' }
                                            backgroundColor={ isLoading || Loading ? "#32AE78" : 'white' }
                                        >
                                            <PaperPlaneIcon marginTop={ '5px' } size={ '25px' } color={ '#32AE78' } />
                                        </Button>

                                        {/*<AttachmentButton style={ { fontSize: "1.2em", paddingLeft: "0.2em", paddingRight: "0.2em" } } />*/ }
                                        {/*<InfoButton onClick={ () => alert( "Important message!" ) } style={ { fontSize: "1.2em", paddingLeft: "0.2em", paddingRight: "0.2em" } } />*/ }
                                    </div>
                                </Box>
                            </VStack>

                            { showConvoFilePicker && (
                                <Box
                                    left={ isMobile ? '8px' : '' }
                                    alignItems='center'
                                    justifyContent='center'
                                    maxHeight={ 170 }
                                    height={ 'auto' }
                                    borderWidth='1'
                                    rounded='sm'
                                    mt='1'
                                    px='3'
                                    py='1'
                                    borderStyle={ 'dashed' }
                                    position={ 'absolute' }
                                    bottom={ isMobile ? '112px' : '115px' }
                                    background={ '#fff' }
                                    width={ isMobile ? '90%' : '100%' }
                                >
                                    <div>
                                        <input
                                            type='file'
                                            accept='image/jpeg,image/jfif,image/png,image/webp,image/gif,video/mp4,'
                                            multiple
                                            hidden
                                            ref={ imageInputRef }
                                            onChange={ handleImages }
                                        />

                                        { images && images.length > 0 ? (
                                            <RenderImages
                                                images={ images }
                                                imageInputRef={ imageInputRef }
                                                setImages={ setImages }
                                                media={ media }
                                                setIsdelete={ setIsdelete }
                                                setMedia={ setMedia }
                                                isImageProcessing={ isImageProcessing }
                                                editingImage={ editingImage }
                                                setUpdateConvoImage={ setUpdateConvoImage }
                                                setEditingImage={ setEditingImage }
                                            />
                                        ) : (
                                            <Box
                                                borderWidth={ 1 }
                                                borderRadius={ 5 }
                                                overflow={ 'hidden' }
                                                borderColor={ 'avcolors.black' }
                                                borderStyle={ 'none' }
                                                maxH={ '300px' }
                                                height='100%'
                                                width='100%'
                                                overflowY={ 'auto' }
                                            >
                                                <Pressable onPress={ () => imageInputRef?.current.click() }>
                                                    <VStack
                                                        alignItems={ 'center' }
                                                        justifyContent={ 'center' }
                                                        minH={ '130px' }
                                                        space={ 2 }
                                                    >
                                                        <Box backgroundColor={ 'AVColor.grey' } p={ 2 } borderRadius='full'>
                                                            <AddIcon color={ 'AVColor.secondary' } />
                                                        </Box>
                                                        <Text fontSize={ 14 } color={ 'AVColor.grey' }>
                                                            { Loc.currentLang.menu.imageOrVideo }
                                                        </Text>
                                                    </VStack>
                                                </Pressable>
                                            </Box>
                                        ) }
                                    </div>
                                </Box>
                            ) }
                            <HStack
                                padding={ '8px' }
                                backgroundColor={ 'white' }
                                width={ isMobile ? '99%' : '100%' }
                                zIndex={ -3 }
                            >
                                <Pressable
                                    onPress={ () =>
                                    {
                                        if ( isMobile )
                                        {
                                            Keyboard.dismiss();
                                            setTimeout( () =>
                                            {
                                                setShowEmoji( !showEmoji );
                                            }, 400 );
                                        }
                                        else
                                            setShowEmoji( !showEmoji );

                                    } }
                                >
                                    <HappyIcon size={ '20px' } color={ 'AVColor.primary' } padding={ '5px' } />
                                </Pressable>
                                <Pressable
                                    ref={ addPhotoIconRef }
                                    onPress={ () =>
                                    {
                                        setShowEmoji( false );
                                        handleFilePicker();
                                    } }>

                                    <AddPhotoIcon size={ '20px' } color={ 'AVColor.primary' } padding={ '5px' } />
                                </Pressable>
                                <AttachIcon size={ '20px' } color={ 'transparent' } padding={ '5px' } />
                                <GifIcon size={ '20px' } color={ 'transparent' } padding={ '5px' } />
                                <LikeIcon size={ '20px' } color={ 'transparent' } padding={ '5px' } />
                                {/*<Button*/ }
                                {/*    bgColor={ 'AVColor.primary' }*/ }
                                {/*    borderRadius={ 19 }*/ }
                                {/*    width={ '130px' }*/ }
                                {/*    height={ '30px' }*/ }
                                {/*    alignContent={ 'space-around' }*/ }
                                {/*    alignSelf={ 'end' }*/ }
                                {/*    isDisabled={*/ }
                                {/*        mdlConvoVisible*/ }
                                {/*            ? !value && media?.length == 0*/ }
                                {/*            :*/ }
                                {/*            ( ( toFK.length > 1 && !subject ) ||*/ }
                                {/*                ( value?.trim()?.length === 0 && media?.length === 0 ) ||*/ }
                                {/*                toFK?.length === 0 )*/ }
                                {/*    }*/ }
                                {/*    marginLeft={ '100px' }*/ }
                                {/*    isLoading={ isLoading ? isLoading : Loading }*/ }
                                {/*    isLoadingText={ isLoading ? 'Sending...' : 'Pic Loading..' }*/ }
                                {/*    onPress={*/ }
                                {/*        mdlConvoVisible*/ }
                                {/*            ? () =>*/ }
                                {/*            {*/ }
                                {/*                editingImage ?*/ }
                                {/*                    handleUpdatePost( itemEdit ) :*/ }
                                {/*                    handleSendConvoMessage( convo )*/ }
                                {/*            }*/ }
                                {/*            : handleSendMessage*/ }
                                {/*    }*/ }
                                {/*>*/ }
                                {/*    <HStack width={ '100%' }>*/ }
                                {/*        <PaperPlaneIcon marginTop={ '7px' } size={ 5 } color={ '#ffffff' } />*/ }
                                {/*        <Text fontSize={ 12 } color={ '#ffffff' } lineHeight={ '2.2rem' }>*/ }
                                {/*            { Loc.currentLang.menu.sendMessage }*/ }
                                {/*        </Text>*/ }
                                {/*    </HStack>*/ }
                                {/*</Button>*/ }
                            </HStack>
                        </VStack>
                    </Box>
                ) }
                { isHide && (
                    <HStack
                        position={ 'fixed' }
                        bottom={ '20px' }
                        width={ '22%' }
                        left={ isMobile ? '200px' : '' }
                        justifyContent={ 'flex-end' }
                        space={ 5 }
                    >
                        <Pressable
                            fontSize={ '16px' }
                            color={ '#00A7BC' }
                            alignSelf={ 'center' }
                            onPress={ () =>
                            {
                                setIsHide( false );
                                setMdlConvoVisible( true );
                                setIsOpenConvo( true )
                            } }
                        >
                            { Loc.currentLang.menu.cancel }
                        </Pressable>
                        <AVButtonRoundedFull
                            btnText={ Loc.currentLang.menu.save }
                            callback={ handlePublish }
                            loading={ isLoading } px={ undefined } height={ undefined } isLoadingText={ undefined }
                            isDisabled={ ( ( subject?.trim()?.length == 0 && toFK.length > 1 ) || toFK?.length == 0 ) }
                            isLoading={ undefined } />
                    </HStack>
                ) }
                { showEmoji && (
                    <Box
                        ref={ menuRef }
                        position={ 'absolute' }
                        bottom={ '160px' }
                        width={ '100%' }
                        height={ '33%' }
                        left={ [ `${ 1 - 1 }px` ] }
                    >
                        <EmojiPicker onEmojiClick={ onEmojiClick } />
                    </Box>
                ) }
            </VStack>
            { isOpen && (
                <Box position='fixed' bottom={ 0 } right={ 0 } top={ 0 } left={ 0 }>
                    <div
                        className={ `create_post_popups ${ isMobile && 'create_post_popup_mobile' }` }
                        style={ {
                            width: isMobile ? dimensions.width - 15 : '382px',
                            height: selectItem?.person?.id !== currentUser?.id ? '175px' : '270px',
                            marginTop: '12px',
                            left: isMobile ? '194px' : ''
                        } }
                    >
                        {/*Dropdown for my*/ }
                        <VStack margin={ '24px' } width={ 'auto' } ref={ menuRef }>

                            <HStack
                                marginTop="20px"
                                zIndex={ 999 }
                                width="100%"
                                height="35px"
                                space={ 2 }
                                backgroundColor="gray.100"
                                justifyContent="space-around"
                                px={ 2 }
                                py={ 1 }
                                borderRadius="full"
                            >
                                { feedbackOptions.map( ( option ) => (
                                    <Pressable key={ option?.impression } onPress={ () => handlePress( option.impression ) }>
                                        <HStack>
                                            <Tooltip title={ option?.title } enterDelay={ 100 } leaveDelay={ 0 } arrow placement="top">
                                                <Image source={ { uri: option.uri } } alt={ option.title } size="24px" />
                                            </Tooltip>
                                        </HStack>
                                    </Pressable>
                                ) ) }
                            </HStack>

                            <Divider marginY={ '5px' } />
                            { selectItem?.person?.id === currentUser?.id && ( <Button
                                width={ '100%' }
                                height={ '50px' }
                                paddingX={ '40px' }
                                background={ 'white' }
                                alignItems={ 'flex-start' }
                                justifyContent={ 'flex-start' }
                                _hover={ {
                                    backgroundColor: 'gray.100'
                                } }
                                onPress={ () => { HandleEditSet( selectItem ); setreplyValue(); setIsOpen( false ); } }
                            >
                                <Text
                                    paddingLeft={ '30px' }
                                    fontSize={ '14px' }
                                    fontFamily={ 'Roboto' }
                                >
                                    { Loc.currentLang.menu.edit }
                                </Text>
                            </Button> ) }
                            { selectItem?.person?.id === currentUser?.id && ( <Divider marginY={ '5px' } /> ) }
                            <Button
                                width={ '100%' }
                                height={ '50px' }
                                paddingX={ '40px' }
                                background={ 'white' }
                                alignItems={ 'flex-start' }
                                justifyContent={ 'flex-start' }
                                _hover={ {
                                    backgroundColor: 'gray.100'
                                } }
                                onPress={ () => { setreplyValue( selectItem ); setIsOpen( false ) } }
                            >
                                <Text
                                    paddingLeft={ '30px' }
                                    fontSize={ '14px' }
                                    fontFamily={ 'Roboto' }
                                >
                                    { Loc.currentLang.menu.reply }
                                </Text>
                            </Button>
                            { selectItem?.person?.id === currentUser?.id && ( <Divider marginY={ '5px' } /> ) }
                            { selectItem?.person?.id === currentUser?.id && ( <Button
                                width={ '100%' }
                                height={ '50px' }
                                paddingX={ '40px' }
                                alignItems={ 'flex-start' }
                                justifyContent={ 'flex-start' }
                                background={ isDeleteLoading ? '#32AE78' : 'white' }
                                isLoading={ isDeleteLoading }

                                _hover={ {
                                    backgroundColor: 'gray.100'
                                } }
                                onPress={ handleDeleteMessage }
                            >
                                <Text
                                    paddingLeft={ '30px' }
                                    fontSize={ '14px' }
                                    fontFamily={ 'Roboto' }
                                >
                                    { Loc.currentLang.menu.delete }
                                </Text>
                            </Button> ) }
                        </VStack>
                    </div>
                </Box>
            ) }

            {
                isConfirmDelete &&
                <AVConfirmationModal showconfirmationPopup={ isConfirmDelete } setshowconfirmationPopup={ setisConfirmDelete }
                    Confirmationtext={ Confirmationtext } type={ selectType } item={ itemSelect } function={ selectType == 'DeleteConvo' ? handleDelteconvo : '' } isLoader={ isLoader } />
            }
        </Box>
    )

}

export default React.memo( Messages );