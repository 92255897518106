//@ts-nocheck
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowDimension } from 'Utility/WindowDimensions';
import { Box, Button, Center, Image } from 'native-base';
import { FeedsLoader, Loader } from 'components/loaders';
import CreatePostpopup from 'Screens/Home/CreatePostpopup';
import Offline from 'react-offline';
import msg from "../../assets/images/create-message.png";
import Card from "../../components/posts/Card";
import useFeeds from '../../Hooks/useFeeds';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import { store } from '../../Redux/store';
import { setFeedsScrollUpdate, setFeedsUpdate } from '../../Redux/Actions/feedsAction';

// Helper Hook to handle post permissions logic
const usePostPermissions = ( userInfo, currentUser ) =>
{
    const isMember = userInfo?.members?.some( ( x ) => x?.id === currentUser?.id );
    const isPlayerOfEntity = userInfo?.players?.some( ( x ) => x?.id === currentUser?.id );
    const isAllowMemberPost = isMember && userInfo?.preferences?.memberPost !== "N";
    const isAllowPlayerPost = isPlayerOfEntity && userInfo?.preferences?.playerPost !== "N";
    const visibilityPermission = isMember
        ? userInfo?.preferences?.memberPost
        : isPlayerOfEntity
            ? userInfo?.preferences?.playerPost
            : "F";

    return {
        isAllowMemberPost,
        isAllowPlayerPost,
        visibilityPermission,
    };
};

const AVFeeds = ( props ) =>
{
    const dispatch = useDispatch();
    const currentUser = useSelector( ( state ) => state.user.user[ 0 ] );
    const userFeeds = useSelector( ( state ) => state.feeds.feeds[ 0 ] ) ?? [];
    const { userInfo } = props;

    const { isAllowMemberPost, isAllowPlayerPost, visibilityPermission } = usePostPermissions( userInfo, currentUser );
    const [ showModal, setShowModal ] = useState( false );
    const [ processedAllPostsInRedux, setProcessedAllPostsInRedux ] = useState( false );
    const [ isFetchingNextPage, setFetchingNextPage ] = useState( false );
    const [ feedChunks, setFeedChunks ] = useState( [] );
    const [ feedsProcessed, setFeedsProcessed ] = useState( 0 );
    const [ hasMorePosts, setHasMorePosts ] = useState( true );  // Track if there are more posts
    const feedsScrollUpdate = useSelector( ( state : RootStateOrAny ) => state.feeds.feedsScrollUpdate );

    const dimensions = useWindowDimension();
    const isMobile = dimensions?.width <= 500 || false;

    const { fetchPosts, processedAllPosts } = useFeeds( props );

    // Memoized Loader Component
    const MemoizedLoader = React.memo( () => (
        <Box m={ 5 }>
            <Loader />
        </Box>
    ) );

    // Memoized Card Component
    const MemoizedCard = React.memo( Card );

    // Process and chunk the userFeeds
    const processFeeds = useCallback( async () =>
    {
        // Get the current feeds from Redux store
        const currentFeedsInRedux = store.getState().feeds.feeds[ 0 ] ?? [];

        // Sort the feeds in descending order based on createDate
        const sortedFeeds = currentFeedsInRedux.sort( ( a, b ) =>
        {
            // Ensure createDate is compared correctly as a date
            return new Date( b.createDate ) - new Date( a.createDate );
        } );

        const chunkSize = 50;
        const totalFeeds = sortedFeeds.length;
        const remainingFeeds = totalFeeds - feedsProcessed;

        // If there are more posts to process
        if ( remainingFeeds > 0 )
        {
            setFetchingNextPage( true );

            // Get the next chunk of posts based on `feedsProcessed`
            const nextChunk = sortedFeeds.slice( feedsProcessed, feedsProcessed + chunkSize );

            // If we have new posts to add
            if ( nextChunk.length > 0 )
            {
                setFeedChunks( ( prevChunks ) => [ ...prevChunks, ...nextChunk ] );
                setFeedsProcessed( feedsProcessed + nextChunk.length );
                setFetchingNextPage( false );
            }
        } else
        {
            setProcessedAllPostsInRedux( true ); // All Redux data processed
        }
    }, [ feedsProcessed ] );

    // Effect to detect and handle new posts from Redux userFeeds
    useEffect( () =>
    {
        // Listen to changes in userFeeds (from Redux) and append new posts to the top
        const currentFeedsInRedux = store.getState().feeds.feeds[ 0 ] ?? [];

        // Sort the feeds in descending order based on createDate
        const sortedFeeds = currentFeedsInRedux.sort( ( a, b ) =>
        {
            return new Date( b.createDate ) - new Date( a.createDate );
        } );

        // Add new posts to the top (prepend them)
        if ( sortedFeeds.length > feedChunks.length )
        {
            const newPosts = sortedFeeds.slice( 0, sortedFeeds.length - feedChunks.length );
            setFeedChunks( ( prevChunks ) => [ ...newPosts, ...prevChunks ] );
        }
    }, [ userFeeds, showModal ] );

    // Function to load more posts (triggering fetchNextPage if needed)
    const loadPosts = async () =>
    {
        if ( processedAllPostsInRedux )
        {
            // If all userFeeds are processed, fetch more posts
            if ( !isFetchingNextPage && hasMorePosts )
            {
                setFetchingNextPage( true );

                try
                {
                    // Fetch the next set of posts
                    const newPosts = await fetchPosts();

                    // If new posts are returned, update the feedChunks state
                    if ( newPosts.length > 0 )
                    {
                        setFeedChunks( ( prevChunks ) => [ ...prevChunks, ...newPosts ] );
                    } else if ( processedAllPosts )
                    {
                        // No more posts available from API
                        setHasMorePosts( false ); // Disable further fetching
                    }
                } catch ( error )
                {
                    console.error( "Error fetching posts:", error );
                    // Optionally handle errors (e.g., show a message to the user)
                } finally
                {
                    setFetchingNextPage( false ); // Reset loading state
                }
            }
        } else
        {
            // If there are still unprocessed posts in Redux, process them
            processFeeds();
        }
    };

    React.useEffect( () =>
    {
        let isMounted = true;
        if ( isMounted )
        {
            if ( feedsScrollUpdate )
            {
                scrollToTop()
                dispatch( setFeedsScrollUpdate( false ) );
            }
            return () =>
            {
                isMounted = false; // Set the flag to false when the component is unmounted
                // Cancel subscriptions or perform any cleanup tasks here
            };
        }

    }, [ feedsScrollUpdate ] );


    const scrollToTop = () =>
    {
        window.scrollTo( { top: 0, behavior: 'smooth' } );
    };

    // Effect to process userFeeds on load or when the processed count changes
    useEffect( () =>
    {
        // Reset feed chunks and processed state whenever props change
        setFeedChunks( [] );
        setFeedsProcessed( 0 );
        setProcessedAllPostsInRedux( false );
        setHasMorePosts( true ); // Reset hasMorePosts
    }, [ props.userInfo, props.page, props.id ] );

    // Filter posts if the page is Profile
    const filteredPosts = useMemo( () =>
    {
        if ( props?.page === 'Profile' && props?.id )
        {
            return feedChunks.filter( ( post ) => post?.owner?.id === props?.id );
        }
        return feedChunks;
    }, [ props?.page, props?.id, feedChunks ] );

    return (
        <Box>
            { !showModal && (
                <Offline>
                    { ( { isOffline } ) =>
                        !isOffline && (
                            <Button
                                bgColor="AVColor.primary"
                                borderRadius="full"
                                padding={ 3 }
                                height="45px"
                                width="45px"
                                onPress={ () => setShowModal( true ) }
                                zIndex={ 1 }
                                position="fixed"
                                bottom={ isMobile ? '110px' : '10px' }
                                marginLeft={ [ props?.offW ? props?.offW - 60 : 100 ] }
                            >
                                <Image source={ msg } alt="Create Post" size="25px" />
                            </Button>
                        )
                    }
                </Offline>
            ) }

            <div className="feed-container">
                <InfiniteScroll
                    pageStart={ 0 }
                    loadMore={ loadPosts }
                    hasMore={ hasMorePosts && !isFetchingNextPage } // Update hasMore logic
                    loader={
                        <Center key={ 0 }>
                            <MemoizedLoader />
                        </Center>
                    }
                    useWindow={ true }
                    threshold={ 200 }
                >
                    <div className="posts-list">
                        { filteredPosts.length > 0 &&
                            filteredPosts.map( ( post, i ) => (
                                <div key={ `${ post?.id }+${ i }` }>
                                    <MemoizedCard
                                        key={ `${ post.id }+${ i }` }
                                        iscommentAccess={ props?.previewdata?.iscommentAccess }
                                        data={ post }
                                        previewdata={ props?.previewdata }
                                        userInfo={ props?.page === 'Home' ? currentUser : props?.previewdata?.userInfo }
                                    />
                                </div>
                            ) ) }
                    </div>
                </InfiniteScroll>

                {/* Loader for fetching next page */ }
                { isFetchingNextPage && filteredPosts.length > 0 && (
                    <Center>
                        <Box m={ 5 }>
                            <Loader />
                        </Box>
                    </Center>
                ) }

                { filteredPosts.length == 0 && (
                    <FeedsLoader />
                ) }
            </div>

            {/* Modal to create a post */ }
            { showModal && (
                <CreatePostpopup
                    visibilityPermission={ visibilityPermission }
                    setShowCreatePost={ setShowModal }
                />
            ) }
        </Box>
    );
};

export default React.memo( AVFeeds );
