import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Moment from 'moment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Button, Center, CloseIcon, Divider, FlatList, HStack, Image, Input, Modal, Pressable, ScrollView, Text, Tooltip, VStack } from 'native-base';
import { CalendarIcon } from '@mui/x-date-pickers';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { AddIcon, AddPhotoIcon, BugReportIcon, DeleteIcon, EditIcon, FeatureRequestIcon, FeedbackIcon, MoreHorizontalIcon, Profile, ViewIcon, GroupChatIcon } from '../../assets/icons/icon-assets';
import Dropdown from '../../assets/images/dropdown.svg';
import helping from '../../assets/images/helping.png';
import inform from '../../assets/images/inform.png';
import suspend from '../../assets/images/unsuspend.png';
import PartlyCloudys from '../../assets/images/PartlyCloudys.png';
import PatchyFog from '../../assets/images/PatchyFog.png';
import ChanceShowersAndThunderstorms from '../../assets/images/ChanceShowersAndThunderstorms.png';
import Rainy from '../../assets/images/Rainy.png';
import MostlyClear from '../../assets/images/MostlyClear.png';
import SlightChanceRainShowers from '../../assets/images/SlightChanceRainShowers.png';
import MostlyCloudy from '../../assets/images/MostlyCloudy.png';
import ChanceSnowShowersthenSunny from '../../assets/images/ChanceSnowShowersthenSunny.png';
import ChanceSnowShowers from '../../assets/images/ChanceSnowShowers.png';
import ChanceRainAndSnowthenCloudy from '../../assets/images/ChanceRainAndSnowthenCloudy.png';
import SlightChanceLightRain from '../../assets/images/SlightChanceLightRain.png';
import PartlySunnythenChanceShowersAndThunderstorms from '../../assets/images/PartlySunnythenChanceShowersAndThunderstorms.png';
import SlightChanceLightRainthenCloudy from '../../assets/images/SlightChanceLightRainthenCloudy.png';
import SlightChanceRainShowersthenMostlySunny from '../../assets/images/SlightChanceRainShowersthenMostlySunny.png';
import MostlySunnythenSlightChanceRainShowers from '../../assets/images/MostlySunnythenSlightChanceRainShowers.png';
import ChanceRainAndSnow from '../../assets/images/ChanceRainAndSnow.png';
import fogday from '../../assets/images/fogday.png';
import clearnight from '../../assets/images/clearnight.png';
import { Weather } from '../../AVCore/Weather';
import * as AV from '../../AVCore/AVCore';
import { AVEntity } from '../../AVCore/AVEntity';
import * as AVI from '../../AVCore/Interfaces/AV.Interfaces';
import { IPlayer } from '../../AVCore/Interfaces/AV.Interfaces';
import { Media } from '../../AVCore/Media';
import { Server } from '../../Platform/Server';
import settings from '../../Platform/settings.json';
import { Loc } from '../../App/Loc';
import { setEntitydata, setLocation, setUser } from '../../Redux/Actions/userActions';
import { useWindowDimension } from 'Utility/WindowDimensions';
import AVBody from 'components/common/AVBody';
import { AVButtonRoundInvert, AVButtonRoundedFull } from 'components/common/AVButtons';
import AVCancel from 'components/common/AVCancel';
import PreviewAlbums from 'components/common/PreviewAlbums';
import { PageLoader } from 'components/loaders';
import PlayersCard from '../../components/teams/PlayersCard';
import ParticipantsCard from '../../components/teams/ParticipantsCard';
import StaffsCard from '../../components/teams/StaffsCard';
import Loader from '../../components/common/Loader';
import Createalbumpopup from '../Home/Createalbumpopup';
import AVInputtext from '../../components/AVInputtext';
import TabHeader from '../../components/common/TabHeader';
import AVCreateEventModal from '../../components/common/AVCreateEventModal';
import ProfileHeader from '../../components/common/ProfileHeader';
import ProfilePic from '../../components/common/ProfilePicture';
import RenderEvents from '../Events/RenderEvents';
import RenderSeriesEvents from '../Events/RenderSeriesEvents';
import AVCreateTeam from '../../components/common/AVCreateTeam';
import AVPersonaModal from '../../components/common/AVPersonModal';
import Filter from '../../components/common/AVFilter';
import AVConfirmationModal from '../../components/common/AVConfirmationModal';
import CustomToast from '../../Hooks/useCustomToast';
import AVComDropdonwn from '../../components/common/AVComDropdown';
import AVSelectDropdown from '../../components/common/AVSelectDropdown';
import { AVCalendarView } from '../../components/common/calendar/AVCalendarView';
import useConvoChat from '../../Hooks/useConvoChat';
import { setConvo } from '../../Redux/Actions/convoActions';
import AVFeeds from '../Feeds/AVFeeds';

const Teams = ( props : any ) =>
{
    const params = useParams();
    const location : any = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const type = params?.type;
    const viewTeam = useMemo( () => location?.state?.team, [ location?.state?.team ] );
    const seriesEvent = useMemo( () => location?.state?.series, [location?.state?.series] )
    const orgFK = location?.state?.orgFK;
    const teamType = location?.state?.teamType;
    const Feedbacktype = useMemo( () => location?.state?.type, [ location?.state?.type ] );
    const FeedBackId = location?.state?.team?.id;
    const region = params?.region;
    const EntityId = params?.entityId;
    const currentUser : any = useSelector( ( state : RootStateOrAny ) => state?.user?.user[ 0 ] );
    const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    let userconvo : any = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const rightRef : any = useRef( null );
    const [ helptext, sethelptext ] = React.useState<any>();
    const [ helptype, sethelptype ] = React.useState<any>();
    const [ staffemail, setstaffemail ] = useState( '' );
    const [ staffphone, setstaffphone ] = useState( '' );
    const [ staffpic, setstaffpic ] = useState<any>( '' );
    const [ albumlist, setalbumlist ] = React.useState<any>( [] );
    const [ filesList, setfilesList ] = React.useState( [] );
    const [ isDocumentPage, setIsDocumentPage ] = useState( false );
    const [ isCreate, setIsCreate ] = useState( location?.state?.isCreate );
    const [ isUpdate, setIsUpdate ] = useState( true );
    const [ isLoader, setLoader ] = useState( false );
    const [ isModalLoader, setModalLoader ] = useState( false );
    const [ isShowEdit, setIsShowEdit ] = useState<any>( false );
    const [ isEditEntireSeries, setIsEditEntireSeries ] = useState<any>( false );
    const [ showAddProfileModal, setShowAddProfileModal ] = useState( false );
    const [ showAddSeasonModal, setShowAddSeasonModal ] = useState( false );
    const [ isPlayer, setIsPlayer ] = useState( true );
    const [ isStaff, setIsStaff ] = useState( false );
    const [ isParticipant, setIsParticipant ] = useState( false );
    const [ showAddEventsModal, setShowAddEventsModal ] = useState( false );
    const [ showEditPopup, setShowEditPopup ] = React.useState( false );
    const [ memberList, setmemberList ] = React.useState<any>( [] );
    const [ GroupList, setGroupList ] = React.useState<any>( [] );
    const [ orgList, setOrgList ] = React.useState<any>( [] );
    const [ playersList, setPlayersList ] = React.useState<any>( [] );
    const [ participantList, setIsParticipantList ] = useState<any>();
    const [ staffList, setStaffList ] = React.useState<any>( [] );
    const [ Editstaffitem, setEditstaffitem ] = React.useState<any>();
    const [ eventsList, setEventsList ] = React.useState<any>( [] );
    const [ leagueTeamFK, setLeagueTeamFK ] = useState<any>();
    const [ isMascotLoader, setMascotLoader ] = useState( false );
    const [ teamName, setteamName ] = useState( location?.state?.searchInput ? location?.state?.searchInput : '' );
    const [ isAlbumView, setisAlbumView ] = React.useState( false );
    const [ links, setLinks ] = useState( '' );
    const [ intro, setIntro ] = useState( '' );
    const [ activity, setActivity ] = useState();
    const [ activityType, setActivityType ] = useState( null );
    const [ seasonList, setSeasonList ] = React.useState<any>( [] );
    const [ playerName, setPlayerName ] = useState( '' );
    const [ playerEmailAddress, setPlayerEmailAddress ] = useState( '' );
    const [ playerPhone, setPlayerPhone ] = useState( '' );
    const [ staffName, setStaffName ] = useState( '' );
    const [ staffEmailAddress, setStaffEmailAddress ] = useState( '' );
    const [ isValid, setIsValid ] = useState( true );
    const [ staffPhone, setStaffPhone ] = useState( '' );
    const [ isTeamsPage, setIsTeamsPage ] = useState( true );
    const [ isGroup, setIsGroup ] = useState( false );
    const [ isOrganization, setIsOrganization ] = useState( false );
    const [ seriesEventsList, setSeriesEventsList ] = React.useState( [] );
    const [ isTeamClub, setIsTeamClub ] = useState( false );
    const [ isFeebackGroup, setIsFeedBackGroup ] = useState( false );
    const [ isPlayerPage, setIsPlayerPage ] = useState( false );
    const [ isStaffPage, setIsStaffPage ] = useState( false );
    const [ isSeasonPage, setIsSeasonPage ] = useState( false );
    const [ isEventPage, setIsEventPage ] = useState( false );
    const [ isPhotoPage, setIsPhotoPage ] = useState( false );
    const [ isOccurencePage, setIsOccurencePage ] = useState( false );
    const [ pageLoader, setPageLoader ] = useState( true );
    const [ isPicLoader, setIsPicLoader ] = useState( false );
    const [ isMembersPage, setIsMembersPage ] = useState( false );
    const [ isLoading, setIsLoading ] = useState( false );
    const [ isMyFavourite, setMyFavourite ] = React.useState( currentUser?.favs?.filter( ( x ) => x?.id === viewTeam?.id )[ 0 ] ? true : false );
    const [ checkvalue, setcheckvalue ] = React.useState( true );
    const [ checkvaluedata, setcheckvaluedata ] = React.useState<any>( '' );
    const [ profile, setProfile ] = useState<any>( { background: null, pic: null, thumb: null, gallery: null, previousPic: null, text: null, color: null } );
    const [ eventType, setEventType ] = useState<any>( 'All' );
    const [ repeatWeek, setRepeatWeek ] = useState<any>();
    const [ filteredResults, setfilteredResults ] = useState<any>( [] );
    const [ isAdminView, setIsAdminView ] = React.useState( false );
    const [ isMember, setIsMember ] = useState( false );
    const pathName = window.location.pathname;
    const [ showTempList, setshowTempList ] = React.useState( [] );
    const [ staffRole, setStaffRole ] = useState<any>( '' );
    const [ teamsList, setTeamsList ] = React.useState<AVI.ITeam[]>( [] );
    const [ seasonName, setseasonName ] = useState( '' );
    const [ locationaddress, setlocationaddress ] = useState<any>( [] );
    const [ locationEntity, setLocationEntity ] = useState<any>();
    const [ googlemap, setgooglemap ] = React.useState();
    const [ isActive, setIsActive ] = React.useState( false );
    const [ isActiveRole, setIsActiveRole ] = React.useState( false );
    const [ activityRole, setActivityRole ] = useState( null );
    const [ showEditaccountsettingPopup, setshowEditaccountsettingPopup ] = React.useState( false );
    const [ isFollowing, setIsFollowing ] = React.useState( false );
    const [ requestSent, setRequestSent ] = React.useState( false );
    const user = useSelector( ( state : RootStateOrAny ) => state?.user?.user[ 0 ] );
    const userfeeds = useSelector( ( state : RootStateOrAny ) => state.feeds.feeds[ 0 ] );
    const [ isStaffentity, setisStaffentity ] = React.useState( false );
    const [ isMemberEntity, setisMemberEntity ] = React.useState( false );
    const [ isPlayerEntity, setisPlayerEntity ] = React.useState( false );
    const [ showFileuploadModal, setshowFileuploadModal ] = useState( false );
    const [ contactList, setcontactList ] = React.useState<any>();
    const [ error, setError ] = useState<any>( null );
    const dimensions = useWindowDimension();
    const mobile = dimensions?.width < 600 || false;
    const [ visible, setVisible ] = useState( false );
    const [ datas, setdatas ] = useState( false );
    const [ isDeleted, setIsDeleted ] = useState( false );
    const [ isSuspended, setIsSuspended ] = useState( false );
    const [ isStaffEdit, setIsStaffEdit ] = useState( false );
    const [ isDropDown, setIsDropDown ] = useState<boolean>( false );
    const isMobile = dimensions?.width <= 500 || false;
    const isLMobile = dimensions?.width <= 767 || false;
    const isTab = dimensions?.width <= 1300 || false;
    const [ typeevent, settypeevent ] = useState( 'Social' );
    const [ isEditPlayerDetails, setIsEditPlayerDetails ] = useState( false );
    const [ menu, setmenu ] = React.useState<any>( '' );
    const [ newname, setnewname ] = React.useState<any>();
    const [ newmail, setnewmail ] = React.useState<any>();
    const [ data, setEventData ] = React.useState<any>( [] );
    const [ isHomePage, setIsHomePage ] = useState( true );
    const [ isshowteamsList, setisshowteamsList ] = React.useState<any>();
    const [ fixedWidth, setFixedWidth ] = React.useState<any>();
    const [ showFields, setShowFields ] = useState( false );
    const [ updateField, setIsUpdateField ] = useState( false );
    const [ fieldName, setIsFieldName ] = useState( '' );
    const [ isLoadingField, setIsLoadingField ] = useState( false );
    const [ fieldList, setFieldList ] = useState( false );
    const [ datafield, setIsDataField ] = useState<any>( [] );
    const [ siteField, setSiteField ] = useState<any>();
    const [ isActives, setIsActives ] = useState( false );
    const [ filterevent, setFilterEvent ] = useState( false );
    const [ isGame, setIsGame ] = useState( false );
    const [ isPractice, setIsPractice ] = useState( false );
    const [ mapField, setIsMapField ] = useState();
    const [ isFieldsName, setIsFieldsName ] = useState();
    const [ PlayersList, setplayerList ] = useState( [] );
    const [ oldSeason, setoldSeason ] = useState<any>();
    const [ staffReq, setIsStaffReq ] = useState<any>( false );
    const [ childOrgReq, setIsChildOrgReq ] = useState( false );
    const [ StaffDesc, setIsStaffDesc ] = useState( '' )
    const [ justi, setJusti ] = useState( '' );
    const [ childOrgOne, setChildOrg ] = useState( '' );
    const [ selctChildOrg, SetSelctChildOrg ] = useState<any>();
    const [ chiledorgId, setChildorgId ] = useState<any>( '' );
    const [ isDropList, setIsDropList ] = useState( false );
    const [ isparticipantspage, setisparticipantspage ] = React.useState( false );
    const [ isResourses, setisResourses ] = React.useState( false );
    const [ weather, setweather ] = React.useState<any>();
    const [ weatherURL, setweatherURL ] = React.useState<any>();
    const [ status, setStatus ] = useState( "All" )
    const [ resoursesList, setresoursesList ] = React.useState( [] );
    const [ editresourses, seteditresourses ] = React.useState( false );
    const [ Owner, setOwner ] = useState( null );
    const [ showAddResourceModal, setshowAddResourceModal ] = React.useState( false );
    const [ showResourceModal, setshowResourceModal ] = React.useState( false );
    const [ description, setdescription ] = React.useState<any>( '' );
    const [ quantity, setquantity ] = React.useState<any>( 1 );
    const [ isSearchPart, setIsSearchPart ] = React.useState( true );
    const [ assienTeammember, setassienTeammember ] = React.useState( '' );
    const [ note, setnote ] = React.useState<any>( '' );
    const [ isremoveLoader, setisremoveLoader ] = React.useState( false );
    const [ deleteresourses, setdeleteresourses ] = React.useState();
    const [ isImagePopup, setIsImagePopup ] = useState( false );
    const [ siteMap, setSiteMap ] = useState<any>()
    const [ isOpen, setIsOpen ] = useState( false );
    const [ isMemberData, setIsMemberData ] = useState( '' );
    const [ isSlienceLoad, setIsSlienceLoad ] = useState( false );
    const [ unSlienceLoad, setIsUnSlienceLoad ] = useState( false )
    var target : any = AV.AVEntity.getFk( leagueTeamFK );
    var sourceFK : any = AV.AVEntity.getFk( currentUser );
    const [ isFilesView, setisFilesView ] = useState( true );
    const [ isConfirmDelete, setisConfirmDelete ] = useState( false );
    const [ Confirmationtext, setConfirmationtext ] = React.useState( "Are you sure ?" );
    const [ selectType, setselectType ] = React.useState<any>();
    const [ itemSelect, setItemSelect ] = React.useState<any>( '' );
    const [ editAccess, setEditAccess ] = useState( false );
    const [ StaffsItem, setStaffsItem ] = useState<any>( '' );
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [ isTeam, setIsTeam ] = useState( false );
    const [ searchTypes, setSearchTypes ] = useState( '' )
    const [ showCreateNewPage, setShowCreateNewPage ] = useState<any>( false );
    const [ createType, setCreateType ] = useState<any>( "" );
    const [ currentstatus, setcurrentStatus ] = useState( Loc.currentLang.menu.current )
    const [ isAll, setIsAll ] = useState( true );
    const [ isGames, setIsGames ] = useState( false );
    const [ isMeeting, setIsMeeting ] = useState( false );
    const [ isOther, setIsOther ] = useState( false );
    const [ datass, setDatass ] = useState<any>( "ALL" );
    const [ passEntity, setPassEntity ] = useState<any>( '' );
    var Status = "ALL"
    const [ isOrgOpen, setOrgOpen ] = useState( false );
    const [ isAssignTeammemberValid, setIsAssignTeammemberValid ] = useState( false );
    const [ filteredParticipantList, setFilteredParticipantList ] = useState( [] )
    const [ showPreview, setShowPreview ] = useState( false );
    const [ singleImage, setSingleImage ] = useState();
    const [ allImages, setShowAllImage ] = useState( [] );
    const [ showCalendarView, setShowCalenderView ] = useState( true );
    const [ restoreRefresh, setRestoreRefresh ] = useState( false )
    const { handleGetGroupConvo } = useConvoChat();
    const [ isLoad, setIsLoad ] = useState( false );
    const [ isOpenConvo, setIsOpenConvo ] = useState( false );
    const { handleSendSingleChat } = useConvoChat();
    const [ isRotate, setIsRotate ] = useState( false );
    const [ isShowEditOccurence, setIsShowEditOccurence ] = useState( false );
    let staffToMember = false;
    let staffToPlayer = false;
    let memberToPlayer = false;
    const [ isConvoData, setIsConvoData ] = useState( '' )
    const storedTab = localStorage.getItem( 'activeTabEntity' );
    const CurrentParentTab : any = storedTab ? JSON.parse( storedTab ) : null;
    console.log( "CurrentParentTab", CurrentParentTab )


    //Reload
    const handleOnline = () =>
    {
        window.location.href = window.location.origin;
        window.location.reload();
    };
    // Add event listener for online event
    window.addEventListener( 'online', handleOnline );
    //Remove staff
    const handleRemoveStaff = async ( item ) =>
    {
        setcontactList( contactList?.filter( ( x ) => x !== item ) );
    };
    //Styling
    const customSelectStyle = {
        maxHeight: '40px', // Set your desired height here
        width: '230px',
    };

    const customSelectStyleeeess = {
        maxHeight: '32px', // Set your desired height here
    };
    //Remove Participant
    const handleRemoveProfile = async ( type, item ) =>
    {
        if ( type === 'Participant' )
        {
            let removeStaff : any = await AV.Event.removeParticipant( AVEntity.getFk( leagueTeamFK ), item );
            if ( removeStaff !== null )
            {
                setLeagueTeamFK( removeStaff );
                setIsParticipantList( removeStaff?.participants );
                setFilteredParticipantList( removeStaff?.participants )
            }
        }
    };
    //Restore account
    const HandleRestoreAccount = () =>
    {
        setisConfirmDelete( true )
        setConfirmationtext( 'Are You Sure? You Want to Restore your Account' );
        setItemSelect( leagueTeamFK );
        setselectType( 'RestoreAccount' )
    }

    const handleRestore = async () =>
    {
        setLoader( true );

        if ( leagueTeamFK?.status === 'Deleted' )
        {
            await AV.AVEntity.unDelete( leagueTeamFK );
            leagueTeamFK.status = '';
            entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === leagueTeamFK?.id ) ) ] = leagueTeamFK;
            dispatch( setEntitydata( entitydatalist ) );
            let Index = user?.orgs?.findIndex( x => x?.id === leagueTeamFK?.id )
            if ( Index !== -1 )
            {
                user.orgs[ Index ].hidden = '';
                dispatch( setUser( user ) );
            } 
        }

        else
            await AV.AVEntity.unSuspend( leagueTeamFK )
        setLoader( false );
        setisConfirmDelete( false );
        setRestoreRefresh( !restoreRefresh )
    };
    //Remove Participant
    const RemoveParticiapnt = async () =>
    {
        setLoader( true );
        let removeStaff : any = await AV.Event.removeParticipant( AVEntity.getFk( leagueTeamFK ), itemSelect );
        if ( removeStaff !== null )
        {
            setLeagueTeamFK( removeStaff );
            setIsParticipantList( removeStaff?.participants );
            setFilteredParticipantList( removeStaff?.participants );
            const isAdminOrStaff = removeStaff?.status == 'Canceled' ? false : removeStaff?.participants?.some(
                x => x?.id === user?.id && ( x?.linkType === "Owner" || x?.linkType === "Staff" )
            );
            setIsAdminView( isAdminOrStaff ); setEditAccess( isAdminOrStaff );
        }
        setLoader( false );
        setisConfirmDelete( false );
    };
    //Search
    const searchPlayerItems = async ( searchValue ) =>
    {
        setcheckvalue( true );
        setcheckvaluedata( '' );
        setIsSearchPart( true );
        setIsAssignTeammemberValid( false ); // Reset validity state
        if ( searchValue == '' )
        {
            setPlayerName( searchValue );
            setassienTeammember( searchValue );
            setfilteredResults( [] );
        } else
        {
            if ( !showAddResourceModal )
            {
                const searchfollowerdata = await AV.Person.localSearch( searchValue );
                setfilteredResults( searchfollowerdata.filter( ( x ) => x.type == 'Person' ) );
                setPlayerName( searchValue );
            }
            else
            {
                const searchparticipantsdata = participantList?.filter( ( l ) => l.title?.toLowerCase().includes( searchValue.toLowerCase() ) );
                setfilteredResults( searchparticipantsdata );
                setassienTeammember( searchValue );
            }
            // Validate the assign team member
            const isValid = filteredResults.some( result => result.title === searchValue );
            setIsAssignTeammemberValid( isValid );
        }
    };
    //HandlePlayerName
    const handlePlayerName = ( item : any ) =>
    {
        setassienTeammember( item?.title );
        setPlayerName( item.title );
        setcheckvaluedata( item );
        setcheckvalue( false );
        setfilteredResults( [] );
        setIsAssignTeammemberValid( true ); // Set to valid when a player is selected
    };
    //Remove Resoruce
    const handleRemoveResourcePopup = ( item ) =>
    {
        setisConfirmDelete( true )
        setItemSelect( item );
        setConfirmationtext( 'Are You Sure? You Want to remove this item' );
        setselectType( 'RemoveResource' );
        setisremoveLoader( true );
    }

    const handleRemoveResourses = async () =>
    {
        setisremoveLoader( true );
        var val : any = await AV.Event.removeResource( AVEntity.getFk( leagueTeamFK ), itemSelect ? itemSelect : deleteresourses );
        if ( val )
        {
            setLeagueTeamFK( val );
            setresoursesList( val?.resources );
        }
        setshowAddResourceModal( false );
        seteditresourses( false );
        setdescription( "" ); setnote( "" ); setassienTeammember( "" ); setquantity( "" ); setcheckvalue( true ); setcheckvaluedata( '' );
        setisremoveLoader( false );
        setisremoveLoader( false );
        setisConfirmDelete( false );
    }
    //Add Resource
    const handleAddResourses = async () =>
    {
        setLoader( true );
        if ( !editresourses )
        {
            let eventResourses : AV.IEventResource = {
                quantity: Number( quantity ),
                notes: note,
                resource: description,
                resourceId: Server.generateUUID(),
                id: checkvaluedata?.id,
                pk: checkvaluedata?.pk,
                region: checkvaluedata?.region,
                type: checkvaluedata?.type,
                thumb: checkvaluedata?.thumb,
                title: checkvaluedata?.title,
                background: checkvaluedata?.background,
            };
            let seriesId = !isEditEntireSeries && leagueTeamFK.series ? leagueTeamFK.id : null;
            let fk = leagueTeamFK;
            if ( !isEditEntireSeries && leagueTeamFK.series )
            {
                fk = {
                    id: leagueTeamFK.series,
                    fk: leagueTeamFK.series.slice( -4 ),
                    region: leagueTeamFK.region,
                    type: leagueTeamFK.type
                }
            }
            var val : any = await AV.Event.addResource( AVEntity.getFk( fk ), eventResourses, seriesId );
            if ( val )
            {
                if ( val.series && !isEditEntireSeries )
                {
                    let seriesEvent = val?.seriesEvents.find( x => x.id == seriesId );
                    setresoursesList( seriesEvent?.resources );
                    //setLeagueTeamFK( val );
                    //setEntity( addpartcipants );
                    entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === val?.id ) ) ] = val;
                    dispatch( setEntitydata( entitydatalist ) );
                }
                else
                {
                    //setLeagueTeamFK( val );
                    setresoursesList( val?.resources );
                    entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === val?.id ) ) ] = val;
                    dispatch( setEntitydata( entitydatalist ) );
                }
            }
        }
        else
        {
            let Editvalue : any = deleteresourses;
            Editvalue.quantity = Number( quantity );
            Editvalue.notes = note;
            Editvalue.resource = description;
            if ( checkvaluedata )
            {
                Editvalue.id = checkvaluedata?.id;
                Editvalue.pk = checkvaluedata?.pk;
                Editvalue.title = checkvaluedata?.title;
                Editvalue.region = checkvaluedata?.region;
                Editvalue.type = checkvaluedata?.type;
                Editvalue.thumb = checkvaluedata?.thumb;
                Editvalue.background = checkvaluedata?.background;
            }
            let seriesId = leagueTeamFK.series ? leagueTeamFK.id : null;
            //leagueTeamFK.id = leagueTeamFK.series ? leagueTeamFK.series : leagueTeamFK.id;
            let fk = {
                id: leagueTeamFK.series,
                fk: leagueTeamFK.series.slice( -4 ),
                region: leagueTeamFK.region,
                type: leagueTeamFK.type
            }
            fk = leagueTeamFK.series ? fk : leagueTeamFK;
            var Editval : any = await AV.Event.updateResource( AVEntity.getFk( fk ), Editvalue, seriesId );
            if ( Editval?.id )
            {
                if ( Editval.series && !isEditEntireSeries )
                {
                    let seriesEvent = Editval?.seriesEvents.find( x => x.id == seriesId );
                    setresoursesList( seriesEvent?.resources );
                    entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === Editval?.id ) ) ] = Editval;
                    dispatch( setEntitydata( entitydatalist ) );
                }
                else
                {
                    setresoursesList( Editval?.resources );
                    entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === Editval?.id ) ) ] = Editval;
                    dispatch( setEntitydata( entitydatalist ) );
                    setLeagueTeamFK( Editval );

                }
                //setresoursesList( Editval?.resources );
            }
            seteditresourses( false );
        }
        setLoader( false );
        setshowAddResourceModal( false );
        setdescription( "" );
        setnote( "" );
        setassienTeammember( "" );
        setquantity( "" );
        setcheckvalue( true );
        setcheckvaluedata( "" );
    };
    //Edit Profile Resource
    const handleEditProfileType = async ( item ) =>
    {
        setdeleteresourses( item );
        setshowAddResourceModal( true );
        seteditresourses( true );
        setIsPlayer( false );
        setShowAddProfileModal( false );
        setdescription( item?.resource );
        setquantity( item?.quantity );
        setnote( item?.notes );
        if ( participantList?.filter( x => x?.id === item?.id )?.length > 0 )
            setassienTeammember( participantList?.filter( x => x?.id === item?.id )[ 0 ]?.title );
        else
        {
            setassienTeammember( "" );
        }
    };
    // View Profile Resource
    const handleShowResourcePopup = async ( item ) =>
    {
        setshowResourceModal( true );
        seteditresourses( false );
        setIsPlayer( false );
        setShowAddProfileModal( false );
        setdescription( item?.resource );
        setquantity( item?.quantity );
        setnote( item?.notes );
        if ( participantList?.filter( x => x?.id === item?.id )?.length > 0 )
            setassienTeammember( participantList?.filter( x => x?.id === item?.id )[ 0 ]?.title );
        else
        {
            setassienTeammember( "" );
        }
    };

    const handleAddResourcePopup = async () =>
    {
        setshowAddResourceModal( true )
        setdescription( '' )
        setquantity( 1 )
        searchPlayerItems( '' )
        setnote( '' )
    };

    const handleCancelItem = async () =>
    {
        if ( showResourceModal )
            setshowResourceModal( false );
        setshowAddResourceModal( false );
        seteditresourses( false );
    }

    //Update Staff  
    const handleUpdateStaff = async ( item ) =>
    {
        let item1 = leagueTeamFK?.roles?.filter( ( x ) => x?.name === staffRole )[ 0 ];
        menu.role = item1;
        if ( newname?.trim()?.length > 0 ) menu.name = newname;
        if ( newmail?.trim()?.length > 0 ) menu.email = newmail;
        setmenu( menu );
        contactList[ contactList.indexOf( item ) ] = menu;
        setcontactList( contactList );
        setmenu( '' );
        setIsEditPlayerDetails( false );
        setnewname( '' );
        setnewmail( '' );
    };
    //Addcontactlist
    const handleAddContactList = async () =>
    {
        setIsLoading( true );
        setIsEditPlayerDetails( false );
        if ( isStaffPage )
        {
            for ( var i = 0; i < contactList.length; i++ )
            {
                if ( !contactList[ i ]?.role ) contactList[ i ].role = leagueTeamFK?.roles[ 0 ];
            }
            let val = await AV.OrgBase.addStaffs( AV.AVEntity.getFk( leagueTeamFK ), contactList );
            if ( val ) setStaffList( val?.staff );
        } else if ( isMembersPage )
        {
            let member : any = await AV.AVEntity.addMembers( AV.AVEntity.getFk( leagueTeamFK ), contactList );
            if ( member )
            {
                setmemberList( member?.members );
            }
        } else if ( isPlayer )
        {
            let players : any = await AV.Team.addPlayers( AV.AVEntity.getFk( leagueTeamFK ), contactList );
            if ( players )
            {
                setPlayersList( players?.players );
            }
        }
        else if ( isParticipant )
        {
            for ( var i = 0; i <= contactList?.length; i++ )
            {
                let players : any = await AV.Event.addParticipant( AV.AVEntity.getFk( leagueTeamFK ), contactList[ i ], leagueTeamFK.id );
                if ( players )
                {
                    setIsParticipantList( players?.participants )
                    setFilteredParticipantList( players?.participants )
                }
                i++;
            }
        }
        setIsLoading( false );
        setshowFileuploadModal( false );
        setShowAddProfileModal( false )
    };
    //Layout
    React.useLayoutEffect( () =>
    {
        if ( rightRef.current )
        {
            setFixedWidth( rightRef.current.offsetWidth - 2 );
        }
    }, [ [ dimensions.width ] ] );
    //GetMedia
    const getMedia = async ( file ?: any ) =>
    {
        let iMedia = await Media.saveFiles(
            AV.Media.getFormData( file ),
            leagueTeamFK?.region ? leagueTeamFK?.region : currentUser?.region,
        );
        setLoader( false );
        setIsPicLoader( false );
        setMascotLoader( false );
        return iMedia[ 0 ];
    };
    //Participant Filter
    const PariticipantFilter = ( { Type, SetType, Name, length } ) =>
    {
        return (
            <Button
                borderRadius={ '19px' }
                height={ '36px' }
                paddingLeft={ '24px' }
                paddingRight={ '24px' }
                bgColor={ Type === Name ? '#2FA3BE' : '' }
                onPress={ () =>
                {
                    SetType( Name );
                } }
            >
                <HStack space={ 1 }>
                    <Text color={ Type === Name ? 'white' : 'rgba(0,0,0,0.6)' } fontWeight={ 400 } fontSize={ '16px' } >{ Name }</Text>
                    <Text color={ Type === Name ? 'white' : 'rgba(0,0,0,0.6)' } fontWeight={ 400 } fontSize={ '16px' } >({ length })</Text>
                </HStack>
            </Button>
        );
    };
    //Participant card


    const HandlePractice = () =>
    {
        handleEventType( 'Practice' );
        settypeevent( 'Practice' )
        setlocationaddress( [] );
        setIsActives( !isActives );
        setIsGame( false );
    }

    const HandleRemoveplayers = ( item ) =>
    {
        setisConfirmDelete( true )
        setConfirmationtext( leagueTeamFK?.subType == 'Class' ? 'Are You Sure? You Want to Delete Your Students' : 'Are You Sure? You Want to Delete Your Player' );
        setItemSelect( item );
        setselectType( 'DeletePlayer' )
    }

    const handleRemoveplayers = async () =>
    {
        setLoader( true );
        let LeagueTeam : any = await AV.Team.removePlayer( AVEntity.getFk( leagueTeamFK ), { id: itemSelect.id, pk: itemSelect.pk, type: "Person" } );
        if ( LeagueTeam !== null )
        {
            setPlayersList( LeagueTeam?.players );
            setLeagueTeamFK( LeagueTeam );
        }
        setLoader( false );
        setisConfirmDelete( false );
    }

    const HandleGame = () =>
    {

        setIsGame( true );
        handleEventType( 'Game' );
        settypeevent( 'Game' );
        setlocationaddress( [] );
        setIsActives( !isActives );
    }

    const HandleSocial = () =>
    {
        handleEventType( 'Social' );
        settypeevent( 'Social' );
        setlocationaddress( [] );
        setIsActives( !isActives );
        setIsGame( false );
    }

    const HandleMeeting = () =>
    {
        handleEventType( 'Meeting' );
        settypeevent( 'Meeting' );
        setlocationaddress( [] );
        setIsActives( !isActives );
        setIsGame( false );
    }

    const HandleCreateGroup = () =>
    {

        setShowCreateNewPage( true );
        setCreateType( "Group" );
        setPassEntity( leagueTeamFK )
        setIsOrganization( false );
        setIsActives( !isActives );
    }

    const HandleTeamorg = () =>
    {
        setShowCreateNewPage( true );
        setCreateType( "Team" );
        setPassEntity( leagueTeamFK )
        setIsOrganization( false );
        setIsActives( !isActives );

    }

    const HandleClubOrg = () =>
    {
        setShowCreateNewPage( true );
        setCreateType( "Club" );
        setPassEntity( leagueTeamFK )
        setIsOrganization( false );
        setIsActives( !isActives );

    }

    const HandleClassOrg = () =>
    {

        setShowCreateNewPage( true );
        setCreateType( "Class" );
        setPassEntity( leagueTeamFK )
        setIsOrganization( false );
        setIsActives( !isActives );
    }

    const HandleGradOrg = () =>
    {
        setShowCreateNewPage( true );
        setCreateType( "GradClass" );
        setIsOrganization( false );
        setPassEntity( leagueTeamFK )
        setIsActives( !isActives );
    }

    const HandleOrg = () =>
    {
        setShowCreateNewPage( true );
        setCreateType( "Organization" );
        setIsOrganization( false );
        setPassEntity( leagueTeamFK )
        setIsActives( !isActives );

    }

    const dropdownComValues = [
        ...( ( leagueTeamFK?.type == 'PlayerOrg' && leagueTeamFK?.subType !== "Term" ) ? [ { id: 1, name: Loc.currentLang.menu.practice, flag: data, fn: HandlePractice } ] : [] ),
        ...( ( leagueTeamFK?.type == 'PlayerOrg' && leagueTeamFK?.subType !== "Term" ) ? [ { id: 2, name: Loc.currentLang.menu.gamesTxt, flag: data, fn: HandleGame } ] : [] ),
        { id: 3, name: Loc.currentLang.menu.socialText, flag: data, fn: HandleSocial },
        { id: 4, name: Loc.currentLang.menu.meeting, flag: data, fn: HandleMeeting },
    ]

    const dropdownComEventValues = [
        { id: 1, name: Loc.currentLang.menu.group, flag: data, fn: HandleCreateGroup },
        ...( leagueTeamFK?.subType == 'GradClass' || leagueTeamFK?.subType == 'Term' ? [ { id: 1, name: Loc.currentLang.menu.team, flag: data, fn: HandleTeamorg } ] : [] ),
        ...( leagueTeamFK?.subType == 'GradClass' ? [ { id: 2, name: Loc.currentLang.menu.club, flag: data, fn: HandleClubOrg } ] : [] ),
    ]

    const dropdownComOrgValues = [
        ...( leagueTeamFK?.type == 'Organization' || leagueTeamFK?.type == 'Neighborhood' || leagueTeamFK?.type == 'Community' || leagueTeamFK?.type == 'School' || leagueTeamFK?.type == 'Club' ? [ { id: 1, name: Loc.currentLang.menu.team, flag: data, fn: HandleTeamorg } ] : [] ),
        ...( leagueTeamFK?.type == 'Organization' || leagueTeamFK?.type == 'Neighborhood' || leagueTeamFK?.type == 'Community' || leagueTeamFK?.type == 'School' || leagueTeamFK?.type == 'Club' ? [ { id: 2, name: Loc.currentLang.menu.club, flag: data, fn: HandleClubOrg } ] : [] ),
        ...( leagueTeamFK?.type !== 'Organization' && leagueTeamFK?.type !== 'Neighborhood' && leagueTeamFK?.type !== 'Community' && leagueTeamFK?.type == 'School' && leagueTeamFK?.type !== 'Club' ? [ { id: 3, name: Loc.currentLang.menu.classes, flag: data, fn: HandleClassOrg } ] : [] ),
        ...( leagueTeamFK?.type !== 'Organization' && leagueTeamFK?.type !== 'Neighborhood' && leagueTeamFK?.type !== 'Community' && leagueTeamFK?.type == 'School' && leagueTeamFK?.type !== 'Club' ? [ { id: 4, name: Loc.currentLang.menu.graduatingClass, flag: data, fn: HandleGradOrg } ] : [] ),
        ...( leagueTeamFK?.type == 'Organization' ? [ { id: 5, name: Loc.currentLang.menu.organization, flag: data, fn: HandleOrg } ] : [] ),
        ...( leagueTeamFK?.type == 'Community' || leagueTeamFK?.type == 'Organization' || leagueTeamFK?.type == 'School' || leagueTeamFK?.type == 'Club' || leagueTeamFK?.type == 'Neighborhood' ? [ { id: 5, name: Loc.currentLang.menu.group, flag: data, fn: HandleCreateGroup } ] : [] ),
    ]

    const HandleAlls = () =>
    {
        setcurrentStatus( Loc.currentLang.menu.all )
        setFilterEvent( !filterevent );
        HandleAll( Status )
    }

    const HandleCurrents = () =>
    {
        setcurrentStatus( Loc.currentLang.menu.current )
        setFilterEvent( !filterevent );
        HandleCurrent( Status, leagueTeamFK ?? entitydatalist?.find( x => x?.id === viewTeam?.id ) );
    }

    const HandlePasts = () =>
    {
        setcurrentStatus( Loc.currentLang.menu.past )
        setFilterEvent( !filterevent );
        HandlePast( Status );
    }

    const HandleCanceled = () =>
    {
        setcurrentStatus( Loc.currentLang.menu.canceled )
        setFilterEvent( !filterevent );
        HandleDelete( Status );

    }

    const dropdownValues = [
        { id: 1, name: Loc.currentLang.menu.all, flag: data, fn: HandleAlls },
        { id: 2, name: Loc.currentLang.menu.current, flag: data, fn: HandleCurrents },
        { id: 3, name: Loc.currentLang.menu.past, flag: data, fn: HandlePasts },
        { id: 4, name: Loc.currentLang.menu.canceled, flag: data, fn: HandleCanceled },
    ]
    //HandleUpload
    const handleUpload = useCallback( async ( e ) =>
    {
        e.id = e.series ? e.series : e.id;
        let Team = await AV.AVEntity.save( e );
        if ( Team )
        {
            setLeagueTeamFK( Team );
            setalbumlist( Team?.albums );
            let oldvalueOrgs = currentUser.orgs.filter( ( a ) => a.id == Team?.id );
            if ( oldvalueOrgs?.length > 0 )
            {
                currentUser.orgs[ currentUser.orgs.indexOf( oldvalueOrgs[ 0 ] ) ] = Team;
                dispatch( setUser( currentUser ) );
            }
            let oldvalueOwned = currentUser.ownedEvents.filter( ( a ) => a.id == Team?.id );
            if ( oldvalueOwned?.length > 0 )
            {
                currentUser.ownedEvents[ currentUser.ownedEvents.indexOf( oldvalueOwned[ 0 ] ) ] = Team;
                dispatch( setUser( currentUser ) );
            }
            let oldvalueEvent = currentUser.events.filter( ( a ) => a.id == Team?.id );
            if ( oldvalueEvent?.length > 0 )
            {
                let status = oldvalueEvent[ 0 ]?.status ? oldvalueEvent[ 0 ]?.status : "RSVP"
                currentUser.events[ currentUser.events.indexOf( oldvalueEvent[ 0 ] ) ] = Team;
                let Index = currentUser.events.findIndex( ( a ) => a.id == Team?.id );
                if ( Index !== -1 )
                {
                    currentUser.events[ Index ].status = status
                }
                dispatch( setUser( currentUser ) );
            }
        }
    }, [] );
    //Edit Profile
    const { getRootProps: getStaffProfilePicFile, getInputProps: getStaffProfileInputProps } =
        useDropzone( {
            accept: 'image/*',
            onDrop: ( acceptedFiles : any ) =>
            {
                if ( acceptedFiles?.filter( ( x ) => x?.type.startsWith( 'image' ) ) && acceptedFiles?.length > 0 )
                {
                    setIsPicLoader( true );
                    getMedia( acceptedFiles ).then( ( res ) =>
                    {
                        Editstaffitem.linkPic = res;
                        setstaffpic( AV.Media.getURL( res ) );
                    } );
                }
                else
                {
                    let clientError = { message: 'Invalid file type' }
                    CustomToast( { clientError } );
                    return;
                }
            },
        } );
    //Filter
    const UiPill = ( { eventType, setEventType, name } ) =>
    {
        return (
            <Button
                borderRadius={ '20px' }
                height={ '30px' }
                m={ '3px' }
                marginLeft={ '2px' }
                width={ '100px' }
                bgColor={ eventType === name ? '#00A7BC' : '#F5F5F5' }
                _hover={ { backgroundColor: '#d4d4d4' } }
                onPress={ () =>
                {
                    setEventType( name );
                } }
            >
                <Text
                    color={ eventType === name ? 'AVColor.white' : 'AVColor.primaryText' }
                    fontWeight={ 400 }
                    fontSize={ '16px' }
                    lineHeight={ '16px' }
                >
                    { name }
                </Text>
            </Button>
        );
    };

    const handleStaffRole = ( e ) =>
    {
        e.preventDefault();
        setStaffRole( e.target.value );
    };

    const HandleRemoveMembers = ( item ) =>
    {
        setisConfirmDelete( true )
        setConfirmationtext( 'Are You Sure? You Want to Delete Your Member' );
        setItemSelect( item );
        setselectType( 'DeleteMember' )
    }

    const handleDeleteMember = async () =>
    {
        setLoader( true );
        let removeMember = await AV.OrgBase.removeMember( AVEntity.getFk( leagueTeamFK ), {
            id: itemSelect.id,
            pk: itemSelect.pk,
            type: 'Person',
        } );
        if ( removeMember !== null )
        {
            setLeagueTeamFK( removeMember );
            setmemberList( removeMember?.members );
        }
        setisremoveLoader( false );
        setLoader( false );
        setisConfirmDelete( false );
    };

    const handleRemoveGroup = ( item ) =>
    {
        setisConfirmDelete( true )
        setConfirmationtext( 'Are You Sure? You Want to Delete Your group' );
        setItemSelect( item );
        setselectType( 'DeleteGroup' )
    }

    const handleDeleteGroup = async () =>
    {
        setLoader( true );
        let removeSeason : any = await AV.Team.removeSeason( AVEntity.getFk( leagueTeamFK ), itemSelect );
        if ( removeSeason !== null )
        {
            setLeagueTeamFK( removeSeason );
            if ( isOrganization )
            {

                setOrgList( removeSeason?.childOrgs )
            }
            else
            {
                setGroupList( removeSeason?.childOrgs?.filter( ( x : any ) => x?.type == "Group" ) )
            }
            /*setSeasonList( removeSeason?.childOrgs );*/
        }

        setisremoveLoader( false );
        setLoader( false );
        setisConfirmDelete( false );
    }

    const handleRemove = async ( type, item : any ) =>
    {
        if ( type === 'Season' )
        {
            setIsLoading( true );
            let removeSeason : any = await AV.Team.removeSeason( AVEntity.getFk( leagueTeamFK ), item );
            if ( removeSeason !== null )
            {
                setLeagueTeamFK( removeSeason );
                if ( isOrganization )
                {

                    setOrgList( removeSeason?.childOrgs )
                }
                else
                {
                    setGroupList( removeSeason?.childOrgs?.filter( ( x : any ) => x?.type == "Group" ) )
                }
                /*setSeasonList( removeSeason?.childOrgs );*/
            }
        } else if ( type === 'Member' )
        {
            setIsLoading( true );
            let removeMember : any = await AV.OrgBase.removeMember( AVEntity.getFk( leagueTeamFK ), {
                id: item.id,
                pk: item.pk,
                type: 'Person',
            } );
            if ( removeMember !== null )
            {
                setLeagueTeamFK( removeMember );
                setmemberList( removeMember?.members );
            }
            setIsLoading( false );
        } else
        {
            setIsLoading( true );
            let removeStaff = await AV.OrgBase.removeStaff( AVEntity.getFk( leagueTeamFK ), {
                id: item.id,
                pk: item.pk,
                type: 'Person',
                role: item.role,
            } );
            if ( removeStaff )
            {
                setLeagueTeamFK( removeStaff );
                setStaffList( removeStaff?.staff );
            }
        }
        setIsLoading( false );
        setIsOpen( !isOpen );
    };

    const handleProfileType = async ( type ) =>
    {
        if ( type === 'Player' )
        {
            setIsPlayer( true );
            setIsStaff( false );
            setIsMember( false );
        } else if ( type == 'Member' )
        {
            setIsStaff( false );
            setIsPlayer( false );
            setIsMember( true );
        }
        else if ( type == 'Participant' )
        {
            setIsStaff( false );
            setIsPlayer( false );
            setIsMember( false );
            setIsParticipant( true );
        }
        else if ( type == 'Group' )
        {
            setIsStaff( false );
            setSearchTypes( leagueTeamFK?.type == "Group" && leagueTeamFK?.subType == undefined ? Loc.currentLang.menu.subGroup : Loc.currentLang.menu.group )
            setIsPlayer( false );
            setIsMember( false );
            setIsParticipant( false );
            setIsTeam( true );
        }
        else if ( type == 'Organization' )
        {
            setIsStaff( false );
            setSearchTypes( Loc.currentLang.menu.organization )
            setIsPlayer( false );
            setIsMember( false );
            setIsParticipant( false );
            setIsTeam( true );
            setOrgOpen( false )
        }
        else
        {
            setIsPlayer( false );
            setIsMember( false );
            setIsStaff( true );
            setIsTeam( false );
        }
        setPlayerName( '' );
        setPlayerEmailAddress( '' );
        setPlayerPhone( '' );
        setStaffName( '' );
        setcheckvalue( true );
        setStaffEmailAddress( '' );
        setStaffPhone( '' );
        setfilteredResults( [] );
        setActivityRole( null );
        setError( null );
        setStaffRole( '' );
        setshowTempList( [] );
        setShowAddProfileModal( true );
    };

    const handleEditStaff = async ( item ) =>
    {
        if ( staffemail )
        {
            if ( !emailRegex.test( staffemail ) )
            {
                let clientError = { message: 'Invalid Email' }
                CustomToast( { clientError } );
                return;
            }
        }
        setIsLoading( true );
        setError( '' )
        let staff : AV.IStaff = item;
        let role : AV.IRole = staff?.role;
        staff.phone = staffphone;
        staff.email = staffemail;
        //staff.linkPic = staffpic;
        role.name = staffRole;
        let staffedit = await AV.OrgBase.updateStaff( AVEntity.getFk( leagueTeamFK ), staff );
        if ( staffedit )
        {
            setStaffList( staffedit?.staff );
        }
        setIsStaffEdit( false );
        setIsLoading( false );

    };

    const GetEventsList = async ( type, events ) =>
    {
        setEventsList( [] );
        setEventsList( events?.ownedEvents );
        setEventData( events?.ownedEvents );
        if ( type !== 'All' )
        {
            setEventsList( events?.ownedEvents.filter( ( x ) => x.eventType === type ) );
        }
        HandleCurrent( "ALL", events );
    };

    const handleSeasonName = ( e ) =>
    {
        e.preventDefault();
        setseasonName( e.target.value );
    };

    //Createseason
    const HandleCreatingSeason = async () =>
    {
        setLoader( true )
        var leagueteam : any = {
            type: 'PlayerOrg', name: oldSeason?.name ? oldSeason?.name : leagueTeamFK?.name, url: oldSeason?.url, season: seasonName,
            profile: oldSeason?.profile ? oldSeason?.profile : leagueTeamFK?.profile,
            gender: oldSeason?.gender, team: AV.AVEntity.getLink( oldSeason?.team ? oldSeason?.team : leagueTeamFK ),
            activity: oldSeason?.activity, Parent: AV.AVEntity.getLink( leagueTeamFK )
        };
        if ( !leagueteam.profile ) leagueteam.profile = { albums: [], previousPic: [], text: '' };
        leagueteam.profile.text = oldSeason?.profile?.text;
        if ( leagueTeamFK?.subType == "Club" ) leagueteam.subType = "Term"
        if ( leagueteam )
        {
            leagueteam = await AV.AVEntity.save( leagueteam );
            currentUser?.orgs?.push( leagueteam )
            dispatch( setUser( currentUser ) );
            leagueTeamFK.childOrgs.push( AV.AVEntity.getLink( leagueteam ) )
            setSeasonList( leagueTeamFK?.childOrgs?.filter( ( x : any ) => x?.type !== "Group" ) || [] );
        }
        setLoader( false )
        setIsCreate( false );
        setseasonName( '' );
        setShowAddSeasonModal( false );
    }

    const handleupdatelocationfield = ( e ) =>
    {
        setIsMapField( e.target.value.name );
        setIsFieldsName( e.target.value );
    };

    const searchOrgs = async ( val ) =>
    {
        setIsDropList( true )
        setChildOrg( val )
        SetSelctChildOrg(
            user?.orgs?.filter(
                ( x ) =>
                    ( x?.target?.title !== undefined &&
                        x?.target?.title?.toLocaleLowerCase().includes( val.toLocaleLowerCase() ) ) ||
                    ( x?.title !== undefined &&
                        x?.title?.toLocaleLowerCase().includes( val.toLocaleLowerCase() ) ) ||
                    ( x?.data !== undefined &&
                        x?.data?.toLocaleLowerCase().includes( val.toLocaleLowerCase() ) )
            )
        );
    };

    const handlestaffandorgReq = async () =>
    {
        setLoader( true );
        if ( staffReq )
        {
            target.linkType = 'StaffRequest';
            target.data = StaffDesc;
            let resp = await AV.Person.makeRequest( sourceFK, target );
        }
        else
        {
            let orgsourceFK : any = AV.AVEntity.getFk( chiledorgId );
            target.linkType = 'OrgParent';
            target.data = justi;
            let resp1 = await AV.Person.makeRequest( orgsourceFK, target );
        }
        setLoader( false );
        setIsStaffDesc( '' )
        setIsStaffReq( false )
        setChildOrg( '' )
        setJusti( '' )
        setIsChildOrgReq( false )
    }

    const HandleSlience = async ( item ) =>
    {
        setIsSlienceLoad( true );
        await AVEntity.silence( sourceFK, item );
        let userUpdate = await AV.Person.getSelf();
        if ( userUpdate?.id )
            dispatch( setUser( userUpdate ) );
        setIsSlienceLoad( false );
        setIsOpen( !isOpen )
    }

    const HandleUnSlience = async ( item ) =>
    {
        setIsUnSlienceLoad( true )
        await AVEntity.UnSilence( sourceFK, item );
        let userUpdate = await AV.Person.getSelf();
        if ( userUpdate?.id )
            dispatch( setUser( userUpdate ) );
        setIsUnSlienceLoad( false )
        setIsOpen( !isOpen )
    }
    //Menu Function
    const handleHomeClick = async () =>
    {
        setIsTeamsPage( true )
        setIsSeasonPage( false )
        setIsEventPage( false )
        setIsStaffPage( false )
        setIsMembersPage( false )
        setIsPhotoPage( false )
        setIsDocumentPage( false )
        setIsPlayerPage( false )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( false )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOrganization( false )
        handleHelpText();
        setIsOccurencePage( false );
        //if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        //    localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "HomePage", id: leagueTeamFK?.id } ) );
    }

    const handleSeasonClick = async () =>
    {
        setIsTeamsPage( false )
        setIsSeasonPage( true )
        setIsEventPage( false )
        setIsStaffPage( false )
        setIsMembersPage( false )
        setIsPhotoPage( false )
        setIsDocumentPage( false )
        setIsPlayerPage( false )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( false )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOrganization( false );
        setIsOccurencePage( false );
        handleHelpText();
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "SeasonPage", id: leagueTeamFK?.id } ) );
    }

    const handleEventsClick = async () =>
    {
        setIsTeamsPage( false )
        setIsSeasonPage( false )
        setEventsList( leagueTeamFK?.ownedEvents );
        setEventData( leagueTeamFK?.ownedEvents );
        Status = "SOCIAL"
        setDatass( "SOCIAL" )
        setEventType( Loc.currentLang.menu.all )
        HandleCurrent( "ALL", leagueTeamFK ?? entitydatalist?.find( x => x?.id === viewTeam?.id ) );
        setIsEventPage( true )
        setIsStaffPage( false )
        setIsMembersPage( false )
        setIsPhotoPage( false )
        setIsDocumentPage( false )
        setIsPlayerPage( false )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( false )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOrganization( false );
        handleHelpText();
        setcurrentStatus( Loc.currentLang.menu.current )
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "EventPage", id: leagueTeamFK?.id } ) );
    }

    const handleStaffClick = async () =>
    {
        setIsTeamsPage( false )
        setIsSeasonPage( false )
        setIsEventPage( false )
        setIsStaffPage( true )
        setIsMembersPage( false )
        setIsPhotoPage( false )
        setIsDocumentPage( false )
        setIsPlayerPage( false )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( false )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOrganization( false );
        handleHelpText();
        setIsOccurencePage( false );
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "StaffPage", id: leagueTeamFK?.id } ) );
    }

    const handleMembersClick = async () =>
    {
        setIsTeamsPage( false )
        setIsSeasonPage( false )
        setIsEventPage( false )
        setIsStaffPage( false )
        setIsMembersPage( true )
        setIsPhotoPage( false )
        setIsDocumentPage( false )
        setIsPlayerPage( false )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( false )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOrganization( false );
        handleHelpText();
        setIsOccurencePage( false );
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "MemberPage", id: leagueTeamFK?.id } ) );
    }

    const handleAblumClick = async () =>
    {
        setIsTeamsPage( false )
        setIsSeasonPage( false )
        setIsEventPage( false )
        setIsStaffPage( false )
        setIsMembersPage( false )
        setIsPhotoPage( true )
        setIsDocumentPage( false )
        setIsPlayerPage( false )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( false )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOrganization( false ); handleHelpText();
        setIsOccurencePage( false );
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "AlbumPage", id: leagueTeamFK?.id } ) );
    }

    const handleFileClick = async () =>
    {
        setIsTeamsPage( false )
        setIsSeasonPage( false )
        setIsEventPage( false )
        setIsStaffPage( false )
        setIsMembersPage( false )
        setIsPhotoPage( false )
        setIsDocumentPage( true )
        setIsPlayerPage( false )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( false )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOrganization( false ); handleHelpText();
        setIsOccurencePage( false );
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "FilePage", id: leagueTeamFK?.id } ) );
    }
    const handleOccurenceClick = async () =>
    {
        setSeriesEventsList( leagueTeamFK?.seriesEvents );
        setIsTeamsPage( false )
        setIsSeasonPage( false )
        setIsEventPage( false )
        setIsStaffPage( false )
        setIsMembersPage( false )
        setIsPhotoPage( false )
        setIsDocumentPage( false )
        setIsPlayerPage( false )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( false )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOrganization( false );
        setIsOccurencePage(true);
        handleHelpText();
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "OccurancePage", id: leagueTeamFK?.id } ) );
    }
    const handlePlayersClick = async () =>
    {
        setIsTeamsPage( false )
        setIsSeasonPage( false )
        setIsEventPage( false )
        setIsStaffPage( false )
        setIsMembersPage( false )
        setIsPhotoPage( false )
        setIsDocumentPage( false )
        setIsPlayerPage( true )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( false )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOrganization( false );
        handleHelpText();
        setIsOccurencePage( false );
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "PlayerPage", id: leagueTeamFK?.id } ) );
    }

    const handleGroupClick = async () =>
    {
        setIsTeamsPage( false )
        setIsSeasonPage( false )
        setIsEventPage( false )
        setIsStaffPage( false )
        setIsMembersPage( false )
        setIsPhotoPage( false )
        setIsDocumentPage( false )
        setIsPlayerPage( false )
        setIsGroup( true )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( false )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOccurencePage( false );
        setIsOrganization( false ); handleHelpText();
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "GroupPage", id: leagueTeamFK?.id } ) );
    }

    const handleOrganizationClick = async () =>
    {
        setIsTeamsPage( false )
        setIsSeasonPage( false )
        setIsEventPage( false )
        setIsStaffPage( false )
        setIsMembersPage( false )
        setIsPhotoPage( false )
        setIsDocumentPage( false )
        setIsPlayerPage( false )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( false )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOccurencePage( false );
        setIsOrganization( true ); handleHelpText();
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "OrganizationPage", id: leagueTeamFK?.id } ) );
    }

    const handleParicipantsClick = async () =>
    {
        setFilteredParticipantList( leagueTeamFK?.participants )
        setIsTeamsPage( false )
        setIsSeasonPage( false )
        setIsEventPage( false )
        setIsStaffPage( false )
        setIsMembersPage( false )
        setIsPhotoPage( false )
        setIsDocumentPage( false )
        setIsPlayerPage( false )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisResourses( false )
        setisparticipantspage( true )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOrganization( false );
        setIsOccurencePage( false );
        handleHelpText();
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "ParticipantPage", id: leagueTeamFK?.id } ) );
    }

    const handleResourcesClick = async () =>
    {
        setresoursesList( leagueTeamFK?.resources );
        setIsTeamsPage( false )
        setIsSeasonPage( false )
        setIsEventPage( false )
        setIsStaffPage( false )
        setIsMembersPage( false )
        setIsPhotoPage( false )
        setIsDocumentPage( false )
        setIsPlayerPage( false )
        setIsGroup( false )
        setIsHomePage( false )
        setIsTeamClub( false )
        setisparticipantspage( false )
        setisResourses( true )
        setIsDropDown( false )
        setIsFeedBackGroup( false );
        setIsOrganization( false );
        setIsOccurencePage( false );
        handleHelpText();
        if ( !CurrentParentTab?.id || CurrentParentTab?.id !== leagueTeamFK?.parent?.id )
        localStorage.setItem( 'activeTabEntity', JSON.stringify( { tab: "ResourcePage", id: leagueTeamFK?.id } ) );
    }

    //Tab Menu
    const TeamsMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id: 2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( isAdminView || leagueTeamFK?.childOrgs?.filter( ( x : any ) => x?.type !== "Group" )?.length > 0 ) ? [ { id: 3, name: Loc.currentLang.menu.seasons, flag: isSeasonPage, fn: handleSeasonClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.filter( ( x : any ) => x?.type == "Group" )?.length > 0 || isAdminView ) ? [ { id: 4, name: Loc.currentLang.menu.organization, flag: isGroup, fn: handleGroupClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id: 5, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id: 6, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id: 7, name: Loc.currentLang.menu.staff, flag: isStaffPage, fn: handleStaffClick } ] : [] ),

        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id: 8, name: Loc.currentLang.menu.members, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
    ]
    const SeasonsMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id:2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( leagueTeamFK?.players?.length > 0 || isAdminView ) ? [ { id:3, name: Loc.currentLang.menu.players, flag: isPlayerPage, fn: handlePlayersClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.filter( ( x : any ) => x?.type !== "Group" )?.length > 0 || isAdminView ) ? [ { id:4, name: Loc.currentLang.menu.organization, flag: isSeasonPage, fn: handleSeasonClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id:5, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id:6, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id:7, name: Loc.currentLang.menu.parents, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id:8, name: Loc.currentLang.menu.staff, flag: isStaffPage, fn: handleStaffClick } ] : [] ),
    ]
    const PerformanceGroupMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id: 2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( leagueTeamFK?.players?.length > 0 || isAdminView ) ? [ { id: 3, name: Loc.currentLang.menu.players, flag: isPlayerPage, fn: handlePlayersClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.filter( ( x : any ) => x?.type == "Group" )?.length > 0 || isAdminView ) ? [ { id: 4, name: Loc.currentLang.menu.organization, flag: isGroup, fn: handleGroupClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id:5, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id:6, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id:7, name: Loc.currentLang.menu.members, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id:8, name: Loc.currentLang.menu.staff, flag: isStaffPage, fn: handleStaffClick } ] : [] ),

    ]
    const TermsMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id: 2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( leagueTeamFK?.players?.length > 0 || isAdminView ) ? [ { id: 3, name: Loc.currentLang.menu.members, flag: isPlayerPage, fn: handlePlayersClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.filter( ( x : any ) => x?.type == "Group" )?.length > 0 || isAdminView ) ? [ { id: 4, name: Loc.currentLang.menu.organization, flag: isGroup, fn: handleGroupClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id: 5, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id: 6, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id: 7, name: Loc.currentLang.menu.directors, flag: isStaffPage, fn: handleStaffClick } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id: 8, name: Loc.currentLang.menu.parents, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
    ]
    const TeamClubMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id: 2, name: Loc.currentLang.menu.staff, flag: isStaffPage, fn: handleStaffClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.filter( ( x : any ) => x?.type !== "Group" )?.length > 0 || isAdminView ) ? [ { id: 3, name: Loc.currentLang.menu.terms, flag: isSeasonPage, fn: handleSeasonClick } ] : [] ),
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id: 4, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id: 5, name: Loc.currentLang.menu.organization, flag: isGroup, fn: handleGroupClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id: 6, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id: 7, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id: 8, name: Loc.currentLang.menu.members, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
    ]
    const EliteClubTab = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id: 2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.length > 0 || isAdminView ) ? [ { id: 3, name: Loc.currentLang.menu.teams, flag: isOrganization, fn: handleOrganizationClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id: 4, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id: 5, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id: 6, name: Loc.currentLang.menu.staff, flag: isStaffPage, fn: handleStaffClick } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id: 7, name: Loc.currentLang.menu.members, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
    ]
    const SchoolMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id:2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.length > 0 || isAdminView ) ? [ { id:3, name: Loc.currentLang.menu.organization, flag: isOrganization, fn: handleOrganizationClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id:4, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id:5, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id:6, name: Loc.currentLang.menu.staff, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id:7, name: Loc.currentLang.menu.administration, flag: isStaffPage, fn: handleStaffClick } ] : [] ),
    ]
    const GradClassMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id:2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.filter( ( x : any ) => x?.type == "Group" )?.length > 0 || isAdminView ) ? [ { id: 3, name: leagueTeamFK?.subType == "GradClass" ? Loc.currentLang.menu.groups : Loc.currentLang.menu.organization, flag: isGroup, fn: handleGroupClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id:4, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id:5, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id:6, name: Loc.currentLang.menu.staff, flag: isStaffPage, fn: handleStaffClick, } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id:7, name: Loc.currentLang.menu.members, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
    ]
    const ClassMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id: 2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( leagueTeamFK?.players?.length > 0 || isAdminView ) ? [ { id: 3, name: Loc.currentLang.menu.students, flag: isPlayerPage, fn: handlePlayersClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.filter( ( x : any ) => x?.type == "Group" )?.length > 0 || isAdminView ) ? [ { id: 4, name: Loc.currentLang.menu.organization   , flag: isGroup, fn: handleGroupClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id:5, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id:6, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : []),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id:7, name: Loc.currentLang.menu.staff, flag: isStaffPage, fn: handleStaffClick } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id:8, name: Loc.currentLang.menu.parents, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
    ]
    const GroupMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id:2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id: 3, name: Loc.currentLang.menu.members, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.filter( ( x : any ) => x?.type == "Group" )?.length > 0 || isAdminView ) ? [ { id: 4, name: Loc.currentLang.menu.organization , flag: isGroup, fn: handleGroupClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id:5, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id:6, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id:7, name: Loc.currentLang.menu.staff, flag: isStaffPage, fn: handleStaffClick, } ] : [] ),
    ]
    const orgsMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id: 2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id: 3, name: Loc.currentLang.menu.employees, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.length > 0 || isAdminView ) ? [ { id: 4, name: Loc.currentLang.menu.organization, flag: isOrganization, fn: handleOrganizationClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id:5, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id:6, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id:7, name: Loc.currentLang.menu.staff, flag: isStaffPage, fn: handleStaffClick, } ] : [] ),

    ]
    const neighborhoodMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id: 2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id:3, name: Loc.currentLang.menu.neighbors, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.length > 0 || isAdminView ) ? [ { id:4, name: Loc.currentLang.menu.organization, flag: isOrganization, fn: handleOrganizationClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id:5, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id:6, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id:7, name: Loc.currentLang.menu.staff, flag: isStaffPage, fn: handleStaffClick, } ] : [] ),
    ]
    const communityMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick },
        ...( ( leagueTeamFK?.ownedEvents?.length > 0 || isAdminView ) ? [ { id: 2, name: Loc.currentLang.menu.events, flag: isEventPage, fn: handleEventsClick } ] : [] ),
        ...( ( leagueTeamFK?.members?.length > 0 || isAdminView ) ? [ { id: 3, name: Loc.currentLang.menu.members, flag: isMembersPage, fn: handleMembersClick } ] : [] ),
        ...( ( leagueTeamFK?.childOrgs?.length > 0 || isAdminView ) ? [ { id: 4, name: Loc.currentLang.menu.organization, flag: isOrganization, fn: handleOrganizationClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView ) ? [ { id:5, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( isFilesView || isAdminView ) ? [ { id:6, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.staff?.length > 0 || isAdminView ) ? [ { id:7, name: Loc.currentLang.menu.staff, flag: isStaffPage, fn: handleStaffClick, } ] : [] ),
    ]
    const eventsMenu = [
        { id: 1, name: Loc.currentLang.menu.home, flag: isTeamsPage, fn: handleHomeClick, },
        ...( ( leagueTeamFK?.participants?.length >= 0 || isAdminView && leagueTeamFK?.status !== 'Canceled' ) ? [ { id: 2, name: Loc.currentLang.menu.participants, flag: isparticipantspage, fn: handleParicipantsClick, } ] : [] ),
        ...( ( leagueTeamFK?.resources?.length >= 0 || isAdminView && leagueTeamFK?.status !== 'Canceled' ) ? [ { id: 3, name: Loc.currentLang.menu.resources, flag: isResourses, fn: handleResourcesClick } ] : [] ),
        ...( ( leagueTeamFK?.albums?.length > 0 || isAdminView && leagueTeamFK?.status !== 'Canceled' ) ? [ { id: 4, name: Loc.currentLang.menu.albums, flag: isPhotoPage, fn: handleAblumClick } ] : [] ),
        ...( ( leagueTeamFK?.files?.length > 0 || isAdminView && leagueTeamFK?.status !== 'Canceled' ) ? [ { id: 5, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFileClick } ] : [] ),
        ...( ( leagueTeamFK?.seriesEvents?.length > 0 && isAdminView && leagueTeamFK?.status !== 'Canceled' ) ? [ { id: 6, name: 'Occurence', flag: isOccurencePage, fn: handleOccurenceClick } ] : [] ),
    ]
    const GroupListItem = ( { item, isAdminView, handleRemoveGroup, navigate } ) =>
    {
        let cacheItem = entitydatalist?.find( ( x : any ) => x?.id == item?.id );
        if ( cacheItem || currentUser?.id == item?.id )
            item = currentUser?.id == item?.id ? AV.AVEntity.getLink( currentUser ) : AV.AVEntity.getLink( cacheItem )
        return (
            <Box
                key={ item.id }
                bgColor={ '#fff' }
                borderWidth={ '1px' }
                borderColor={ 'AVColor.hovercolor2' }
                minWidth={ 130 }
                minHeight={ 175 }
                borderRadius={ 5 }
                m={ 2 }
                shadow={ 1 }
            >
                { isAdminView && (
                    <Pressable
                        key={ item.id }
                        padding={ 1.5 }
                        rounded={ 'full' }
                        position={ 'absolute' }
                        right={ -10 }
                        top={ -10 }
                        zIndex={ 999 }
                        _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                        backgroundColor={ 'AVColor.buttonBg' }
                        onPress={ () => handleRemoveGroup( item ) }
                    >
                        <CloseIcon size={ '12px' } color={ 'AVColor.secondaryText' } />
                    </Pressable>
                ) }
                <VStack alignItems={ 'center' } height={ '100%' } width={ '100%' }>
                    <Pressable
                        key={ item.id }
                        height={ '100%' }
                        justifyContent={ 'center' }
                        width={ '100%' }
                        _hover={ { bgColor: '#EEEEEE' } }
                        onPress={ () =>
                        {
                            item?.type == 'Person'
                                ? navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                    state: {
                                        user: {
                                            id: item?.id,
                                            pk: item?.pk,
                                            region: item?.region,
                                            type: item?.type,
                                            name: item?.title,
                                        },
                                    },
                                } )
                                : item?.type == 'PlayerOrg'
                                    ? navigate( `/season/${ item?.id }/${ item?.region }`, { state: { isCreate: false, team: item } } )
                                    : item?.type == 'Group' ? navigate( `/teams/${ item?.id }/${ item?.region }`, { state: { isCreate: false, team: item } } ) :
                                        navigate( `/organizations/${ item?.id }/${ item?.region }/${ item?.type }`, {
                                            state: { isCreate: false, orgType: item?.type, org: item },
                                        } )

                        } }
                    >
                        { item?.thumb && (
                            <Box
                                bgColor={ '#FF6600' }
                                borderRadius={ [ 'full' ] }
                                width={ '70px' }
                                height={ '70px' }
                                alignSelf={ 'center' }
                                p={ 0 }
                            >
                                <Image
                                    source={ {
                                        uri: AV.Media.getThumb( item ),
                                    } }
                                    borderRadius={ [ 'full' ] }
                                    //top={ "2" }
                                    width={ '100%' }
                                    height={ [ '100%' ] }
                                />
                            </Box>
                        ) }
                        { !item?.thumb && (
                            <Box
                                alignItems={ 'center' }
                            >
                                <Profile height={ '70' } width={ '70' } color={ "#32AE78" } />
                            </Box>
                        ) }
                        <Tooltip label={ item.title } openDelay={ 500 }>
                            <Pressable
                                onPress={ () =>
                                {
                                    navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                        state: {
                                            user: {
                                                id: item?.id,
                                                pk: item?.pk,
                                                region: item?.region,
                                                type: item?.type,
                                                name: item?.title,
                                            },
                                        },
                                    } );
                                } }
                            >
                                <Text
                                    marginTop={ 2 }
                                    left={ '2px' }
                                    alignItems='center'
                                    width='120px'
                                    numberOfLines={ 2 }
                                    textAlign='center'
                                >
                                    { item.title }
                                </Text>
                            </Pressable>
                        </Tooltip>
                    </Pressable>
                </VStack>
            </Box>
        );
    };


    const handledocument = async ( item ) =>
    {
        let val = await AV.Media.getFile( item?.mListId, item.id, item?.storeId );
        if ( val )
        {
            var blobUrl = val;
            var fileName = item?.orgFile;
            var a = document.createElement( 'a' );
            a.href = blobUrl.toString();
            a.download = fileName;
            document.body.appendChild( a );
            a.click();
            document.body.removeChild( a );
            //    window.open(val);
        }
    };

    const setTeam = () =>
    {
        setEventType( Loc.currentLang.menu.team );
        setisshowteamsList( teamsList?.filter( ( x ) => x?.type === 'Team' ) );
    };
    const setAllTeam = () =>
    {
        setEventType( Loc.currentLang.menu.all );
        setisshowteamsList( teamsList );
    };
    const setGroup = () =>
    {
        setEventType( Loc.currentLang.menu.groupOrClub );
        setisshowteamsList( teamsList?.filter( ( x ) => x?.type === 'Group' ) );
    };

    const filterParticipants = ( status ) =>
    {
        if ( status === "ALL" )
        {
            return participantList;
        }
        if ( status === "ALL" || status === "RSVP" )
        {
            return participantList.filter( item => item.status == undefined );
        }
        else if ( status === "Yes" )
        {
            return participantList.filter( item => item.status === "Yes" );
        } else if ( status === "No" )
        {
            return participantList.filter( item => item.status === "No" );
        } else if ( status === "Maybe" )
        {
            return participantList.filter( item => item.status === "Maybe" );
        }
        return participantList;
    };


    const setAllStatus = () =>
    {
        setStatus( "All" )
        const filteredParticipantsALL = filterParticipants( "All" );
        setFilteredParticipantList( filteredParticipantsALL )
    }
    const setRsvpStatus = () =>
    {
        setStatus( "RSVP" )
        const filteredParticipantRSVP = filterParticipants( "RSVP" );
        setFilteredParticipantList( filteredParticipantRSVP )
    }
    const setYesStatus = () =>
    {
        setStatus( "Yes" )
        const filteredParticipantYes = filterParticipants( "Yes" );
        setFilteredParticipantList( filteredParticipantYes )
    }
    const setNoStatus = () =>
    {
        setStatus( "No" )
        const filteredParticipantNo = filterParticipants( "No" );
        setFilteredParticipantList( filteredParticipantNo )
    }
    const setMaybeStatus = () =>
    {
        setStatus( "Maybe" )
        const filteredParticipantMayBe = filterParticipants( "Maybe" );
        setFilteredParticipantList( filteredParticipantMayBe )
    }


    const HandleRemoveStaffs = ( item ) =>
    {
        setisConfirmDelete( true )
        setConfirmationtext( leagueTeamFK?.type == 'School' ? 'Are You Sure? You Want to Delete Your Adminstration' : 'Are You Sure? You Want to Delete Your Staff' );
        setItemSelect( item );
        setselectType( 'DeleteStaff' )
    }
    const RemoveSeason = async () =>
    {
        setLoader( true );
        let removeSeason : any = await AV.Team.removeSeason( AVEntity.getFk( leagueTeamFK ), {
            id: itemSelect.id,
            pk: itemSelect.pk,
            type: 'Person',
            /*role: item.role,*/
        } );
        if ( removeSeason !== null )
        {
            setLeagueTeamFK( removeSeason );
            setSeasonList( removeSeason?.childOrgs );
        }
        setLoader( false );
        setisConfirmDelete( false );
    }
    const RemoveStaffs = async () =>
    {
        setLoader( true );
        let LeagueTeam = await AV.OrgBase.removeStaff( AVEntity.getFk( leagueTeamFK ), {
            id: itemSelect.id,
            pk: itemSelect.pk,
            type: 'Person',
            role: itemSelect.role,
        } );
        if ( LeagueTeam )
        {
            setStaffList( LeagueTeam?.staff );
        }
        setLoader( false );
        setisConfirmDelete( false );
    };
    //GroupChat
    const HandleGroupChat = async ( type ) =>
    {
        setIsLoad( true )
        let validation = type == "S" ? leagueTeamFK?.sConvo : type == "M" ? leagueTeamFK?.mConvo : type == "P" ? leagueTeamFK?.pConvo : type == "N" ? leagueTeamFK?.nConvo : type == "W" ? leagueTeamFK?.wConvo : type == "X" ? leagueTeamFK?.xConvo : type == "Y" ? leagueTeamFK?.yConvo : '';
        if (validation !== '' || validation !== undefined )
        {
            setIsConvoData(validation)
        }
        if ( validation === '' || validation == undefined )
        {
            let Response : any = await AV.AVEntity.CreateGroupConvo( target, type );
            console.log( "Response", Response )
            let Index = entitydatalist?.findIndex( x => x?.id == Response?.id )
            if ( Index !== -1 )
            {
                entitydatalist[ Index ] = Response;
                setLeagueTeamFK( Response )
            }
            dispatch( setEntitydata( entitydatalist ) );
            const fk = {
                id: type == "S" ? Response.sConvo : type == "M" ? Response?.mConvo : type == "P" ? Response?.pConvo : type == "N" ? Response?.nConvo : type == "W" ? Response?.wConvo : type == "X" ? Response?.xConvo : type == "Y" ? Response?.yConvo : '',
                pk: type == "S" ? Response.sConvo : type == "M" ? Response?.mConvo : type == "P" ? Response?.pConvo : type == "N" ? Response?.nConvo : type == "W" ? Response?.wConvo : type == "X" ? Response?.xConvo : type == "Y" ? Response?.yConvo : '',
                region: leagueTeamFK?.region,
                type: "GroupConvo",
            }
            setIsConvoData(fk?.id)
            const feedParam : AV.IFeedParam = {
                feed: fk,
                startDate: new Date( 0 ), forward: true
            };
            await AV.Feed.getFeed( feedParam ).then( async ( response : any ) =>
            {
                if ( response !== null )
                {
                    const existingconvo = userconvo?.length > 0 && userconvo.find( ( x : any ) => x?.id === response?.id ) ? true : false;
                    if ( !existingconvo )
                    {
                        if ( userconvo?.length > 0 )
                        {
                            userconvo?.unshift( response )
                        }
                        else
                        {
                            userconvo = [];
                            userconvo?.unshift( response )
                        }
                    }
                    dispatch( setConvo( userconvo ) );
                    await handleGetGroupConvo( response )
                }
            } );
        }
        setIsOpenConvo( true )
        setIsLoad( false )
    }
    const EntityDataFinding = async ( e : any ) =>
    {
        if ( e )
        {

            setisStaffentity( e?.staff?.some( ( x : any ) => x?.id === currentUser?.id ) );
            setisMemberEntity( e?.members?.some( ( x : any ) => x?.id === currentUser?.id ) );
            setisPlayerEntity( e?.players?.some( ( x : any ) => x?.id === currentUser?.id ) );
            if ( currentUser?.favs?.filter( ( x ) => x?.id === e?.id )[ 0 ] ) setMyFavourite( true );
            const isAdmin = e?.staff?.some( ( x ) => x?.id === currentUser?.id );
            const isWardStaff = currentUser?.wards?.some( ( ward ) =>
                e?.staff?.some( ( staff ) => staff?.id === ward?.id )
            );
            let isAdminOrStaff = e?.status == 'Canceled' ? false : e?.participants?.some(
                x => x?.id === user?.id && ( x?.linkType === "Owner" || x?.linkType === "Staff" )
            );
            setIsAdminView( isAdmin ? isAdmin : isAdminOrStaff );

            if ( e.series )
            {
                var fk = {
                    id: e.series,
                    fk: e.series.slice( -4 ),
                    type: e.type,
                    region: e.region
                };

                const seriesEvent : any = await AV.AVEntity.get( fk );

                isAdminOrStaff = seriesEvent?.status == 'Canceled' ? false : seriesEvent?.participants?.some(
                    x => x?.id === user?.id && ( x?.linkType === "Owner" || x?.linkType === "Staff" )
                );
                setIsAdminView( isAdminOrStaff );
                setEditAccess( true );
                console.log( "Series Events", seriesEvent );
            }


            let follower = currentUser?.follows?.filter( ( x : any ) => x?.id === e?.id );
            setisAlbumView( ( isAdmin || ( follower?.length > 0 && leagueTeamFK?.albums?.length > 0 ) ) ? true : false );
            GetEventsList( 'All', e );
            if ( e?.url )
            {
                setLinks( e?.url );
            }
            if ( e?.albums )
            {
                setalbumlist( e?.albums );
            }
            if ( e?.files )
            {
                setfilesList( e?.files );
            }
            if ( e?.activity )
            {
                setActivity( e?.activity );
            }
            if ( e?.location?.siteMap )
            {
                setSiteMap( Media.getURL( e?.location?.siteMap ) )
            }
            if ( e?.childOrgs )
            {
                setSeasonList( e?.childOrgs?.filter( ( x : any ) => x?.type !== "Group" ) || [] );
                setGroupList( e?.childOrgs?.filter( ( x : any ) => x?.type == "Group" ) )
                setOrgList( e?.childOrgs )
            }
            setIsDeleted( e?.status === 'Deleted' ? false : true );
            setIsSuspended( e?.status === 'Suspended' ? false : true );
            if ( e?.parent?.type === "Person" && currentUser?.id == e?.parent?.id )
            {
                setOwner( currentUser );
            } else if (
                [ "Team", "PlayerOrg", "Club", "School" ].includes( e?.parent?.type ) &&
                currentUser?.orgs?.some( x => x?.id == e?.parent?.id )
            )
            {
                setOwner( currentUser?.orgs?.find( x => x?.id == e?.parent?.id ) );
            } else
            {
                if ( e?.parent )
                {
                    let eventowner : any = await AV.AVEntity.get( e?.parent );
                    setOwner( eventowner );
                }
            }
            setProfile( e?.profile );
            if ( e?.members )
            {
                var memberCheck = e?.members?.some( ( x : any ) => x?.id === currentUser?.id );
            }
            if ( e?.guardians )
            {
                var guardianCheck = e?.guardians?.some(
                    ( x : any ) => x?.id === currentUser?.id,
                );
            }
            setisFilesView( memberCheck || guardianCheck || isAdmin );
            if ( e?.players )
            {
                setPlayersList( e?.players );
            }
            if ( e?.resources )
            {
                setresoursesList( e?.resources );
            }
            if ( e?.participants )
            {
                setIsParticipantList( e?.participants )
                setFilteredParticipantList( e?.participants )
            }
            if ( e?.members )
            {
                setmemberList( e?.members );
            }
            if ( e?.staff )
            {
                setStaffList( e?.staff );
            }
            setisshowteamsList( e?.childOrgs )
            if ( e?.location )
            {
                GetWeatherIcon( e );

            }
            const visifiles = e?.members?.filter( ( x ) => x.id === currentUser?.id );
            setVisible( visifiles?.length > 0 ? true : false );
            const viewadmin : any = e?.id === currentUser?.id;
            setdatas( viewadmin?.length > 0 ? true : false );
            const loc : any = await AV.Media.getMap( e?.location );
            if ( loc )
            {
                setgooglemap( loc );
            }
        }
    }

    const handleSwitchTab = async (e) =>
    {
        //const CurrentTab : any = localStorage.getItem( 'activeTabEntity' );
        const storedTab1 = localStorage.getItem( 'activeTabEntity' );
        const CurrentParentTab1 : any = storedTab ? JSON.parse( storedTab ) : null;
        if ( CurrentParentTab?.id !== e?.id )
        {
            handleHomeClick();
            return;
        }
        switch ( CurrentParentTab1?.tab)
        {
            case "HomePage":
                await handleHomeClick();
                break;
            case "EventPage":
                await handleEventsClick();
                break;
            case "SeasonPage":
                await handleSeasonClick();
                break;
            case "MemberPage":
                await handleMembersClick();
                break;
            case "AlbumPage":
                await handleAblumClick();
                break;
            case "FilePage":
                await handleFileClick();
                break;
            case "OccurancePage":
                await handleOccurenceClick();
                break;
            case "PlayerPage":
                await handlePlayersClick();
                break;
            case "GroupPage":
                await handleGroupClick();
                break;
            case "OrganizationPage":
                await handleOrganizationClick();
                break;
            case "ParticipantPage":
                await handleParicipantsClick();
                break;
            case "ResourcePage":
                await handleResourcesClick();
                break;
            default:
                await handleHomeClick();
                break;
        }
    }

    useEffect( () =>
    {
        setEventsList( leagueTeamFK?.ownedEvents );
        setEventData( leagueTeamFK?.ownedEvents );
        Status = "SOCIAL"
        setDatass( "SOCIAL" )
        HandleCurrent( "ALL", leagueTeamFK );

    }, [ leagueTeamFK?.ownedEvents, isEventPage ] )
    const setAlls = () =>
    {
        setIsAll( true );
        setIsParticipant( false )
        setIsGames( false );
        setIsMeeting( false );
        setIsOther( false );
        setEventType( Loc.currentLang.menu.all )
        Status = 'ALL'
        if ( currentstatus === Loc.currentLang.menu.current )
        {
            HandleCurrent( "ALL", leagueTeamFK ?? entitydatalist?.find( x => x?.id === viewTeam?.id ) )
        }
        else if ( currentstatus === Loc.currentLang.menu.all )
        {
            HandleAll( "ALL" )
        }
        else if ( currentstatus === Loc.currentLang.menu.past )
        {
            HandlePast( "ALL" )
        }
        else if ( currentstatus === Loc.currentLang.menu.canceled )
        {
            HandleDelete( "ALL" )
        }
    };
    const setGames = () =>
    {
        setEventType( Loc.currentLang.menu.gamesTxt )
        setDatass( "GAME" )
        setIsAll( false );
        setIsParticipant( false )
        setIsGames( true );
        setIsMeeting( false );
        setIsOther( false );
        Status = "GAME"
        if ( currentstatus === Loc.currentLang.menu.current )
        {
            HandleCurrent( "GAME", leagueTeamFK ?? entitydatalist?.find( x => x?.id === viewTeam?.id ) )
        }
        else if ( currentstatus === Loc.currentLang.menu.all )
        {
            HandleAll( "GAME" )
        }
        else if ( currentstatus === Loc.currentLang.menu.past )
        {
            HandlePast( "GAME" )
        }
        else if ( currentstatus === Loc.currentLang.menu.canceled )
        {
            HandleDelete( "GAME" )
        }

    };
    const setSocial = () =>
    {

        setEventType( Loc.currentLang.menu.socialText )
        Status = "SOCIAL"
        setDatass( "SOCIAL" )
        setIsAll( false );
        setIsParticipant( false )
        setIsGames( false );
        setIsMeeting( false );
        setIsOther( true );
        if ( currentstatus === Loc.currentLang.menu.current )
        {
            HandleCurrent( "SOCIAL", leagueTeamFK ?? entitydatalist?.find( x => x?.id === viewTeam?.id ) )
        }
        else if ( currentstatus === Loc.currentLang.menu.all )
        {
            HandleAll( "SOCIAL" )
        }
        else if ( currentstatus === Loc.currentLang.menu.past )
        {
            HandlePast( "SOCIAL" )
        }
        else if ( currentstatus === Loc.currentLang.menu.canceled )
        {
            HandleDelete( "SOCIAL" )
        }
    };
    const setMeeting = () =>
    {
        setEventType( Loc.currentLang.menu.meeting )
        Status = "MEETING"
        setDatass( "MEETING" )
        setIsAll( false );
        setIsParticipant( false )
        setIsGames( false );
        setIsMeeting( true );
        setIsOther( false );
        if ( currentstatus === Loc.currentLang.menu.current )
        {
            HandleCurrent( "MEETING", leagueTeamFK ?? entitydatalist?.find( x => x?.id === viewTeam?.id ) )
        }
        else if ( currentstatus === Loc.currentLang.menu.all )
        {
            HandleAll( "MEETING" )
        }
        else if ( currentstatus === Loc.currentLang.menu.past )
        {
            HandlePast( "MEETING" )
        }
        else if ( currentstatus === Loc.currentLang.menu.canceled )
        {
            HandleDelete( "MEETING" )
        }
    };
    const setPractices = () =>
    {
        setEventType( Loc.currentLang.menu.practice )
        Status = "PRACTICE"
        setDatass( "PRACTICE" )
        if ( currentstatus === Loc.currentLang.menu.current )
        {
            HandleCurrent( "PRACTICE", leagueTeamFK ?? entitydatalist?.find( x => x?.id === viewTeam?.id ) )
        }
        else if ( currentstatus === Loc.currentLang.menu.all )
        {
            HandleAll( "PRACTICE" )
        }
        else if ( currentstatus === Loc.currentLang.menu.past )
        {
            HandlePast( "PRACTICE" )
        }
        else if ( currentstatus === Loc.currentLang.menu.canceled )
        {
            HandleDelete( "PRACTICE" )
        }
        setIsAll( false );
        setIsParticipant( true );
        setIsGames( false );
        setIsMeeting( false );
        setIsOther( false );
    };
    const HandlePast = ( type ) =>
    {
        const CurrentDateTime = new Date();
        let EventDataList = type == "ALL" ? leagueTeamFK?.ownedEvents : type == "PRACTICE" ? eventsList?.filter( ( x ) => x.linkType === 'Practice' && x.status !== "Canceled" ) : type == "GAME" ? eventsList?.filter( ( x ) => x.linkType === 'Game' && x.status !== "Canceled" ) : type == "MEETING" ? eventsList?.filter( ( x ) => x.linkType === 'Meeting' && x.status !== "Canceled" ) :
            eventsList?.filter( ( x ) => x.linkType === 'Social' && x.status !== "Canceled" )
        var PastEventsList : any = EventDataList
            .filter( event =>
            {
                const originalStartDate = new Date( event.end );
                const formattedEndDate = originalStartDate.toISOString();

                return formattedEndDate < CurrentDateTime.toISOString();
            } )
            .map( event =>
            {
                return {
                    ...event,
                };
            } ).sort( ( a : any, b : any ) =>
            {
                const startA : any = new Date( a.start );
                const startB : any = new Date( b.start );
                return startA - startB;
            } );
        PastEventsList = PastEventsList?.filter( x => x?.data !== "Canceled" && x?.status !== "Canceled" )
        setEventData( PastEventsList );
    };

    const HandleCurrent = ( type, leagueTeamFK ) =>
    {
        const CurrentDateTime = new Date();

        let EventDataList = type == "ALL" ? leagueTeamFK?.ownedEvents : type == "PRACTICE" ? eventsList?.filter( ( x ) => x.linkType === 'Practice' && x.status !== "Canceled" ) : type == "GAME" ? eventsList?.filter( ( x ) => x.linkType === 'Game' && x.status !== "Canceled" ) : type == "MEETING" ? eventsList?.filter( ( x ) => x.linkType === 'Meeting' && x.status !== "Canceled" ) :
            eventsList?.filter( ( x ) => x.linkType === 'Social' && x.status !== "Canceled" )
        var currentEventsList : any = EventDataList?.filter( event =>
        {
            const originalStartDate = new Date( event.end );
            const formattedStartDate = originalStartDate.toISOString();

            return formattedStartDate >= CurrentDateTime.toISOString();
        } )
            .map( event =>
            {
                return {
                    ...event,
                };
            } ).sort( ( a : any, b : any ) =>
            {
                const startA : any = new Date( a.start );
                const startB : any = new Date( b.start );
                return startA - startB;
            } );
        currentEventsList = currentEventsList?.filter( ( x ) => x.staus !== "Canceled" && x.data !== "Canceled" )
        setEventData( currentEventsList );

    };
    const HandleAll = ( type ) =>
    {
        let EventDataList = type == "ALL" ? leagueTeamFK?.ownedEvents : type == "PRACTICE" ? eventsList?.filter( ( x ) => x.linkType === 'Practice' ) : type == "GAME" ? eventsList?.filter( ( x ) => x.linkType === 'Game' ) : type == "MEETING" ? eventsList?.filter( ( x ) => x.linkType === 'Meeting' ) :
            eventsList?.filter( ( x ) => x.linkType === 'Social' )
        var Alleventlist : any = EventDataList
            .map( event =>
            {
                return {
                    ...event,
                };
            } ).sort( ( a : any, b : any ) =>
            {
                const startA : any = new Date( a.start );
                const startB : any = new Date( b.start );
                return startA - startB;
            } );
        Alleventlist = Alleventlist?.filter( x => x?.data !== "Canceled" && x?.status !== "Canceled" )
        setEventData( Alleventlist );

    };
    const HandleDelete = ( type ) =>
    {
        let EventDataList = type == "ALL" ? eventsList?.filter( ( x ) => x?.data === "Canceled" ) : type == "PRACTICE" ? eventsList?.filter( ( x ) => x.linkType === 'Practice' && x?.data === "Canceled" ) : type == "GAME" ? eventsList?.filter( ( x ) => x.linkType === 'Game' && x?.data === "Canceled" ) : type == "MEETING" ? eventsList?.filter( ( x ) => x.linkType === 'Meeting' && x?.data === "Canceled" ) :
            eventsList?.filter( ( x ) => x.linkType === 'Social' && ( x?.data === "Canceled" && x?.status === "Canceled" ) )
        setEventData( EventDataList );

    };
    const GetWeatherIcon = async ( e ) =>
    {
        try
        {
            let result = await Weather.getWeather( e?.location?.geoData?.lat, e?.location?.geoData?.lng );
            let URL : any = await Weather.GetURL( e?.location?.geoData?.lat, e?.location?.geoData?.lng );
            if ( URL != "" )
                setweatherURL( URL );
            if ( result == null )
                return;
            let weatherdata = result?.periods.filter( a => Moment( a?.startTime ).format( 'YYYY-MM-DD' ) == Moment( new Date() ).format( 'YYYY-MM-DD' ) );
            switch ( weatherdata[ 0 ].shortForecast )
            {
                case 'Cloudy':
                case 'Partly Cloudy':
                    weatherdata[ 0 ].icon = PartlyCloudys;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Areas Of Fog then Mostly Sunny':
                case 'Areas Of Fog':
                case 'Patchy Fog':
                case 'Widespread Fog':
                    if ( weatherdata[ 0 ]?.isDaytime )
                        weatherdata[ 0 ].icon = fogday;
                    else
                        weatherdata[ 0 ].icon = PatchyFog;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Showers And Thunderstorms':
                case 'Slight Chance Showers And Thunderstorms then Partly Sunny':
                case 'Chance Showers And Thunderstorms':
                case "Showers And Thunderstorms Likely":
                    weatherdata[ 0 ].icon = ChanceShowersAndThunderstorms;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Sunny':
                case 'Mostly Sunny':
                case 'Partly Sunny':
                case 'Clear':
                case 'Mostly Clear':
                    if ( weatherdata[ 0 ]?.isDaytime )
                        weatherdata[ 0 ].icon = MostlyClear;
                    else
                        weatherdata[ 0 ].icon = clearnight;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Rain':
                    weatherdata[ 0 ].icon = Rainy;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Chance Rain Showers':
                case ' Mostly Cloudy then Slight Chance Light Rain':
                case 'Slight Chance Rain Showers':
                    weatherdata[ 0 ].icon = SlightChanceRainShowers;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Chance Snow Showers':
                    weatherdata[ 0 ].icon = ChanceSnowShowers;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Chance Snow Showers then Sunny':
                    weatherdata[ 0 ].icon = ChanceSnowShowersthenSunny;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Chance Rain And Snow then Cloudy':
                    weatherdata[ 0 ].icon = ChanceRainAndSnowthenCloudy;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Light Rain':
                case 'Chance Light Rain':
                case 'Slight Chance Light Rain':
                    weatherdata[ 0 ].icon = SlightChanceLightRain;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Slight Chance Light Rain then Cloudy':
                    weatherdata[ 0 ].icon = SlightChanceLightRainthenCloudy;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Light Rain then Rain And Snow':
                case 'Slight Chance Rain And Snow then Mostly Cloudy':
                    weatherdata[ 0 ].icon = ChanceRainAndSnow;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Slight Chance Rain Showers then Mostly Sunny':
                    weatherdata[ 0 ].icon = SlightChanceRainShowersthenMostlySunny;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Partly Sunny then Chance Showers And Thunderstorms':
                    weatherdata[ 0 ].icon = PartlySunnythenChanceShowersAndThunderstorms;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Mostly Cloudy':
                    weatherdata[ 0 ].icon = MostlyCloudy;
                    setweather( weatherdata[ 0 ] )
                    break;
                case 'Mostly Sunny then Slight Chance Rain Showers':
                    weatherdata[ 0 ].icon = MostlySunnythenSlightChanceRainShowers;
                    setweather( weatherdata[ 0 ] )
                    break;
                default:
                    setweather( weatherdata[ 0 ] )
                    break;
            }
        } catch ( ex )
        {
            console.log( ex )
        }
    };
    const HandeFeedBackNavigate = ( item ) =>
    {
        setIsFeedBackGroup( false );
        navigate( `/teams/${ item?.id }/${ item?.region }`, {
            state: { isCreate: false, team: item, type: 'Feedback' },
        } );
    }
    const handleEventType = ( type ) =>
    {
        setActivityType( type );
        if ( type == 'Practice' || type == 'Meeting' )
        {
            setIsPractice( true );
            setRepeatWeek( true );
            setShowAddEventsModal( !showAddEventsModal );
            setIsActives( !isActives );
        } else if ( type == 'Social' )
        {
            setIsGame( false );
            setIsPractice( false );
            setShowAddEventsModal( !showAddEventsModal );
            setRepeatWeek( false );
        }
        else
        {
            setRepeatWeek( false );
            setShowAddEventsModal( !showAddEventsModal );
            setIsActives( !isActives );
        }
    };

    /*HandleHelptext*/
    const handlePersonTapHelpText = async () =>
    {
        if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.person.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.person.friendTab );
            sethelptype( Loc.currentLang.menu.friends );
        }
        else if ( isSeasonPage )
        {
            sethelptext( Loc.currentLang.helpText.person.organizationTab );
            sethelptype( Loc.currentLang.menu.organizations );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.person.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.person.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
        //else if (isGuardiansPage) {
        //    sethelptext(Loc.currentLang.helpText.person.guardianTab);
        //    sethelptype(Loc.currentLang.menu.guardians);
        //}
        //else if (isDependantsPage) {
        //    sethelptext(Loc.currentLang.helpText.person.dependentTab);
        //    sethelptype(Loc.currentLang.menu.dependants);
        //}
    }
    const handleTeamTapHelpText = async () =>
    {
        if ( isSeasonPage )
        {
            sethelptext( Loc.currentLang.helpText.team.seasonTab );
            sethelptype( Loc.currentLang.menu.season );
        }
        else if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.team.staffTab );
            sethelptype( Loc.currentLang.menu.staff );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.team.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isGroup )
        {
            sethelptext( Loc.currentLang.helpText.team.groupTab );
            sethelptype( Loc.currentLang.menu.Group );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.team.memberTab );
            sethelptype( Loc.currentLang.menu.members );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.team.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.team.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }

    }
    const handleLeagueTeamTapHelpText = async () =>
    {
        if ( isPlayerPage )
        {
            sethelptext( Loc.currentLang.helpText.season.playersTab );
            sethelptype( Loc.currentLang.menu.players );
        }
        else if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.season.staffTab );
            sethelptype( Loc.currentLang.menu.staff );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.season.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isGroup )
        {
            sethelptext( Loc.currentLang.helpText.season.groupTab );
            sethelptype( Loc.currentLang.menu.Group );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.season.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.season.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.season.parentTab );
            sethelptype( Loc.currentLang.menu.parents );
        }
        else if ( isSeasonPage )
        {
            sethelptext( Loc.currentLang.helpText.season.subTeamTab );
            sethelptype( Loc.currentLang.menu.parents );
        }
    }
    const handlePerformanceTapHelpText = async () =>
    {
        if ( isPlayerPage )
        {
            sethelptext( Loc.currentLang.helpText.season.playersTab );
            sethelptype( Loc.currentLang.menu.players );
        }
        else if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.season.staffTab );
            sethelptype( Loc.currentLang.menu.staff );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.season.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isGroup )
        {
            sethelptext( Loc.currentLang.helpText.group.groupTab );
            sethelptype( Loc.currentLang.menu.Group );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.season.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.season.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.club.memberTab );
            sethelptype( Loc.currentLang.menu.parents );
        }
        else if ( isSeasonPage )
        {
            sethelptext( Loc.currentLang.helpText.season.subTeamTab );
            sethelptype( Loc.currentLang.menu.parents );
        }
    }
    const handleEventTapHelpText = async () =>
    {
        if ( isparticipantspage && !isResourses )
        {
            sethelptext( Loc.currentLang.helpText.event.participantTab );
            sethelptype( Loc.currentLang.menu.participants );
        }
        else if ( isResourses && !isparticipantspage )
        {
            sethelptext( Loc.currentLang.helpText.event.resourcesTab );
            sethelptype( Loc.currentLang.menu.resources );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.event.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.event.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
    }
    const handleClubTapHelpText = async () =>
    {
        if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.club.staffTab );
            sethelptype( Loc.currentLang.menu.staff );
        }
        else if ( isSeasonPage )
        {
            sethelptext( Loc.currentLang.helpText.club.termTab );
            sethelptype( Loc.currentLang.menu.Terms );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.club.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isGroup )
        {
            sethelptext( Loc.currentLang.helpText.club.groupTab );
            sethelptype( Loc.currentLang.menu.Group );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.club.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.club.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.club.memberTab );
            sethelptype( Loc.currentLang.menu.members );
        }
    }
    const handleEliteClubTapHelpText = async () =>
    {
        if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.eliteClub.staffTab );
            sethelptype( Loc.currentLang.menu.staff );
        }
        else if ( isSeasonPage )
        {
            sethelptext( Loc.currentLang.helpText.eliteClub.organizationTab );
            sethelptype( Loc.currentLang.menu.organizations );
        }
        else if ( isOrganization )
        {
            sethelptext( Loc.currentLang.helpText.eliteClub.teamsTab );
            sethelptype( Loc.currentLang.menu.organizations );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.eliteClub.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.eliteClub.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.eliteClub.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.eliteClub.memberTab );
            sethelptype( Loc.currentLang.menu.members );
        }
    }
    const handleSchoolTapHelpText = async () =>
    {
        if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.school.administrationTab );
            sethelptype( Loc.currentLang.menu.Administration );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.school.staffTab );
            sethelptype( Loc.currentLang.menu.staff );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.school.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isSeasonPage )
        {
            sethelptext( Loc.currentLang.helpText.school.organizationTab );
            sethelptype( Loc.currentLang.menu.organizations );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.school.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.school.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
        else if ( isOrganization )
        {
            sethelptext( Loc.currentLang.helpText.neighborHood.organizationTab );
            sethelptype( Loc.currentLang.menu.organizations );
        }

    }
    const handleGroupTapHelpText = async () =>
    {
        if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.group.staffTab );
            sethelptype( Loc.currentLang.menu.staff );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.group.memberTab );
            sethelptype( Loc.currentLang.menu.members );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.group.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isSeasonPage )
        {
            sethelptext( Loc.currentLang.helpText.group.organizationTab );
            sethelptype( Loc.currentLang.menu.organizations );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.group.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.group.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
        else if ( isGroup )
        {
            sethelptext( Loc.currentLang.helpText.group.groupTab );
            sethelptype( Loc.currentLang.menu.files );
        }

    }
    const handleClassTapHelpText = async () =>
    {
        if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.class.staffTab );
            sethelptype( Loc.currentLang.menu.staff );
        }
        else if ( isPlayerPage )
        {
            sethelptext( Loc.currentLang.helpText.class.studentTab );
            sethelptype( Loc.currentLang.menu.students );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.class.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isGroup )
        {
            sethelptext( Loc.currentLang.helpText.group.groupTab );
            sethelptype( Loc.currentLang.menu.Group );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.class.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.class.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.class.parentTab );
            sethelptype( Loc.currentLang.menu.parents );
        }
    }
    const handleGradClassTapHelpText = async () =>
    {
        if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.gradClass.staffTab );
            sethelptype( Loc.currentLang.menu.staff );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.gradClass.memberTab );
            sethelptype( Loc.currentLang.menu.members );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.gradClass.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isGroup )
        {
            sethelptext( Loc.currentLang.helpText.gradClass.groupTab );
            sethelptype( Loc.currentLang.menu.Group );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.gradClass.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.gradClass.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
    }
    const handleCommunityTapHelpText = async () =>
    {
        if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.community.staffTab );
            sethelptype( Loc.currentLang.menu.staff );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.community.memberTab );
            sethelptype( Loc.currentLang.menu.members );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.community.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isSeasonPage )
        {
            sethelptext( Loc.currentLang.helpText.community.organizationTab );
            sethelptype( Loc.currentLang.menu.organizations );
        }
        else if ( isOrganization )
        {
            sethelptext( Loc.currentLang.helpText.community.organizationTab );
            sethelptype( Loc.currentLang.menu.organizations );
        }
    }
    const handleNeighborhoodTapHelpText = async () =>
    {
        if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.neighborHood.administrationTab );
            sethelptype( Loc.currentLang.menu.Administration );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.neighborHood.neighborTab );
            sethelptype( Loc.currentLang.menu.Neighbors );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.neighborHood.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isOrganization )
        {
            sethelptext( Loc.currentLang.helpText.neighborHood.organizationTab );
            sethelptype( Loc.currentLang.menu.organizations );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.neighborHood.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.neighborHood.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
    }
    const handleOrganizationTapHelpText = async () =>
    {
        if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.organization.staffTab );
            sethelptype( Loc.currentLang.menu.staff );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.organization.memberTab );
            sethelptype( Loc.currentLang.menu.members );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.organization.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isOrganization )
        {
            sethelptext( Loc.currentLang.helpText.organization.organizationTab );
            sethelptype( Loc.currentLang.menu.organizations );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.organization.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.organization.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
    }
    const handleTermTapHelpText = async () =>
    {
        if ( isPlayerPage )
        {
            sethelptext( Loc.currentLang.helpText.term.memberTab );
            sethelptype( Loc.currentLang.menu.members );
        }
        else if ( isStaffPage )
        {
            sethelptext( Loc.currentLang.helpText.term.directorTab );
            sethelptype( Loc.currentLang.menu.directors );
        }
        else if ( isEventPage )
        {
            sethelptext( Loc.currentLang.helpText.term.eventTab );
            sethelptype( Loc.currentLang.menu.event );
        }
        else if ( isGroup )
        {
            sethelptext( Loc.currentLang.helpText.term.groupTab );
            sethelptype( Loc.currentLang.menu.organizations );
        }
        else if ( isPhotoPage )
        {
            sethelptext( Loc.currentLang.helpText.term.photoTab );
            sethelptype( Loc.currentLang.menu.albums );
        }
        else if ( isDocumentPage )
        {
            sethelptext( Loc.currentLang.helpText.term.documentTab );
            sethelptype( Loc.currentLang.menu.files );
        }
        else if ( isMembersPage )
        {
            sethelptext( Loc.currentLang.helpText.term.parentTab );
            sethelptype( Loc.currentLang.menu.parents );
        }
    }
    const handleHelpText = async () =>
    {

        switch ( leagueTeamFK?.type )
        {
            case 'Person':
                await handlePersonTapHelpText()
                break
            case 'Team':
                if ( leagueTeamFK?.subType == "Club" )
                    await handleClubTapHelpText()
                else
                    await handleTeamTapHelpText()
                break
            case 'PlayerOrg':
                if ( leagueTeamFK?.subType == "Term" )
                    await handleTermTapHelpText()
                else if ( leagueTeamFK?.subType == "Class" )
                    await handleClassTapHelpText()
                else if ( leagueTeamFK?.subType == "MusicGroup" )
                    await handlePerformanceTapHelpText  ()
                else
                    await handleLeagueTeamTapHelpText()
                break
            case 'Event':
                await handleEventTapHelpText()
                break
            case 'Club':
                await handleEliteClubTapHelpText()
                break
            case 'School':
                await handleSchoolTapHelpText()
                break
            case 'Group':
                if ( leagueTeamFK?.subType == "GradClass" )
                    await handleGradClassTapHelpText()
                else
                    await handleGroupTapHelpText()
                break
            case 'Community':
                await handleCommunityTapHelpText()
                break
            case 'Neighborhood':
                await handleNeighborhoodTapHelpText()
                break
            case 'Organization':
                await handleOrganizationTapHelpText()
                break
            default:
                sethelptext( Loc.currentLang.helpText.Team );
                sethelptype( Loc.currentLang.menu.help );
                break;
        }
    }
    const openPreview = ( item, allImages ) =>
    {
        setShowPreview( true );
        setSingleImage( item );
        allImages?.push( item )
        setShowAllImage( allImages );
    };
    const findPersonInChats = ( conversations, personToCheck ) =>
    {
        return conversations.find( convo =>
            convo?.subject === '' &&
            convo?.people?.some( person =>
                person?.id === personToCheck?.id &&
                person?.pk === personToCheck?.pk
            )
        );
    };

    const handleChat = async ( item : any ) =>
    {
        setIsRotate( true )
        let People : any = [];
        People?.push( item )
        let valid = await findPersonInChats( userconvo, item )
        if ( valid?.id !== undefined )
        {
            setIsConvoData( valid?.id )
            setIsRotate( false )
            setIsOpenConvo( true )
            return;
        }
        await handleSendSingleChat( People );
        setIsRotate( false )
        setIsOpenConvo( true )
    }

    const MemberCard = ( {
        item,
        currentUser,
        isAdminView,
        isOpen,
        isMemberData,
        setIsOpen,
        setIsMemberData,
        HandleSlience,
        HandleUnSlience,
        HandleRemoveMembers,
        isSlienceLoad,
        unSlienceLoad,
        isLoading,
        navigate,
        menuRefs,
        handleChat
    } ) =>  
    {
        let cacheItem = entitydatalist?.find( ( x : any ) => x?.id == item?.id );
        if ( cacheItem || currentUser?.id == item?.id )
            item = currentUser?.id == item?.id ? AV.AVEntity.getLink( currentUser ) : AV.AVEntity.getLink( cacheItem )
        return (
            <>
                <Box
                    key={ item.id }
                    bgColor={ '#fff' }
                    borderWidth={ '1px' }
                    borderColor={ 'gray.400' }
                    zIndex={ -3 }
                    width={ '235px' }
                    height={ '240px' }
                    borderRadius={ 10 }
                    m={ '12px' }
                    shadow={ 1 }
                >
                    { currentUser?.id !== item?.id && (
                        <Pressable zIndex={ 999 } position={ 'absolute' } top={ '10px' } left={ '10px' } onPress={ () => handleChat( item ) }>
                            { !isRotate ?
                                <GroupChatIcon size={ 23 } color={ 'rgba(0, 0, 0, 0.5)' } /> : <div className="loading"></div>
                            }
                        </Pressable>
                    ) }
                    {/*{isAdminView && (*/ }
                    {/*    <Pressable*/ }
                    {/*        key={item.id}*/ }
                    {/*        padding={1.5}*/ }
                    {/*        rounded={'full'}*/ }
                    {/*        position={'absolute'}*/ }
                    {/*        right={-10}*/ }
                    {/*        top={-10}*/ }
                    {/*        zIndex={999}*/ }
                    {/*        _hover={{ backgroundColor: 'AVColor.hovercolor2' }}*/ }
                    {/*        backgroundColor={'AVColor.buttonBg'}*/ }
                    {/*        onPress={() => handleRemove('Member', item)}*/ }
                    {/*    >*/ }
                    {/*        <CloseIcon size={'12px'} color={'AVColor.secondaryText'} />*/ }
                    {/*    </Pressable>*/ }
                    {/*)}*/ }

                    <VStack alignItems={ 'center' } height={ '100%' } width={ '100%' }>
                        <Pressable
                            key={ item.id }
                            height={ '100%' }
                            justifyContent={ 'center' }
                            borderRadius={ '10px' }
                            width={ '100%' }
                            _hover={ { bgColor: '#EEEEEE' } }
                            onPress={ () =>
                            {
                                navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                    state: {
                                        user: {
                                            id: item?.id,
                                            pk: item?.pk,
                                            region: item?.region,
                                            type: item?.type,
                                            name: item?.title,
                                        },
                                    },
                                } );
                            } }
                        >
                            { isAdminView && (
                                <Pressable
                                    position={ 'absolute' }
                                    right={ '15px' }
                                    zIndex={ 999 }
                                    top={ '10px' }
                                    onPress={ () =>
                                    {
                                        setIsOpen( !isOpen );
                                        setIsMemberData( item );
                                    } }
                                >
                                    <MoreHorizontalIcon
                                        size={ '24px' }
                                        color={ "gray.600" }
                                        style={ { transform: [ { rotate: '90deg' } ] } }
                                    />
                                </Pressable>
                            ) }
                            { ( isOpen && isMemberData?.id == item?.id ) && ( <VStack
                                width={ '128px' }
                                ref={ menuRefs }
                                height='auto'
                                shadow={ 2 }
                                background={ '#FFFFFF' }
                                position={ 'absolute' }
                                top={ '40px' }
                                right={ '40px' }
                                zIndex={ 999 }
                                borderRadius={ '6px' }
                            >
                                { currentUser?.silenced?.filter( x => x?.id == item?.id )?.length == 0 ?
                                    <Button
                                        width={ '100%' }
                                        height={ '36px' }
                                        alignItems={ 'center' }
                                        justifyContent={ 'flex-start' }
                                        backgroundColor={ isSlienceLoad ? 'gray.400' : 'white' }
                                        isLoading={ isSlienceLoad }
                                        onPress={ () => HandleSlience( item ) }

                                    >
                                        <Text
                                            fontSize={ '14px' }
                                            fontFamily={ 'Roboto' }
                                        >
                                            Silence
                                        </Text>
                                    </Button>
                                    : <Button
                                        width={ '100%' }
                                        height={ '36px' }
                                        alignItems={ 'center' }
                                        justifyContent={ 'flex-start' }
                                        isLoading={ unSlienceLoad }
                                        backgroundColor={ unSlienceLoad ? 'gray.400' : 'white' }
                                        onPress={ () => HandleUnSlience( item ) }

                                    >
                                        <Text
                                            fontSize={ '14px' }
                                            fontFamily={ 'Roboto' }
                                        >
                                            UnSilence
                                        </Text>
                                    </Button> }
                                <Divider />
                                <Button
                                    width={ '100%' }
                                    height={ '36px' }
                                    alignItems={ 'center' }
                                    justifyContent={ 'flex-start' }
                                    isLoading={ isLoading }
                                    backgroundColor={ isLoading ? 'gray.400' : 'white' }
                                    onPress={ () => HandleRemoveMembers( item ) }

                                >
                                    <Text
                                        fontSize={ '14px' }
                                        fontFamily={ 'Roboto' }
                                    >
                                        Delete
                                    </Text>
                                </Button>
                            </VStack> ) }
                            { item?.thumb && (
                                <Box
                                    bgColor={ '#FF6600' }
                                    borderRadius={ [ 'full' ] }
                                    width={ '120px' }
                                    height={ '120px' }
                                    alignSelf={ 'center' }
                                    p={ 0 }
                                >
                                    <Image
                                        source={ {
                                            uri: AV.Media.getThumb( item ),
                                        } }
                                        borderRadius={ [ 'full' ] }

                                        width={ '100%' }
                                        height={ [ '100%' ] }
                                    />
                                </Box>
                            ) }
                            { !item?.thumb && (
                                <Box
                                    alignItems={ 'center' }
                                >
                                    <Profile height={ '120' } width={ '120' } color={ "#32AE78" } />
                                </Box>
                            ) }
                            <Tooltip label={ item.title } openDelay={ 500 }>
                                <Pressable
                                    alignItems={ 'center' }
                                    justifyContent={ 'center' }
                                    onPress={ () =>
                                    {
                                        navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                            state: {
                                                user: {
                                                    id: item?.id,
                                                    pk: item?.pk,
                                                    region: item?.region,
                                                    type: item?.type,
                                                    name: item?.title,
                                                },
                                            },
                                        } );
                                    } }
                                >
                                    <Text
                                        marginTop={ '28px' }
                                        justifyContent={ 'center' }
                                        alignItems={ 'center' }
                                        width='120px'
                                        fontSize={ '18px' }
                                        fontWeight={ 500 }
                                        numberOfLines={ 1 }
                                        textAlign='center'
                                    >
                                        { item.title }
                                    </Text>
                                </Pressable>
                            </Tooltip>
                        </Pressable>
                    </VStack>
                </Box>
            </> )
    }




    /*USEEFFECT*/
    //useEffect( () =>
    //{
    //    setIsTeamsPage( true )
    //    setIsHomePage( true )
    //    setIsGroup( false )
    //    setIsSeasonPage( false )
    //    setIsEventPage( false )
    //    setIsStaffPage( false )
    //    setIsMembersPage( false )
    //    setIsPhotoPage( false )
    //    setIsDocumentPage( false )
    //    setIsPlayerPage( false )
    //    setIsTeamClub( false )
    //    setisResourses( false )
    //    setisparticipantspage( false )
    //    setIsDropDown( false )
    //    setIsFeedBackGroup( false );
    //    setIsOrganization( false );
    //    setIsOccurencePage( false );

    //}, [ createType, showCreateNewPage ] )
    useEffect( () =>
    {
        window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } );
        async function fetchData ()
        {
            if ( ( location?.state?.isCreate ) || ( EntityId === 'Create' ) || EntityId === 'USD' || EntityId === 'USC' )
            {
                setIsCreate( true );
                setLeagueTeamFK( undefined ); setProfile( {
                    background: null,
                    pic: null,
                    thumb: null,
                    gallery: null,
                    previousPic: null,
                    text: null,
                } );
                setteamName( location?.state?.searchInput ? location?.state?.searchInput : '' );
                setIntro( '' );
                setActivityType( null );
                setIsTeamsPage( false );
                setIsMembersPage( false );
                setIsPlayerPage( false );
                setIsStaffPage( false );
                setIsSeasonPage( false );
                setIsEventPage( false );
                setIsPhotoPage( false );
                setIsDocumentPage( false )
                setIsGroup( false )
                setIsHomePage( false )
                setIsTeamClub( false )
                setisResourses( false )
                setisparticipantspage( false )
                setIsDropDown( false )
                setIsFeedBackGroup( false );
                setIsOrganization( false );
                setIsOccurencePage( false );
            }
            else if ( !location?.state?.isCreate && ( isUpdate || EntityId !== 'Create' ) )
            {
                setIsCreate( false );
                setIsUpdate( false );
                setIsTeamsPage( true )
                setIsSeasonPage( false )
                setIsEventPage( false )
                setIsStaffPage( false )
                setIsMembersPage( false )
                setIsPhotoPage( false )
                setIsDocumentPage( false )
                setIsPlayerPage( false )
                setIsGroup( false )
                setIsHomePage( false )
                setIsTeamClub( false )
                setisResourses( false )
                setisparticipantspage( false )
                setIsDropDown( false )
                setIsFeedBackGroup( false );
                setIsOccurencePage( false );
                //Entity Update Start
                if ( entitydatalist?.filter( x => x?.id === viewTeam?.id )?.length > 0 && viewTeam )
                {
                    if ( viewTeam.type == 'Event' && viewTeam.series && viewTeam.id !== viewTeam.series )
                    {
                        setLeagueTeamFK( viewTeam );
                        await EntityDataFinding( entitydatalist?.find( x => x?.id === viewTeam?.id ) );
                        await handleSwitchTab( entitydatalist?.find( x => x?.id === viewTeam?.id ) );
                    }
                    else
                    {
                        setPageLoader( true );
                        setLeagueTeamFK( entitydatalist?.find( x => x?.id === viewTeam?.id ) )
                        await EntityDataFinding( entitydatalist?.find( x => x?.id === viewTeam?.id ) );
                        await handleSwitchTab( entitydatalist?.find( x => x?.id === viewTeam?.id ) );
                        if ( !location?.state?.newEntity )
                        {
                            AV.AVEntity.get( viewTeam ).then( ( e : any ) =>
                            {
                                if ( e )
                                {
                                    setLeagueTeamFK( e );

                                    EntityDataFinding( e );
                                    handleSwitchTab(e);
                                    entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === viewTeam?.id ) ) ] = e;
                                    dispatch( setEntitydata( entitydatalist ) );
                                }
                            } );
                        }
                    }
                }
                else
                {
                    setPageLoader( true );
                    if ( viewTeam )
                    {
                        await AV.AVEntity.get( viewTeam ).then( ( e : any ) =>
                        {

                            if ( e )
                            {
                                setLeagueTeamFK( e ); EntityDataFinding( e );
                                handleSwitchTab(e);
                                entitydatalist?.push( e );
                                dispatch( setEntitydata( entitydatalist ) );
                            }

                        } );
                    }
                    else
                    {
                        if ( EntityId !== 'Create' )
                        {
                            setPageLoader( true );
                            var teamValue = currentUser?.orgs?.filter( ( x ) => x?.id === EntityId );
                            if ( teamValue?.length == 0 )
                            {
                                teamValue = currentUser?.events?.filter( ( x ) => x?.id === EntityId );
                            }
                            if ( teamValue?.length == 0 )
                            {
                                teamValue = currentUser?.ownedEvents?.filter( ( x ) => x?.id === EntityId );
                            }
                            const targetFK = {
                                Id: EntityId,
                                Pk: EntityId?.substr( -4 ),
                                Region: region,
                                Type: 'Team',
                            };
                            let e : any = await AV.AVEntity.get( teamValue?.length > 0 ? teamValue[ 0 ] : targetFK );
                            if ( e )
                            {
                                await handleSwitchTab(e);
                                setLeagueTeamFK( e ); EntityDataFinding( e );
                                entitydatalist?.push( e );
                                dispatch( setEntitydata( entitydatalist ) );
                            }
                            //AV.AVEntity.checkLinks( currentUser, e ).then( ( x ) =>
                            //{
                            //    dispatch( setUser( x ) );
                            //    AV.Person.checkIfSelf( x );
                            //} );
                            if ( e?.id == '78b5b063-fd3e-4925-92bd-2a1888c6a46a' || e?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa7f' || e?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa71' || e?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa72' )
                            {
                                navigate( `/teams/${ e?.id }/${ currentUser?.region }`, {
                                    state: {
                                        team: {
                                            id: e?.id,
                                            pk: 'a46a',
                                            region: currentUser?.region,
                                            type: 'Organization',
                                            name: 'Actavivo',
                                        },
                                        type: 'Feedback'
                                    },
                                } );

                            }
                            else
                            {
                                if ( e )
                                {
                                    setLeagueTeamFK( e ); EntityDataFinding( e );
                                    await handleSwitchTab(e);
                                }
                            }
                        }
                    }
                    setPageLoader( false );
                }

            }
        }
        let isMounted = true;
        if ( isMounted )
        {
            fetchData();
        }
        return () =>
        {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };
    }, [ EntityId, viewTeam, entitydatalist, restoreRefresh ] );
    useEffect( () =>
    {
        setIsHomePage( true )
    }, [ showCreateNewPage ] )
    useEffect( () =>
    {
        staffToMember = memberList?.filter( item1 => item1?.id === currentUser?.id )?.length > 0 ? true : false;
        staffToPlayer = playersList?.filter( item1 => item1?.id === currentUser?.id )?.length > 0 ? true : false;
        memberToPlayer = memberList?.filter( item1 => playersList?.some( item2 => item2?.id === item1?.id ) )?.length > 0 ? true : false;

    }, [ staffList, memberList, playersList ] );
    useEffect( () =>
    {
        setIsParticipantList( leagueTeamFK?.participants )
        //setFilteredParticipantList(leagueTeamFK?.participant)
        filterParticipants( "ALL" );
    }, [ leagueTeamFK, participantList ] )
    useEffect( () =>
    {
        let isMounted = true;
        if ( isMounted )
        {
            handleHelpText();
        }
        return () =>
        {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };

    }, [ helptext, isHomePage, isTeamsPage, isEventPage, isMembersPage, isStaffPage, isPlayerPage, isPhotoPage, isGroup, isOrganization, isSeasonPage ] )
    useEffect( () =>
    {
        let isMounted = true;
        if ( isMounted )
        {
            const following = user?.follows;
            const requests = user?.myRequests;
            const Participant : any = leagueTeamFK?.status == 'Canceled' ? false : leagueTeamFK?.participants?.some(
                x => x?.id === user?.id && ( x?.linkType === "Owner" || x?.linkType === "Staff" ) );
            following?.filter( ( item ) => item?.id === leagueTeamFK?.id ).length > 0
                ? setIsFollowing( true )
                : setIsFollowing( false );
            requests?.filter( ( item ) => item?.target?.id === leagueTeamFK?.id ).length > 0
                ? setRequestSent( true )
                : setRequestSent( false );
            Participant ? setEditAccess( true ) : setEditAccess( false );
        }
        return () =>
        {
            isMounted = false; // Set the flag to false when the component is unmounte
            // Cancel subscriptions or perform any cleanup tasks here
        };
    }, [ leagueTeamFK?.id, user?.id ] );

    //Outside click to close
    const menuRefs = useRef( null );
    useOutsideAlerter( menuRefs );
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            let isMounted = true;
            if ( isMounted )
            {
                function handleClickOutside ( event )
                {
                    if ( ref.current && !ref.current.contains( event.target ) )
                    {
                        setshowEditaccountsettingPopup( false );
                        setIsActiveRole( false );
                        setOrgOpen( false );
                        setIsActive( false );
                        setIsActives( false );
                        setOrgOpen( false );
                        setIsOpen( false );
                        setIsShowEditOccurence( false );
                    }
                }
                document.addEventListener( 'mousedown', handleClickOutside );
                return () =>
                {
                    document.removeEventListener( 'mousedown', handleClickOutside );
                };
            }
            return () =>
            {
                isMounted = false; // Set the flag to false when the component is unmounted
                // Cancel subscriptions or perform any cleanup tasks here
            };
        }, [ ref ] );
    }
    return (
        <>

            <AVBody setShowCreateNewPage={ setShowCreateNewPage } isOpenConvo={ isOpenConvo } setIsOpenConvo={ setIsOpenConvo } setIsShowEdit={ setIsShowEdit } showCreateNewPage={ showCreateNewPage } setCreateType={ setCreateType } createType={ createType } passEntity={ passEntity } setPassEntity={ setPassEntity } setIsConvoData={ setIsConvoData } isConvoData={ isConvoData }>
                { ( ( pageLoader && !isCreate ) && ( leagueTeamFK?.length === 0 || leagueTeamFK == undefined || leagueTeamFK == null ) ) ?

                    <PageLoader /> :
                    <>
                        <HStack flex={ 1 } width={ '100%' } space={ [ 0, 0, 3, 4 ] }>
                            <HStack flex={ [ 1, 1, 3 ] }>
                                <VStack flex={ 1 }>
                                    <Box
                                        height={ 'auto' }
                                        borderRadius={ 5 }
                                        backgroundColor={ 'AVColor.white' }
                                        shadow={ 1 }
                                        borderTopLeftRadius={ 10 }
                                        borderTopRightRadius={ 10 }
                                        marginBottom={ '12px' }
                                    >
                                        {/*ProfileHeader*/ }
                                        <ProfileHeader isCreate={ isCreate } isShowEdit={ isShowEdit } Entity={ leagueTeamFK } handleUpload={ handleUpload } EntityType={ viewTeam ? viewTeam : leagueTeamFK } type={ Feedbacktype } setIsShowEdit={ setIsShowEdit } profile={ profile } setProfile={ setProfile } googlemap={ googlemap } isAdminView={ isAdminView } weatherURL={ weatherURL } weather={ weather } Owner={ Owner } setIsImagePopup={ setIsImagePopup } setEntity={ setLeagueTeamFK } editAccess={ editAccess } setIsStaffReq={ setIsStaffReq } setIsChildOrgReq={ setIsChildOrgReq } isDeleted={ isDeleted } HandleBlockUser={ undefined } setIsConvoData={ setIsConvoData } HandleUnBlockUser={ undefined } HandleRestoreAccount={ HandleRestoreAccount } HandleUnFollowUser={ undefined } setIsOpenConvo={ setIsOpenConvo } setIsEditEntireSeries={ setIsEditEntireSeries } isEditEntireSeries={ isEditEntireSeries } seriesEvent={ seriesEvent } />
                                        {/*Create and Edit All entity*/ }
                                        { ( isCreate || isShowEdit ) && (
                                            <AVCreateTeam isCreate={ isCreate } setIsCreate={ setIsCreate } type={ location?.state?.type ? location?.state?.type : leagueTeamFK?.type } profile={ profile } isShowEdit={ isShowEdit } setIsShowEdit={ setIsShowEdit } teamType={ teamType } Entity={ leagueTeamFK } setEntity={ setLeagueTeamFK } EntityDataFinding={ EntityDataFinding } setgooglemap={ setgooglemap } setProfile={ setProfile } GetWeatherIcon={ GetWeatherIcon } handleUpload={ handleUpload } setShowCreateNewPage={ setShowCreateNewPage } setCreateType={ setCreateType } setPassEntity={ setPassEntity } passEntity={ passEntity } isEditEntireSeries={ isEditEntireSeries } setIsEditEntireSeries={ setIsEditEntireSeries } seriesEvent = { seriesEvent } />
                                        ) }
                                        {/* TabHeader and feedback*/ }
                                        { ( !isCreate && !isShowEdit && isDeleted ) && (
                                            <HStack height={ ( Feedbacktype === 'Feedback' && FeedBackId === "78b5b063-fd3e-4925-92bd-2a1888c6a46a" ) ? 'auto' : FeedBackId === "b495d4e0-bf52-4690-8dc0-677db954aa72" ? '0px' : '61px' } width={ '100%' }>
                                                { Feedbacktype !== 'Feedback' && (
                                                    <TabHeader menu={ leagueTeamFK?.subType == "Club" ? TeamClubMenu : leagueTeamFK?.type == "Club" ? EliteClubTab : leagueTeamFK?.subType == "Class" ? ClassMenu : leagueTeamFK?.subType == "GradClass" ? GradClassMenu : leagueTeamFK?.type == "Neighborhood" ? neighborhoodMenu : leagueTeamFK?.type == "Community" ? communityMenu  : leagueTeamFK?.type === "School" ? SchoolMenu : leagueTeamFK?.type == 'Team' ? TeamsMenu : leagueTeamFK?.type == 'PlayerOrg' && leagueTeamFK?.subType !== 'Term' && leagueTeamFK?.subType !== 'MusicGroup' ? SeasonsMenu : leagueTeamFK?.type == 'PlayerOrg' && leagueTeamFK?.subType === 'Term' ? TermsMenu : leagueTeamFK?.type == 'Event' ? eventsMenu : leagueTeamFK?.type == 'PlayerOrg' && leagueTeamFK?.subType === 'MusicGroup' ?  PerformanceGroupMenu : leagueTeamFK?.type == 'Group' ? GroupMenu : orgsMenu }

                                                        MoreMenu={ leagueTeamFK?.subType == "Club" ? TeamClubMenu : leagueTeamFK?.type == "Club" ? EliteClubTab : leagueTeamFK?.subType == "Class" ? ClassMenu : leagueTeamFK?.subType == "GradClass" ? GradClassMenu : leagueTeamFK?.type == "Neighborhood" ? neighborhoodMenu : leagueTeamFK?.type == "Community" ? communityMenu  : leagueTeamFK?.type === "School" ? SchoolMenu : leagueTeamFK?.type == 'Team' ? TeamsMenu : leagueTeamFK?.type == 'PlayerOrg' && leagueTeamFK?.subType !== 'Term' && leagueTeamFK?.subType !== 'MusicGroup' ? SeasonsMenu : leagueTeamFK?.type == 'PlayerOrg' && leagueTeamFK?.subType === 'Term' ? TermsMenu : leagueTeamFK?.type == 'Event' ? eventsMenu : leagueTeamFK?.type == 'Group' ? GroupMenu : leagueTeamFK?.type == 'PlayerOrg' && leagueTeamFK?.subType === 'MusicGroup' ? PerformanceGroupMenu : orgsMenu }
                                                        isDropDown={ isDropDown } Entity={ leagueTeamFK } setIsDropDown={ setIsDropDown } type={ leagueTeamFK?.type }
                                                    /> ) }
                                                { Feedbacktype === 'Feedback' && (
                                                    <VStack paddingY={ '24px' } paddingLeft={ '24px' } paddingRight={ '12px' } width={ '100%' }>
                                                        { ( Feedbacktype === 'Feedback' && FeedBackId === "78b5b063-fd3e-4925-92bd-2a1888c6a46a" ) &&
                                                            ( <HStack marginBottom={ '24px' }>
                                                                <Text fontSize={ '16px' } fontWeight={ 400 }>To Start a post please select a category.</Text>
                                                            </HStack> ) }
                                                        { ( Feedbacktype === 'Feedback' && ( FeedBackId === "b495d4e0-bf52-4690-8dc0-677db954aa7f" || FeedBackId === "b495d4e0-bf52-4690-8dc0-677db954aa7f" ) ) && (
                                                            <HStack marginBottom={ '24px' } space={ 4 }>
                                                                <Text fontSize={ '16px' } fontWeight={ 400 }>Share Your Feedback Let us know what is going on your mind</Text>
                                                            </HStack> ) }
                                                        { ( Feedbacktype === 'Feedback' && ( FeedBackId === "b495d4e0-bf52-4690-8dc0-677db954aa71" || FeedBackId === "b495d4e0-bf52-4690-8dc0-677db954aa71" ) ) && (
                                                            <HStack marginBottom={ '24px' }>
                                                                <Text fontSize={ '16px' } fontWeight={ 400 }>Share Your Feedback Let us know what is going on your mind</Text>
                                                            </HStack>
                                                        ) }
                                                        { ( type === 'Feedback' && ( FeedBackId === "b495d4e0-bf52-4690-8dc0-677db954aa72" || FeedBackId === "b495d4e0-bf52-4690-8dc0-677db954aa72" ) ) && (
                                                            <HStack marginBottom={ '24px' }>
                                                                <Text fontSize={ '16px' } fontWeight={ 400 }>Share Your Feedback Let us know what is going on your mind</Text>
                                                            </HStack>
                                                        ) }

                                                        { ( FeedBackId !== "b495d4e0-bf52-4690-8dc0-677db954aa72" && FeedBackId !== "b495d4e0-bf52-4690-8dc0-677db954aa71" && FeedBackId !== "b495d4e0-bf52-4690-8dc0-677db954aa7f" ) &&
                                                            ( <HStack
                                                                flexWrap={ 'wrap' }
                                                                zIndex={ -2 }
                                                                alignSelf={ 'center' }
                                                                space={ '24px' }
                                                            >
                                                                { isshowteamsList?.length > 0 &&
                                                                    isshowteamsList?.map( ( item : any, i ) => (
                                                                        <Box
                                                                            key={ item.id + i }
                                                                            bgColor={ '#fff' }
                                                                            borderWidth={ '1px' }
                                                                            borderColor={ 'gray.100' }
                                                                            shadow={ 3 }
                                                                            width={ '235px' }
                                                                            marginY={ '5px' }
                                                                            height={ '208px' }
                                                                            borderRadius={ 8 }
                                                                        >
                                                                            <VStack key={ item?.id } alignItems={ 'center' } padding={ 2 } height={ '100%' }
                                                                                width={ '100%' }>
                                                                                <Pressable
                                                                                    key={ item.id }
                                                                                    _hover={ { bgColor: 'gray.100' } }
                                                                                    p={ 2 }
                                                                                    height={ '100%' }
                                                                                    alignItems={ 'center' }
                                                                                    width={ '100%' }
                                                                                    borderRadius={ 5 }
                                                                                    minWidth={ 120 }
                                                                                    onPress={ () => HandeFeedBackNavigate( item ) }
                                                                                >
                                                                                    { item?.thumb && (
                                                                                        <Box
                                                                                            bgColor={ '#FF6600' }
                                                                                            borderRadius={ [ 'full' ] }
                                                                                            width={ '100px' }
                                                                                            height={ '100px' }
                                                                                            alignSelf={ 'center' }
                                                                                        >
                                                                                            <Image
                                                                                                source={ {
                                                                                                    uri: AV.Media.getThumb( item ),
                                                                                                } }
                                                                                                borderRadius={ [ 'full' ] }
                                                                                                width={ '100%' }
                                                                                                height={ [ '100%' ] }
                                                                                            />
                                                                                        </Box>
                                                                                    ) }
                                                                                    { item.title === 'Feedback' && ( <FeedbackIcon height={ '100' } width={ '100' } /> ) }
                                                                                    { item.title === 'Bug Reports' && ( <BugReportIcon height={ '100' } width={ '100' } /> ) }
                                                                                    { item.title === 'Feature Requests' && ( <FeatureRequestIcon height={ '100' } width={ '100' } /> ) }
                                                                                    <Tooltip label={ item.title } openDelay={ 500 }>
                                                                                        <Pressable
                                                                                            paddingTop={ '24px' }
                                                                                            onPress={ () => HandeFeedBackNavigate( item ) }
                                                                                        >
                                                                                            <Text
                                                                                                numberOfLines={ 1 }

                                                                                                alignSelf={ 'center' }
                                                                                                fontWeight={ 500 }
                                                                                            >
                                                                                                { item.title }
                                                                                            </Text>
                                                                                        </Pressable>
                                                                                    </Tooltip>
                                                                                </Pressable>
                                                                            </VStack>
                                                                        </Box>
                                                                    ) ) }
                                                            </HStack> ) }
                                                    </VStack>
                                                ) }

                                            </HStack> ) }
                                    </Box>
                                    {/*Suspended POPUP*/ }
                                    { leagueTeamFK?.status === 'Suspended' && !isSuspended && !isAdminView && !isCreate && !isShowEdit && (
                                        <Box
                                            position={ 'fixed' }
                                            top={ 0 }
                                            bottom={ 0 }
                                            left={ 0 }
                                            right={ 0 }
                                            display={ 'flex' }
                                            alignItems={ 'center' }
                                            justifyContent={ 'center' }
                                            backgroundColor={ 'rgba(0, 0, 0, 0.25)' }
                                            zIndex={ 9999 }
                                        >
                                            <div className={ `customModal` }>
                                                <div className={ `show_unauthorized_details` }>
                                                    <VStack alignItems={ 'center' } width={ '100%' }>
                                                        <HStack
                                                            position={ 'absolute' }
                                                            justifyContent={ 'flex-end' }
                                                            right={ '15px' }
                                                            top={ '13px' }
                                                            width={ '100%' }
                                                        >
                                                            <AVCancel color={ undefined } size={ undefined } callback={ () => setIsSuspended( true ) } />
                                                        </HStack>
                                                        <img src={ suspend } alt='image' height={ '220px' } width={ '240px' } />
                                                        <HStack paddingBottom={ '8px' }>
                                                            <Text fontWeight={ 400 } fontSize={ '24px' }>
                                                                { Loc.currentLang.menu.temporaryTakingaBreak }
                                                            </Text>
                                                        </HStack>
                                                        <HStack>
                                                            <Text>{ Loc.currentLang.menu.restrictPostTxt }</Text>
                                                        </HStack>
                                                        <HStack marginTop={ '15px' }>
                                                            <Button
                                                                rounded='full'
                                                                backgroundColor={ '#EEEEEE' }
                                                                height={ '30px' }
                                                                width={ '60px' }
                                                                onPress={ () => setIsSuspended( true ) }
                                                            >
                                                                <Text fontSize={ '14px' } color={ 'rgba(0,0,0,0.5)' } fontWeight={ 'bold' }>
                                                                    { Loc.currentLang.menu.ok }
                                                                </Text>
                                                            </Button>
                                                        </HStack>
                                                    </VStack>
                                                </div>
                                            </div>
                                        </Box>
                                    )
                                    }


                                    <VStack zIndex={ isDropDown ? -3 : "" }>
                                        {/* Event page*/ }
                                        { isEventPage && !isShowEdit && (
                                            <>
                                                <Box
                                                    width={ "100%" }
                                                    minHeight={ "340px" }
                                                    my={ 1 }
                                                    marginRight={ 4 }
                                                    h="auto"
                                                    shadow={ 1 }
                                                    bgColor="#FFFFFF"
                                                    rounded="lg">
                                                    <VStack>
                                                        <HStack alignItems={ "center" } justifyContent={ "space-between" } width={ "98%" }>
                                                            <Text
                                                                fontWeight={ "500" }
                                                                marginLeft={ "15px" }
                                                                fontSize={ "22px" }
                                                                color={ "AVColor.primary" }
                                                                py={ "12px" }
                                                                width={ "150px" }>
                                                                { Loc.currentLang.menu.events }
                                                            </Text>
                                                            <VStack>
                                                                <HStack alignItems={ "center" } justifyContent={ isMobile ? "flex-end" : "" } space={ 3 }>
                                                                    {/* v2 event icons*/ }
                                                                    <FormatListBulletedIcon
                                                                        style={ { color: showCalendarView ? "#ccc" : "#00A7BC" } }
                                                                        onClick={ () => setShowCalenderView( false ) }
                                                                        cursor={ "pointer" }
                                                                    />
                                                                    <CalendarIcon
                                                                        style={ { color: !showCalendarView ? "#ccc" : "#00A7BC" } }
                                                                        onClick={ () => setShowCalenderView( true ) }
                                                                        cursor={ "pointer" }
                                                                    />
                                                                    { eventsList?.length > 0 && !showCalendarView && (
                                                                        <AVSelectDropdown
                                                                            dropdownValues={ dropdownValues }
                                                                            currentstatus={ currentstatus }
                                                                            setIsActives={ setFilterEvent }
                                                                            isActives={ filterevent }
                                                                        />
                                                                    ) }
                                                                    <Box
                                                                        marginRight={ "10px" }
                                                                        p={ "9px" }
                                                                        //display={ data?.length === 0 && leagueTeamFK?.ownedEvents?.length === 0 ? "none" : "" }
                                                                        shadow={ 1 }
                                                                        zIndex={ -3 }
                                                                        backgroundColor={ "AVColor.liteBg" }
                                                                        rounded={ "full" }>
                                                                        <Tooltip label={ Loc.currentLang.helpText.person.eventTab } w={ 300 }>
                                                                            <img src={ helping } height={ "17px" } width={ "17px" } style={ { zIndex: -3 } } />
                                                                        </Tooltip>
                                                                    </Box>
                                                                    { isAdminView && <AVComDropdonwn dropdownValues={ dropdownComValues } /> }
                                                                </HStack>
                                                            </VStack>
                                                        </HStack>
                                                        <Divider my={ 2 } zIndex={ -3 } />
                                                        { leagueTeamFK?.type == "PlayerOrg" && data?.length !== 0 && (
                                                            <HStack zIndex={ -3 } width={ "98%" }>
                                                                <HStack
                                                                    marginY={ "24px" }
                                                                    overflowX={ isMobile ? "scroll" : undefined }
                                                                    backgroundColor={ "rgba(0,0,0,0.05)" }
                                                                    borderRadius={ "18px" }
                                                                    minWidth={ isMobile ? "322px" : "40px" }
                                                                    maxWidth={ isMobile ? "322px" : "500px" }
                                                                    left={ "10px" }
                                                                    height={ "36px" }>
                                                                    { eventsList?.length > 0 && (
                                                                        <Filter Type={ eventType } SetType={ setAlls } Name={ Loc.currentLang.menu.all } />
                                                                    ) }
                                                                    { eventsList?.filter( x => x?.linkType == "Game" || x?.eventType == "Game" )?.length >
                                                                        0 && (
                                                                            <Filter Type={ eventType } SetType={ setGames } Name={ Loc.currentLang.menu.gamesTxt } />
                                                                        ) }
                                                                    { eventsList?.filter( x => x?.linkType == "Practice" || x?.eventType == "Practice" )
                                                                        ?.length > 0 && (
                                                                            <Filter
                                                                                Type={ eventType }
                                                                                SetType={ setPractices }
                                                                                Name={ Loc.currentLang.menu.practice }
                                                                            />
                                                                        ) }
                                                                    { eventsList?.filter( x => x?.linkType == "Meeting" || x?.eventType == "Meeting" )
                                                                        ?.length > 0 && (
                                                                            <Filter Type={ eventType } SetType={ setMeeting } Name={ Loc.currentLang.menu.meeting } />
                                                                        ) }
                                                                    { eventsList?.filter( x => x?.linkType == "Social" || x?.eventType == "Social" )
                                                                        ?.length > 0 && (
                                                                            <Filter
                                                                                Type={ eventType }
                                                                                SetType={ setSocial }
                                                                                Name={ Loc.currentLang.menu.socialText }
                                                                            />
                                                                        ) }
                                                                </HStack>
                                                            </HStack>
                                                        ) }
                                                    </VStack>

                                                    <VStack zIndex={ -3 }>
                                                        <HStack
                                                            backgroundColor={ "AVColor.white" }
                                                            space={ 1 }
                                                            flexWrap={ "wrap" }
                                                            mt={ 2 }
                                                            borderRadius={ 5 }
                                                            justifyContent={ "flex-start" }
                                                            p={ 2 }
                                                            width={ "100%" }
                                                            zIndex={ -3 }>
                                                            { console.log( "EVENTSLIST", eventsList ) }
                                                            {showCalendarView ? (
                                                                <AVCalendarView data={ eventsList } eventType={ eventType } />
                                                            ) : (
                                                                <RenderEvents data={ data } />
                                                            ) }
                                                            { data?.length === 0 && leagueTeamFK?.ownedEvents?.length != 0 && (
                                                                <VStack width={ "100%" } justifyContent={ "center" } alignItems={ "center" }>
                                                                    <HStack padding={ "10px" } alignItems={ "center" } zIndex={ -3 }>
                                                                        <Text>{ Loc.currentLang.menu.noEventsFound }</Text>
                                                                    </HStack>
                                                                </VStack>
                                                            ) }
                                                            { !showCalendarView && ( data?.length === 0 || data?.length == undefined ) &&
                                                                ( leagueTeamFK?.ownedEvents?.length === 0 ||
                                                                    leagueTeamFK?.ownedEvents?.length === undefined ) && (
                                                                    <HStack
                                                                        width={ "100%" }
                                                                        top={ isMobile ? "20px" : "0px" }
                                                                        padding={ isMobile ? "10px" : "10px" }
                                                                        marginTop={ isMobile ? "-10px" : "0px" }
                                                                        space={ isMobile ? 2 : 2 }>
                                                                        <img
                                                                            src={ inform }
                                                                            style={ {
                                                                                width: "20px",
                                                                                height: "20px",
                                                                            } }
                                                                        />
                                                                        { helptext }
                                                                    </HStack>
                                                                ) }
                                                        </HStack>
                                                    </VStack>
                                                </Box>
                                            </>
                                        ) }
                                        {/* season page*/ }
                                        { isSeasonPage && !isShowEdit && (
                                            <Box
                                                minHeight={ "340px" }
                                                paddingTop={ "10px" }
                                                borderRadius={ 5 }
                                                backgroundColor={ "AVColor.white" }
                                                shadow={ 1 }
                                                flex={ 1 }
                                                mb={ 3 }>
                                                <VStack>
                                                    <HStack alignItems={ "center" } justifyContent={ "space-between" }>
                                                        <Text
                                                            fontWeight={ "500" }
                                                            marginLeft={ "15px" }
                                                            fontSize={ "22px" }
                                                            color={ "AVColor.primary" }
                                                            py={ "12px" }>
                                                            { leagueTeamFK?.subType == "Club"
                                                                ? Loc.currentLang.menu.terms
                                                                : leagueTeamFK?.type == "PlayerOrg"
                                                                    ? Loc.currentLang.menu.organization
                                                                    : Loc.currentLang.menu.seasons }
                                                        </Text>


                                                        <HStack alignItems={ 'center' } pr={ 2 }>
                                                            <Box
                                                                marginRight={ '10px' }
                                                                p={ '9px' }
                                                                display={
                                                                    seasonList?.length === 0
                                                                        ? 'none'
                                                                        : ''
                                                                }
                                                                shadow={ 1 }
                                                                zIndex={ -3 }
                                                                backgroundColor={ 'AVColor.liteBg' }
                                                                rounded={ 'full' }
                                                            >
                                                                <Tooltip label={ helptext } w={ 300 }>
                                                                    <img
                                                                        src={ helping }
                                                                        height={ '17px' }
                                                                        width={ '17px' }
                                                                        style={ { zIndex: -3 } }
                                                                    />
                                                                </Tooltip>
                                                            </Box>
                                                            {/*<SearchIcon size='5' marginRight={ 5 } />*/ }
                                                            { isAdminView && ( <Button
                                                                bgColor={ 'gray.100' }
                                                                _hover={ { bgColor: 'gray.50' } }
                                                                borderRadius={ 'full' }
                                                                p={ '10px' }
                                                                shadow={ 1 }
                                                                rounded={ 'full' }
                                                                disabled={ isCreate }
                                                                onPress={ () => setShowAddSeasonModal( true ) }
                                                            >
                                                                <AddIcon size={ '20px' } color={ '#32AE78' } />
                                                            </Button> ) }
                                                        </HStack>

                                                    </HStack>
                                                </VStack>

                                                <Divider my={ 2 } zIndex={ -3 } />

                                                <HStack flexWrap={ 'wrap' } alignSelf={ 'flex-start' } space={ 3 } p={ 2 }>
                                                    { seasonList?.map( ( item ) => (
                                                        <Pressable
                                                            key={ item.id }
                                                            bgColor={ '#fff' }
                                                            borderWidth={ '1px' }
                                                            borderColor={ 'AVColor.hovercolor2' }
                                                            _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                                                            minWidth={ 130 }
                                                            minHeight={ 150 }
                                                            borderRadius={ 5 }
                                                            m={ 2 }
                                                            py={ 3 }
                                                            shadow={ 1 }
                                                            onPress={ () =>
                                                            {
                                                                navigate( `/season/${ item?.id }/${ item?.region }`, {
                                                                    state: { isCreate: false, team: item },
                                                                } );
                                                            } }
                                                        >


                                                            <VStack alignItems={ 'center' } padding={ 2 }>
                                                                { ( item?.thumb || item?.profile?.pic?.id ) && (
                                                                    <Box
                                                                        bgColor={ '#FF6600' }
                                                                        borderRadius={ [ 'full' ] }
                                                                        width={ '70px' }
                                                                        height={ '70px' }
                                                                        left={ '1px' }
                                                                        p={ 0 }
                                                                    >
                                                                        <Image
                                                                            source={ {
                                                                                uri: AV.Media.getThumb( item ),
                                                                            } }
                                                                            borderRadius={ [ 'full' ] }
                                                                            width={ '100%' }
                                                                            height={ [ '100%' ] }
                                                                        />
                                                                    </Box>
                                                                ) }
                                                                { !item?.thumb && !item?.profile?.pic?.id && (
                                                                    <Box
                                                                        alignItems={ 'center' }
                                                                    >
                                                                        <Profile height={ '70' } width={ '70' } color={ "#32AE78" } />
                                                                    </Box>
                                                                ) }
                                                                <Tooltip
                                                                    label={ item?.data ? item?.data : item?.title }
                                                                    openDelay={ 500 }
                                                                >
                                                                    <Pressable
                                                                        onPress={ () =>
                                                                        {
                                                                            navigate( `/season/${ item?.id }/${ item?.region }`, {
                                                                                state: { isCreate: false, team: item },
                                                                            } );
                                                                        } }
                                                                    >
                                                                        <Text
                                                                            marginTop={ 2 }
                                                                            left={ '2px' }
                                                                            alignItems='center'
                                                                            width='120px'
                                                                            numberOfLines={ 2 }
                                                                            textAlign='center'
                                                                        >
                                                                            { item?.data ? item?.data : item?.title }
                                                                        </Text>
                                                                    </Pressable>
                                                                </Tooltip>
                                                            </VStack>
                                                        </Pressable>
                                                    ) ) }
                                                    { seasonList?.length === 0 && (
                                                        <HStack width={ '100%' } top={ isMobile ? '20px' : '0px' }
                                                            padding={ isMobile ? '10px' : '10px' }
                                                            marginTop={ isMobile ? '-10px' : '0px' } space={ isMobile ? 2 : 2 }>
                                                            <img
                                                                src={ inform }
                                                                style={ {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                } }
                                                            />
                                                            { helptext }
                                                        </HStack>
                                                    ) }
                                                </HStack>
                                            </Box>
                                        ) }
                                        {/* staff page*/ }
                                        { isStaffPage && !isShowEdit && (
                                            <>
                                                <Box
                                                    width={ '100%' }
                                                    minHeight={ '340px' }
                                                    h='auto'
                                                    paddingTop={ '10px' }
                                                    bgColor='#fff'
                                                    rounded='lg'
                                                >
                                                    <VStack zIndex={ -3 }>
                                                        <HStack alignItems={ 'center' } justifyContent={ 'space-between' }>
                                                            <Text
                                                                fontWeight={ '500' }
                                                                marginLeft={ '15px' }
                                                                fontSize={ '22px' }
                                                                color={ 'AVColor.primary' }
                                                                py={ '12px' }
                                                            >
                                                                { leagueTeamFK?.type == 'School' ? Loc.currentLang.menu.administration : Loc.currentLang.menu.staff }
                                                            </Text>

                                                            <HStack alignItems={ 'center' } zIndex={ -3 }>
                                                                <Tooltip
                                                                    label={ helptext }
                                                                    w={ 200 }
                                                                    openDelay={ 500 }
                                                                >
                                                                    <Box
                                                                        marginRight={ '10px' }
                                                                        p={ '9px' }
                                                                        shadow={ 1 }
                                                                        zIndex={ -3 }
                                                                        backgroundColor={ 'AVColor.liteBg' }
                                                                        rounded={ 'full' }
                                                                    >
                                                                        <img
                                                                            src={ helping }
                                                                            height={ '17px' }
                                                                            width={ '17px' }
                                                                            style={ { zIndex: -3 } }
                                                                        />
                                                                    </Box>
                                                                </Tooltip>
                                                                { isAdminView && (
                                                                    <Tooltip label={ Loc.currentLang.menu.editRoles } openDelay={ 500 }>
                                                                        <Pressable
                                                                            backgroundColor={ 'AVColor.liteBg' }
                                                                            rounded={ 'full' }
                                                                            p={ '10px' }
                                                                            marginRight={ '10px' }
                                                                            _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                                                            onPress={ () =>
                                                                            {
                                                                                navigate(
                                                                                    `/accountsettings/${ leagueTeamFK?.id }/${ leagueTeamFK?.region }`,
                                                                                    {
                                                                                        state: {
                                                                                            page: pathName,
                                                                                            Entity: leagueTeamFK,
                                                                                            isEdit: true,
                                                                                        },
                                                                                    }
                                                                                );
                                                                            } }
                                                                        >
                                                                            <EditIcon size='20px' />
                                                                        </Pressable>
                                                                    </Tooltip> ) }
                                                                { isAdminView && staffList?.length > 0 && (
                                                                    <Button
                                                                    p={ '10px' }
                                                                    shadow={ 1 }
                                                                    bgColor={ isLoad ? 'gray.400' : 'gray.100' }
                                                                    _hover={ { bgColor: 'gray.50' } }
                                                                    isLoading={isLoad}
                                                                    rounded={ 'full' }
                                                                    marginRight={ '3px' }
                                                                    onPress={ () => HandleGroupChat( 'S' ) }
                                                                >
                                                                    {
                                                                       
                                  <GroupChatIcon size={ 23 } color={'rgba(0, 0, 0, 0.5)'} /> 
                                                                    }


                                                                </Button> ) }
                                                                { isAdminView && (
                                                                    <Button
                                                                        p={ '10px' }
                                                                        shadow={ 1 }
                                                                        bgColor={ 'gray.100' }
                                                                        _hover={ { bgColor: 'gray.50' } }
                                                                        rounded={ 'full' }
                                                                        marginRight={ '3px' }
                                                                        onPress={ () => handleProfileType( 'Staff' ) }
                                                                    >
                                                                        <AddIcon size={ '20px' } color={ '#32AE78' } />
                                                                    </Button> ) }
                                                            </HStack>
                                                        </HStack>
                                                    </VStack>
                                                    <Divider my={ 2 } zIndex={ -3 } />
                                                    <HStack flexWrap={ 'wrap' } width={ '100%' }>
                                                        <FlatList
                                                            width={ '100%' }
                                                            data={ staffList }
                                                            scrollEnabled={ true }
                                                            ListEmptyComponent={
                                                                <Center height={ 200 }>{ Loc.currentLang.menu.noStaffsFound }</Center>
                                                            }
                                                            renderItem={ ( item : any ) => (
                                                                <StaffsCard
                                                                    leagueTeam={ leagueTeamFK }
                                                                    data={ item }
                                                                    { ...props }
                                                                    key={ item?.id }
                                                                    item={ item }
                                                                    setStaffList={ setStaffList }
                                                                    Editstaffitem={ Editstaffitem }
                                                                    setEditstaffitem={ setEditstaffitem }
                                                                    staffList={ staffList }
                                                                    setIsStaffEdit={ setIsStaffEdit }
                                                                    setstaffemail={ setstaffemail }
                                                                    setstaffphone={ setstaffphone }
                                                                    setStaffRole={ setStaffRole }
                                                                    setstaffpic={ setstaffpic }
                                                                    HandleRemoveStaffs={ HandleRemoveStaffs }
                                                                    setIsOpenConvo={ setIsOpenConvo }
                                                                    setIsConvoData={ setIsConvoData }
                                                                    
                                                                />
                                                            ) }
                                                            showsVerticalScrollIndicator={ false }
                                                            numColumns={ isMobile ? 1 : isTab ? 2 : 3 }
                                                        />
                                                    </HStack>
                                                </Box>
                                                { isStaffEdit && (
                                                    <Box
                                                        position='fixed'
                                                        bgColor={ 'AVColor.overlay2' }
                                                        bottom={ 0 }
                                                        right={ 0 }
                                                        top={ 0 }
                                                        left={ 0 }
                                                        zIndex={ 99 }
                                                    >
                                                        <div
                                                            style={ {
                                                                position: 'fixed',
                                                                top: '58%',
                                                                left: '53%',
                                                                width: '90%',
                                                                transform: 'translate(-53%, -50%)',
                                                                background: '#ffffff',
                                                                boxShadow:
                                                                    '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
                                                                minWidth: isMobile ? '320px' : isLMobile ? '480px' : '600px',
                                                                maxWidth: '1000px',
                                                                borderRadius: '8px',
                                                                minHeight: '300px',
                                                            } }
                                                        >
                                                            <VStack
                                                                flex={ 1 }
                                                                minHeight={ isMobile ? '600px' : '450px' }
                                                                maxHeight={ '625px' }
                                                            >
                                                                <HStack
                                                                    alignItems={ 'center' }
                                                                    justifyContent={ 'space-between' }
                                                                    borderBottomColor={ 'AVColor.grey' }
                                                                    px={ 5 }
                                                                    zIndex={ 99 }
                                                                    marginTop={ '10px' }
                                                                >
                                                                    <Text fontSize={ 24 }>{ Loc.currentLang.menu.editStaffMember }</Text>
                                                                    <HStack justifyContent={ 'flex-end' }>
                                                                        <AVCancel color={ undefined } size={ undefined } callback={ () => setIsStaffEdit( false ) } />
                                                                    </HStack>
                                                                </HStack>
                                                                <Pressable
                                                                    zIndex={ -3 }
                                                                >
                                                                    <Box p={ 1 } borderRadius={ 'full' }>
                                                                        <HStack space={ 2 } px={ 2 }>
                                                                            { Editstaffitem?.thumb || Editstaffitem?.linkPic ? (
                                                                                <Box
                                                                                    bgColor={ '#FF6600' }
                                                                                    borderRadius={ [ 'full' ] }
                                                                                    width={ '70px' }
                                                                                    height={ '70px' }
                                                                                    left={ '1px' }
                                                                                    p={ 0 }
                                                                                >
                                                                                    <Image
                                                                                        source={ {
                                                                                            uri: Editstaffitem?.linkPic
                                                                                                ? AV.Media.getURL( Editstaffitem?.linkPic )
                                                                                                : AV.Media.getThumb(
                                                                                                    Editstaffitem
                                                                                                ),
                                                                                        } }
                                                                                        borderRadius={ [ 'full' ] }
                                                                                        //top={ "2" }
                                                                                        width={ '100%' }
                                                                                        height={ [ '100%' ] }
                                                                                    />
                                                                                    <Button
                                                                                        bgColor={ 'AVColor.white' }
                                                                                        position={ 'absolute' }
                                                                                        top={ '45px' }
                                                                                        right={ '-6px' }
                                                                                        p={ 0 }
                                                                                        rounded={ 'full' }
                                                                                        _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                                                                        shadow={ 1 }
                                                                                        zIndex={ 99 }
                                                                                        isLoading={ isPicLoader }
                                                                                        _spinner={ { color: 'AVColor.black' } }
                                                                                    >
                                                                                        <Box
                                                                                            { ...getStaffProfilePicFile( { className: 'dropzone' } ) }
                                                                                        >
                                                                                            <input { ...getStaffProfileInputProps() } />
                                                                                            <AddPhotoIcon size={ 18 } p={ 1 } />
                                                                                        </Box>
                                                                                    </Button>
                                                                                </Box>
                                                                            ) : (
                                                                                <Box
                                                                                    alignItems={ 'center' }
                                                                                >
                                                                                    <Profile height={ '70' } width={ '70' } color={ "#32AE78" } />

                                                                                    <Button
                                                                                        bgColor={ 'AVColor.white' }
                                                                                        position={ 'absolute' }
                                                                                        top={ '45px' }
                                                                                        right={ '-6px' }
                                                                                        p={ 0 }
                                                                                        rounded={ 'full' }
                                                                                        _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                                                                        shadow={ 1 }
                                                                                        zIndex={ 99 }
                                                                                        isLoading={ isPicLoader }
                                                                                        _spinner={ { color: 'AVColor.black' } }
                                                                                    >
                                                                                        <Box
                                                                                            { ...getStaffProfilePicFile( { className: 'dropzone' } ) }
                                                                                        >
                                                                                            <input { ...getStaffProfileInputProps() } />
                                                                                            <AddPhotoIcon size={ 18 } p={ 1 } />
                                                                                        </Box>
                                                                                    </Button>
                                                                                </Box>
                                                                            ) }
                                                                            <Text
                                                                                marginTop={ '20px' }
                                                                                left={ '2px' }
                                                                                marginLeft={ '35px' }
                                                                                alignItems='center'
                                                                                width='300px'
                                                                                numberOfLines={ 1 }
                                                                                textAlign='center'
                                                                                zIndex={ -3 }
                                                                                fontSize={ 24 }
                                                                            >
                                                                                { Editstaffitem?.title }
                                                                            </Text>
                                                                        </HStack>
                                                                    </Box>
                                                                </Pressable>
                                                                <HStack
                                                                    alignItems={ 'center' }
                                                                    display={ 'block' }
                                                                    borderBottomColor={ 'AVColor.grey' }
                                                                    px={ 5 }
                                                                    py={ 5 }
                                                                    zIndex={ 99 }
                                                                >
                                                                    <VStack marginTop={ '15px' }>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id='demo-simple-select-label'>
                                                                                { Loc.currentLang.menu.assignRoleReq }
                                                                            </InputLabel>
                                                                            <Select
                                                                                labelId='demo-simple-select-label'
                                                                                id='demo-simple-select'
                                                                                value={ staffRole }
                                                                                label={ Loc.currentLang.menu.assignRoleReq }
                                                                                onChange={ handleStaffRole }
                                                                            >
                                                                                { leagueTeamFK?.roles?.map( ( option ) => (
                                                                                    <MenuItem key={ option?.name } value={ option?.name }>
                                                                                        { option?.name }
                                                                                    </MenuItem>
                                                                                ) ) }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </VStack>
                                                                    <VStack marginTop={ '15px' }>
                                                                        <AVInputtext
                                                                            type={ 'text' }
                                                                            onChange={ ( e ) => setstaffphone( e.target.value.replace( /[^0-9]/g, '' ) ) }
                                                                            value={ staffphone }

                                                                            label={ Loc.currentLang.menu.phone }
                                                                            height={ '15px' }
                                                                            width={ isMobile ? '245px' : '200px' }
                                                                            maxLength={ 50 } defaultValue={ undefined } minRows={ undefined } multiline={ undefined } placeholder={ undefined } size={ undefined } />
                                                                    </VStack>
                                                                    <VStack marginTop={ '15px' }>
                                                                        <AVInputtext
                                                                            type={ 'text' }
                                                                            onChange={ ( e ) => setstaffemail( e.target.value ) }
                                                                            value={ staffemail }
                                                                            label={ Loc.currentLang.menu.emailAddressNotReq }
                                                                            height={ '15px' }
                                                                            width={ isMobile ? '245px' : '200px' }
                                                                            maxLength={ 50 } defaultValue={ undefined } minRows={ undefined } multiline={ undefined } placeholder={ undefined } size={ undefined } />
                                                                    </VStack>
                                                                </HStack>
                                                                <HStack
                                                                    width={ '100%' }
                                                                    height='100%'
                                                                    space={ 5 }
                                                                    flex={ 1 }
                                                                    justifyContent={ 'center' }
                                                                    px={ 5 }
                                                                    m={ 3 }
                                                                    zIndex={ -1 }
                                                                >
                                                                    <Button
                                                                        rounded='full'
                                                                        backgroundColor={ '#EEEEEE' }
                                                                        paddingX={ '20px' }
                                                                        width={ isMobile ? '40%' : '24%' }
                                                                        height={ '40px' }
                                                                        onPress={ () =>
                                                                        {
                                                                            setIsStaffEdit( false );
                                                                        } }
                                                                    >
                                                                        <Text
                                                                            fontSize={ '14px' }
                                                                            color={ 'rgba(0,0,0,0.5)' }
                                                                            fontWeight={ 'bold' }
                                                                        >
                                                                            CANCEL
                                                                        </Text>
                                                                    </Button>
                                                                    <Button
                                                                        rounded='full'
                                                                        backgroundColor={ '#32AE78' }
                                                                        paddingX={ '20px' }
                                                                        onPress={ () =>
                                                                        {
                                                                            handleEditStaff( Editstaffitem );
                                                                        } }
                                                                        isLoading={ isLoading }
                                                                        isLoadingText={ Loc.currentLang.menu.saveLoad }
                                                                        width={ isMobile ? '40%' : '24%' }
                                                                        height={ '40px' }
                                                                    >
                                                                        <Text fontSize={ '14px' } color={ 'white' }>
                                                                            SAVE
                                                                        </Text>
                                                                    </Button>

                                                                </HStack>
                                                                { error && <HStack justifyContent={ 'center' } marginBottom={ '10px' }>
                                                                    <Text color={ 'red.500' } fontWeight={ 400 }>{ error }</Text>
                                                                </HStack> }
                                                            </VStack>
                                                        </div>
                                                    </Box>
                                                ) }
                                            </>
                                        ) }
                                        {/* player page*/ }
                                        { isPlayerPage && !isShowEdit && (
                                            <Box
                                                width={ '100%' }
                                                minHeight={ '340px' }
                                                marginRight={ 4 }
                                                p={ 2 }
                                                h='auto'
                                                shadow={ 1 }
                                                bgColor='#fff'
                                                rounded='lg'
                                            >
                                                <VStack>
                                                    <HStack alignItems={ 'center' } justifyContent={ 'space-between' }>
                                                        <Text
                                                            fontWeight={ '500' }
                                                            marginLeft={ '15px' }
                                                            fontSize={ '22px' }
                                                            color={ 'AVColor.primary' }
                                                            py={ '12px' }
                                                        >
                                                            { leagueTeamFK?.subType == 'Class' ? Loc.currentLang.menu.students :
                                                                leagueTeamFK?.subType == 'Term' ? Loc.currentLang.menu.members : Loc.currentLang.menu.players }
                                                        </Text>

                                                        <HStack alignItems={ 'center' }>
                                                            <Tooltip label={ helptext } w={ 200 } openDelay={ 100 }>
                                                                <Box
                                                                    marginRight={ '10px' }
                                                                    p={ '9px' }
                                                                    shadow={ 1 }
                                                                    bgColor={ 'gray.100' }
                                                                    display={ playersList?.length === 0 ? 'none' : '' }
                                                                    rounded={ 'full' }
                                                                >
                                                                    <img src={ helping } height={ '17px' } width={ '17px' } />
                                                                </Box>
                                                            </Tooltip>
                                                            { isAdminView && playersList?.length > 0 && ( <Button
                                                                rounded={ 'full' }
                                                                backgroundColor={ '#F8F8F8' }
                                                                marginRight={'10px'}
                                                                _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                                                shadow={ 1 }
                                                                padding={ '10px' }
                                                                isLoading={ isLoad }
                                                                    onPress={ () => HandleGroupChat( staffToPlayer ? 'X' :'P' ) }
                                                                >
                                                               
                                                                    <GroupChatIcon size={ 23 } color={ 'rgba(0, 0, 0, 0.5)' } />
                                                            </Button> ) }
                                                            { isAdminView && (
                                                                <Button
                                                                    rounded={ 'full' }
                                                                    backgroundColor={ '#F8F8F8' }
                                                                    _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                                                    shadow={ 1 }
                                                                    padding={ '10px' }
                                                                    onPress={ () => handleProfileType( 'Player' ) }
                                                                >
                                                                    <AddIcon size={ '20px' } color={ '#32AE78' } />
                                                                </Button> ) }
                                                        </HStack>

                                                    </HStack>
                                                </VStack>
                                                <HStack>
                                                    <FlatList
                                                        height={ dimensions.height - 110 }
                                                        width={ '100%' }
                                                        data={ playersList }
                                                        scrollEnabled={ true }
                                                        ListEmptyComponent={
                                                            <HStack width={ '100%' } top={ isMobile ? '20px' : '0px' }
                                                                padding={ isMobile ? '10px' : '10px' }
                                                                marginTop={ isMobile ? '-10px' : '0px' } space={ isMobile ? 2 : 2 }>
                                                                <img
                                                                    src={ inform }
                                                                    style={ {
                                                                        width: '20px',
                                                                        height: '20px',
                                                                    } }
                                                                />
                                                                { helptext }
                                                            </HStack>
                                                        }
                                                        renderItem={ ( item : any ) => (
                                                            <PlayersCard
                                                                leagueTeam={ leagueTeamFK }
                                                                data={ item }
                                                                { ...props }
                                                                key={ item.id }
                                                                item={ item }
                                                                playersList={ playersList }
                                                                setPlayersList={ setPlayersList }
                                                                HandleRemoveplayers={ HandleRemoveplayers }
                                                                setIsConvoData={ setIsConvoData }
                                                                isAdminView={ isAdminView }
                                                            />
                                                        ) }
                                                        showsVerticalScrollIndicator={ false }
                                                        numColumns={ 3 }
                                                    />
                                                </HStack>
                                            </Box>
                                        ) }
                                        {/* participant page*/ }
                                        { ( isparticipantspage && !isShowEdit ) &&
                                            <Box
                                                pb={ 5 }
                                                zIndex={ isDropDown ? -3 : '' }
                                                minHeight={ '340px' }
                                                borderRadius={ 5 }
                                                backgroundColor={ 'AVColor.white' }
                                                overflow={ 'hidden' }
                                                shadow={ 1 }
                                                width={ '100%' }
                                            >
                                                <HStack justifyContent={ "space-between" }  >
                                                    <Text
                                                        fontWeight={ '500' }
                                                        marginLeft={ '15px' }
                                                        fontSize={ '22px' }
                                                        color={ 'AVColor.primary' }
                                                        py={ '12px' }
                                                        width={ '150px' }
                                                    >
                                                        { Loc.currentLang.menu.participants }
                                                    </Text>

                                                    <HStack space={ '45px' }>
                                                        <Tooltip
                                                            label={ helptext }
                                                            w={ 200 }
                                                            openDelay={ 500 }
                                                        >
                                                            <Box
                                                                marginTop={ '5px' }
                                                                height={ '36px' }
                                                                marginRight={ '10px' }
                                                                p={ '9px' }
                                                                display={ participantList?.length > 0 ? '' : 'none' }
                                                                shadow={ 1 }
                                                                backgroundColor={ 'AVColor.liteBg' }
                                                                rounded={ 'full' }
                                                            >
                                                                <img
                                                                    src={ helping }
                                                                    height={ '17px' }
                                                                    width={ '17px' }
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                        { editAccess && participantList?.length > 0 && ( <Button
                                                            position={ 'absolute' }
                                                            right={ '5px' }
                                                            top={ '5px' }
                                                            p={ [ 1, 2 ] }
                                                            rounded={ 'full' }
                                                            _hover={ { bgColor: !isLoad ? 'gray.50' : '' } }
                                                            shadow={ 1 }
                                                            bgColor={ isLoad ? 'gray.400' : 'gray.100' }
                                                            marginRight={'43px' }
                                                            isLoading={ isLoad }
                                                            onPress={ () => HandleGroupChat( 'N' ) }
                                                        >
                                                            {

                                                                <GroupChatIcon size={ 23 } color={ 'rgba(0, 0, 0, 0.5)' } />
                                                            }


                                                        </Button> ) }

                                                        { editAccess && < Pressable
                                                            position={ 'absolute' }
                                                            right={ '5px' }
                                                            top={ '5px' }
                                                            p={ [ 1, 2 ] }
                                                            rounded={ 'full' }
                                                            bgColor={ 'gray.100' }
                                                            _hover={ { bgColor: 'gray.50' } }
                                                            shadow={ 1 }
                                                            onPress={ () => leagueTeamFK.series ? setIsShowEditOccurence( !isShowEditOccurence ) : handleProfileType( 'Participant' ) }
                                                        >
                                                            <AddIcon color={ '#32AE78' } size={ '20px' } />
                                                        </Pressable> }
                                                        { isShowEditOccurence &&
                                                            <VStack
                                                                ref={ menuRefs }
                                                                width={ '125px' }
                                                                height='auto'
                                                                right={ '10px' }
                                                                shadow={ 2 }
                                                                background={ '#FFFFFF' }
                                                                position={ 'relative' }
                                                                top={ '30px' }
                                                                zIndex={ 999 }
                                                                borderRadius={ '6px' }
                                                            >
                                                                <Pressable
                                                                    ref={ menuRefs }
                                                                    width={ '100%' }
                                                                    height={ '36px' }
                                                                    paddingX={ '10px' }
                                                                    alignItems={ 'flex-start' }
                                                                    justifyContent={ 'center' }
                                                                    onPress={ () =>
                                                                    {
                                                                        setIsOpen( false );
                                                                        setIsEditEntireSeries( false );
                                                                        setIsShowEditOccurence( false );
                                                                        handleProfileType( 'Participant' );
                                                                    } }
                                                                    _hover={ {
                                                                        backgroundColor: 'gray.100'
                                                                    } }
                                                                >
                                                                    <Text
                                                                        fontSize={ '14px' }
                                                                        fontFamily={ 'Roboto' }
                                                                    >
                                                                        { "This Occurenece" }
                                                                    </Text>
                                                                </Pressable>
                                                                <Divider />
                                                                <Pressable
                                                                    ref={ menuRefs }
                                                                    width={ '100%' }
                                                                    height={ '36px' }
                                                                    paddingX={ '10px' }
                                                                    alignItems={ 'flex-start' }
                                                                    justifyContent={ 'center' }
                                                                    onPress={ () =>
                                                                    {
                                                                        setIsOpen( false );
                                                                        setIsEditEntireSeries( true );
                                                                        setIsShowEditOccurence( false );
                                                                        handleProfileType( 'Participant' );
                                                                    } }
                                                                    _hover={ {
                                                                        backgroundColor: 'gray.100'
                                                                    } }
                                                                >
                                                                    <Text
                                                                        fontSize={ '14px' }
                                                                        fontFamily={ 'Roboto' }
                                                                    >
                                                                        { "Entire Series" }
                                                                    </Text>
                                                                </Pressable>
                                                            </VStack>
                                                        }
                                                    </HStack>


                                                </HStack>
                                                <Divider my={ 2 } zIndex={ -3 } />

                                                <VStack p={ 1 }>
                                                    <div className="custom-scrollbar">
                                                        <HStack
                                                            marginY={ '24px' }
                                                            overflowY={ isMobile ? 'auto' : undefined }
                                                            backgroundColor={ 'rgba(0,0,0,0.05)' }
                                                            borderRadius={ '18px' }
                                                            marginLeft={ '24px' }
                                                            height={ '36px' }
                                                            width={ isMobile ? '' : '500px' }
                                                        >
                                                            <PariticipantFilter Type={ status } SetType={ setAllStatus } Name={ Loc.currentLang.menu.all } length={ participantList?.length } />
                                                            <PariticipantFilter Type={ status } SetType={ setRsvpStatus } Name={ Loc.currentLang.menu.rsvp } length={ participantList?.filter( x => x?.status === undefined )?.length } />
                                                            <PariticipantFilter Type={ status } SetType={ setYesStatus } Name={ Loc.currentLang.menu.yes } length={ participantList?.filter( x => x.status === 'Yes' )?.length } />
                                                            <PariticipantFilter Type={ status } SetType={ setNoStatus } Name={ Loc.currentLang.menu.no } length={ participantList?.filter( x => x.status === 'No' )?.length } />
                                                            <PariticipantFilter Type={ status } SetType={ setMaybeStatus } Name={ Loc.currentLang.menu.maybe } length={ participantList?.filter( x => x.status === 'Maybe' )?.length } />
                                                        </HStack>
                                                    </div>
                                                    <HStack alignItems={ 'center' } px={ 5 } flexWrap={ 'nowrap' } space={ 2 }>
                                                        <HStack flexWrap={ "wrap" } maxWidth={ "100%" } alignSelf={ "flex-start" }>
                                                            { filteredParticipantList?.map( ( item, i ) => (
                                                                <ParticipantsCard index={ i } item={ item } status={ status } editAccess={ editAccess } setisConfirmDelete={ setisConfirmDelete } setConfirmationtext={ setConfirmationtext } setItemSelect={ setItemSelect } setselectType={ setselectType } filteredParticipantList={ filteredParticipantList } setIsOpenConvo={ setIsOpenConvo } />
                                                            ) ) }
                                                        </HStack>
                                                    </HStack>
                                                </VStack>
                                            </Box> }
                                        {/* resource page*/ }
                                        { ( isResourses && !isShowEdit ) &&
                                            <Box
                                                pb={ 5 }
                                                zIndex={ isDropDown ? -3 : '' }
                                                minHeight={ '340px' }
                                                borderRadius={ 5 }
                                                backgroundColor={ 'AVColor.white' }
                                                overflow={ 'hidden' }
                                                shadow={ 1 }
                                            >
                                                <HStack>
                                                    <Text
                                                        fontWeight={ '500' }
                                                        marginLeft={ '15px' }
                                                        fontSize={ '22px' }
                                                        color={ 'AVColor.primary' }
                                                        py={ '12px' }
                                                        width={ '150px' }
                                                    >
                                                        { Loc.currentLang.menu.resources }
                                                    </Text>
                                                    { editAccess &&
                                                        < Pressable
                                                            position={ 'absolute' }
                                                            right={ '5px' }
                                                            top={ '5px' }
                                                            p={ [ 1, 2 ] }
                                                            rounded={ 'full' }
                                                            bgColor={ 'gray.100' }
                                                            _hover={ { bgColor: 'gray.50' } }
                                                            shadow={ 1 }
                                                            //onPress={ () => setIsShowEditOccurence( true ) }
                                                            onPress={ () =>
                                                                leagueTeamFK.series ? setIsShowEditOccurence( !isShowEditOccurence ) : handleAddResourcePopup() }
                                                        >
                                                            <AddIcon color={ '#32AE78' } size={ '20px' } />
                                                        </Pressable>
                                                    }
                                                    { isShowEditOccurence &&
                                                        <VStack
                                                            ref={ menuRefs }
                                                            width={ '125px' }
                                                            height='auto'
                                                            left={ '500px' }
                                                            shadow={ 2 }
                                                            background={ '#FFFFFF' }
                                                            position={ 'relative' }
                                                            top={ '30px' }
                                                            zIndex={ 999 }
                                                            borderRadius={ '6px' }
                                                        >
                                                            <Pressable
                                                                ref={ menuRefs }
                                                                width={ '100%' }
                                                                height={ '36px' }
                                                                paddingX={ '10px' }
                                                                alignItems={ 'flex-start' }
                                                                justifyContent={ 'center' }
                                                                onPress={ () =>
                                                                {
                                                                    setIsOpen( false );
                                                                    setIsEditEntireSeries( false );
                                                                    setIsShowEditOccurence( false );
                                                                    handleAddResourcePopup();
                                                                } }
                                                                _hover={ {
                                                                    backgroundColor: 'gray.100'
                                                                } }
                                                            >
                                                                <Text
                                                                    fontSize={ '14px' }
                                                                    fontFamily={ 'Roboto' }
                                                                >
                                                                    { "This Occurenece" }
                                                                </Text>
                                                            </Pressable>
                                                            <Divider />
                                                            <Pressable
                                                                ref={ menuRefs }
                                                                width={ '100%' }
                                                                height={ '36px' }
                                                                paddingX={ '10px' }
                                                                alignItems={ 'flex-start' }
                                                                justifyContent={ 'center' }
                                                                onPress={ () =>
                                                                {
                                                                    setIsOpen( false );
                                                                    setIsEditEntireSeries( true );
                                                                    setIsShowEditOccurence( false );
                                                                    handleAddResourcePopup();
                                                                } }
                                                                _hover={ {
                                                                    backgroundColor: 'gray.100'
                                                                } }
                                                            >
                                                                <Text
                                                                    fontSize={ '14px' }
                                                                    fontFamily={ 'Roboto' }
                                                                >
                                                                    { "Entire Series" }
                                                                </Text>
                                                            </Pressable>
                                                        </VStack>
                                                    }
                                                </HStack>
                                                <Divider my={ 2 } zIndex={ -3 } />
                                                <VStack paddingX={ '15px' } space={ 3 }>
                                                    { resoursesList?.length > 0 &&
                                                        resoursesList?.map( ( item : any ) => (
                                                            <HStack alignItems={ 'center' } p={ 2 } backgroundColor={ '#F5F5F5' } borderStyle={ 'solid' } borderColor={ 'rgba(0, 0, 0, 0.05)' } borderRadius={ '5px' } width={ '98%' } height={ '36px' } marginTop={ '12px' } >
                                                                <HStack width={ '40%' } >
                                                                    <Tooltip label={ item?.resource } openDelay={ 500 }>
                                                                        <Text fontWeight='500' numberOfLines={ 1 } text-overflow={ 'ellipsis' } fontSize={ '16px' } textAlign={ 'left' } color={ '#000000' } textTransform={ 'capitalize' }>{ item?.resource }</Text>
                                                                    </Tooltip>
                                                                </HStack>
                                                                <HStack width={ '10%' } >
                                                                    <Tooltip label={ item?.quantity } openDelay={ 500 }>
                                                                        <Text fontSize={ '16px' } numberOfLines={ 1 } text-overflow={ 'ellipsis' } textAlign={ 'left' } fontWeight={ 'medium' } paddingLeft={ '2px' } color={ '#000000' }>{ item?.quantity }</Text>
                                                                    </Tooltip>
                                                                </HStack>
                                                                <HStack  >
                                                                    {/*<Tooltip label={item?.notes} openDelay={500}>*/ }
                                                                    {/*    <Text fontSize={'16px'} width={'125px'} numberOfLines={1} text-overflow={'ellipsis'} textAlign={'center'} paddingLeft={'2px'} fontWeight={'medium'} textTransform={'capitalize'}>{item?.notes}</Text>*/ }
                                                                    {/*    </Tooltip>*/ }
                                                                </HStack >
                                                                { participantList?.filter( x => x?.id === item?.id )?.length > 0 ? <HStack width={ '40%' }>
                                                                    <HStack textAlign={ 'left' } paddingLeft={ '15px' } >
                                                                        <Pressable onPress={ () =>
                                                                            navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                                                state: { user: item },
                                                                            } )
                                                                        }>
                                                                            <ProfilePic width={ 30 } height={ 30 } link={ entitydatalist?.filter( ( x : any ) => x?.id == item?.id )?.length > 0 ? AV.AVEntity.getLink( entitydatalist?.find( ( x : any ) => x?.id == item?.id ) ) : participantList?.filter( x => x?.id === item?.id )[ 0 ] } />
                                                                        </Pressable>
                                                                        { participantList?.filter( x => x?.id === item?.id )[ 0 ]?.id !== currentUser?.id ?
                                                                            <Pressable onPress={ () =>
                                                                                navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                                                    state: { user: item },
                                                                                } )
                                                                            }>
                                                                                < Text textAlign={ 'left' } marginTop={ '3px' } paddingLeft={ '6px' } textTransform={ 'capitalize' } numberOfLines={ 1 } text-overflow={ 'ellipsis' }>{ participantList?.filter( x => x?.id === item?.id )[ 0 ]?.title }</Text></Pressable>
                                                                            :
                                                                            <Pressable onPress={ () =>
                                                                                navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                                                    state: { user: item },
                                                                                } )
                                                                            }>
                                                                                < Text textAlign={ 'left' } marginTop={ '3px' } paddingLeft={ '6px' } textTransform={ 'capitalize' }>{ "You" }</Text>
                                                                            </Pressable>
                                                                        }
                                                                    </HStack>
                                                                </HStack> : <HStack width={ '40%' }   >
                                                                    <Text numberOfLines={ 1 } text-overflow={ 'ellipsis' } paddingLeft={ '2px' } textTransform={ 'capitalize' }></Text></HStack> }
                                                                <HStack space={ '15px' }>
                                                                    { editAccess && ( <Pressable
                                                                        onPress={ () => handleEditProfileType( item ) }
                                                                    >
                                                                        <EditIcon size={ '20px' } />
                                                                    </Pressable> ) }
                                                                    { editAccess && ( <Pressable
                                                                        onPress={ () => handleRemoveResourcePopup( item ) }
                                                                    >
                                                                        <DeleteIcon size={ '20px' } />
                                                                    </Pressable> ) }
                                                                    { !editAccess && ( <Pressable
                                                                        onPress={ () => handleShowResourcePopup( item ) }>
                                                                        <ViewIcon size={ '20px' } />
                                                                    </Pressable> ) }
                                                                </HStack>
                                                            </HStack>
                                                        ) ) }
                                                </VStack>
                                                { resoursesList?.length < 1 && <HStack width={ '100%' } top={ isMobile ? '20px' : '0px' }
                                                    padding={ isMobile ? '10px' : '10px' }
                                                    marginTop={ isMobile ? '-10px' : '0px' } space={ isMobile ? 2 : 2 }
                                                ><img src={ inform } style={ { width: '20px', height: '20px' } } />{ Loc.currentLang.helpText.event.resourcesTab }</HStack> }
                                            </Box>
                                        }
                                        {/* teamclub page*/ }
                                        { isTeamClub && !isShowEdit && (
                                            <>
                                                <Box
                                                    zIndex={ isDropDown ? -3 : '' }
                                                    minHeight={ '340px' }
                                                    borderRadius={ 5 }
                                                    backgroundColor={ 'AVColor.white' }
                                                    shadow={ 1 }
                                                    flex={ 1 }
                                                    mb={ 3 }
                                                >
                                                    <VStack >
                                                        <HStack
                                                            justifyContent={ 'space-between' }
                                                            alignItems={ 'center' }
                                                            space={ 2 }
                                                            marginY={ '11px' }
                                                            marginX={ '10px' }

                                                        >
                                                            <HStack
                                                                backgroundColor={ '#F5F5F5' }
                                                                borderWidth={ '1px' }
                                                                borderStyle={ 'solid' }
                                                                borderColor={ 'rgba(0, 0, 0, 0.05)' }
                                                                borderRadius={ '18px' }
                                                                width={ '320px' }
                                                                height={ '36px' }
                                                            >
                                                                <UiPill
                                                                    eventType={ eventType }
                                                                    setEventType={ setAllTeam }
                                                                    name={ Loc.currentLang.menu.all }
                                                                />
                                                                <UiPill
                                                                    eventType={ eventType }
                                                                    setEventType={ setTeam }
                                                                    name={ Loc.currentLang.menu.team }
                                                                />
                                                                <UiPill
                                                                    eventType={ eventType }
                                                                    setEventType={ setGroup }
                                                                    name={ Loc.currentLang.menu.groupOrClub }
                                                                />
                                                            </HStack>
                                                            <HStack alignItems={ 'center' } pr={ 2 }>
                                                                { isAdminView && (
                                                                    <HStack>
                                                                        <Box
                                                                            marginRight={ '10px' }
                                                                            p={ '9px' }
                                                                            display={ teamsList?.length > 0 ? '' : 'none' }
                                                                            shadow={ 1 }
                                                                            marginTop={ '3px' }
                                                                            bgColor={ 'gray.100' }
                                                                            rounded={ 'full' }
                                                                        >
                                                                            <img src={ helping } height={ '17px' } width={ '17px' } />
                                                                        </Box>
                                                                        { orgFK?.type === "School" && <Button
                                                                            p={ [ 1, 2 ] }
                                                                            rounded={ 'full' }
                                                                            bgColor={ 'gray.100' }
                                                                            _hover={ { bgColor: 'gray.50' } }
                                                                            shadow={ 1 }
                                                                            disabled={ isCreate }
                                                                            marginRight={ '10px' }
                                                                            onPress={ () => handleProfileType( 'Team' ) }
                                                                        >
                                                                            <AddIcon size={ '20px' } color={ '#32AE78' } />
                                                                        </Button> }

                                                                        <HStack ref={ menuRefs }>
                                                                            <HStack>
                                                                                <Button
                                                                                    width={ '100px' }
                                                                                    bgColor={ '#32AE78' }
                                                                                    rounded='full'
                                                                                    fontWeight='550px'
                                                                                    color='#fff'
                                                                                    p={ 2 }
                                                                                    justifyContent={ 'flex-start' }
                                                                                    minHeight='35px'
                                                                                    borderRadius={ '20px' }
                                                                                    borderColor={ 'AVColor.primary' }
                                                                                    onPress={ () =>
                                                                                    {
                                                                                        setIsActive( !isActive );
                                                                                    } }
                                                                                >
                                                                                    <Text
                                                                                        color={ 'white' }
                                                                                        lineHeight={ '1rem' }
                                                                                        paddingLeft={ '18px' }
                                                                                    >
                                                                                        { Loc.currentLang.menu.create }
                                                                                    </Text>
                                                                                </Button>
                                                                                <Pressable
                                                                                    position={ 'absolute' }
                                                                                    right={ 0 }
                                                                                    top={ 2 }
                                                                                    onPress={ () =>
                                                                                    {
                                                                                        setIsActive( !isActive );
                                                                                    } }
                                                                                >
                                                                                    <Image source={ Dropdown } alt='Actavivo' size='25px' />
                                                                                </Pressable>
                                                                            </HStack>
                                                                            {/*<HStack>*/ }
                                                                            {/*    {isActive && (*/ }
                                                                            {/*        <HStack*/ }
                                                                            {/*            position={'absolute'}*/ }
                                                                            {/*            right={0}*/ }
                                                                            {/*            top={'39px'}*/ }
                                                                            {/*            flexDirection={'column'}*/ }
                                                                            {/*            width={'156px'}*/ }
                                                                            {/*            borderRadius={'5px'}*/ }
                                                                            {/*            bgColor='#FFFFFF'*/ }
                                                                            {/*            shadow='9'*/ }
                                                                            {/*            rounded='lg'*/ }
                                                                            {/*            zIndex={100}*/ }
                                                                            {/*        >*/ }
                                                                            {/*            <Button*/ }
                                                                            {/*                onPress={() => {*/ }
                                                                            {/*                    setteamType(Loc.currentLang.menu.team);*/ }
                                                                            {/*                    setIsActive(!isActive);*/ }
                                                                            {/*                    handleOpen('Team');*/ }
                                                                            {/*                }}*/ }
                                                                            {/*                bgColor='#FFFFFF'*/ }
                                                                            {/*                height='30px'*/ }
                                                                            {/*                justifyContent={'start'}*/ }
                                                                            {/*                _hover={{ backgroundColor: 'AVColor.hovercolor' }}*/ }
                                                                            {/*                width={'156px'}*/ }
                                                                            {/*            >*/ }
                                                                            {/*                <Text color={'black'}>{Loc.currentLang.menu.team}</Text>*/ }
                                                                            {/*            </Button>*/ }
                                                                            {/*            <Button*/ }
                                                                            {/*                onPress={() => {*/ }
                                                                            {/*                    setteamType(Loc.currentLang.menu.groupTxt);*/ }
                                                                            {/*                    setIsActive(!isActive);*/ }
                                                                            {/*                    handleOpen('Group');*/ }
                                                                            {/*                }}*/ }
                                                                            {/*                bgColor='#FFFFFF'*/ }
                                                                            {/*                height='30px'*/ }
                                                                            {/*                justifyContent={'start'}*/ }
                                                                            {/*                _hover={{ backgroundColor: 'AVColor.hovercolor' }}*/ }
                                                                            {/*                width={'156px'}*/ }
                                                                            {/*            >*/ }
                                                                            {/*                <Text color={'black'}>*/ }
                                                                            {/*                    {Loc.currentLang.menu.groupOrClub}*/ }
                                                                            {/*                </Text>*/ }
                                                                            {/*            </Button>*/ }

                                                                            {/*        </HStack>*/ }
                                                                            {/*    )}*/ }
                                                                            {/*</HStack>*/ }
                                                                        </HStack>
                                                                    </HStack>
                                                                ) }
                                                                {/*    <MoreHorizontalIcon size='md' marginRight={ 5 } />*/ }
                                                            </HStack>
                                                        </HStack>
                                                        <HStack
                                                            flexWrap={ 'wrap' }
                                                            zIndex={ -2 }
                                                            alignSelf={ 'flex-start' }
                                                            space={ 3 }
                                                            p={ 2 }
                                                        >
                                                            { isshowteamsList?.length > 0 &&
                                                                isshowteamsList?.map( ( item, i ) => (
                                                                    <Box
                                                                        key={ item.id + i }
                                                                        bgColor={ '#fff' }
                                                                        borderWidth={ '1px' }
                                                                        borderColor={ 'AVColor.hovercolor2' }
                                                                        minWidth={ 130 }
                                                                        minHeight={ 175 }
                                                                        borderRadius={ 5 }
                                                                        marginLeft={ 2 }
                                                                        marginY={ 2 }
                                                                        py={ 3 }
                                                                        shadow={ 1 }
                                                                    >
                                                                        <Pressable
                                                                            key={ item.id + i }
                                                                            padding={ 1.5 }
                                                                            rounded={ 'full' }
                                                                            position={ 'absolute' }
                                                                            right={ -10 }
                                                                            top={ -10 }
                                                                            _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                                                                            backgroundColor={ 'AVColor.buttonBg' }
                                                                            onPress={ () => handleRemoveProfile( 'Team', item ) }
                                                                        >
                                                                            <CloseIcon size={ '12px' } color={ 'AVColor.secondaryText' } />
                                                                        </Pressable>

                                                                        <VStack key={ item?.id } alignItems={ 'flex-start' } padding={ 2 }>
                                                                            <Pressable
                                                                                key={ item.id }
                                                                                _hover={ { bgColor: 'gray.100' } }
                                                                                p={ 2 }
                                                                                borderRadius={ 5 }
                                                                                minWidth={ 120 }
                                                                                onPress={ () =>
                                                                                {
                                                                                    navigate( `/teams/${ item?.id }/${ item?.region }`, {
                                                                                        state: { isCreate: false, team: item },
                                                                                    } );
                                                                                } }
                                                                            >
                                                                                { item?.thumb && (
                                                                                    <Box
                                                                                        bgColor={ '#FF6600' }
                                                                                        borderRadius={ [ 'full' ] }
                                                                                        width={ '60px' }
                                                                                        height={ '60px' }
                                                                                        alignSelf={ 'center' }
                                                                                    >
                                                                                        <Image
                                                                                            source={ {
                                                                                                uri: AV.Media.getThumb( item ),
                                                                                            } }
                                                                                            borderRadius={ [ 'full' ] }
                                                                                            width={ '100%' }
                                                                                            height={ [ '100%' ] }
                                                                                        />
                                                                                    </Box>
                                                                                ) }
                                                                                { !item?.thumb && (
                                                                                    <Box
                                                                                        alignItems={ 'center' }
                                                                                    >
                                                                                        <Profile height={ '70' } width={ '70' } color={ "#32AE78" } />
                                                                                    </Box>
                                                                                ) }
                                                                                <Tooltip label={ item.title } openDelay={ 500 }>
                                                                                    <Pressable
                                                                                        onPress={ () =>
                                                                                        {
                                                                                            navigate( `/teams/${ item?.id }/${ item?.region }`, {
                                                                                                state: { isCreate: false, team: item },
                                                                                            } );
                                                                                        } }
                                                                                    >
                                                                                        <Text
                                                                                            numberOfLines={ 1 }
                                                                                            maxWidth={ '100px' }
                                                                                            alignSelf={ 'center' }
                                                                                            fontWeight={ '500' }
                                                                                        >
                                                                                            { item.title }
                                                                                        </Text>
                                                                                    </Pressable>
                                                                                </Tooltip>
                                                                            </Pressable>
                                                                        </VStack>
                                                                    </Box>
                                                                ) ) }
                                                        </HStack>
                                                    </VStack>
                                                    { teamsList?.length < 1 && (
                                                        <HStack zIndex={ -1 } width={ '100%' } top={ isMobile ? '20px' : '0px' }
                                                            padding={ isMobile ? '10px' : '10px' }
                                                            marginTop={ isMobile ? '-10px' : '0px' } space={ isMobile ? 2 : 2 }>
                                                            <img
                                                                src={ inform }
                                                                style={ {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                } }
                                                            />
                                                            { helptext }
                                                        </HStack>
                                                    ) }
                                                </Box>
                                            </>
                                        ) }
                                        {/* group page*/ }
                                        { isGroup && !isShowEdit && (
                                            <>
                                                <Box
                                                    minHeight={ '340px' }
                                                    borderRadius={ 5 }
                                                    backgroundColor={ 'AVColor.white' }
                                                    shadow={ 1 }
                                                    flex={ 1 }
                                                    mb={ 3 }
                                                >
                                                    <VStack>
                                                        <HStack alignItems={ 'center' } justifyContent={ 'space-between' }>
                                                            <Text
                                                                fontWeight={ '500' }
                                                                marginLeft={ '15px' }
                                                                fontSize={ '22px' }
                                                                color={ 'AVColor.primary' }
                                                                py={ '12px' }
                                                            >
                                                                {Loc.currentLang.menu.organization }
                                                            </Text>

                                                            <HStack alignItems={ 'center' } pr={ 2 }>
                                                                <HStack space={ 3 }>
                                                                    <Tooltip
                                                                        label={ helptext }
                                                                        w={ 200 }
                                                                        openDelay={ 500 }
                                                                    >
                                                                        <Box
                                                                            marginTop={ '5px' }
                                                                            height={ '36px' }
                                                                            marginRight={ '10px' }
                                                                            p={ '9px' }
                                                                            display={ GroupList?.length > 0 ? '' : 'none' }
                                                                            shadow={ 1 }
                                                                            backgroundColor={ 'AVColor.liteBg' }
                                                                            rounded={ 'full' }
                                                                        >
                                                                            <img
                                                                                src={ helping }
                                                                                height={ '17px' }
                                                                                width={ '17px' }
                                                                            />
                                                                        </Box>
                                                                    </Tooltip>
                                                                    { isAdminView && (
                                                                        <AVComDropdonwn dropdownValues={ dropdownComEventValues } />
                                                                    ) }
                                                                    { isAdminView && (
                                                                        <VStack height={ '40px' } width={ '40px' } ref={ menuRefs } >
                                                                            <Pressable
                                                                                p={ '10px' }
                                                                                shadow={ 1 }
                                                                                bgColor={ 'gray.100' }
                                                                                _hover={ { bgColor: 'gray.50' } }
                                                                                rounded={ 'full' }
                                                                                marginRight={ '3px' }
                                                                                onPress={ () => setOrgOpen( !isOrgOpen ) }
                                                                            >
                                                                                <MoreHorizontalIcon
                                                                                    height={ '4px' } width={ '16px' } size={ 'md' } color={ "#000000" } style={ { transform: [ { rotate: '90deg' } ] } }

                                                                                />
                                                                            </Pressable>
                                                                            { isOrgOpen && ( <Pressable
                                                                                backgroundColor={ 'gray.100' }
                                                                                shadow={ 9 }
                                                                                width={ '100px' }
                                                                                height={ '40px' }
                                                                                padding={ '10px' }
                                                                                position={ 'absolute' }
                                                                                _hover={ { bgColor: 'gray.50' } }
                                                                                top={ '47px' }
                                                                                right={ '25px' }
                                                                                onPress={ () => handleProfileType( 'Group' ) }
                                                                            >
                                                                                <Text color={ 'black' } textAlign={ 'center' }>{ Loc.currentLang.menu.add }</Text>
                                                                            </Pressable> ) }
                                                                        </VStack> ) }
                                                                </HStack>
                                                                <Pressable
                                                                    onPress={ () =>
                                                                    {
                                                                        //  handleEditPopup();
                                                                        //  setShowEditPopup(!showEditPopup);
                                                                    } }
                                                                >
                                                                    {/*    <MoreHorizontalIcon size={ 'md' } color={ 'AVColor.grey' } />*/ }
                                                                </Pressable>
                                                                { showEditPopup && (
                                                                    <Box
                                                                        position={ 'absolute' }
                                                                        right={ 0 }
                                                                        top={ '43px' }
                                                                        background={ '#f2f2f2' }
                                                                        borderRadius={ '5px' }
                                                                        height='40px'
                                                                        width='150px'
                                                                    >
                                                                        {/*<Pressable*/ }
                                                                        {/*    onPress={handleRole}*/ }
                                                                        {/*    top='5px'*/ }
                                                                        {/*>*/ }
                                                                        {/*    <Text margin={'5px'} alignSelf='center'>*/ }
                                                                        {/*        {Loc.currentLang.menu.manageRoles}*/ }
                                                                        {/*    </Text>*/ }
                                                                        {/*</Pressable>*/ }
                                                                    </Box>
                                                                ) }
                                                            </HStack>
                                                        </HStack>
                                                    </VStack>
                                                    <Divider my={ 2 } zIndex={ -3 } />
                                                    <HStack flexWrap={ 'wrap' } alignSelf={ 'flex-start' } space={ 3 } p={ 2 } zIndex={ -3 }>
                                                        { GroupList?.length > 0 &&
                                                            GroupList?.map( ( item ) => (
                                                                <GroupListItem
                                                                    key={ item.id }
                                                                    item={ item }
                                                                    isAdminView={ isAdminView }
                                                                    handleRemoveGroup={ handleRemoveGroup }
                                                                    navigate={ navigate }
                                                                />
                                                            ) ) }
                                                    </HStack>
                                                    { ( GroupList?.length == 0 || GroupList?.length == undefined ) && (
                                                        <HStack zIndex={ -3 } width={ '100%' } top={ isMobile ? '20px' : '0px' }
                                                            padding={ isMobile ? '10px' : '10px' }
                                                            marginTop={ isMobile ? '-10px' : '0px' } space={ isMobile ? 2 : 1 }
                                                            marginLeft={ isMobile ? '3px' : '5px' }>
                                                            <img
                                                                src={ inform }
                                                                style={ {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                } }
                                                            />
                                                            { helptext }
                                                        </HStack>
                                                    ) }
                                                </Box>
                                            </>
                                        ) }
                                        {/* organization page*/ }
                                        { isOrganization && !isShowEdit && (
                                            <>
                                                <Box
                                                    minHeight={ '340px' }
                                                    borderRadius={ 5 }
                                                    backgroundColor={ 'AVColor.white' }
                                                    shadow={ 1 }
                                                    flex={ 1 }
                                                    mb={ 3 }
                                                >
                                                    <VStack>
                                                        <HStack alignItems={ 'center' } justifyContent={ 'space-between' }>
                                                            <Text
                                                                fontWeight={ '500' }
                                                                marginLeft={ '15px' }
                                                                fontSize={ '22px' }
                                                                color={ 'AVColor.primary' }
                                                                py={ '12px' }
                                                            >
                                                                { leagueTeamFK?.type === 'Club' ? Loc.currentLang.menu.teams : Loc.currentLang.menu.organization }
                                                            </Text>

                                                            <HStack alignItems={ 'center' } pr={ 2 }>
                                                                <HStack zIndex={ -3 } space={ 3 }>
                                                                    <Tooltip label={ helptext } openDelay={ 500 } w={ 200 }>
                                                                        <Box
                                                                            marginRight={ '10px' }
                                                                            p={ '9px' }
                                                                            display={ orgList?.length > 0 ? '' : 'none' }
                                                                            shadow={ 1 }
                                                                            backgroundColor={ 'AVColor.liteBg' }
                                                                            rounded={ 'full' }
                                                                        >
                                                                            <img
                                                                                src={ helping }
                                                                                height={ '17px' }
                                                                                width={ '17px' }
                                                                            />
                                                                        </Box>
                                                                    </Tooltip>
                                                                    { isAdminView && (
                                                                        <AVComDropdonwn dropdownValues={ dropdownComOrgValues } />
                                                                    ) }
                                                                    { isAdminView && (
                                                                        <VStack height={ '40px' } width={ '40px' } ref={ menuRefs } >
                                                                            <Pressable
                                                                                p={ '10px' }
                                                                                shadow={ 1 }
                                                                                bgColor={ 'gray.100' }
                                                                                _hover={ { bgColor: 'gray.50' } }
                                                                                rounded={ 'full' }
                                                                                marginRight={ '3px' }
                                                                                onPress={ () => setOrgOpen( !isOrgOpen ) }
                                                                            >
                                                                                <MoreHorizontalIcon
                                                                                    height={ '4px' } width={ '16px' } size={ 'md' } color={ "#000000" } style={ { transform: [ { rotate: '90deg' } ] } }

                                                                                />
                                                                            </Pressable>
                                                                            { isOrgOpen && ( <Button
                                                                                backgroundColor={ 'gray.100' }
                                                                                shadow={ 9 }
                                                                                width={ '100px' }
                                                                                position={ 'absolute' }
                                                                                top={ '47px' }
                                                                                right={ '25px' }
                                                                                onPress={ () => handleProfileType( 'Organization' ) }
                                                                            >
                                                                                <Text color={ 'black' }>{ Loc.currentLang.menu.add }</Text>
                                                                            </Button> ) }
                                                                        </VStack> ) }


                                                                </HStack>
                                                            </HStack>
                                                        </HStack>
                                                    </VStack>
                                                    <Divider my={ 2 } zIndex={ -3 } />
                                                    <HStack flexWrap={ 'wrap' } zIndex={ -3 } alignSelf={ 'flex-start' } space={ 3 } p={ 2 }>
                                                        { orgList?.length > 0 &&
                                                            orgList?.map( ( item ) => (
                                                                <Box
                                                                    key={ item.id }
                                                                    zIndex={ -3 }
                                                                    bgColor={ '#fff' }
                                                                    borderWidth={ '1px' }
                                                                    borderColor={ 'AVColor.hovercolor2' }
                                                                    minWidth={ 130 }
                                                                    minHeight={ 150 }
                                                                    borderRadius={ 5 }
                                                                    m={ 2 }
                                                                    shadow={ 1 }
                                                                >
                                                                    { isAdminView && (
                                                                        <Pressable
                                                                            key={ item.id }
                                                                            padding={ 1.5 }
                                                                            rounded={ 'full' }
                                                                            position={ 'absolute' }
                                                                            right={ -10 }
                                                                            top={ -10 }
                                                                            zIndex={ 999 }
                                                                            _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                                                                            backgroundColor={ 'AVColor.buttonBg' }
                                                                            onPress={ () => handleRemove( 'Season', item ) }
                                                                        >
                                                                            <CloseIcon size={ '12px' } color={ 'AVColor.secondaryText' } />
                                                                        </Pressable>
                                                                    ) }
                                                                    <VStack alignItems={ 'center' } height={ '100%' } width={ '100%' }>
                                                                        <Pressable
                                                                            key={ item.id }
                                                                            height={ '100%' }
                                                                            justifyContent={ 'center' }
                                                                            width={ '100%' }
                                                                            _hover={ { bgColor: '#EEEEEE' } }
                                                                            onPress={ () =>
                                                                            {
                                                                                item?.type == 'Person'
                                                                                    ? navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                                                        state: {
                                                                                            user: {
                                                                                                id: item?.id,
                                                                                                pk: item?.pk,
                                                                                                region: item?.region,
                                                                                                type: item?.type,
                                                                                                name: item?.title,
                                                                                            },
                                                                                        },
                                                                                    } )
                                                                                    : item?.type == 'PlayerOrg'
                                                                                        ?
                                                                                        navigate( `/season/${ item?.id }/${ item?.region }`, { state: { isCreate: false, team: item } } )
                                                                                        : item?.type == 'Team' ? navigate( `/teams/${ item?.id }/${ item?.region }`, { state: { isCreate: false, team: item } } ) :
                                                                                            navigate( `/teams/${ item?.id }/${ item?.region }`, { state: { isCreate: false, team: item } } )
                                                                                setIsOrganization( false );

                                                                            } }
                                                                        >
                                                                            { item?.thumb && (
                                                                                <Box
                                                                                    bgColor={ '#FF6600' }
                                                                                    borderRadius={ [ 'full' ] }
                                                                                    width={ '70px' }
                                                                                    height={ '70px' }
                                                                                    alignSelf={ 'center' }
                                                                                    p={ 0 }
                                                                                >
                                                                                    <Image
                                                                                        source={ {
                                                                                            uri: AV.Media.getThumb( item ),
                                                                                        } }
                                                                                        borderRadius={ [ 'full' ] }
                                                                                        //top={ "2" }
                                                                                        width={ '100%' }
                                                                                        height={ [ '100%' ] }
                                                                                    />
                                                                                </Box>
                                                                            ) }
                                                                            { !item?.thumb && (
                                                                                <Box
                                                                                    alignItems={ 'center' }
                                                                                >
                                                                                    <Profile height={ '70' } width={ '70' } color={ "#32AE78" } />
                                                                                </Box>
                                                                            ) }
                                                                            <Tooltip label={ item.title } openDelay={ 500 }>
                                                                                <Pressable
                                                                                    onPress={ () =>
                                                                                    {
                                                                                        navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                                                            state: {
                                                                                                user: {
                                                                                                    id: item?.id,
                                                                                                    pk: item?.pk,
                                                                                                    region: item?.region,
                                                                                                    type: item?.type,
                                                                                                    name: item?.title,
                                                                                                },
                                                                                            },
                                                                                        } );
                                                                                    } }
                                                                                >
                                                                                    <Text
                                                                                        marginTop={ 2 }
                                                                                        left={ '2px' }
                                                                                        alignItems='center'
                                                                                        width='120px'
                                                                                        numberOfLines={ 2 }
                                                                                        textAlign='center'
                                                                                    >
                                                                                        { item.title }
                                                                                    </Text>
                                                                                </Pressable>
                                                                            </Tooltip>
                                                                        </Pressable>
                                                                    </VStack>
                                                                </Box>
                                                            ) ) }
                                                    </HStack>
                                                    { ( orgList?.length == 0 || orgList?.length == undefined ) && (
                                                        <HStack zIndex={ -3 } width={ '100%' } top={ isMobile ? '20px' : '0px' }
                                                            padding={ isMobile ? '10px' : '10px' }
                                                            marginTop={ isMobile ? '-10px' : '0px' } space={ isMobile ? 2 : 2 }>
                                                            <img
                                                                src={ inform }
                                                                style={ {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                } }
                                                            />
                                                            { helptext }
                                                        </HStack>
                                                    ) }
                                                </Box>
                                            </>
                                        ) }
                                        {/* feedback page*/ }
                                        { isFeebackGroup && !isShowEdit && (
                                            <Box
                                                zIndex={ isDropDown ? -3 : '' }
                                                minHeight={ '340px' }
                                                borderRadius={ 5 }
                                                backgroundColor={ 'AVColor.white' }
                                                shadow={ 1 }
                                                flex={ 1 }
                                                mb={ 3 }
                                            >
                                                <VStack >
                                                    <HStack
                                                        flexWrap={ 'wrap' }
                                                        zIndex={ -2 }
                                                        alignSelf={ 'flex-start' }
                                                        space={ 3 }
                                                        p={ 2 }
                                                    >
                                                        { isshowteamsList?.length > 0 &&
                                                            isshowteamsList?.map( ( item, i ) => (
                                                                <Box
                                                                    key={ item.id + i }
                                                                    bgColor={ '#fff' }
                                                                    borderWidth={ '1px' }
                                                                    borderColor={ 'AVColor.hovercolor2' }
                                                                    minWidth={ 130 }
                                                                    minHeight={ 175 }
                                                                    borderRadius={ 5 }
                                                                    marginLeft={ 2 }
                                                                    marginY={ 2 }
                                                                    py={ 3 }
                                                                    shadow={ 1 }
                                                                >
                                                                    <VStack key={ item?.id } alignItems={ 'flex-start' } padding={ 2 } height={ '100%' }
                                                                        width={ '100%' }>
                                                                        <Pressable
                                                                            height={ '100%' }
                                                                            width={ '100%' }
                                                                            key={ item.id }
                                                                            _hover={ { bgColor: 'gray.100' } }
                                                                            p={ 2 }
                                                                            borderRadius={ 5 }
                                                                            minWidth={ 120 }
                                                                            onPress={ () =>
                                                                            {
                                                                                navigate( `/teams/${ item?.id }/${ item?.region }`, {
                                                                                    state: { isCreate: false, team: item, type: 'Group' },
                                                                                } );
                                                                                setIsFeedBackGroup( false );
                                                                            } }
                                                                        >
                                                                            { item?.thumb && (
                                                                                <Box
                                                                                    bgColor={ '#FF6600' }
                                                                                    borderRadius={ [ 'full' ] }
                                                                                    width={ '60px' }
                                                                                    height={ '60px' }
                                                                                    alignSelf={ 'center' }
                                                                                >
                                                                                    <Image
                                                                                        source={ {
                                                                                            uri: AV.Media.getThumb( item ),
                                                                                        } }
                                                                                        borderRadius={ [ 'full' ] }
                                                                                        width={ '100%' }
                                                                                        height={ [ '100%' ] }
                                                                                    />
                                                                                </Box>
                                                                            ) }
                                                                            { !item?.thumb && (
                                                                                <Box
                                                                                    alignItems={ 'center' }
                                                                                >
                                                                                    <Profile height={ '60' } width={ '60' } color={ "#32AE78" } />
                                                                                </Box>
                                                                            ) }
                                                                            <Tooltip label={ item.title } openDelay={ 500 }>
                                                                                <Pressable
                                                                                    onPress={ () =>
                                                                                    {
                                                                                        navigate( `/teams/${ item?.id }/${ item?.region }`, {
                                                                                            state: { isCreate: false, team: item },
                                                                                        } );
                                                                                    } }
                                                                                >
                                                                                    <Text
                                                                                        numberOfLines={ 1 }
                                                                                        maxWidth={ '100px' }
                                                                                        alignSelf={ 'center' }
                                                                                        fontWeight={ '500' }
                                                                                    >
                                                                                        { item.title }
                                                                                    </Text>
                                                                                </Pressable>
                                                                            </Tooltip>
                                                                        </Pressable>
                                                                    </VStack>
                                                                </Box>
                                                            ) ) }
                                                    </HStack>
                                                </VStack>
                                            </Box>
                                        ) }
                                        {/* member page*/ }
                                        { isMembersPage && !isShowEdit && (
                                            <Box
                                                minHeight={ '340px' }
                                                borderRadius={ 5 }
                                                backgroundColor={ 'AVColor.white' }
                                                shadow={ 1 }
                                                flex={ 1 }
                                                mb={ 3 }
                                            >
                                                <VStack>
                                                    <HStack alignItems={ 'center' } justifyContent={ 'space-between' }>
                                                        <Text
                                                            fontWeight={ '500' }
                                                            marginLeft={ '15px' }
                                                            fontSize={ '22px' }
                                                            color={ 'AVColor.primary' }
                                                            py={ '12px' }
                                                        >
                                                            { leagueTeamFK?.type == 'School' ? Loc.currentLang.menu.staff : ( leagueTeamFK?.type == 'PlayerOrg' || leagueTeamFK?.subType == 'Class' ) && leagueTeamFK?.subType !== 'MusicGroup'  ? Loc.currentLang.menu.parents : leagueTeamFK?.type == 'Neighborhood' ? Loc.currentLang.menu.neighbors : leagueTeamFK?.type == 'PlayerOrg' && leagueTeamFK?.subType == 'MusicGroup' ? Loc.currentLang.menu.members : Loc.currentLang.menu.members}                                                        </Text>

                                                        <HStack alignItems={ 'center' } pr={ 2 }>

                                                            <HStack>
                                                                <Tooltip label={ helptext } w={ 200 }>
                                                                    <Box
                                                                        marginRight={ '10px' }
                                                                        p={ '9px' }
                                                                        display={ memberList?.length > 0 ? '' : 'none' }
                                                                        shadow={ 1 }
                                                                        backgroundColor={ 'AVColor.liteBg' }
                                                                        rounded={ 'full' }
                                                                    >
                                                                        <img
                                                                            src={ helping }
                                                                            height={ '17px' }
                                                                            width={ '17px' }
                                                                        />
                                                                    </Box>
                                                                </Tooltip>
                                                                { isAdminView && memberList?.length > 0 && ( <Button
                                                                    rounded={ 'full' }
                                                                    bgColor={ isLoad ? 'gray.400' :'gray.100' }
                                                                    _hover={ { bgColor: 'gray.50' } }
                                                                    shadow={ 1 }
                                                                    p={ '10px' }
                                                                   
                                                                    marginRight={'10px'}
                                                                    isLoading={ isLoad }
                                                                    onPress={ () => HandleGroupChat( staffToMember ? 'M' : 'W' ) }
                                                                >
                                                                    {
                                                                        <GroupChatIcon size={ 23 } color={ 'rgba(0, 0, 0, 0.5)' } />
                                                                     }
                                                                </Button> ) }
                                                                { isAdminView && (
                                                                    <Button
                                                                        rounded={ 'full' }
                                                                        bgColor={ 'gray.100' }
                                                                        _hover={ { bgColor: 'gray.50' } }
                                                                        shadow={ 1 }
                                                                        p={ '10px' }
                                                                        disabled={ isCreate }
                                                                        onPress={ () => handleProfileType( 'Member' ) }
                                                                    >
                                                                        <AddIcon size={ '20px' } color={ '#32AE78' } />
                                                                    </Button> ) }
                                                            </HStack>

                                                            <Pressable
                                                                onPress={ () =>
                                                                {
                                                                    //  handleEditPopup();
                                                                    //  setShowEditPopup(!showEditPopup);
                                                                } }
                                                            >
                                                                {/*    <MoreHorizontalIcon size={ 'md' } color={ 'AVColor.grey' } />*/ }
                                                            </Pressable>
                                                            { showEditPopup && (
                                                                <Box
                                                                    position={ 'absolute' }
                                                                    right={ 0 }
                                                                    top={ '43px' }
                                                                    background={ '#f2f2f2' }
                                                                    borderRadius={ '5px' }
                                                                    height='40px'
                                                                    width='150px'
                                                                >
                                                                    {/*<Pressable*/ }
                                                                    {/*    onPress={handleRole}*/ }
                                                                    {/*    top='5px'*/ }
                                                                    {/*>*/ }
                                                                    {/*    <Text margin={'5px'} alignSelf='center'>*/ }
                                                                    {/*        {Loc.currentLang.menu.manageRoles}*/ }
                                                                    {/*    </Text>*/ }
                                                                    {/*</Pressable>*/ }
                                                                </Box>
                                                            ) }
                                                        </HStack>
                                                    </HStack>
                                                </VStack>
                                                <Divider my={ 2 } zIndex={ -3 } />
                                                <HStack flexWrap={ 'wrap' } alignSelf={ 'flex-start' }>
                                                    { memberList?.length > 0 &&
                                                        memberList?.map( ( item ) => (
                                                            <MemberCard
                                                                key={ item.id }
                                                                item={ item }
                                                                currentUser={ currentUser }
                                                                isAdminView={ isAdminView }
                                                                isOpen={ isOpen }
                                                                isMemberData={ isMemberData }
                                                                setIsOpen={ setIsOpen }
                                                                setIsMemberData={ setIsMemberData }
                                                                HandleSlience={ HandleSlience }
                                                                HandleUnSlience={ HandleUnSlience }
                                                                HandleRemoveMembers={ HandleRemoveMembers }
                                                                isSlienceLoad={ isSlienceLoad }
                                                                unSlienceLoad={ unSlienceLoad }
                                                                isLoading={ isLoading }
                                                                navigate={ navigate }
                                                                menuRefs={ menuRefs }
                                                                handleChat={ handleChat }
                                                            />
                                                        ) ) }
                                                </HStack>
                                                { ( memberList?.length == 0 || memberList?.length == undefined ) && (
                                                    <HStack width={ '100%' } top={ isMobile ? '20px' : '0px' }
                                                        padding={ isMobile ? '10px' : '10px' }
                                                        marginTop={ isMobile ? '-10px' : '0px' } space={ isMobile ? 2 : 2 }>
                                                        <img
                                                            src={ inform }
                                                            style={ {
                                                                width: '20px',
                                                                height: '20px',
                                                            } }
                                                        />
                                                        { helptext }
                                                    </HStack>
                                                ) }
                                            </Box>
                                        ) }
                                        {/* photo page*/ }
                                        { isPhotoPage && !isShowEdit && (
                                            <Createalbumpopup
                                                isDocumentPage={ isDocumentPage }
                                                Parentdata={ leagueTeamFK }
                                                isOwner={ isAdminView }
                                                isPhotoPage={ isPhotoPage }
                                                albumlist={ albumlist }
                                                setalbumlist={ setalbumlist }
                                                filesList={ filesList }
                                                setfilesList={ setfilesList } filesListany={ undefined }
                                                editAccess={ editAccess }
                                            />
                                        ) }
                                        {/* document page*/ }
                                        { isDocumentPage && !isShowEdit && !isCreate && (
                                            <Createalbumpopup
                                                isDocumentPage={ isDocumentPage }
                                                Parentdata={ leagueTeamFK }
                                                isPhotoPage={ isPhotoPage }
                                                albumlist={ albumlist }
                                                isOwner={ isAdminView }
                                                setalbumlist={ setalbumlist }
                                                filesList={ filesList }
                                                setfilesList={ setfilesList }
                                                filesListany={ undefined }
                                                editAccess={ editAccess }
                                            />
                                        ) }
                                        {/* occurence page*/ }
                                        { isOccurencePage && !isShowEdit && (
                                            <Box
                                                width={ '100%' }
                                                minHeight={ '340px' }
                                                marginRight={ 4 }
                                                p={ 2 }
                                                h='auto'
                                                shadow={ 1 }
                                                bgColor='#fff'
                                                rounded='lg'
                                            >
                                                <HStack
                                                    flexWrap={ 'wrap' }>
                                                    <RenderSeriesEvents
                                                        seriesEventsList={ seriesEventsList }
                                                        event={ leagueTeamFK }
                                                    />
                                                </HStack>
                                               
                                            </Box>
                                        ) }
                                        {/* feeds page*/ }
                                        <Box

                                            mt={ [ 5 ] }
                                            justifyContent={ 'space-between' }
                                            maxWidth={ [ 1063 ] }
                                            paddingRight={ 0 }
                                            height={ 'auto' }
                                            marginBottom={ '30px' }
                                        >
                                            { ( !isShowEdit && isTeamsPage && leagueTeamFK && leagueTeamFK?.status !== 'Deleted' ) && (
                                                <>
                                                    <VStack
                                                        borderRadius={ 12 }
                                                        bgColor={ '#fff' }
                                                        maxWidth={ [ 1052 ] }
                                                        flex={ 1 }
                                                        ref={ rightRef }
                                                    >
                                                        <VStack alignItems={ 'spece-around' } marginTop={ '-20px' }>
                                                            <AVFeeds
                                                                page={ 'Profile' }
                                                                userInfo={ leagueTeamFK }
                                                                iscommentAccess={ isStaffentity || isMemberEntity || isPlayerEntity }
                                                                isOwner={ isAdminView ? isAdminView : leagueTeamFK?.staff?.filter( ( x ) => x?.id === currentUser?.id )?.length > 0 ? true : false }
                                                                id={ leagueTeamFK?.id }
                                                                isFollowing={ isFollowing }
                                                                Postdata={ leagueTeamFK }
                                                                offW={ fixedWidth }
                                                                { ...props }
                                                            />
                                                        </VStack>
                                                    </VStack>
                                                </>
                                            ) }
                                        </Box>
                                    </VStack>
                                </VStack>
                            </HStack>
                        </HStack>
                    </>
                }
            </AVBody>
            { showFileuploadModal && (
                /* FileUploadModal Popup*/
                <Box
                    position='fixed'
                    bgColor={ 'AVColor.overlay2' }
                    bottom={ 0 }
                    right={ 0 }
                    top={ 0 }
                    left={ 0 }
                    zIndex={ 99 }
                >
                    <Modal.Content
                        bgColor={ '#fff' }
                        maxWidth={ '100%' }
                        width={ '50%' }
                        padding={ '24px' }
                        minHeight={ [ 500 ] }
                        left={ '22%' }
                        top={ '20%' }
                    >
                        <HStack fontSize={ '24px' } fontWeight={ 400 } marginBottom={ '24px' }>
                            { isStaff ? Loc.currentLang.menu.staff : isPlayer ? Loc.currentLang.menu.players : leagueTeamFK?.type == 'PlayerOrg' || leagueTeamFK?.subType == 'Class' ? Loc.currentLang.menu.parents : Loc.currentLang.menu.members }
                        </HStack>
                        <VStack alignItems={ 'center' } space={ 4 } width={ 'auto' } overflowY={ 'auto' } height={ '334px' } justifyContent={ 'flex-start' }>
                            <HStack flexWrap={ 'wrap' } alignSelf={ 'flex-start' } space={ 6 } zIndex={ -1 } justifyContent={ 'center' } height={ '145px' } width={ '98%' }>
                                { contactList?.map( ( item ) => (
                                    <>
                                        <HStack
                                            alignItems={ 'center' }
                                            borderStyle={ 'solid' }
                                            borderColor={ 'rgba(0, 0, 0, 0.05)' }
                                            borderRadius={ '5px' }
                                            width={ '100%' }
                                            height={ '40px' }

                                        >
                                            <HStack width={ '85%' }>
                                                <HStack marginRight={ '10px' } width={ '30%' }>
                                                    { !isEditPlayerDetails || menu !== item ? (
                                                        <Text
                                                            fontWeight='500'
                                                            numberOfLines={ 1 }
                                                            ellipsizeMode={ 'tail' }
                                                            fontSize={ '16px' }
                                                            textAlign={ 'left' }
                                                            color={ '#000000' }
                                                            width={ '100%' }
                                                            textTransform={ 'capitalize' }
                                                        >
                                                            { item?.name }
                                                        </Text>
                                                    ) : (
                                                        <Box width={ '80%' } marginTop={ '3px' }>
                                                            <Input
                                                                textAlign={ 'left' }
                                                                fontSize={ '16px' }
                                                                paddingLeft={ '0px' }
                                                                paddingRight={ '0px' }
                                                                value={ newname }
                                                                defaultValue={ menu?.name }
                                                                onChangeText={ ( e ) => setnewname( e ) }
                                                            />
                                                        </Box>
                                                    ) }
                                                </HStack>
                                                <HStack width={ '48%' } marginRight={ '10px' }>
                                                    { !isEditPlayerDetails || menu !== item ? (
                                                        <Text
                                                            fontSize={ '16px' }
                                                            width={ '100%' }
                                                            numberOfLines={ 1 }
                                                            ellipsizeMode={ 'tail' }
                                                            textAlign={ 'left' }
                                                            textTransform={ 'capitalize' }
                                                        >
                                                            { item?.email }
                                                        </Text>
                                                    ) : (
                                                        <Box width={ '80%' } marginTop={ '3px' }>
                                                            <Input
                                                                textAlign={ 'left' }
                                                                fontSize={ '16px' }
                                                                paddingLeft={ '0px' }
                                                                paddingRight={ '0px' }
                                                                value={ newmail }
                                                                defaultValue={ menu?.email }
                                                                onChangeText={ ( e ) => setnewmail( e ) }
                                                            />
                                                        </Box>
                                                    ) }
                                                </HStack>
                                                { isStaff && (
                                                    <HStack width={ '16%' } marginRight={ '10px' }>
                                                        { !isEditPlayerDetails || menu !== item ? (
                                                            <Text
                                                                fontSize={ '16px' }
                                                                width={ '100%' }
                                                                textAlign={ 'left' }
                                                                color={ '#000000' }
                                                            >
                                                                { isStaff
                                                                    ? item?.role?.name
                                                                        ? item?.role?.name
                                                                        : leagueTeamFK?.roles[ 0 ]?.name
                                                                    : '' }
                                                            </Text>
                                                        ) : (
                                                            <HStack width={ '65%' } marginTop={ '10px' }>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        { Loc.currentLang.menu.roles }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={ staffRole }
                                                                        label={ Loc.currentLang.menu.roles }
                                                                        onChange={ ( e ) => setStaffRole( e.target.value ) }
                                                                        style={ isMobile ? customSelectStyle : customSelectStyleeeess }
                                                                    >
                                                                        { leagueTeamFK?.roles?.map( ( option ) => (
                                                                            <MenuItem key={ option?.name } value={ option?.name }>
                                                                                { option?.name }
                                                                            </MenuItem>
                                                                        ) ) }
                                                                    </Select>
                                                                </FormControl>
                                                            </HStack>
                                                        ) }
                                                    </HStack>
                                                ) }
                                            </HStack>
                                            <HStack position={ 'absolute' } right={ 0 } space={ 3 }>
                                                { ( !isEditPlayerDetails || menu !== item ) && (
                                                    <Button
                                                        backgroundColor={ 'white' }
                                                        width={ '50px' }
                                                        height={ '40px' }
                                                        isDisabled={ isLoading }
                                                        onPress={ () =>
                                                        {
                                                            setmenu( item );
                                                            setnewmail( item?.email )
                                                            setStaffRole( item?.role?.name ? item?.role?.name : leagueTeamFK?.roles[ 0 ]?.name );
                                                            setnewname( item?.name )
                                                            setIsEditPlayerDetails( true );
                                                        } }
                                                    >
                                                        <HStack>
                                                            <Text
                                                                fontSize={ '16px' }
                                                                underline
                                                                textAlign={ 'center' }
                                                                color={ '#00A7BC' }
                                                            >
                                                                { Loc.currentLang.menu.edit }
                                                            </Text>
                                                        </HStack>
                                                    </Button>
                                                ) }{ ' ' }
                                                { isEditPlayerDetails && menu === item && (
                                                    <Button
                                                        backgroundColor={ 'white' }
                                                        width={ '50px' }
                                                        height={ '40px' }
                                                        onPress={ () =>
                                                        {
                                                            handleUpdateStaff( item );
                                                        } }
                                                    >
                                                        <HStack>
                                                            <Text
                                                                fontSize={ '16px' }

                                                                underline
                                                                textAlign={ 'center' }
                                                                color={ '#00A7BC' }
                                                            >
                                                                { Loc.currentLang.menu.update }
                                                            </Text>
                                                        </HStack>
                                                    </Button>
                                                ) }
                                                <Button backgroundColor={ 'white' } height={ '40px' } width={ '50px' } isDisabled={ isLoading } onPress={ () => handleRemoveStaff( item ) }>
                                                    <HStack>
                                                        <Text
                                                            fontSize={ '16px' }
                                                            underline

                                                            textAlign={ 'center' }
                                                            color={ '#DF2827' }
                                                        >
                                                            { Loc.currentLang.menu.delete }
                                                        </Text>
                                                    </HStack>
                                                </Button>
                                            </HStack>
                                        </HStack>
                                        <Divider marginTop={ '5px' } />
                                    </>
                                ) ) }
                            </HStack>

                        </VStack>
                        <HStack
                            width={ '100%' }
                            space={ 5 }
                            flex={ 1 }
                            justifyContent={ 'flex-end' }
                            alignItems={ 'top' }
                            pr={ 5 }
                            top={ '88%' }
                            position='absolute'
                            right='10px'
                            maxHeight={ '200px' }
                            padding={ 0 }
                            background={ 'none' }
                        >
                            <Button
                                background={ '#EEEEEE' }
                                borderRadius={ 15 }
                                px={ 16 }
                                height={ '40px' }
                                width={ '40px' }
                                rounded='full'
                                onPress={ () =>
                                {
                                    setshowFileuploadModal( false );
                                } }
                            >
                                <Text color={ '#000000' }>{ Loc.currentLang.menu.cancel }</Text>
                            </Button>
                            <Button
                                bgColor={ 'AVColor.primary' }
                                height={ '40px' }
                                isLoading={ isLoading }
                                isLoadingText={ 'Saving..' }
                                width={ '40px' }
                                borderRadius={ 15 }
                                px={ 16 }
                                rounded='full'
                                onPress={ handleAddContactList }
                            >
                                <Text color={ '#ffffff' }>{ Loc.currentLang.menu.save }</Text>
                            </Button>
                        </HStack>
                    </Modal.Content>
                </Box>
            ) }
            { staffReq && (
                /*Staff req popup*/
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 } zIndex={ 9999 }>
                    <div
                        className={ `create_post_popup ${ mobile && 'create_post_popup_mobile' }` }
                        style={ {
                            width: mobile ? dimensions.width - 15 : '600px',
                            height: 'auto',
                            marginTop: '12px',
                        } }
                    >
                        <VStack height={ '100%' } width={ '100%' } padding={ '24px' }>
                            <HStack>
                                <Text fontWeight={ 400 } fontSize={ '30px' }>Staff Request</Text>
                            </HStack>
                            <HStack marginY={ '24px' }>
                                <Text fontWeight={ 500 } fontSize={ '16px' }>Staff request for submission</Text>
                            </HStack>
                            <Box>
                                <AVInputtext
                                    type={ 'text' }
                                    minRows='5'
                                    size='medium'
                                    multiline={ true }
                                    maxLength={ undefined }
                                    onChange={ ( e ) => setIsStaffDesc( e?.target?.value ) }
                                    defaultValue={ undefined }
                                    width={ undefined } value={ StaffDesc }
                                    height={ '60px' }
                                    label={ 'Add a Description' }
                                    placeholder={ undefined }
                                />
                            </Box>
                            <HStack justifyContent={ 'center' } top={ '65%' } space={ '24px' } marginTop={ '24px' }>
                                <Button
                                    width={ '20%' }
                                    height={ '40px' }
                                    borderRadius={ '19px' }
                                    backgroundColor={ '#e5e5e5' }
                                    onPress={ () => { setIsStaffReq( false ); setIsStaffDesc( '' ) } }
                                >
                                    <Text color={ 'rgba(0,0,0,0.5)' } fontWeight={ 500 } fontSize={ [ 14, 16 ] }>
                                        CANCEL
                                    </Text>
                                </Button>
                                <Button
                                    width={ '22%' }
                                    height={ '40px' }
                                    borderRadius={ '19px' }
                                    backgroundColor={ 'AVColor.primary' }
                                    isLoading={ isLoader }
                                    isDisabled={ !StaffDesc }
                                    onPress={ () => handlestaffandorgReq() }
                                >
                                    <Text
                                        color={ 'white' }
                                        fontWeight={ 500 }
                                        fontSize={ [ 14, 16 ] }
                                    >
                                        SUBMIT
                                    </Text>
                                </Button>
                            </HStack>
                        </VStack>
                    </div>
                </Box> ) }
            { childOrgReq && (
                /*Child org req popup*/
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 } zIndex={ 9999 }>
                    <div
                        className={ `create_post_popup ${ mobile && 'create_post_popup_mobile' }` }
                        style={ {
                            width: mobile ? dimensions.width - 15 : '600px',
                            height: 'auto',
                            marginTop: '12px',
                        } }
                    >
                        <VStack height={ '100%' } width={ '100%' } padding={ '24px' }>
                            <HStack>
                                <Text fontWeight={ 400 } fontSize={ '30px' }>Add child organization</Text>
                            </HStack>
                            <HStack marginY={ '24px' }>
                                <Text fontWeight={ 500 } fontSize={ '16px' }>Request for adding a child organization</Text>
                            </HStack>
                            <Box marginBottom={ '24px' }>
                                <AVInputtext
                                    type={ 'text' }
                                    label={ 'child org One' }
                                    maxLength={ 50 }
                                    onChange={ ( e ) => searchOrgs( e?.target?.value ) }
                                    defaultValue={ undefined }
                                    width={ undefined } value={ childOrgOne }
                                    height={ undefined }
                                    minRows={ undefined }
                                    multiline={ undefined }
                                    size={ undefined }
                                    placeholder={ undefined }
                                />
                            </Box>
                            { ( childOrgOne?.length > 0 && isDropList ) &&
                                selctChildOrg?.map( ( item ) => ( <VStack
                                    width={ '300px' }
                                    height={ 'auto' }
                                    shadow={ 2 }
                                    padding={ '24px' }
                                    background={ '#FFFFFF' }
                                    position={ 'absolute' }
                                    top={ '50%' }
                                    zIndex={ 999 }
                                    borderRadius={ '6px' }
                                >
                                    <Pressable
                                        width={ '250px' }
                                        height={ '36px' }
                                        alignItems={ 'center' }
                                        justifyContent={ 'center' }
                                        onPress={ () =>
                                        {
                                            setChildOrg( item?.title );
                                            setChildorgId( item );
                                            setIsDropList( false )
                                        } }
                                    >
                                        <Text
                                            fontSize={ '14px' }
                                            fontFamily={ 'Roboto' }
                                            numberOfLines={ 1 }
                                            width={ '250px' }
                                        >
                                            { item?.title }
                                        </Text>
                                    </Pressable>
                                </VStack> ) )
                            }
                            <Box>
                                <AVInputtext
                                    type={ 'text' }
                                    minRows='5'
                                    size='medium'
                                    multiline={ true }
                                    maxLength={ undefined }
                                    onChange={ ( e ) => setJusti( e?.target?.value ) }
                                    defaultValue={ undefined }
                                    width={ undefined } value={ justi }
                                    height={ '60px' }
                                    label={ 'Justification' }
                                    placeholder={ undefined }
                                />
                            </Box>
                            <HStack justifyContent={ 'center' } top={ '65%' } space={ '24px' } marginTop={ '24px' }>
                                <Button
                                    width={ '20%' }
                                    height={ '40px' }
                                    borderRadius={ '19px' }
                                    backgroundColor={ '#e5e5e5' }
                                    onPress={ () => { setIsChildOrgReq( false ); setJusti( '' ); setChildOrg( '' ); setChildorgId( '' ); } }
                                >
                                    <Text color={ 'rgba(0,0,0,0.5)' } fontWeight={ 500 } fontSize={ [ 14, 16 ] }>
                                        CANCEL
                                    </Text>
                                </Button>
                                <Button
                                    width={ '22%' }
                                    height={ '40px' }
                                    borderRadius={ '19px' }
                                    backgroundColor={ 'AVColor.primary' }
                                    isDisabled={ !childOrgOne || !justi }
                                    isLoading={ isLoader }
                                    onPress={ () => handlestaffandorgReq() }
                                >
                                    <Text
                                        color={ 'white' }
                                        fontWeight={ 500 }
                                        fontSize={ [ 14, 16 ] }
                                    >
                                        SUBMIT
                                    </Text>
                                </Button>
                            </HStack>
                        </VStack>
                    </div>
                </Box> ) }
            { isImagePopup &&
                /*View Map popup*/
                (
                    <Box
                        position={ 'fixed' }
                        top={ 0 }
                        bottom={ 0 }
                        left={ 0 }
                        right={ 0 }
                        display={ 'flex' }
                        alignItems={ 'center' }
                        justifyContent={ 'center' }
                        backgroundColor={ 'rgba(0, 0, 0, 0.25)' }
                        zIndex={ 9999 }
                    >
                        <div className={ `show_unauthorized_details` }>
                            <HStack
                                alignItems={ 'center' }
                                justifyContent={ 'flex-end' }
                                borderBottomColor={ 'AVColor.grey' }
                                px={ 5 }
                                py={ 3 }
                                zIndex={ 99 }
                                width={ isMobile ? '85%' : '' }
                            >
                                <AVCancel color={ undefined } size={ undefined } callback={ () => setIsImagePopup( false ) } />
                            </HStack>
                            <HStack width={ isMobile ? '90%' : '100%' } height={ '100%' } space={ 3 }>
                                { leagueTeamFK?.location?.siteMap?.type?.startsWith( 'image' ) ?
                                    <Pressable
                                        width={ isMobile ? '90%' : '100%' } height={ '80%' }
                                        onPress={ () => { openPreview( leagueTeamFK?.location?.siteMap, allImages ); setIsImagePopup( false ) } }
                                    >
                                        <img
                                            src={ Media.getURL( leagueTeamFK?.location?.siteMap ) }
                                            alt='sitemap'
                                            height={ '100%' }
                                            width={ '100%' }
                                            style={ { objectFit: 'contain' } }
                                        />
                                    </Pressable> :
                                    <Pressable
                                        width={ isMobile ? '90%' : '100%' } height={ '80%' }
                                        onPress={ () => { handledocument( leagueTeamFK?.location?.siteMap ) } }
                                    >
                                        <img
                                            //@ts-ignore
                                            src={
                                                ( leagueTeamFK?.location?.siteMap?.type === 'application/pdf' && settings.pdf ) ||
                                                ( ( leagueTeamFK?.location?.siteMap?.type === 'application/msword' || leagueTeamFK?.location?.siteMap?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ) && settings.word ) ||
                                                ( ( leagueTeamFK?.location?.siteMap?.type === 'application/vnd.ms-powerpoint' || leagueTeamFK?.location?.siteMap?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ) && settings.powerpoint ) ||
                                                ( ( leagueTeamFK?.location?.siteMap?.type?.type === 'application/vnd.ms-excel' || leagueTeamFK?.location?.siteMap?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ) && settings.excel ) ||
                                                ( ( leagueTeamFK?.location?.siteMap?.type === 'text/plain' || leagueTeamFK?.location?.siteMap?.type === 'text/plain' ) && settings.file ) ||
                                                settings.file
                                            }
                                            style={ { margin: '1px', objectFit: 'contain', height: '100%', width: '100%' } }
                                        />
                                    </Pressable> }

                            </HStack>
                        </div>
                    </Box>

                ) }
            { showAddProfileModal && (
                /*Staff,member,player,participant popup*/
                <AVPersonaModal isMember={ isMember } isParticipant={ isParticipant } setShowAddProfileModal={ setShowAddProfileModal } isStaff={ isStaff } Entity={ leagueTeamFK } setEntity={ setLeagueTeamFK } setcontactList={ setcontactList } setshowFileuploadModal={ setshowFileuploadModal } setStaffList={ setStaffList } setmemberList={ setmemberList } isPlayer={ isPlayer } setPlayersList={ setPlayersList } setIsParticipantList={ setIsParticipantList } setFilteredParticipantList={ setFilteredParticipantList } showAddProfileModal={ showAddProfileModal } isTeam={ isTeam } searchTypes={ searchTypes }
                    setOrgList={
                        setOrgList } setGroupList={ setGroupList } isEditEntireSeries={ isEditEntireSeries } />
            ) }
            { isConfirmDelete &&
                /*Confirmation popup*/
                <AVConfirmationModal showconfirmationPopup={ isConfirmDelete } setshowconfirmationPopup={ setisConfirmDelete }
                    Confirmationtext={ Confirmationtext } type={ selectType } item={ itemSelect } function={ selectType == 'RestoreAccount' ? handleRestore :
                        selectType == 'DeleteSeason' ? RemoveSeason :
                            selectType == 'DeleteStaff' ? RemoveStaffs : selectType == "DeleteParticipant" ? RemoveParticiapnt : selectType == "DeletePlayer" ? handleRemoveplayers : selectType == 'RemoveResource' ? handleRemoveResourses : selectType == 'DeleteMember' ? handleDeleteMember : selectType == 'DeleteGroup' ? handleDeleteGroup : '' } isLoader={ isLoader } /> }
            { showAddResourceModal && (
                /*Resources Popup*/
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } top={ [ `${ 65 - 1 }px` ] } right={ 0 } left={ 0 }>
                    {/* body */ }
                    <div style={ {
                        position: 'fixed',
                        top: '53%',
                        left: '50%',
                        transform: 'translate(-53%, -50%)',
                        background: '#ffffff',
                        boxShadow:
                            '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
                        width: isMobile ? '88%' : '48%',
                        borderRadius: '8px',
                        minHeight: '400px',
                    } }>
                        <VStack flex={ 1 } minHeight={ '450px' } space={ 3 }>
                            {/* Header */ }
                            <HStack
                                alignItems={ 'center' }
                                justifyContent={ 'space-between' }
                                borderBottomColor={ 'AVColor.grey' }
                                px={ 5 }
                                py={ 3 }

                            >
                                <Text fontSize={ 24 }>{ showResourceModal ? "View Item" : editresourses ? Loc.currentLang.menu.editItem : Loc.currentLang.menu.addItem }</Text>
                                { !editresourses && !showResourceModal && ( <Tooltip label={ Loc.currentLang.helpText.event.resourcesTab } w={ 200 } openDelay={ 100 }>
                                    <Box position={ 'absolute' } right={ '60px' }
                                        p={ '9px' }
                                        backgroundColor={ 'AVColor.liteBg' }
                                        rounded={ 'full' }>

                                        <img src={ helping } height={ '17px' } width={ '17px' } />
                                    </Box>
                                </Tooltip> ) }
                                <AVCancel color={ undefined } size={ undefined } callback={ () => setshowAddResourceModal( false ) } />
                            </HStack>


                            <Divider />
                            {/* modal-Body */ }
                            <VStack px={ 5 } py={ 3 } space={ 3 } justifyContent={ 'center' } alignItems={ 'center' }>
                                <HStack justifyContent={ 'center' } width={ '100%' }>
                                    <Box
                                        width={ '100%' }
                                    >
                                        <AVInputtext
                                            type={ 'text' }
                                            label={ Loc.currentLang.menu.itemDescription }
                                            onChange={ ( e ) => setdescription( e.target.value ) }
                                            value={ description }
                                            width={ '100%' }
                                            height={ '20px' }
                                            defaultValue={ undefined } maxLength={ 50 } minRows={ undefined } multiline={ undefined } placeholder={ undefined } size={ undefined }
                                        />
                                    </Box>

                                </HStack>
                                <HStack justifyContent={ 'center' } width={ '100%' }>
                                    <Box
                                        width={ '100%' }
                                    >
                                        <AVInputtext
                                            type={ 'text' }
                                            label={ Loc.currentLang.menu.quantity }
                                            onChange={ ( e ) => setquantity( e.target.value.replace( /[^0-9]/g, '' ) ) }
                                            value={ quantity }
                                            width={ '100%' }
                                            height={ '20px' }
                                            maxLength={ 6 }
                                            defaultValue={ undefined } minRows={ undefined } multiline={ undefined } placeholder={ undefined } size={ undefined }
                                        />
                                    </Box>
                                </HStack>
                                <HStack justifyContent={ 'center' } width={ '100%' }>
                                    <Box
                                        width={ '100%' }
                                    >
                                        <AVInputtext
                                            type={ 'text' }
                                            label={ Loc.currentLang.menu.assignToTeamMember }
                                            onChange={ ( e ) => searchPlayerItems( e.target.value ) }
                                            value={ assienTeammember }
                                            width={ '100%' }
                                            height={ '20px' }
                                            defaultValue={ undefined } maxLength={ 50 } minRows={ undefined } multiline={ undefined } placeholder={ undefined } size={ undefined }
                                        />
                                    </Box>
                                </HStack>
                                <HStack width='100%' height='auto' zIndex='9999'>
                                    { filteredResults?.length > 0 && (
                                        <Box
                                            width={ '94%' }
                                            position='absolute'
                                            top={ '-13' }
                                            maxHeight='230px'
                                            minHeight='43px'
                                            bgColor={ [ 'AVColor.white' ] }
                                            background={ '#ffffff' }
                                            borderColor={ 'AVColor.buttonBg' }
                                            shadow='2'
                                            left={ '19px' }
                                            borderRadius='8px'
                                            overflowY='scroll'
                                        >
                                            { filteredResults?.map( ( item, i ) => (
                                                <Box key={ item.id + i }>
                                                    <Pressable

                                                        padding={ '10px' }
                                                        _hover={ { bgColor: 'AVColor.buttonBg' } }
                                                        onPress={ ( e ) => handlePlayerName( item ) }
                                                    >
                                                        <HStack space={ 2 }>
                                                            <ProfilePic width={ 50 } height={ 50 } link={ item } />
                                                            <VStack>
                                                                <Text fontWeight='bold'>{ item.title }</Text>
                                                                {/*<Text fontSize={'12px'}>{item.blurb}</Text>*/ }
                                                            </VStack>
                                                        </HStack>
                                                    </Pressable>
                                                </Box>
                                            ) ) }
                                        </Box>
                                    ) }
                                </HStack>
                                <HStack justifyContent={ 'center' } width={ '100%' }>
                                    <Box
                                        width={ '100%' }
                                    >
                                        <AVInputtext
                                            type={ 'text' }
                                            label={ Loc.currentLang.menu.additionalNote }
                                            onChange={ ( e ) => setnote( e.target.value ) }
                                            value={ note }
                                            width={ '100%' }
                                            height={ '38px' }
                                            defaultValue={ undefined } maxLength={ 500 } minRows={ 6 } multiline={ true } placeholder={ undefined } size={ 'medium' }
                                        />
                                    </Box>
                                </HStack>
                                <HStack
                                    width={ '100%' }
                                    height='100%'
                                    space={ 5 }
                                    flex={ 1 }
                                    justifyContent={ 'flex-end' }
                                    px={ 5 }
                                    m={ 3 }
                                    zIndex={ -1 }
                                >
                                    <AVButtonRoundInvert
                                        btnText={ Loc.currentLang.menu.cancel }
                                        callback={ () =>

                                            setshowAddResourceModal( false )

                                        } px={ undefined } height={ undefined } isLoadingText={ undefined } isDisabled={ undefined } isLoading={ undefined } loading={ undefined } />
                                    { editresourses && <AVButtonRoundInvert
                                        btnText={ Loc.currentLang.menu.removeItem }
                                        loading={ isremoveLoader } isLoadingText={ Loc.currentLang.menu.removingLoad }
                                        callback={ handleRemoveResourses } px={ undefined } height={ undefined } isDisabled={ undefined } isLoading={ undefined } /> }
                                    <AVButtonRoundedFull loading={ isLoader } isLoadingText={ editresourses ? Loc.currentLang.menu.saveLoad : Loc.currentLang.menu.adding } isDisabled={ !quantity || description?.trim()?.length == 0 || assienTeammember?.trim()?.length == 0 } btnText={ editresourses ? Loc.currentLang.menu.save : Loc.currentLang.menu.add } callback={ handleAddResourses } px={ undefined } height={ undefined } isLoading={ undefined } />
                                </HStack>
                            </VStack>
                        </VStack>
                    </div>
                </Box>
            ) }
            { showAddEventsModal && (
                /*CreateEvent Popup*/
                <AVCreateEventModal Entity={ leagueTeamFK } repeatWeek={ repeatWeek } setRepeatWeek={ setRepeatWeek } isGame={ isGame } isPractice={ isPractice } setShowAddEventsModal={ setShowAddEventsModal } typeevent={ typeevent } setlocationaddress={ setlocationaddress } locationaddress={ locationaddress } datafield={ datafield } showFields={ showFields } setShowFields={ setShowFields }
                    updateField={ updateField } setIsUpdateField={ setIsUpdateField } siteField={ siteField } setSiteField={ setSiteField } mapField={ mapField } setIsMapField={ setIsMapField } isFieldsName={ isFieldsName } setIsFieldsName={ setIsFieldsName } handleupdatelocationfield={ handleupdatelocationfield } setIsFieldName={ setIsFieldName } fieldName={ fieldName } isShowEdit={ isShowEdit } setIsShowField={ undefined } isShowField={ undefined } showAddEventsModal={ showAddEventsModal } />
            ) }

            { showAddSeasonModal && (
                /*Add season popup*/
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 }>
                    {/* body */ }
                    <div
                        style={ {
                            position: 'fixed',
                            top: '53%',
                            left: '50%',
                            transform: 'translate(-53%, -50%)',
                            background: '#ffffff',
                            boxShadow:
                                '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
                            minWidth: isMobile ? '320px' : isLMobile ? '450px' : '700px',
                            maxWidth: '1000px',
                            borderRadius: '8px',
                            height: isMobile ? '48%' : isMobile ? '50%' : '35%',
                        } }
                    >
                        <VStack flex={ 1 } minHeight={ '400px' }>
                            {/* Header */ }
                            <Center> { isModalLoader && <Loader status={ 'Creating Season' } /> }</Center>
                            <HStack
                                alignItems={ 'center' }
                                justifyContent={ 'space-between' }
                                borderBottomColor={ 'AVColor.grey' }
                                px={ 5 }
                            >
                                <Text fontSize={ 24 } width={ '245px' } ellipsizeMode='tail' numberOfLines={ 1 }>
                                    { leagueTeamFK?.subType == 'Club' ? 'Add Term' : leagueTeamFK?.type == 'PlayerOrg' ? Loc.currentLang.menu.addSubTeams : Loc.currentLang.menu.addSeason }
                                </Text>
                                <HStack>
                                    <Tooltip label={ helptext } w={ 200 } openDelay={ 100 }>
                                        <Box
                                            marginRight={ '10px' }
                                            p={ '9px' }
                                            shadow={ 1 }
                                            zIndex={ -3 }
                                            backgroundColor={ 'AVColor.liteBg' }
                                            rounded={ 'full' }

                                        >
                                            <img src={ helping } height={ '17px' } width={ '17px' } style={ { zIndex: -3 } } />
                                        </Box>
                                    </Tooltip>
                                    <Box height={ '30px' }>
                                        <AVCancel color={ undefined } size={ undefined } callback={ () => { setShowAddSeasonModal( false ); setseasonName( '' ) } } />
                                    </Box>
                                </HStack>
                            </HStack>
                            <Divider zIndex={ -1 } marginTop={ '10px' } />
                            {/* modal-Body */ }
                            <VStack
                                my={ '20px' }
                                alignItems={ 'center' }
                                space={ 4 }
                                width={ '100%' }
                                justifyContent={ 'center' }
                                zIndex={ -1 }
                            >
                                {
                                    <>
                                        <HStack width={ '100%' } space={ 5 } alignItems='center' left='25px' zIndex={ -1 }>
                                            <Box width={ isMobile ? '65%' : '80%' }>
                                                <AVInputtext
                                                    type={ 'text' }
                                                    label={ leagueTeamFK?.subType == 'Club' ? 'Term Name' : leagueTeamFK?.type == 'PlayerOrg' ? Loc.currentLang.menu.subTeamsName : Loc.currentLang.menu.seasonName }
                                                    onChange={ handleSeasonName }
                                                    value={ seasonName } defaultValue={ undefined } width={ undefined } height={ undefined } minRows={ undefined } multiline={ undefined } maxLength={ undefined } placeholder={ undefined } size={ undefined } />
                                            </Box>
                                            <VStack>
                                                <Button
                                                    bgColor={ 'AVColor.primary' }
                                                    borderRadius={ 'full' }
                                                    padding={ 3 }
                                                    isDisabled={ !seasonName }
                                                    isLoading={ isLoader }
                                                    onPress={ () =>
                                                    {
                                                        HandleCreatingSeason();
                                                    } }
                                                >
                                                    <AddIcon size={ '20px' } color='AVColor.white' />
                                                </Button>
                                            </VStack>
                                        </HStack>
                                    </>
                                }
                            </VStack>
                            { PlayersList?.length > 0 && (
                                <VStack padding={ 2 } width={ '85%' } left={ '3%' } >
                                    <Text fontWeight='bold' width={ '90%' } numberOfLines={ 1 }>
                                        { "Players" }
                                    </Text>
                                    <ScrollView
                                        nestedScrollEnabled={ true }
                                        showsHorizontalScrollIndicator={ true }
                                    >
                                        { PlayersList?.map( ( item : any, index : any ) => (
                                            <VStack key={ index } borderBottomColor={ 'AVColor.inputBg' } width={ '100%' }>
                                                <HStack
                                                    width={ '100%' }
                                                    padding={ 2 }
                                                    key={ index }
                                                    alignItems={ 'center' }
                                                    borderBottomColor={ 'AVColor.inputBg' }
                                                >
                                                    <Text width={ '95%' } ellipsizeMode="tail" numberOfLines={ 1 }>
                                                        { item?.title }
                                                    </Text>
                                                    <Pressable onPress={ () => { setPlayersList( PlayersList?.filter( ( x : any ) => x?.id !== item?.id ) ) } }>
                                                        <CloseIcon
                                                            size='14px'
                                                            color='gray.500'

                                                            alignSelf={ 'center' }
                                                            pr={ '3' }
                                                        />
                                                    </Pressable>
                                                </HStack>
                                                <Divider />
                                            </VStack>
                                        ) ) }
                                    </ScrollView>
                                </VStack>
                            ) }
                        </VStack>
                    </div>
                </Box>
            ) }
            { showPreview && (
                <PreviewAlbums item={ singleImage } items={ allImages } setShowPreview={ setShowPreview } setShowAllImage={ setShowAllImage } />
            ) }

        </>
    );
};
export default React.memo( Teams );