import React, { useState, useEffect, useRef } from 'react';
import { HStack, VStack, Box, Button, Text, Pressable, Image, Divider, Tooltip } from 'native-base';
import { MoreHorizontalIcon, FavouriteIcon, AddIcon, GroupChatIcon } from '../../assets/icons/icon-assets';
import AVHeaderTitle from './AVHeaderTitle';
import AVCoverPic from './AVCoverPic';
import AVProfilePic from './AVProfilePic';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import * as AV from '../../AVCore/AVCore';
import { setUser, setEntitydata } from '../../Redux/Actions/userActions';
import { useNavigate } from 'react-router-dom';
import { Loc } from '../../App/Loc';
import { Dimensions } from 'react-native';
import Dropdown from '../../assets/images/Drop.png';
import { useLocation, useParams } from 'react-router-dom';
import Deleted from '../../assets/images/Deleted.png';
import Offline from "react-offline";
import { setConvo } from '../../Redux/Actions/convoActions';
import useConvoChat from '../../Hooks/useConvoChat';


const ProfileHeader = ( { isCreate, isDeleted, isShowEdit, Entity, setEntity, EntityType, type, handleUpload, setIsShowEdit, profile, setProfile, googlemap, isAdminView, setIsStaffReq, setIsChildOrgReq, weatherURL, weather, Owner, setIsImagePopup, editAccess, HandleBlockUser, HandleUnBlockUser, HandleRestoreAccount, setIsConvoData, HandleUnFollowUser, setIsOpenConvo, setIsEditEntireSeries, isEditEntireSeries, seriesEvent } ) =>
{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location : any = useLocation();
    const params = useParams();
    let userconvo : any = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const dimensions = Dimensions.get( 'window' );
    const isMobile = dimensions?.width <= 600 || false;
    const data = location?.state?.user;
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
    const [ isMyFavourite, setMyFavourite ] = useState( false );
    const [ isRequestAgain, setIsRequestAgain ] = useState( false );
    const [ isFavLoader, setIsFavLoader ] = useState( false );
    var target : any = AV.AVEntity.getFk( Entity );
    const [ isFollowing, setIsFollowing ] = React.useState( false );
    const [ requestSent, setRequestSent ] = React.useState( false );
    const [ followRequestSending, setFollowRequestSending ] = React.useState( false );
    const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    const pathName = window.location.pathname;
    const [ isOpen, setIsOpen ] = useState( false );
    const [ isShowEditOccurence, setIsShowEditOccurence ] = useState( false );
    const [ isUpdateRSVPStatus, setIsUpdateRSVPStatus ] = useState( "" );
  const [ participantstatus, setParticipantStatus ] = useState(Entity?.participants?.filter((x) => x.id === currentUser?.id)[ 0 ]?.status ?  Entity?.participants?.filter((x) => x.id === currentUser?.id)[ 0 ]?.status : "RSVP" )
    const [ playersList, setPlayersList ] = React.useState<any>( [] )
    const [ isActives, setIsActives ] = useState( false );
    const [ isBlock, setIsBlock ] = useState( false );
    const userID = params?.userId;
    const region = params?.region;
    const [ isBlockLoad, setIsBlockLoad ] = useState<any>( false );
    const participantUser = Entity?.participants?.filter( ( x ) => x.id === currentUser?.id );
    const [ isShowPicker, setIsShowpicker ] = useState( false )
    const [ imageLoader, setImageLoader ] = useState( false );
    const { handleGetGroupConvo, handleSendSingleChat } = useConvoChat();
    const [ isLoad, setisLoad ] = useState( false );
    //console.log("Entity Type::::",EntityType);

    //Follow and Unfollow 
    const handleFollowProfile = async ( type ) =>
    {
        var targetFK : any = AV.Person.getLink( Entity );
        targetFK.linkType = 'Follow';
        switch ( type )
        {
            case 'follow':
                setFollowRequestSending( true );
                let result : any = await AV.Person.makeRequest( AV.Person.getLink( currentUser ), targetFK );
                if ( result )
                {
                    dispatch( setUser( result ) );
                    if ( result.follows.filter( ( x ) => x.id === targetFK.id ).length > 0 )
                    {
                        setIsFollowing( true );
                    } else
                    {
                        setRequestSent( true );
                    }
                }
                setFollowRequestSending( false );
                break;
            case 'unfollow':
                setFollowRequestSending( true );
                let results = await AV.Person.unFollow( AV.Person.getLink( currentUser ), targetFK );
                let user = await AV.Person.self();
                dispatch( setUser( user ) );
                setIsFollowing( false );
                setRequestSent( false );

                // Set isRequestAgain to true to display follow request icon again
                setIsRequestAgain( true );
                setFollowRequestSending( false );
                break;
        }
    };
    //Add and Remove Favourite
    const handleFavourite = async ( type ) =>
    {
        setIsFavLoader( true );
        let resp =
            type === 'addFavourite'
                ? await AV.Person.addFavorite(
                    AV.AVBase.getFk( currentUser ),
                    AV.AVEntity.getLink( Entity )
                ).then( ( x ) =>
                {
                    dispatch( setUser( x ) );
                } )
                : type === 'removeFavourite'
                    ? await AV.Person.removeFavorite(
                        AV.AVBase.getFk( currentUser ),
                        AV.AVEntity.getLink( Entity )
                    ).then( ( x ) =>
                    {
                        dispatch( setUser( x ) );
                    } )
                    : '';
        type === 'addFavourite' ? setMyFavourite( true ) : setMyFavourite( false );
        setIsFavLoader( false );
    };

    const handleRestore = async () =>
    {
        if ( Entity?.status === 'Deleted' )
            await AV.AVEntity.unDelete( Entity );
        else
            await AV.AVEntity.unSuspend( Entity )
    };

    const findPersonInChats = ( conversations, personToCheck ) =>
    {
        return conversations.find( convo =>
            convo?.subject === '' &&
            convo?.people?.some( person =>
                person?.id === personToCheck?.id &&
                person?.pk === personToCheck?.pk
            )
        );
    };

    const handleChat = async ( item : any ) =>
    {
        setisLoad( true )
        let People : any = [];
        const sourceFK : any = {
            Id: item?.id,
            Pk: item?.pk,
            Region: item?.region,
            Type: item?.type,
        };

        People?.push( sourceFK )
        let valid = await findPersonInChats( userconvo, item )
        if ( valid?.id !== undefined )
        {
            setIsConvoData( valid?.id )
            setisLoad( false )
            setIsOpenConvo( true )
            return;
        }

        await handleSendSingleChat( People );
        setisLoad( false )
        setIsOpenConvo( true )
    }

    const HandleGroupChat = async ( type ) =>
    {
        setisLoad( true )
        if ( Entity?.type == "Person" )
        {
            handleChat(Entity);
            return;
        }
        let valid = type == "W" ? Entity.wConvo : type == "A" ? Entity?.aConvo : '';
        if ( valid !== '' || valid !== undefined )
        {
            setIsConvoData( valid )
        }
        if ( valid === '' || valid == undefined )
        {
            let Response : any = await AV.AVEntity.CreateGroupConvo( target, type );
            console.log( "Response", Response )
            let Index = entitydatalist?.findIndex( x => x?.id == Response?.id )
            if ( Index !== -1 )
            {
                entitydatalist[ Index ] = Response;
                setEntity( Response )
            }

            dispatch( setEntitydata( entitydatalist ) );
            const fk = {
                id: type == "W" ? Response.wConvo : type == "A" ? Response?.aConvo : '',
                pk: type == "W" ? Response.wConvo : type == "A" ? Response?.aConvo : '',
                region: Entity?.region,
                type: "GroupConvo",
            }
            setIsConvoData( fk?.id )
            const feedParam : AV.IFeedParam = {
                feed: fk,
                startDate: new Date( 0 ), forward: true
            };
            await AV.Feed.getFeed( feedParam ).then( async ( response : any ) =>
            {
                if ( response !== null )
                {
                    const existingconvo = userconvo?.length > 0 && userconvo.find( ( x : any ) => x?.id === response?.id ) ? true : false;
                    if ( !existingconvo )
                    {
                        if ( userconvo?.length > 0 )
                        {
                            userconvo?.unshift( response )
                        }
                        else
                        {
                            userconvo = [];
                            userconvo?.unshift( response )
                        }
                    }
                    dispatch( setConvo( userconvo ) );
                    await handleGetGroupConvo( response )
                }
            } );
        }
        setisLoad( false );
        setIsOpenConvo( true );

    }
    //RSVP function
    const handleUpdateParticipant = async ( participantStatus ) =>
    {
        if ( participantStatus == 'Yes')
        {
            setParticipantStatus( Loc.currentLang.menu.attending );
        }
        else if ( participantStatus == 'No' )
        {
            setParticipantStatus( Loc.currentLang.menu.notAttending );
        }
        else if ( participantStatus == 'Maybe' )
        {
            setParticipantStatus( Loc.currentLang.menu.mayBe );
        }
        //setIsActives( !isActives );
        setParticipantStatus( participantStatus );
        let seriesId = Entity.series && !isEditEntireSeries ? Entity.id != Entity.series ? Entity.id : null : null;
        Entity.id = Entity.series ? Entity.series : Entity.id;
        setIsUpdateRSVPStatus("");
        await AV.Event.updateParticipant( AV.AVEntity.getFk( Entity ), { ...AV.AVEntity.getFk( currentUser ), status: participantStatus }, seriesId ).then( ( e : any ) =>
        {
            if ( e )
            {
                let statusevent = e?.participants?.filter( ( x ) => x?.id === currentUser?.id );
                if ( statusevent?.length > 0 )
                    setParticipantStatus( statusevent[ 0 ]?.status ? statusevent[ 0 ]?.status : "RSVP" );
                setEntity( e ); /*setEventData(e);*/ setPlayersList( e?.participants );
                let oldvalue = currentUser.events.filter( a => a.id == e?.id );
                e.status = statusevent[ 0 ]?.status ? statusevent[ 0 ]?.status : "RSVP"; currentUser.events[ currentUser.events.indexOf( oldvalue[ 0 ] ) ] = e
                entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === e?.id ) ) ] = e;
                dispatch( setEntitydata( entitydatalist ) );
                if ( currentUser )
                    dispatch( setUser( currentUser ) );
            }

        } )
    };

    const targetFK : any = {
        Id: data?.id ? data?.id : userID,
        Pk: data?.pk ? data?.pk : userID?.substr( -4 ),
        Region: data?.region ? data?.region : region,
        Type: data?.type ? data?.type : 'Person',
    };
    const sourceFK : any = {
        Id: currentUser?.id,
        Pk: currentUser?.pk,
        Region: currentUser?.region,
        Type: currentUser?.type,
    };
    //Block and unBlock
    const handleProfile = async ( type ) =>
    {
        setIsBlockLoad( true );
        let resp =
            type === 'block'
                ? await AV.Person.block( sourceFK, targetFK )
                : type === 'unblock'
                    ? await AV.Person.unBlock( sourceFK, targetFK )
                    : '';
        type === 'block' ? setIsBlock( true ) : setIsBlock( false );
        await AV.Person.getSelf( true ).then( ( x ) =>
        {
            dispatch( setUser( x ) );
        } );
        setIsBlockLoad( false );
        setIsOpen( false );
    };

    const menuRef = useRef( null );
    useOutsideAlerter( menuRef );
    //Hook that alerts clicks outside of the passed ref
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            // Alert if clicked on outside of element
            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {
                    setIsActives( false );
                    setIsOpen( false );
                    setIsShowEditOccurence( false );
                }

            }
            // Bind the event listener
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                // Unbind the event listener on clean up
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }

    useEffect( () =>
    {
        let isMounted = true;
        if ( isMounted )
        {
            const following = currentUser?.follows?.filter( ( item ) => item?.id === Entity?.id );
            const requests = currentUser?.myRequests?.filter( ( item ) => item?.target?.id === Entity?.id && item?.type === 'Follow' );
            const Blocked = currentUser?.blocked;
            following?.length > 0
                ? setIsFollowing( true )
                : setIsFollowing( false );
            requests?.length > 0
                ? setRequestSent( true )
                : setRequestSent( false );
            if ( currentUser?.favs?.filter( ( x ) => x?.id === Entity?.id )[ 0 ] )
            {
                setMyFavourite( true );
            }
            else
            {
                setMyFavourite( false );
            }
            Blocked?.filter( ( item ) => item?.id === Entity?.id ).length > 0
                ? setIsBlock( true )
                : setIsBlock( false );
            if ( Entity?.participants )
            {
                let status = Entity?.participants?.filter( ( x ) => x?.id === currentUser?.id );
                if ( status?.length > 0 )
                {
                    setParticipantStatus( status[ 0 ]?.status ? status[ 0 ]?.status : "RSVP" );
                }
            }
        }
        return () =>
        {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };

    }, [ Entity, currentUser ] )

    return (
        <>
            <Box
                height={ 'auto' }
                zIndex={-3}
                backgroundColor={ 'AVColor.white' }
                shadow={ 1 }
                borderTopLeftRadius={ 10 }
                borderTopRightRadius={ 10 }
            >
                <Box height={ type === 'Feedback' ? '140px' : Entity?.type === "Event" && !Entity?.profile?.background?.id && !isShowEdit ? '95px' : Entity?.type === "Event" && !Entity?.profile?.background?.id && isShowEdit ? "42px":'230px' } backgroundColor={ Entity?.type === "Event" && !Entity?.profile?.background?.id ? 'white' : 'AVColor.hovercolor2' } borderTopLeftRadius={ 10 }
                    borderTopRightRadius={ 10 }>
                    {/*Background*/ }
                    <AVCoverPic Entity={ Entity } isShowEdit={ isShowEdit } handleUpload={ handleUpload } profile={ profile } isCreate={ isCreate } setProfile={ setProfile } type={ type } isAdminView={ isAdminView } editAccess={ editAccess } isShowPicker={ isShowPicker } setIsShowpicker={ setIsShowpicker } imageLoader={ imageLoader } setImageLoader={ setImageLoader } />
                    {/*Follow and FavouriteIcon*/ }
                    { ( !isCreate && !isShowEdit && isDeleted ) && ( <Box width={ '100%' } alignItems={ 'flex-end' } right={ "27px" } top={ Entity?.type === "Event" && !Entity?.profile?.background?.id ? '37px' : '25px' }>
                        <HStack space={ 3 }>
                            {/* Follow */ }
                            { ( !isFollowing && !requestSent && !isAdminView && !isRequestAgain && currentUser?.autoFollows?.filter( ( x ) => x?.id === Entity?.id )?.length == 0 && currentUser?.id !== Entity?.id && EntityType?.type !== 'Event' ) && (
                                <Tooltip openDelay={ 500 } label={ Loc.currentLang.follow.follow }>
                                    <Button width={ '40px' } height={ '40px' } rounded={ 'full' } onPress={ () =>
                                    {
                                        handleFollowProfile( 'follow' );
                                    } }
                                        isLoading={ followRequestSending }
                                    >
                                        <AddIcon size={ '22px' } color={ 'AVColor.white' } />
                                    </Button>
                                </Tooltip>
                            ) }
                            {/*Request sent*/ }
                            { ( requestSent && currentUser?.id !== Entity?.id && EntityType?.type !== 'Event' ) && (
                                <Button
                                    pl={ 2 }
                                    pr={ 4 }
                                    py={ 2 }
                                    borderRadius={ '45px' }
                                    minWidth={ '120px' }
                                    bgColor={ 'AVColor.buttonBg' }
                                    _text={ { color: 'AVColor.primaryText' } }
                                >
                                    { Loc.currentLang.menu.requestSent }
                                </Button>
                            ) }
                            {/*FavouriteIcon*/ }
                            { !isMyFavourite && currentUser?.id !== Entity?.id && Entity?.type !== 'Event' && (
                                <Tooltip openDelay={ 500 } label={ Loc.currentLang.menu.favourite }>
                                <Button width={ '40px' } height={ '40px' }
                                    rounded={ 'full' }
                                    isLoading={ isFavLoader }
                                    onPress={ () => handleFavourite( 'addFavourite' ) }>
                                    <FavouriteIcon size={ '22px' } color={ 'AVColor.white' } />
                                    </Button> 
                                    </Tooltip>
                                ) }
                            { currentUser?.id !== Entity?.id && Entity?.type !== 'Event' && (
                                <Tooltip openDelay={ 500 } label={ Loc.currentLang.activity.chat}>
                                    <Button width={ '40px' } height={ '40px' }
                                    rounded={ 'full' }
                                        onPress={ () => HandleGroupChat( Entity?.type == 'PlayerOrg' && Entity?.subType == undefined ? 'A' : 'W' ) }
                                    backgroundColor={'#18A6B9'}
                                        isLoading={ isLoad }
                                       >
                                        <GroupChatIcon size={23} color={ 'white' } />
                                    </Button>
                                </Tooltip>)}
                            
                            {/*/>)}*/ }
                { ((EntityType?.type === 'Event' && isAdminView && editAccess && Entity?.status !== 'Canceled' && !isMobile) || (EntityType?.type === 'Event' && Entity?.status !== 'Canceled' && participantUser && participantUser.length > 0 ) ) && ( <HStack>
                                <HStack ref={ menuRef } top={ isMobile ? '57px' : '' } left={ isMobile ? '25px':'' } >
                                    <Button
                                        width={ '122px' }
                                        bgColor={ '#32AE78' }
                                        rounded='full'
                                        fontWeight='550px'
                                        color='#fff'
                                        p={ 2 }
                                        justifyContent={ 'flex-start' }
                                        height={ '40px' }
                                        borderRadius={ '20px' }
                                        
                                        borderColor={ 'AVColor.primary' }
                                        onPress={ () =>
                                        {
                                            setIsActives( !isActives );
                                        } }
                                    >
                                        <Text
                                            color={ 'white' }
                                            lineHeight={ '2rem' }
                                            paddingLeft={ '13px' }
                                            maxW={ [ '130px' ] }
                                            text-overflow={ 'ellipsis' }
                                            numberOfLines={ 1 }
                                            fontWeight={ 500 }
                                            fontSize={ '16px' }
                                        >
                                            { participantstatus }
                                        </Text>
                                    </Button>
                                    <Pressable
                                        position={ 'absolute' }
                                        top={ '12px' }
                                        right={ '10px' }
                                        onPress={ () =>
                                        {
                                            setIsActives( !isActives );
                                        } }
                                    >
                                        <Image source={ Dropdown } alt='Actavivo' size='15px' />
                                    </Pressable>
                                </HStack>
                                <HStack>
                                    {isActives && (
                                        <HStack
                                            ref={ menuRef }
                                            position={ 'absolute' }
                                            top={ isMobile ? '100px' : '50px' }
                                            right={ isMobile ? '-25px' : '' }
                                            flexDirection={ 'column' }
                                            width={ '120px' }
                                            left={ '-112px' }
                                            borderRadius={ '5px' }
                                            bgColor='#FFFFFF'
                                            shadow='9'
                                            rounded='lg'
                                            zIndex={ 999 }
                                        >
                                            <Button
                                                onPress={ () =>
                                                {
                                                    Entity?.series ? setIsShowEditOccurence( true ) : handleUpdateParticipant( 'Yes' );
                                                    setIsUpdateRSVPStatus( "Yes" );
                                                    //handleUpdateParticipant( 'Yes' );
                                                    //setParticipantStatus( Loc.currentLang.menu.attending )
                                                    setIsActives( !isActives );

                                                } }
                                                bgColor='#FFFFFF'
                                                height='30px'
                                                justifyContent={ 'start' }
                                                zIndex={ 999 }
                                                _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                                width={ '120px' }
                                            >
                                                <Text color={ 'black' }>
                                                    { Loc.currentLang.menu.attending }
                                                </Text>
                                            </Button>
                                            <Button
                                                onPress={ () =>
                                                {
                                                    //setIsShowEditOccurence( true );
                                                    Entity?.series ? setIsShowEditOccurence( true ) : handleUpdateParticipant( 'No' );
                                                    setIsUpdateRSVPStatus( "No" );
                                                    //handleUpdateParticipant( 'No' );
                                                    //setParticipantStatus( Loc.currentLang.menu.notAttending )
                                                    setIsActives( !isActives );
                                                } }
                                                bgColor='#FFFFFF'
                                                height='30px'
                                                justifyContent={ 'start' }
                                                zIndex={ 999 }
                                                _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                                width={ '120px' }
                                            >
                                                <Text color={ 'black' }>
                                                    { Loc.currentLang.menu.notAttending }
                                                </Text>
                                            </Button>
                                            <Button
                                                bgColor='#FFFFFF'
                                                height='30px'
                                                justifyContent={ 'start' }
                                                zIndex={ 999 }
                                                _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                                width={ '120px' }
                                                onPress={ () =>
                                                {
                                                    //setIsShowEditOccurence( true );
                                                    Entity?.series ? setIsShowEditOccurence( true ) : handleUpdateParticipant( 'Maybe' );
                                                    setIsUpdateRSVPStatus( "Maybe" );
                                                    //handleUpdateParticipant( 'Maybe' );
                                                    //setParticipantStatus(Loc.currentLang.menu.mayBe)
                                                    setIsActives( !isActives );
                                                } }
                                            >
                                                <Text color={ 'black' }>
                                                    { Loc.currentLang.menu.mayBe }
                                                </Text>
                                            </Button>
                                        </HStack>
                                    ) }
                                </HStack>
                            </HStack> ) }
                            { ( ( EntityType?.type === 'Event' && Entity?.status === 'Canceled' ) || ( EntityType?.type === 'Event' && Entity?.status === 'Canceled' && participantUser && participantUser.length > 0 ) ) && ( <HStack>
                                <Text color={ 'red.500' } fontSize={ '24px' } fontWeight={ 400 }>{ Loc.currentLang.menu.canceled ? Loc.currentLang.menu.canceled : 'Canceled'}</Text>
                </HStack>) }
                            { ( ( Entity?.type == 'Event' ? editAccess : isAdminView ) || ( isAdminView && Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa7f" && Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa71" && Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa72" ) ) && (

                                <Pressable
                                onPress={ () => setIsOpen( !isOpen ) }
                            >
                                    { Entity?.status !== 'Canceled' && <MoreHorizontalIcon size='24px' color={ 'AVColor.primaryText' } alignSelf={ 'center' } />}
                                </Pressable>
                            ) }
                            {!isAdminView && !participantUser && ( Entity?.id !== "78b5b063-fd3e-4925-92bd-2a1888c6a46a" && Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa7f" && Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa71" && Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa72" && EntityType?.status != 'Canceled' ) && ( <Pressable
                                onPress={ () => setIsOpen( !isOpen ) }
                            >
                                <MoreHorizontalIcon size='24px' color={ 'AVColor.primaryText' } alignSelf={ 'center' } />
                            </Pressable> ) }
                        </HStack>
                        { ( isOpen && isAdminView ) && ( <VStack
                            ref={ menuRef }
                            width={ '128x' }
                            height='auto'
                            shadow={ 2 }
                            background={ '#FFFFFF' }
                            position={ 'absolute' }
                            top={ '30px' }
                            zIndex={ 999 }
                            borderRadius={ '6px' }
                        >
                            <Pressable
                                ref={ menuRef }
                                width={ '100%' }
                                height={ '36px' }
                                paddingX={ '10px' }
                                alignItems={ 'flex-start' }
                                justifyContent={ 'center' }
                                onPress={ () =>
                                {
                                    Entity?.series ? setIsShowEditOccurence( true ) : setIsShowEdit( true );
                                    setIsOpen( false );
                                } }
                                _hover={ {
                                    backgroundColor: 'gray.100'
                                } }
                            >
                                <Text
                                    fontSize={ '14px' }
                                    fontFamily={ 'Roboto' }
                                >
                                    { Loc.currentLang.menu.edit }
                                </Text>
                            </Pressable>
                            <Divider />
                            <Pressable
                                ref={ menuRef }
                                width={ '100%' }
                                height={ '36px' }
                                paddingX={ '10px' }
                                alignItems={ 'flex-start' }
                                justifyContent={ 'center' }
                                onPress={ () =>
                                {
                                    Entity?.type == 'Person' ? 
                                        navigate( `/accountsettings/${ currentUser?.id }/${ currentUser?.region }`, {
                                            state: {
                                                page: 'Home',
                                            },
                                        } ):
                                    navigate(
                                        `/accountsettings/${ Entity?.id }/${ Entity?.region }`,
                                        {
                                            state: {
                                                page: pathName,
                                                Entity: Entity,
                                            },
                                        }
                                    );
                                } }
                                _hover={ {
                                    backgroundColor: 'gray.100'
                                } }
                            >
                                <Text
                                    fontSize={ '14px' }
                                    fontFamily={ 'Roboto' }
                                >
                                    { Loc.currentLang.menu.setting }
                                </Text>
                            </Pressable>
                        </VStack> ) }
                        { isShowEditOccurence && ( <VStack
                            ref={ menuRef }
                            width={ '128x' }
                            height='auto'
                            shadow={ 2 }
                            background={ '#FFFFFF' }
                            position={ 'absolute' }
                            top={ '30px' }
                            zIndex={ 999 }
                            borderRadius={ '6px' }
                        >
                            <Pressable
                                ref={ menuRef }
                                width={ '100%' }
                                height={ '36px' }
                                paddingX={ '10px' }
                                alignItems={ 'flex-start' }
                                justifyContent={ 'center' }
                                onPress={ () =>
                                {
                                    isUpdateRSVPStatus == "Yes" ? handleUpdateParticipant( 'Yes' ) : isUpdateRSVPStatus == "No" ? handleUpdateParticipant( 'No' ) : isUpdateRSVPStatus == "Maybe" ? handleUpdateParticipant( 'Maybe' ) : setIsShowEdit( true )
                                    setIsOpen( false );
                                    setIsEditEntireSeries( false );
                                    setIsShowEditOccurence( false );
                                } }
                                _hover={ {
                                    backgroundColor: 'gray.100'
                                } }
                            >
                                <Text
                                    fontSize={ '14px' }
                                    fontFamily={ 'Roboto' }
                                >
                                    { "This Occurrence" }
                                </Text>
                            </Pressable>
                            <Divider />
                            <Pressable
                                ref={ menuRef }
                                width={ '100%' }
                                height={ '36px' }
                                paddingX={ '10px' }
                                alignItems={ 'flex-start' }
                                justifyContent={ 'center' }
                                onPress={ () =>
                                {
                                    isUpdateRSVPStatus == "Yes" ? handleUpdateParticipant( 'Yes' ) : isUpdateRSVPStatus == "No" ? handleUpdateParticipant( 'No' ) : isUpdateRSVPStatus == "Maybe" ? handleUpdateParticipant( 'Maybe' ) : setIsShowEdit( true )
                                    setIsOpen( false );
                                    setIsEditEntireSeries( true );
                                    setIsShowEditOccurence( false );
                                } }
                                _hover={ {
                                    backgroundColor: 'gray.100'
                                } }
                            >
                                <Text
                                    fontSize={ '14px' }
                                    fontFamily={ 'Roboto' }
                                >
                                    { "Entire Series" }
                                </Text>
                            </Pressable>
                        </VStack> ) }
                        { ( isOpen && !isAdminView && Entity?.type !== 'Person' && Entity?.type !== 'Event' ) && ( <VStack
                            width={ '185x' }
                            height='auto'
                            shadow={ 2 }
                            background={ '#FFFFFF' }
                            position={ 'absolute' }
                            top={ '50px' }
                            zIndex={ 999 }
                            borderRadius={ '6px' }
                            ref={ menuRef }
                        >
                            <Pressable
                                width={ '100%' }
                                height={ '36px' }
                                paddingX={ '5px' }
                                paddingY={ '5px' }
                                _hover={ {
                                    backgroundColor: 'gray.100'
                                } }
                                alignItems={ 'flex-start' }
                                justifyContent={ 'center' }
                                onPress={ () =>
                                {
                                    setIsStaffReq( true );
                                    setIsOpen( false );
                                } }
                            >
                                <Text
                                    fontSize={ '14px' }
                                    fontFamily={ 'Roboto' }
                                >
                                    { Loc.currentLang.menu.requestToJoinStaff ? Loc.currentLang.menu.requestToJoinStaff : 'Request to Join Staff' }
                                </Text>
                            </Pressable>
                            <Divider />
                            <Pressable
                                width={ '100%' }
                                height={ '36px' }
                                paddingX={ '5px' }
                                paddingY={ '5px' }
                                _hover={ {
                                    backgroundColor: 'gray.100'
                                } }
                                alignItems={ 'flex-start' }
                                justifyContent={ 'center' }
                                onPress={ () =>
                                {
                                    setIsChildOrgReq( true );
                                    setIsOpen( false );
                                } }

                            >
                                <Text
                                    paddingX={ 1 }
                                    numberOfLines={ 2 }
                                    fontSize={ '14px' }
                                    fontFamily={ 'Roboto' }
                                >
                                    { Loc.currentLang.menu.requestToAddChildOrganization ? Loc.currentLang.menu.requestToAddChildOrganization : "Request to Add ChilOrganization" }
                                </Text>
                            </Pressable>
                        </VStack> ) }
                        { ( isOpen && !isAdminView && Entity?.type == 'Person' ) && ( <VStack
                            ref={menuRef }
                            width={ '128px' }
                            height='auto'
                            shadow={ 2 }
                            background={ '#FFFFFF' }
                            position={ 'absolute' }
                            top={ '24px' }
                            zIndex={ 999 }
                            borderRadius={ '6px' }
                        >
                            { !isBlock ?
                                <Button
                                width={ '100%' }
                                height={ '36px' }
                                paddingX={ '10px' }
                                alignItems={ 'flex-start' }
                                _hover={ {
                                    backgroundColor: 'gray.100'
                                } }
                                justifyContent={ 'center' }
                                backgroundColor={ isBlockLoad ? 'gray.500' : '' }
                                isLoading={ isBlockLoad }
                                onPress={ () => { HandleBlockUser( 'block' ); setIsOpen( false ); } }
                            >
                                <Text
                                    fontSize={ '14px' }
                                    fontFamily={ 'Roboto' }
                                >
                                    Block
                                </Text>
                            </Button> :
                                <Button
                                    width={ '100%' }
                                    height={ '36px' }
                                    paddingX={ '10px' }
                                    _hover={ {
                                        backgroundColor: 'gray.100'
                                    } }
                                    alignItems={ 'flex-start' }
                                    justifyContent={ 'center' }
                                    backgroundColor={ isBlockLoad ? 'gray.500' : '' }
                                    onPress={() => { HandleUnBlockUser('unblock'); setIsOpen( false ); } }
                                    isLoading={ isBlockLoad }

                                >
                                    <Text
                                        fontSize={ '14px' }
                                        fontFamily={ 'Roboto' }
                                    >
                                        Un Block
                                    </Text>
                                </Button> }
                            {currentUser?.follows?.filter(x => x?.id == Entity?.id)?.length > 0 && (<Button
                                width={ '100%' }
                                height={ '36px' }
                                paddingX={ '10px' }
                                alignItems={ 'flex-start' }
                                _hover={ {
                                    backgroundColor: 'gray.100'
                                } }
                                backgroundColor={ isBlockLoad ? 'gray.500' : '' }
                                justifyContent={ 'center' }
                                onPress={ () => { HandleUnFollowUser(AV.AVEntity.getFk( Entity )); setIsOpen( false ); } }
                            >
                                <Text
                                    fontSize={ '14px' }
                                    fontFamily={ 'Roboto' }
                                >
                                    Unfollow
                                </Text>
                            </Button>)}
                        </VStack> ) }
                    </Box> ) }
                </Box>
                {/*Profile*/ }
                <AVProfilePic isMyFavourite={ isMyFavourite } isShowEdit={ isShowEdit } EntityType={ EntityType } handleFavourite={ handleFavourite } Entity={ Entity } isLoader={ isFavLoader } handleUpload={ handleUpload } profile={ profile } isCreate={ isCreate } setProfile={ setProfile } type={ type } isAdminView={ isAdminView } imageLoader={ imageLoader } setImageLoader={ setImageLoader }  />
                { ( EntityType?.type === 'Event' && !isShowEdit ) && (
                    <VStack position={ 'absolute' }
                        left={isMobile ? '187px': '250px' }
                        width={ isShowPicker ? "15%":'40%'}
                        top={ Entity?.type === "Event" && !Entity?.profile?.background?.id ? '35px' : '228px' }
                        paddingTop={ '12px' }
                    >
                        <HStack width={'100%%' } >
                            <Text fontWeight={ 500 } fontSize={ '24px' } numberOfLines={ 1 } width={'100%'}>{ Entity?.name }</Text>
                        </HStack>
               <HStack width={'100%' }>
                <Pressable
                  width={ '100%' }
                                onPress={ () =>
                                {
                                    Owner?.type == 'Person'
                                        ? navigate( `/profile/${ Owner?.id }/${ Owner?.region }`, {
                                            state: {
                                                user: {
                                                    id: Owner?.id,
                                                    pk: Owner?.pk,
                                                    region: Owner?.region,
                                                    type: Owner?.type,
                                                    name: Owner?.title,
                                                },
                                            },
                                        } )
                                        : Owner?.type == 'PlayerOrg'
                                            ? navigate( `/season/${ Owner?.id }/${ Owner?.region }`, { state: { isCreate: false, team: Owner } } )
                                            : Owner?.type == 'Team' ? navigate( `/teams/${ Owner?.id }/${ Owner?.region }`, { state: { isCreate: false, team: Owner } } ) :
                                                navigate( `/season/${ Owner?.id }/${ Owner?.region }`, { state: { isCreate: false, team: Owner } } );
                                } }
                            >

                                <Text fontWeight={ 400 } fontSize={ '16px' } width={ '100%' } numberOfLines={ 1 } color={ '#00A7BC' }>{ Owner?.type === 'PlayerOrg' ? Owner?.name?.trim()?.length > 0 ? Owner?.name : Owner?.title  || Owner?.data : Owner?.title || Owner?.name }</Text>
                            </Pressable>
                        </HStack>
                    </VStack>
                ) }
                {/* Title*/ }
                { ( !isCreate && !isShowEdit ) && (
                    <VStack height={ 'auto' } marginTop={ '55px' } marginBottom={ Entity?.type == 'Event' ? '' : '22px' } width={ '100%' } zIndex={ -3 }>
                        <AVHeaderTitle Entity={ Entity } EntityType={ EntityType } googlemap={ googlemap } weatherURL={ weatherURL } weather={ weather } setIsImagePopup={ setIsImagePopup } seriesEvent={ seriesEvent } />
                        { !isDeleted && (Entity?.type == "Person" ? !isAdminView : isAdminView) && !isCreate && !isShowEdit && ( <HStack justifyContent={ 'flex-end' } marginTop={ '24px' } marginX={ '24px' }>
                            { !isAdminView && !participantUser && ( Entity?.id !== "78b5b063-fd3e-4925-92bd-2a1888c6a46a" && Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa7f" && Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa71" && Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa72" && EntityType?.status != 'Canceled' ) && (  <Button
                                width={ '246px' }
                                bgColor={ '#32AE78' }
                                rounded='full'
                                color='#fff'
                                p={ 2 }
                                justifyContent={ 'center' }
                                height={ '40px' }
                                borderRadius={ '20px' }
                                onPress={HandleRestoreAccount}
                            >
                                <Text color={ 'white' }>
                                    RESTORE YOUR ACCOUNT
                                </Text>
                            </Button>)}
                        </HStack> ) }
                        { Entity?.status === 'Deleted' && !isDeleted && ( Entity?.type == "Person" ? isAdminView : !isAdminView)  && !isCreate && !isShowEdit && (
                            <VStack alignItems={ 'center' } marginTop={ '35px' }>
                                <img src={ Deleted } alt='image' height={ '220px' } width={ '240px' } />
                                <HStack paddingBottom={ '8px' }>
                                    <Text fontWeight={ 400 } fontSize={ '18px' }>
                                        { Loc.currentLang.menu.noLongerTxt }
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text>{ Loc.currentLang.menu.restrictPostTxt }</Text>
                                </HStack>
                            </VStack>
                        ) }
                    </VStack>
                ) }
            </Box>
        </>
    )

}


export default React.memo(ProfileHeader);