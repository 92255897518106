//@ts-nocheck
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRemainingEntities, setRemainingPosts, setFetchProcess, setFeeds } from '../Redux/Actions/feedsAction';
import _ from 'lodash';
import * as AV from '../AVCore/AVCore';
import { store } from '../Redux/store';

const useFeeds = ( props : any ) =>
{
    const dispatch = useDispatch();
    const currentUser = useSelector( ( state : any ) => state.user.user[ 0 ] );
    const userFeeds : AV.IPost[] = useSelector( ( state : any ) => state.feeds.feeds[ 0 ] ) ?? []; // Existing posts from Redux
    const remainingEntitiesInRedux = useSelector( ( state : any ) => state.feeds.remainingEntities ) ?? [];
    const remainingPostsInRedux = useSelector( ( state : any ) => state.feeds.remainingPosts ) ?? [];
    const fetchProcess = useSelector( ( state : any ) => state.feeds.fetchProcess );
    const [ processedAllPosts, setProcessedAllPosts ] = useState( false );

    const addEntitiesToCombined = ( entities : any[], type : string, combinedEntities : any[] ) =>
    {
        const uniqueEntities = _.uniqBy( entities, 'id' );
        uniqueEntities.forEach( ( entity ) =>
        {
            combinedEntities.push( createEntityObject( entity, type ) );
        } );
    };

    const createEntityObject = ( entity : any, type : string ) => ( {
        Entity: entity,
        type,
        LastPostProcessed: new Date( 0 ),
        IsProcessed: false,
    } );

    const CombineAllEntities = async ( currentUser : any ) =>
    {
        const remainingEntitiesInRedux = store.getState().feeds.remainingEntities;
        let combinedEntities : Entity[] = _.cloneDeep( remainingEntitiesInRedux ) || [];

        if ( combinedEntities.length === 0 )
        {
            console.log( 'Combining all entities...' );
            addEntitiesToCombined( currentUser?.events || [], 'Events', combinedEntities );
            addEntitiesToCombined( currentUser?.members || [], 'Members', combinedEntities );
            addEntitiesToCombined( currentUser?.orgs || [], 'Orgs', combinedEntities );
            addEntitiesToCombined( currentUser?.follows || [], 'Follows', combinedEntities );
            addEntitiesToCombined( currentUser?.autoFollows || [], 'AutoFollows', combinedEntities );
            combinedEntities.push( createEntityObject( currentUser, 'Person' ) );
            combinedEntities = _.uniqBy( combinedEntities, ( entity ) => entity?.Entity?.id );

            const existingEntities = store.getState().feeds.remainingEntities;
            if ( !_.isEqual( existingEntities, combinedEntities ) )
            {
                try
                {
                    const posts = await fetchFeedPosts( combinedEntities[ 0 ] );
                    combinedEntities.shift();
                    store.dispatch( setRemainingEntities( combinedEntities ) );
                    store.dispatch( setFetchProcess( true ) );

                    return posts;
                } catch ( error )
                {
                    console.error( 'Error fetching feed posts:', error );
                    store.dispatch( setFetchProcess( false ) );
                    return [];
                }
            }
        }

        try
        {
            const posts = await fetchFeedPosts( combinedEntities[ 0 ] );
            combinedEntities.shift();
            store.dispatch( setRemainingEntities( combinedEntities ) );

            return posts;
        } catch ( error )
        {
            console.error( 'Error fetching feed posts:', error );
            return [];
        }
    };

    const fetchFeedPosts = async ( entity : any ) =>
    {
        const feedParam = {
            feed: { id: entity?.Entity?.id, pk: entity?.Entity?.id, type: 'Feed' },
            startDate: new Date( 0 ),
            forward: true,
            checkArchive: false,
        };

        try
        {
            let feed = await AV.Feed.getFeed( feedParam );
            let feedFK = AV.AVEntity.getFk( feed );
            let currentEntityPostIds = _.uniqBy( [ ...( feed?.followIds || [] ), ...( feed?.privateIds || [] ), ...( feed?.publicIds || [] ) ], 'id' );

            if ( currentEntityPostIds.length > 0 )
            {
                let top20Posts = currentEntityPostIds.slice( 0, 20 );
                let remainingPostIds = currentEntityPostIds.slice( 20 );

                if ( remainingPostIds.length > 0 )
                {
                    let remainingPosts = [ ...remainingPostsInRedux ];
                    remainingPostIds.forEach( ( element ) =>
                    {
                        let objRemainingPost = {
                            Owner: feedFK,
                            PostId: element.id,
                            Postdate: element.date,
                        };
                        remainingPosts.push( objRemainingPost );
                    } );
                    dispatch( setRemainingPosts( remainingPosts ) );
                }

                return await processFeeds( feedFK, top20Posts.map( ( x ) => x.id ) );
            } else
            {
                return [];
            }
        } catch ( error )
        {
            console.error( 'Error fetching feed for entity:', entity, error );
            return [];
        }
    };

    const processFeeds = async ( owner : any, postIds : any ) =>
    {
        let posts : AV.IPost[] = [];
        try
        {
            if ( postIds?.length > 0 )
            {
                posts = await AV.Feed.getPosts( owner, postIds );
                for ( let i = 0; i < posts?.length; i++ )
                {
                    if ( posts[ i ].posts[ 0 ]?.length > 0 )
                    {
                        let cmdPost : any = await AV.Feed.getPosts( AV.AVEntity.getFk( posts[ i ] ), posts[ i ]?.posts );
                        if ( cmdPost?.length > 0 && posts[ i ] != undefined )
                        {
                            posts[ i ].children = cmdPost;
                        }
                    }
                }
            }
        } catch ( error )
        {
            console.error( 'Error processing feed for entity:', owner, error );
            return [];
        }
        return posts;
    };

    const listToTree = ( list : Post[] ) =>
    {
        const map : { [ key : string ] : number } = {};
        const roots : Post[] = [];

        list.forEach( ( node, i ) =>
        {
            map[ node?.id ] = i;
            if ( !node?.children ) node.children = [];
            if ( !node?.galleryPost ) node.galleryPost = [];
        } );

        list.forEach( ( node ) =>
        {
            if ( node.parentPost !== undefined )
            {
                const parentNode = list[ map[ node.parentPost ] ];
                if ( parentNode?.gallery?.items?.includes( node.id ) && !node.gallery )
                {
                    let excitingPost = parentNode?.galleryPost?.find( ( x ) => x?.id === node?.id );
                    if ( !excitingPost ) parentNode?.galleryPost.push( node );
                } else
                {
                    let excitingComment = parentNode?.children?.find( ( x ) => x?.id === node?.id );
                    if ( !excitingComment ) parentNode?.children.push( node );
                }
            } else
            {
                roots.push( node );
            }
        } );

        return roots;
    };

    const fetchPosts = async () =>
    {
        try
        {
            let posts : AV.IPost[] = [];
            if ( props?.page === 'Home' )
            {
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate( thirtyDaysAgo.getDate() - 30 );

                const recentPosts = remainingPostsInRedux.filter( ( post ) =>
                {
                    const postDate = new Date( post.createdAt );
                    return postDate >= thirtyDaysAgo;
                } );

                if ( recentPosts.length > 0 )
                {
                    // process recent posts
                } else if ( remainingEntitiesInRedux.length > 0 || !fetchProcess )
                {
                    posts = await CombineAllEntities( currentUser );
                } else if ( remainingPostsInRedux.length > 0 )
                {
                    posts = await processFeedsInRedux( 20, remainingPostsInRedux );
                } else
                {
                    setProcessedAllPosts( true );
                }
            } else if ( props?.page === 'Profile' )
            {
                const filteredEntities = remainingEntitiesInRedux.filter( ( entity ) => entity.Entity.id === props?.id );
                const filteredPostsInRedux = remainingPostsInRedux.filter( ( x ) => x.Owner.id === props?.id );

                if ( filteredEntities.length > 0 )
                {
                    posts = await fetchFeedPosts( filteredEntities[ 0 ] );
                    const updatedEntities = remainingEntitiesInRedux.filter( ( entity ) => entity.Entity.id !== props?.id );
                    dispatch( setRemainingEntities( updatedEntities ) );
                } else if ( filteredPostsInRedux.length > 0 )
                {
                    posts = await processFeedsInRedux( 20, filteredPostsInRedux );
                } else
                {
                    let uniquePosts : AV.IPost[] = _.uniqBy( userFeeds, 'id' );
                    let currentEntityId = props?.id;
                    uniquePosts = uniquePosts?.filter( ( x ) => x?.owner?.id === currentEntityId );

                    if ( uniquePosts.length === 0 )
                    {
                        let objEntity = {
                            Entity: props?.userInfo,
                            Type: props?.entityType,
                            FollowerList: [],
                        };
                        posts = await fetchFeedPosts( objEntity );
                        if ( posts.length == 0 )
                        {
                            setProcessedAllPosts( true );
                        }
                    }
                    else
                    {
                        setProcessedAllPosts( true );
                    }
                }
            }

            let returnData = listToTree( posts );

            returnData = returnData.sort( ( a, b ) =>
            {
                const dateA = new Date( a?.createDate ).getTime();
                const dateB = new Date( b?.createDate ).getTime();
                return dateB - dateA;
            } );

            // Dispatch the new posts to Redux, appending them to existing posts
            dispatch( setFeeds( [ ...userFeeds, ...returnData ] ) );

            return returnData || [];
        } catch ( error )
        {
            console.error( 'Error fetching posts:', error );
            return [];
        }
    };
    const processFeedsInRedux = async ( count, remainingPostsInRedux ) =>
    {
        let PostList = [];
        console.log( "Processing remaining posts::::::::::::::::" );
        // Sort posts by Postdate in descending order
        remainingPostsInRedux = remainingPostsInRedux.sort( ( a, b ) =>
        {
            return new Date( b.Postdate ) - new Date( a.Postdate );
        } );

        // Step 2: Take top 'count' posts
        const topPosts = remainingPostsInRedux.slice( 0, count );

        // Step 3: Group by Owner.id and collect PostIds in arrays
        const groupedByOwner = topPosts.reduce( ( acc, post ) =>
        {
            // Use the 'id' field from the Owner object as the key for grouping
            const ownerId = post.Owner.id;

            // If the owner doesn't exist in the accumulator, initialize it
            if ( !acc[ ownerId ] )
            {
                acc[ ownerId ] = {
                    Owner: post.Owner,  // Store the entire Owner object
                    PostIds: []             // Initialize the PostIds array
                };
            }

            // Push the PostId into the PostIds array for this owner
            acc[ ownerId ].PostIds.push( post.PostId );
            return acc;
        }, {} );

        // Step 4: Convert the grouped object into an array and remove duplicates from PostIds
        const result = Object.values( groupedByOwner ).map( ( { Owner, PostIds } ) => ( {
            Owner,                           // Entire Owner object
            PostIds: [ ...new Set( PostIds ) ]    // Remove duplicates by converting to Set and back to array
        } ) );


        let processingPost = [];
        for ( const post of result )
        {
            try
            {
                let { Owner, PostIds } = post;

                // Await the post processing function
                const posts = await processFeeds( Owner, PostIds );

                if ( posts != null )
                {
                    PostList.push( ...posts );  // Add the new posts to the PostList
                }

                processingPost.push( post ); // Track the post as processed

                // Exit early if we have more than 20 posts
                if ( PostList.length > 20 )
                {
                    console.log( "PostList has more than 20 posts, returning the list." );
                    return PostList;
                }
            } catch ( error )
            {
                console.error( "Error processing post:", error );
                // Optionally, handle the error (e.g., retry logic or skip this post)
            }
        }
        console.log( "Remaining posts in Redux before filter:", remainingPostsInRedux.length );
        console.log( "processingPost posts in Redux before filter:", processingPost.length );
        // Filter out processed posts from remainingPosts
        let remainingFilteredPostsInRedux = remainingPostsInRedux.filter( entity =>
            !processingPost.some( toProcessEntity => toProcessEntity.PostIds.includes( entity.PostId ) )
        );


        console.log( "Remaining posts in Redux:", remainingFilteredPostsInRedux.length );

        // Dispatch the updated remaining posts to Redux
        store.dispatch( setRemainingPosts( remainingFilteredPostsInRedux ) );


        return PostList;  // In case the loop finishes without returning early

    };
    return {
        fetchPosts,
        processedAllPosts,
    };
};

export default useFeeds;
