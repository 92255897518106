import React, { useEffect, useState, useRef } from "react";
import { VStack, HStack, Pressable, Text } from 'native-base'
import Url from "../../assets/images/Url.png";
import photos from "../../assets/images/photos.png";
import Dropdown from "../../assets/images/dropdown.svg";
import cemaraIcon from "../../assets/images/camera.png";
import cloudIcon from "../../assets/images/IcloudIcon.png";
import dropboxIcon from "../../assets/images/DropboxIcon.png";
import onedriveIcon from "../../assets/images/onedriveIcon.png";
import googleDocsIcon from "../../assets/images/googledocsIcon.png";
import OneDrive from "./OneDrive";
import DropboxChooser from 'react-dropbox-chooser';


const ExternalLink = ( { setIsOpenBox, setIsOpenCemera, imageInputRef, menuRefs, handleOpenPicker, setExternalLink, HandleAddOneDrive, setShowExternalLinkInput, showExternalLinkInput, HandleAddDropBoxLink
} ) =>
{

    return (
        <>
            <VStack
                ref={ menuRefs }
                width={ '342px' }
                position={ 'absolute' }
                bottom={ '60px' }
                left={ '20px' }
                height={ '188px' }
                shadow={ 2 }
                background={ 'white' }
                zIndex={ 999 }
                borderWidth={ '1px' }
                borderStyle={ 'solid' }
                borderColor={ 'gray.500' }
                borderRadius={ '6px' }
            >
                <HStack space={ '40px' } margin={ '24px' }>
                    <Pressable onPress={ () =>
                    {
                        setIsOpenBox( false );
                        setIsOpenCemera( true );
                    } }>
                        <VStack space={ 3 }>
                            <img src={ cemaraIcon } height={ '32px' } width={ '32px' } />
                            <Text>Camera</Text>
                        </VStack>
                    </Pressable>
                    <Pressable onPress={ () =>
                    {
                        setIsOpenBox( false );
                        imageInputRef?.current?.click();
                    } }>
                        <VStack space={ 3 }>
                            <img src={ photos } height={ '32px' } width={ '32px' } />
                            <Text>Photos</Text>
                        </VStack>
                    </Pressable>
                    <OneDrive setExternalLink={ setExternalLink } HandleAddOneDrive={ HandleAddOneDrive } />
                    {/*<Pressable onPress={ () => { setIsOpenBox( false ); setShowExternalLinkInput( !showExternalLinkInput ); setExternalLink( '' ); } }>*/ }
                    {/*    <VStack space={ 3 }>*/ }
                    {/*        <img src={ cloudIcon } height={ '32px' } width={ '32px' } />*/ }
                    {/*        <Text>i Cloud</Text>*/ }
                    {/*    </VStack>*/ }
                    {/*</Pressable>*/ }
                    <Pressable onPress={ () => { setIsOpenBox( false ); setShowExternalLinkInput( !showExternalLinkInput ); setExternalLink( '' ); } }>
                        <VStack space={ 3 }>
                            <img src={ Url } height={ '32px' } width={ '32px' } />
                            <Text>Url</Text>
                        </VStack>
                    </Pressable>
                </HStack>
                <HStack space={ '40px' } marginBottom={ '24px' } marginLeft={ '24px' } marginRight={ '24px' } marginTop={ 0 }>
                    <Pressable onPress={ () =>
                    {
                        setIsOpenBox( false );
                        handleOpenPicker();
                    } }>
                        <VStack space={ 3 }>
                            <img src={ googleDocsIcon } height={ '32px' } width={ '32px' } />
                            <Text>Docs</Text>
                        </VStack>
                    </Pressable>
                    <Pressable>
                        <DropboxChooser
                            appKey={ '7b1iy85b4spix3q' }
                            success={ files => HandleAddDropBoxLink( files ) }
                            cancel={ () => setIsOpenBox( false ) }
                            multiselect={ true }
                            extensions={ [ '.mp4', '.jpg', '.png', '.jpeg', '.gif' ] } >
                            <VStack space={ 3 }>
                                <img src={ dropboxIcon } height={ '32px' } width={ '32px' } />
                                <Text>Dropbox</Text>
                            </VStack>
                        </DropboxChooser>
                    </Pressable>

                </HStack>
            </VStack>
        </>
    )
}

export default ExternalLink;